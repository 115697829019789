import { authHeader } from "../../../../auth";

export default {
    data() {
        return {
            childCategoryList: [],
            siteUrl: this.$serverURL + this.$api.childCategoryMeta.childCategoryMetaURL,
            categoryId: "",
            categoryName: "",
            subcategoryId: "",
            subcategoryName: "",
            childCategoryId: "",
            childCategoryName: "",
            keywords: "",
            description: ""
        }
    },
    async created() {
        document.title = "HGS - Child Category Meta";
        await this.getProductList(this.siteUrl);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getProductList: async function (url) {
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.childCategoryList = await response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        updateMetaKeyword: async function() {
            try {
                if (!this.keywords) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter keywords!"
                    });
                    return false;
                }
                let data = {
                    categoryId: this.categoryId,
                    categoryName: this.categoryName,
                    subcategoryId: this.subcategoryId,
                    subcategoryName: this.subcategoryName,
                    childCategoryId: this.childCategoryId,
                    childCategoryName: this.childCategoryName,
                    metaKeyword: this.keywords,
                    metaDescription: this.description,
                    metaTitle: this.metaTitle,
                };
                let config = {
                    method: 'POST',
                    url: this.siteUrl,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                await this.getProductList(this.siteUrl);
                this.categoryId = "";
                this.categoryName = "";
                this.subcategoryId = "";
                this.subcategoryName = "";
                this.childCategoryId = "",
                this.childCategoryName = "",
                this.metaKeyword = "";
                this.metaDescription = "";
                this.metaTitle = "";
            }
            catch(error) {
                console.log(error);
            }
        },
        updateMetaDescription: async function() {
            try {
                if (!this.description) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter description!"
                    });
                    return false;
                }
                let data = {
                    categoryId: this.categoryId,
                    categoryName: this.categoryName,
                    subcategoryId: this.subcategoryId,
                    subcategoryName: this.subcategoryName,
                    childCategoryId: this.childCategoryId,
                    childCategoryName: this.childCategoryName,
                    metaKeyword: this.keywords,
                    metaDescription: this.description,
                    metaTitle: this.metaTitle,
                };
                let config = {
                    method: 'POST',
                    url: this.siteUrl,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                await this.getProductList(this.siteUrl);
                this.categoryId = "";
                this.categoryName = "";
                this.subcategoryId = "";
                this.subcategoryName = "";
                this.childCategoryId = "",
                this.childCategoryName = "",
                this.keywords = "";
                this.description = "";
                this.metaTitle = "";
            }
            catch(error) {
                console.log(error);
            }
        },
        updateMetaTitle: async function () {
            try {
                if (!this.metaTitle) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter meta title!"
                    });
                    return false;
                }
                let data = {
                    categoryId: this.categoryId,
                    categoryName: this.categoryName,
                    subcategoryId: this.subcategoryId,
                    subcategoryName: this.subcategoryName,
                    childCategoryId: this.childCategoryId,
                    childCategoryName: this.childCategoryName,
                    metaKeyword: this.keywords,
                    metaDescription: this.description,
                    metaTitle: this.metaTitle,
                };
                let config = {
                    method: 'POST',
                    url: this.siteUrl,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                await this.getProductList(this.siteUrl);
                this.categoryId = "";
                this.categoryName = "";
                this.subcategoryId = "";
                this.subcategoryName = "";
                this.childCategoryId = "",
                this.childCategoryName = "",
                this.keywords = "";
                this.description = "";
                this.metaTitle = "";
            }
            catch (error) {
                console.log(error);
            }
        },
        async updateModalValue(data) {
            this.categoryId = data.categoryId;
            this.categoryName = data.categoryName;
            this.subcategoryId = data.subcategoryId;
            this.subcategoryName = data.subcategoryName;
            this.childCategoryId = data.childCategoryId,
            this.childCategoryName = data.childCategoryName,
            this.keywords = data.metaKeyword;
            this.description = data.metaDescription;
            this.metaTitle = data?.metaTitle;
        }
    }
}