import { authHeader } from "../../../../auth";
import * as XLSX from "xlsx";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  components: {
    VPagination,
  },
  data() {
    return {
      productList: [],
      siteUrl: this.$serverURL + this.$api.product.productWithPageCountAPI,
      siteProductUrl: this.$serverURL + this.$api.product.productAPI,
      sheets: [],
      page: 1,
      limit: 10,
      totalPage: 0,
      searchValue: "",
      pageSizeDropDown: [10, 20, 50, 100, 500, 1000, 3000],
    };
  },
  async created() {
    document.title = "HGS - Products";
    // await this.getDataList(this.siteUrl);
    await this.getProductsWithLimit();
    var scripts = [
      "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
      "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
      "/core/assets/js/table-datatable.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });

    let style = document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href =
      "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
    document.head.appendChild(style);
  },
  methods: {
    priceShowHide: async function (data) {
      data.flag = !data.flag;
    },
    costShowHide: async function (data) {
      data.count = !data.count;
    },
    updatePrice: async function (data, index) {
      data.flag = !data.flag;
      let value = {
        price: data.price,
      };
      let config = {
        method: "PATCH",
        url:
          this.$serverURL +
          this.$api.product.productSinglePriceUpdate +
          "/" +
          data.id,
        data: value,
        headers: {
          Authorization: authHeader(),
        },
      };
      await this.$axios(config)
        .then(async (response) => {
          if (response.status == 200) {
            this.dataList[index]["price"] = data.price;
            this.$swal.fire({
              toast: true,
              position: "top-end",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              animation: false,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Failed! please try again.",
            });
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            text: error.response.data.message,
          });
        });
    },
    updateCost: async function (data, index) {
      data.count = !data.count;
      let value = {
        cost: data.cost,
      };
      let config = {
        method: "PATCH",
        url:
          this.$serverURL +
          this.$api.product.productSingleCostUpdate +
          "/" +
          data.id,
        data: value,
        headers: {
          Authorization: authHeader(),
        },
      };
      await this.$axios(config)
        .then(async (response) => {
          if (response.status == 200) {
            this.dataList[index]["cost"] = data.cost;
            this.$swal.fire({
              toast: true,
              position: "top-end",
              text: response.data.message,
              icon: "success",
              showConfirmButton: false,
              timer: 3000,
              animation: false,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Failed! please try again.",
            });
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            text: error.response.data.message,
          });
        });
    },
    getExcelProducts: async function () {
      let params = "?page=1&limit=7000";
      if (this.searchValue) {
        params += `&search=${this.searchValue}`;
        this.searchValue = "";
      }
      let config = {
        method: "GET",
        url: this.siteUrl + params,
        headers: {
          Authorization: authHeader(),
        },
      };
      let productList;
      await this.$axios(config)
        .then(async (response) => {
          if (response.data.statusCode == 200) {
            productList =
              response.data.products?.data.length > 0
                ? response.data.products.data
                : [];
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Failed! please try again.",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.sheets = productList.map((el) => {
        return {
          Name: el.name,
          Sku: el.sku,
          Price: el.price,
          Cost: el.cost,
          Quantity: el.stock,
          Weight: el.weight,
          "Brand Name": el.brandName,
          Category: el.parentCategoryName,
          "Sub Category": el?.subCategoryName ?? "",
          "Child Category": el?.childCategoryName ?? "",
          title: el.title || null,
          urlSlug: el.urlSlug || null,
          productCanonical: el.productCanonical || null,
          createdAT: el.createdAt,
        };
      });
      const data = XLSX.utils.json_to_sheet(this.sheets);
      const wb = XLSX.utils.book_new();
      const date = new Date().toLocaleString();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "product-report(" + date + ").xlsx");
    },
    deleteProduct: async function (id, name = "") {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              let config = {
                method: "DELETE",
                url: this.siteProductUrl + "/" + id,
                headers: {
                  Authorization: authHeader(),
                },
              };
              await this.deleteDataToBackend(config);

              // add log to database
              const firstName = localStorage.getItem("firstname");
              const lastName = localStorage.getItem("lastname");
              const configLogData = {
                moduleName: "product",
                action: "delete",
                moduleId: id,
                logMessage: `${name} deleted by ${firstName} ${lastName}`,
              };
              await this.createUserLog(configLogData);
              // await this.getDataList(this.siteUrl);
              await this.getProductsWithLimit();
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                text: "Something went wrong, please try agian later." + error,
              });
            }
          }
        });
    },
    getAllProducts: async function (config) {
      this.productList = [];
      await this.$axios(config)
        .then(async (response) => {
          if (response.data.statusCode == 200) {
            this.productList =
              response.data.products?.data.length > 0
                ? response.data.products.data
                : [];
            this.totalPage = response.data.products.pageCount;
            this.productList.map((el) => {
              el.flag = false;
              el.count = false;
            });
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Failed! please try again.",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProductsWithLimit: async function () {
      // this.scrollToTop();
      let params = "?page=" + this.page + "&limit=" + this.limit;
      if (this.searchValue) {
        params += `&search=${this.searchValue}`;
        //this.searchValue = "";
      }
      let config = {
        method: "GET",
        url: this.siteUrl + params,
        headers: {
          Authorization: authHeader(),
        },
      };
      await this.getAllProducts(config);
    },
    searchProduct: async function () {
      this.page = 1;
      await this.getProductsWithLimit();
    },
    searchProductClear: async function () {
      this.page = 1;
      this.searchValue = "";
      await this.getProductsWithLimit();
    },
  },
};
