<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Shipping Cutoff Time CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Shipping Cutoff Time CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Shipping Cutoff Time CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-8">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <label class="form-label">Title</label>
                    <input
                      type="text"
                      v-model="pageCMSData.title"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter title text"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Sub Title</label>
                    <input
                      type="text"
                      v-model="pageCMSData.subTitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter sub title text"
                    />
                  </div>
                  <div class="d-grid">
                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3">
                    <label class="form-label">
                      Image:
                      <strong>[Preferred Image Size: 820X372, Max Upload Limit: 1MB]</strong>
                    </label>
                    <input
                      class="form-control"
                      type="file"
                      @change="uploadImage"
                      ref="addImage"
                      id="formFile"
                    />
                  </div>
                  <div class="mb-3">
                    <div class="col-md-12" v-if="previewImage || pageCMSData.image">
                      <img v-if="previewImage" :src="previewImage" height="82" width="150" />
                      <img v-else :src="pageCMSData.image" height="82" width="150" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Fedx Cutoff Times List</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="d-flex flex-row-reverse mb-3">
              <button
                class="btn btn-sm btn-primary"
                style="margin-right: 15px; float:right"
                @click="addMore"
              >
                <i class="bx bxs-plus-square"></i>Add More
              </button>
            </div>
            <!-- {{timeValue}} -->
            <div class="row">
              <div class="col-lg-12">
                <div class="table-responsive">
                  <table id="example" class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>SL</th>
                        <th>Title</th>
                        <th>Sub Title</th>
                        <th>Time</th>
                        <th>Default</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in timeList" :key="index">
                        <td>{{index + 1}}</td>
                        <td>
                          <div class="m-2">
                            <input
                              type="text"
                              class="form-control"
                              id="inputPageHeaderPageName"
                              placeholder="Enter title text"
                              v-model="timeValue[index].title"
                              :disabled="data.noUpdate"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="m-2">
                            <input
                              type="text"
                              class="form-control"
                              id="inputPageHeaderPageName"
                              placeholder="Enter sub title text"
                              v-model="timeValue[index].subTitle"
                              :disabled="data.noUpdate"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="m-2">
                            <input
                              type="datetime-local"
                              class="form-control"
                              id="inputPageHeaderPageName"
                              v-model="timeValue[index].time"
                              :min="getCurrentLocalDate"
                              :disabled="data.noUpdate"
                            />
                          </div>
                        </td>
                        <td>
                          <button
                            :disabled="checkHasKey(data) || data.default"
                            class="btn btn-sm btn-primary m-2"
                            @click="handleDefault(data)"
                          >{{(data.isDefault && !checkHasKey(data)) ? 'Selected' : 'Default'}}</button>
                        </td>
                        <td>
                          <div class="d-flex order-actions m-2">
                            <a
                              href="javascript:void(0);"
                              class="ms-3"
                              :class="checkHasKey(data) ? 'info-disabled btn-disabled' : 'info'"
                              @click="setSingleTimeValue(data, index)"
                            >
                              <i class="bx bxs-edit"></i>
                            </a>
                            <a
                              href="javascript:void(0);"
                              class="ms-3"
                              :class="checkHasKey(data) ? 'danger-disabled btn-disabled' : 'danger'"
                              @click="handleDelete(data)"
                            >
                              <i class="bx bxs-trash"></i>
                            </a>
                            <a
                              v-if="checkHasKey(data) && hasIteminTimeListMoreThanOne"
                              href="javascript:void(0)"
                              @click="removeFromTimeList(index)"
                              class="ms-3 danger"
                            >
                              <i class="bx bx-x"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>SL</th>
                        <th>Title</th>
                        <th>Sub Title</th>
                        <th>Time</th>
                        <th>Default</th>
                        <th>Actions</th>
                      </tr>
                    </tfoot>
                  </table>
                  <div class="d-flex flex-row-reverse mb-3">
                    <button class="btn btn-primary" v-if="checkForNewItem" @click="createTimes">Save</button>
                    <button class="btn btn-primary" v-if="checkForUpdate" @click="updateTimes">Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/fedx-cutoff-time.js"></script>

<style scoped>
.btn-disabled {
  pointer-events: none;
}
/* primary */
.info {
  border-color: #2196f3;
  color: dodgerblue !important;
}

.info-disabled {
  background-color: #d6dadd;
  border-color: #62b1f1;
  color: #62b1f1 !important;
}

.info:hover {
  background: #2196f3;
  color: white !important;
}

.danger {
  border-color: #f32121;
  color: rgb(255, 38, 30) !important;
}

.danger-disabled {
  background-color: #d6dadd;
  border-color: #f58080;
  color: #f58080 !important;
}

.danger:hover {
  background: #f32121;
  color: white !important;
}

.danger .btn-disabled {
  border-color: #d87777;
  color: rgb(255, 38, 30) !important;
}
</style>