import { authHeader } from "../../../auth";

export default {
    name: "EditLocation",
    data() {
        return {
            id: '',
            location: '',
            address: '',
            urlSlug: '',
            contactNumber: '',
            email: '',
            timeFrame: '',
            mapLink: null
        }
    },
    async created() {
        document.title = "HGS - Location";
    },
    mounted() {
        if(this.$route.params.id != undefined) {
            this.fetchEditData(this.$route.params.id);
        }
    },
    methods: {
        async fetchEditData(e) {
            let jsonData = JSON.parse(e);
            this.id = jsonData['id'];
            this.location = jsonData['location'];
            this.urlSlug = jsonData['urlSlug'];
            this.address = jsonData['address'];
            this.contactNumber = jsonData['contactNumber'];
            this.email = jsonData['email'];
            this.timeFrame = jsonData['timeFrame'];
            this.mapLink = jsonData['mapLink'];
        },
        update: async function() {
            let data = {
                location: this.location,
                urlSlug: this.urlSlug,
                address: this.address,
                contactNumber: this.contactNumber,
                email: this.email,
                timeFrame: this.timeFrame,
                mapLink: this.mapLink
            };
            let config = {
                method: "PATCH",
                url: this.$serverURL + this.$api.location.requestURL + '/' + this.id,
                data: data,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Successfully updated data!"
                    });
                }
                this.$router.push("/location-list");
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    },
    watch: {
        address: function(newValue) {
            this.urlSlug = this.createUrlSlug(newValue);
        }
    }
}