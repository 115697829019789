<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Product Reorder List
            </li>
          </ol>
        </nav>
        <div class="ms-auto">
          <div class="btn-group">
            <button type="button" class="btn btn-secondary btn-sm m-1" @click="getExcelProducts">
              <i class="bx bxs-plus-square"></i>Export</button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Product Reorder List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Brand</th>
                  <th>Featured Image</th>
                  <th>Stock</th>
                  <th>Quantity Reorder</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.name }}</td>
                  <td>{{ data.brandName }}</td>
                  <td>
                    <img v-if="data.featuredImage" :src="$imageURL + 'productImage/' + data.featuredImage" alt=""
                      height="90" width="110" />
                    <img v-else src="/core/assets/images/400.png" alt="" height="90" width="110" />
                  </td>
                  <td>{{ data.stock }}</td>
                  <td>{{ data.quantityReorder }}</td>
                  <td>{{ data.price }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Brand</th>
                  <th>Featured Image</th>
                  <th>Stock</th>
                  <th>Quantity Reorder</th>
                  <th>Price</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/product-reorder.js'></script>


<style scoped>
  .edit-button {
    background-color: #17a00e;
  }

  .delete-button {
    background-color: #f41127;
  }
</style>