import { authHeader } from "../../../../auth";
export default {
    data() {
        return {
            setURL: this.$serverURL + this.$api.ComponentCms.shippingCmsURL,
            pageCMSData: [],
            imagePath: this.$imageURL + 'shipping-methods/',
            previewImage1: '',
            previewImage2: '',
            previewImage3: '',
            fedexDisplayName: "",
            fedexImage: "",
            upsDisplayName: "",
            upsImage: "",
            localDisplayName: "",
            localImage: "",
        }
    },
    async mounted() {
        document.title = "HGS - Shipping CMS";
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        uploadImage(event) {
            this.pageCMSData.fedexImage = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage1 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadImage2(event) {
            this.pageCMSData.upsImage = event.target.files[0];
            let input = this.$refs.addImage2;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage2 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadImage3(event) {
            this.pageCMSData.localImage = event.target.files[0];
            let input = this.$refs.addImage3;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage3 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async update() {
            try {
                let formData = new FormData();
                formData.append('fedexDisplayName', this.pageCMSData.fedexDisplayName);
                formData.append('upsDisplayName', this.pageCMSData.upsDisplayName);
                formData.append('localDisplayName', this.pageCMSData.localDisplayName);
                formData.append('fedexImage', this.pageCMSData.fedexImage);
                formData.append('upsImage', this.pageCMSData.upsImage);
                formData.append('localImage', this.pageCMSData.localImage);
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.createUpdateCMS(config);
                this.previewImage2 = '';
                this.previewImage1 = '';
                this.previewImage3 = '';
                this.fedexDisplayName = "";
                this.fedexImage = "";
                this.upsDisplayName = "";
                this.upsImage = "";
                this.localDisplayName = "";
                this.localImage = "";
                this.$refs.addImage.value = ""
                this.$refs.addImage2.value = ""
                this.$refs.addImage3.value = ""
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        }
    }
}