<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Import Product
            </li>
          </ol>
        </nav>
      </div>
      <div class="text-center">
        <a
          :href="$serverURL + 'template/product-import.xlsx'"
          class="btn btn-info text-white"
          download
          ><i class="fas fa-file-download"> </i> Download Import Product
          Template
        </a>
        <form
          ref="resetForm"
          class="
            d-flex
            justify-content-center
            align-items-center
            gap-3
            mt-4
            mb-4
          "
          @submit.prevent="excelImport($event)"
        >
          <input
            type="file"
            class="form-control bulk_custom_file_input"
            accept=".xlsx, .xls"
          />
          <button type="submit" class="btn btn-success">Upload</button>
        </form>
      </div>
      <div v-if="productList.length > 0" class="card">
        <div class="card-body">
          <h6 class="mb-0 text-uppercase">Product List</h6>
          <hr />
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Sku</th>
                  <th>Cost</th>
                  <th>Price</th>
                  <th>Stock</th>
                  <th>Quantity Reorder</th>
                  <th>Weight</th>
                  <th>BrandName</th>
                  <th>Category Name</th>
                  <th>Sub Category Name</th>
                  <th>Child Category Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in productList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.Name }}</td>
                  <td>{{ data.Sku }}</td>
                  <td>{{ data.Cost }}</td>
                  <td>{{ data.Price }}</td>
                  <td>{{ data.Stock }}</td>
                  <td>{{ data.QuantityReorder }}</td>
                  <td>{{ data.Weight }}</td>
                  <td>{{ data.BrandName }}</td>
                  <td>{{ data.ParentCategoryName }}</td>
                  <td>{{ data.SubCategoryName }}</td>
                  <td>{{ data.ChildCategory }}</td>
                  <td>{{ data.Status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-end mt-3">
            <button class="btn btn-primary" @click="confirmExcelImport">
              Confirm Import Product List
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/product-bulk-upload.js">
</script>

<style scoped>
.bulk_custom_file_input {
  max-width: 500px;
  width: 100%;
}
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>
