<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Blog Meta
                        </li>
                    </ol>
                </nav>
            </div>
            <h6 class="mb-0 text-uppercase">Blog Meta List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Title</th>
                                    <th>Meta Title</th>
                                    <th>Meta Keyword</th>
                                    <th>Meta Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in blogList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.title }}</td>
                                    <td>
                                        <span class="metaMod" data-bs-toggle="modal" data-bs-target="#addTitleModal" @click="updateModalValue(data)">
                                            {{ data.metaTitle ? data.metaTitle : 'No Keywords Found' }}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="metaMod" data-bs-toggle="modal" data-bs-target="#addModal" @click="updateModalValue(data)">
                                            {{ data.metaKeyword ? data.metaKeyword : 'No Keywords Found' }}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="metaMod" data-bs-toggle="modal" data-bs-target="#addDesModal" @click="updateModalValue(data)">
                                            {{ data.metaDescription ? data.metaDescription.slice(0, 20) + ' ...' : 'No Meta Description Found' }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Title</th>
                                    <th>Meta Title</th>
                                    <th>Meta Keyword</th>
                                    <th>Meta Description</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
        <form @submit.prevent="updateMetaKeyword" enctype="multipart/form-data">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addModalLabel">Add Meta Keywords ( {{ blogTitle }} )</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <label class="form-label">Keywords:</label>
                        <textarea class="form-control mb-1" type="text" v-model="keywords" placeholder="Enter Keywords" aria-label="default input example" />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal fade" id="addTitleModal" tabindex="-1" aria-labelledby="addTitleModalLabel" aria-hidden="true">
        <form @submit.prevent="updateMetaTitle" enctype="multipart/form-data">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addTitleModalLabel">Add Meta Title ( {{ blogTitle }} )</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <label class="form-label">Title:</label>
                        <textarea class="form-control mb-1" type="text" v-model="metaTitle" placeholder="Enter Title" aria-label="default input example" />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal fade" id="addDesModal" tabindex="-1" aria-labelledby="addDModalLabel" aria-hidden="true">
        <form @submit.prevent="updateMetaDescription" enctype="multipart/form-data">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addModalLabel">Add Meta Description ( {{ blogTitle }} )</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <label class="form-label">Description:</label>
                        <textarea class="form-control mb-1" type="text" v-model="description" placeholder="Enter Description" aria-label="default input example" />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script src='../js/blogMeta.js'></script>
<style scoped>
.metaMod {
    color: black;
    cursor: pointer;
    text-decoration: none;
    display: block;
}

.metaMod:hover {
    cursor: pointer;
    color: #09f;
}
</style>
