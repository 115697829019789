import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "About Content",
    data() {
        return {
            editor: ClassicEditor,
            imagePath: this.$imageURL + 'about/',
            aboutContentList: [],
            singleAboutContent: {},
            previewImage: '',
            updatePreviewImage: '',
            aboutContentName: "",
            featuredImage: "",
            imagePositions: ['Left', 'Right'],
            imagePosition: '',
            title: '',
            subTitle: '',
            description: '',
            currentImage: '',
            setURL: this.$serverURL + this.$api.aboutUs.aboutUsContent
        }
    },
    async created() {
        document.title = "HGS - About Content";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        uploadFeaturedImage: function (event) {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateFeaturedImage: function (event) {
            this.singleAboutContent.image = event.target.files[0];
            let input = this.$refs.updateImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.updatePreviewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        addAboutContent: async function () {

            if (!this.title || !this.subTitle || !this.description || !this.featuredImage || !this.imagePosition) {

                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Content title, subtitle, image, image position and description!"
                });
            }
            else {
                let formData = new FormData();
                formData.append('title', this.title);
                formData.append('subTitle', this.subTitle);
                formData.append('image', this.featuredImage);
                formData.append('imagePosition', this.imagePosition);
                formData.append('description', this.description);
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data",
                    }
                };
                await this.postDataToBackend(config);
                this.title = '';
                this.subTitle = '';
                this.$refs.addImage.value = null;
                this.featuredImage = '';
                this.imagePosition = '';
                this.description = '';
                this.previewImage = '';
                await this.getDataList(this.setURL);
            }
        },
        editAboutContent: async function (data) {
            this.singleAboutContent = data;
            this.currentImage = this.singleAboutContent.image
        },
        updateAboutContent: async function () {
            if (!this.singleAboutContent.title || !this.singleAboutContent.subTitle || !this.singleAboutContent.description || !this.singleAboutContent.image || !this.singleAboutContent.imagePosition) {

                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Content title, subtitle, image, image position and description!"
                });
            }
            else {
                let formData = new FormData();
                formData.append('title', this.singleAboutContent.title);
                formData.append('subTitle', this.singleAboutContent.subTitle);
                formData.append('image', this.singleAboutContent.image);
                formData.append('imagePosition', this.singleAboutContent.imagePosition);
                formData.append('description', this.singleAboutContent.description);
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.aboutUs.singleAboutUsContent + this.singleAboutContent.id,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data",
                    }
                };
                await this.updateDataToBackend(config);
                this.$refs.updateImage.value = null;
                this.singleAboutContent = {};
                this.currentImage = '';
                this.updatePreviewImage = '';
                this.previewImage = '';
                await this.getDataList(this.setURL);
            }
        },
        deleteAboutContent: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.aboutUs.singleAboutUsContent + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })

        }
    }
}