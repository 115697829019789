<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            About Content List
                        </li>
                    </ol>
                </nav>

                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                            <i class="bx bxs-plus-square"></i>Add New About Content
                        </button>
                    </div>
                    <!-- Add Modal -->

                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel"
                        aria-hidden="true">
                        <form @submit.prevent="addAboutContent" enctype="multipart/form-data">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add About Content</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">About Content Title:</label>
                                        <input class="form-control mb-1" type="text" v-model="title"
                                            placeholder="About Content Title" aria-label="default input example" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">About Content Subtitle:</label>
                                        <input class="form-control mb-1" type="text" v-model="subTitle"
                                            placeholder="About Content Subtitle" aria-label="default input example" />
                                    </div>
                                    <div class="form-group m-3">
                                        <label for="exampleInputEmail1">Image: <strong>[Preferred Image Size: 350X268, Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" name="featuredImage" id="featuredImage"
                                            aria-describedby="imageHelp" ref="addImage" accept="image/*"
                                            @change="uploadFeaturedImage" />
                                        
                                    </div>
                                    <div class="form-group m-3">
                                        <img
                                            v-if="previewImage"
                                            :src="previewImage"
                                            height="70"
                                            width="100"
                                        />
                                    </div>
                                    <div class="form-group m-3">
                                        <label class="form-label">Select Image Position</label>
                                        <select v-model="imagePosition" class="form-select mb-3" 
                                        >
                                        <option v-for="(data, index) in imagePositions" :key="index"
                                            :value="data">
                                            {{ data }}
                                        </option>
                                        </select>
                                    </div>
                                    <div class="form-group m-3">
                                        <label>About Content Description</label>
                                        <ckeditor :editor="editor" v-model="description"
                                            :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Add Modal -->
                    <!-- Edit Role Modal -->

                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel"
                        aria-hidden="true">
                        <form @submit.prevent="updateAboutContent">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Update About Content</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">About Content Title:</label>
                                        <input class="form-control mb-1" type="text" v-model="singleAboutContent.title"
                                            placeholder="About Content Title" aria-label="default input example" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">About Content Subtitle:</label>
                                        <input class="form-control mb-1" type="text" v-model="singleAboutContent.subTitle"
                                            placeholder="About Content Subtitle" aria-label="default input example" />
                                    </div>
                                    <div class="form-group m-3">
                                        <label for="exampleInputEmail1">Current Image: &ensp;</label>
                                        <img :src="imagePath + currentImage" alt="" height="90" width="110" />
                                    </div>
                                    <div class="form-group m-3">
                                        <label for="exampleInputEmail1">Image: <strong>[Preferred Image Size: 350X268, Max Upload Limit: 1MB]</strong></label>
                                        <input class="form-control" type="file" name="featuredImage" id="featuredImage"
                                            aria-describedby="imageHelp" ref="updateImage" accept="image/*"
                                            @change="updateFeaturedImage" />
                                    </div>
                                    <div class="form-group m-3">
                                        <img
                                            v-if="updatePreviewImage"
                                            :src="updatePreviewImage"
                                            height="70"
                                            width="100"
                                        />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Select Image Position</label>
                                        <select v-model="singleAboutContent.imagePosition" class="form-select mb-3" 
                                        >
                                        <option v-for="(data, index) in imagePositions" :key="index"
                                            :value="data">
                                            {{ data }}
                                        </option>
                                        </select>
                                    </div>
                                    <div class="form-group m-3">
                                        <label>About Content Description</label>
                                        <ckeditor :editor="editor" v-model="singleAboutContent.description"
                                            :config="editorConfig"></ckeditor>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Edit Role Modal -->
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">About Content List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Title</th>
                                    <th>Subtitle</th>
                                    <th>Image</th>
                                    <th>Image Position</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{index+1}}</td>
                                    <td>{{data.title}}</td>
                                    <td>{{data.subTitle}}</td>
                                    <td>
                                        <img :src="imagePath + data.image" alt="" height="90" width="110" />
                                    </td>
                                    <td>{{data.imagePosition}}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" v-on:click="editAboutContent(data)"
                                                data-bs-toggle="modal" data-bs-target="#editModal"
                                                class="edit-button"><i class="bx bxs-edit text-white"></i></a>
                                            <a href="javascript:void(0);" v-on:click="deleteAboutContent(data.id)"
                                                class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Title</th>
                                    <th>Subtitle</th>
                                    <th>Image</th>
                                    <th>Image Position</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/about-content.js'></script>


<style scoped>

    .edit-button {
        background-color: #17a00e;
    }

    .delete-button {
        background-color: #f41127;
    }
</style>