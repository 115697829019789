import { authHeader } from "../../../../auth";
import * as XLSX from 'xlsx';

export default {
    name: "BulkPriceUpdate",
    data() {
        return {
            productList: [],
            dumpID: null,
            siteUrl: this.$serverURL + this.$api.product.productAPI,
            sheets: []
        }
    },
    async created() {
        document.title = "HGS - Bulk Product Price";
        await this.getDataList(this.siteUrl);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        excelImport: async function () {
            let excelFile = await event.target.children[0].files ? event.target.children[0].files[0] : null;
            if (!excelFile) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please choose file!"
                });
            }
            else {
                const formData = new FormData();
                formData.append('excelFile', excelFile);
                const headers = { 'Content-Type': 'multipart/form-data' };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.product.bulkProductPriceUpdateUpload,
                    header: headers,
                    data: formData
                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        console.log(response);
                        this.productList = response.data.product_price_dump.stock;
                        this.dumpID = response.data.product_price_dump.stockID;
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                        this.$refs.resetForm.reset();
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again.Error:" + error
                    });
                });
            }
        },
        confirmExcelImport: async function () {
            this.$swal.fire({
                title: 'Are you sure to change bulk product price?',
                text: "",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    if (!this.dumpID) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Please Upload file again!"
                        });
                    } else {

                        try {
                            let data = {
                                stockID: this.dumpID
                            }
                            let config = {
                                method: "POST",
                                url: this.$serverURL + this.$api.product.confirmBulkProductPriceUpload,
                                data: data,
                                header: authHeader()
                            };
                            await this.$axios(config).then((response) => {
                                if (response.data.statusCode == 200) {
                                    this.productList = [];
                                    this.dumpID = null;
                                    this.$swal.fire({
                                        toast: true,
                                        position: 'top-end',
                                        text: response.data.message,
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: 3000,
                                        animation: false,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                                        }
                                    });
                                    this.$router.push('/product-list');
                                }
                            }).catch(error => {
                                this.$swal.fire({
                                    icon: "error",
                                    text: "Something went wrong. Please try again! Error:" + error,
                                });
                            });
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong. Please try again! Error:" + error,
                            });
                        }
                    }
                }
            })
        },
        downloadData: async function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    'Id': el.id,
                    'Brand': el.brandName,
                    'Name': el.name,
                    'SKU': el.sku,
                    'Cost': el.cost,
                    "Price": el.price,
                    'Quantity': el.stock
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets);
            const wb = XLSX.utils.book_new();
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            XLSX.writeFile(wb, 'bulk_price_update_' + date + '.xlsx');
            this.sheets = [];
        }
    }
}
