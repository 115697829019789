<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Footer Branding</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Footer Branding</li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="updateFooterBrandingCms">
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Footer Branding</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12">
                  <div class="border border-3 p-4 rounded mb-4">

                    <div class="row mb-3">
                      <div class="col-md-6">
                        <label for="topLogo" class="form-label">
                          Top Logo: <br />
                          <strong>[Preferred Image Size: 150X40, Max Upload Limit: 1MB]</strong>
                        </label>
                        <input
                          class="form-control mb-2"
                          type="file"
                          name="top_logo_image"
                          id="topLogo"
                          ref="topLogo"
                          aria-describedby="imageHelp"
                          accept="image/*"
                          @change="uploadTopLogo($event)"
                        />
                      </div>
                      <div class="col-md-6">
                          <img v-if="previewTopLogo" :src="previewTopLogo" height="40" width="150" />
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-md-12">
                        <label class="form-label">Top Logo Link</label>
                        <input
                          type="text"
                          v-model="topLogoLink"
                          class="form-control"
                          placeholder="Top Logo Link"
                        />
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-md-6">
                        <label for="contactIconImage" class="form-label">
                          Contact Icon Image: <br />
                          <strong>[Preferred Image Size: 50X50, Max Upload Limit: 1MB]</strong>
                        </label>
                        <input
                          class="form-control mb-2"
                          type="file"
                          name="contact_icon_image"
                          id="contactIconImage"
                          ref="contactIconImage"
                          aria-describedby="imageHelp"
                          accept="image/*"
                          @change="uploadContactIconImage($event)"
                        />
                      </div>
                      <div class="col-md-6">
                          <img v-if="previewContactIconImage" :src="previewContactIconImage" height="50" />
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-md-6">
                        <label class="form-label">Contact Text</label>
                        <input
                          type="text"
                          v-model="contactText"
                          class="form-control"
                          placeholder="Contact Text"
                        />
                      </div>
                      <div class="col-md-6">
                        <label class="form-label">Contact Number</label>
                        <input
                          type="text"
                          v-model="contactNumber"
                          class="form-control"
                          placeholder="Contact Number"
                        />
                      </div>
                    </div>

                   
                    <div class="mb-3">
                      <label class="form-label">Social Connect Title</label>
                      <input
                        type="text"
                        v-model="socialConnectTitle"
                        class="form-control"
                        placeholder="Social Connect Title"
                      />
                    </div>

                    <div class="row mb-3">
                      <div class="col-md-6">
                        <label class="form-label">Instagram Link</label>
                        <input
                          type="text"
                          v-model="instagramLink"
                          class="form-control"
                          placeholder="Instagram Link"
                        />
                      </div>
                      <div class="col-md-6">
                        <label class="form-label">Facebook Link</label>
                        <input
                          type="text"
                          v-model="facebookLink"
                          class="form-control"
                          placeholder="Facebook Link"
                        />
                      </div>
                    </div>

                    <div class="row mb-3">
                      <div class="col-md-6">
                        <label class="form-label">Twitter Link</label>
                        <input
                          type="text"
                          v-model="twitterLink"
                          class="form-control"
                          placeholder="Twitter Link"
                        />
                      </div>
                      <div class="col-md-6">
                        <label class="form-label">YouTube Link</label>
                        <input
                          type="text"
                          v-model="youTubeLink"
                          class="form-control"
                          placeholder="YouTube Link"
                        />
                      </div>
                    </div>

                    
                    
                    
                      <div class="row mb-3">
                        <div class="col-3">
                          <label class="form-label">Facebook Status</label>
                        </div>
                        <div class="col-3">
                          <input
                            type="checkbox"
                            v-model="facebookStatus"
                            class="form-check-input"
                          />
                        </div>
  
                        <div class="col-3">
                          <label class="form-label">Instagram Status</label>
                        </div>
                        <div class="col-3">
                          <input
                            type="checkbox"
                            v-model="instagramStatus"
                            class="form-check-input"
                          />
                        </div>
                      </div>

                      <div class="row mb-3">
                        <div class="col-3">
                          <label class="form-label">Twitter Status</label>
                        </div>
                        <div class="col-3">
                          <input
                            type="checkbox"
                            v-model="twitterStatus"
                            class="form-check-input"
                          />
                        </div>

                        <div class="col-3">
                          <label class="form-label">Youtube Status</label>
                        </div>
                        <div class="col-3">
                          <input
                            type="checkbox"
                            v-model="youtubeStatus"
                            class="form-check-input"
                          />
                        </div>
                      </div>
                    <div class="mb-3">
                      <label class="form-label">Payment Title</label>
                      <input
                        type="text"
                        v-model="paymentTitle"
                        class="form-control"
                        placeholder="Payment Title"
                      />
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <label for="paymentImage" class="form-label">
                          Payment Image:  <br />
                          <strong>[Preferred Image Size: 400X50, Max Upload Limit: 1MB]</strong>
                        </label>
                        <input
                          class="form-control mb-2"
                          type="file"
                          name="payment_image"
                          id="paymentImage"
                          ref="paymentImage"
                          aria-describedby="imageHelp"
                          accept="image/*"
                          @change="uploadPaymentImage($event)"
                        />
                      </div>
                      <div class="col-md-6">
                        <img v-if="previewPaymentImage" :src="previewPaymentImage" height="50" />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <label for="bottomLogo" class="form-label">
                          Bottom Logo: <br />
                          <strong>[Preferred Image Size: 150X40, Max Upload Limit: 1MB]</strong>
                        </label>
                        <input
                          class="form-control mb-2"
                          type="file"
                          name="bottom_logo"
                          id="bottomLogo"
                          ref="bottomLogo"
                          aria-describedby="imageHelp"
                          accept="image/*"
                          @change="uploadBottomLogo($event)"
                        />
                      </div>
                      <div class="col-md-6">
                          <img v-if="previewBottomLogo" :src="previewBottomLogo" height="40" />
                      </div>
                    </div>

           
                  
                    <div class="mb-3">
                      <label class="form-label">Bottom Logo Link</label>
                      <input
                        type="text"
                        v-model="bottomLogoLink"
                        class="form-control"
                        placeholder="Bottom Logo Link"
                      />
                    </div>
                    <div class="mb-3 col-md-3">
                      <div class="row">
                        <div class="col-7">
                          <label class="form-label">Background Color</label>
                        </div>
                        <div class="col-3">
                          <input
                            type="color"
                            v-model="backgroundColor"
                            class="form-check-input"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 col-md-3">
                      <div class="row">
                        <div class="col-7">
                          <label class="form-label">Box Font Color</label>
                        </div>
                        <div class="col-3">
                          <input
                            type="color"
                            v-model="boxFontColor"
                            class="form-check-input"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="d-grid">
                      <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/footer-branding"></script>
