import { authHeader } from "../../../../auth";
export default {
    data() {
        return {}
    },
    async created() {
        document.title = "HGS - Success Order";
        await this.checkSuccessOrder()
    },
    methods: {
        checkSuccessOrder: async function () {
            console.log(this.$route.params.id);
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.stripe.verifyStripeOrder + "/" + this.$route.params.id,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.$axios(config).then((response) => {
                console.log(response);
                if (response.data.orderCreateStatus == true) {
                    this.$swal.fire({
                        icon: "success",
                        text: "Order Completed Successfully!"
                    });
                    this.$router.push("/create-order");
                }else{
                    this.$swal.fire({
                        icon: "error",
                        text: "Order Not Completed!"
                    });
                    this.$router.push("/create-order");
                }
            }).catch(error => {
                this.$swal.hideLoading()
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        }
    },

}