<template>
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
      <div>
        <img
          src="../../../../public/core/assets/images/logo-icon.png"
          class="logo-icon"
          alt="logo icon"
        />
      </div>
      <div>
        <h4 class="logo-text">HGS</h4>
      </div>
      <div class="toggle-icon ms-auto">
        <i class="bx bx-arrow-to-left"></i>
      </div>
    </div>
    <ul class="metismenu" id="menu">
      <li>
        <a href="javascript:void(0)" @click="$router.push('/home')">
          <div class="parent-icon">
            <i class="bx bx-home-circle"></i>
          </div>
          <div class="menu-title">Dashboard</div>
        </a>
      </li>
      <li v-if="isAdmin">
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-user"></i>
          </div>
          <div class="menu-title">User</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/admins')">
              <i class="bx bx-right-arrow-alt"></i>Admins
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/seo-engineer')">
              <i class="bx bx-right-arrow-alt"></i>SEO Engineer
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/customers')">
              <i class="bx bx-right-arrow-alt"></i>Customers
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class="lni lni-producthunt"></i>
          </div>
          <div class="menu-title">Product</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/user-wise-product-discount')">
              <i class="bx bx-right-arrow-alt"></i>User Wise Product Discount
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-discount')">
              <i class="bx bx-right-arrow-alt"></i>Discount
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-list')">
              <i class="bx bx-right-arrow-alt"></i>Products
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-on-sale')">
              <i class="bx bx-right-arrow-alt"></i>Products On Sale
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-reorder')">
              <i class="bx bx-right-arrow-alt"></i>Product Re-Order
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/stock-in-product')">
              <i class="bx bx-right-arrow-alt"></i>Stock In
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/stock-list')">
              <i class="bx bx-right-arrow-alt"></i>Stock In Report
            </a>
          </li>

          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-brand')">
              <i class="bx bx-right-arrow-alt"></i>Brand
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/tag')">
              <i class="bx bx-right-arrow-alt"></i>Tag
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-review')">
              <i class="bx bx-right-arrow-alt"></i>Product Review
            </a>
          </li>
          <li>
            <a href="#" class="has-arrow">
              <div class="parent-icon">
                <i class="fa-solid fa-cubes"></i>
              </div>
              <div class="menu-title">Product Category</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/parent-category')">
                  <i class="bx bx-right-arrow-alt"></i>Category
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/sub-category')">
                  <i class="bx bx-right-arrow-alt"></i>Sub Category
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/child-category')">
                  <i class="bx bx-right-arrow-alt"></i>Child Category
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/featured-categories')">
                  <i class="bx bx-right-arrow-alt"></i>Featured Categories
                </a>
              </li>
            </ul>
          </li>
          <!-- <li>
              <a href="javascript:void(0)" @click="$router.push('/product-color')"
                ><i class="bx bx-right-arrow-alt"></i>Product Color</a
              >
            </li>
            <li>
              <a href="javascript:void(0)" @click="$router.push('/product-size')"
                ><i class="bx bx-right-arrow-alt"></i>Product Size</a
              >
          </li>-->
        </ul>
      </li>
      <li>
        <a class="has-arrow" >
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-cart"></i>
          </div>
          <div class="menu-title">POS / Orders</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/create-order')">
              <i class="bx bx-right-arrow-alt"></i>Create Order
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/orders')">
              <i class="bx bx-right-arrow-alt"></i>Order List
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/sales-tax')">
              <i class="bx bx-right-arrow-alt"></i>Sales Tax
            </a>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class="fa-solid fa-circle-minus"></i>
              </div>
              <div class="menu-title">Tax Exempt</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/unapproved-tax-exempt')">
                  <i class="bx bx-right-arrow-alt"></i>Unapproved
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/approved-tax-exempt')">
                  <i class="bx bx-right-arrow-alt"></i>Approved
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/rejected-tax-exempt')">
                  <i class="bx bx-right-arrow-alt"></i>Rejected
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="#">
              <div class="parent-icon">
                <i class="fadeIn animated bx bx-info-circle"></i>
              </div>
              <div class="menu-title">Return Products</div>
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/return-product-unapproved')">
                  <i class="bx bx-right-arrow-alt"></i>Unapproved
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/return-product-approved')">
                  <i class="bx bx-right-arrow-alt"></i>Approved
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/return-product-rejected')">
                  <i class="bx bx-right-arrow-alt"></i>Rejected
                </a>
          </li>
         
        </ul>

      </li>
      <li>
        <a class="has-arrow" href="#">
          <div class="parent-icon">
            <i class="fa-solid fa-money-bill"></i>
          </div>
          <div class="menu-title">Payment</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/payment-method')">
              <i class="bx bx-right-arrow-alt"></i>Payment Methods
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/payment-list')">
              <i class="bx bx-right-arrow-alt"></i>Payment List
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/payment-settings')">
              <i class="bx bx-right-arrow-alt"></i>Payment Settings
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="#">
          <div class="parent-icon">
            <i class="fa-solid fa-truck"></i>
          </div>
          <div class="menu-title">Shipping Settings</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/fedex-settings')">
              <i class="bx bx-right-arrow-alt"></i>Fedex Settings
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/ups-settings')">
              <i class="bx bx-right-arrow-alt"></i>Ups Settings
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/easypost-settings')">
              <i class="bx bx-right-arrow-alt"></i>Easy Post
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/ltl-freight-setting')">
              <i class="bx bx-right-arrow-alt"></i>LTL Freight Setting
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/shipping-methods')">
              <i class="bx bx-right-arrow-alt"></i>Local Shipping Methods
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/shipping-cms')">
              <i class="bx bx-right-arrow-alt"></i>Shipping CMS
            </a>
          </li>
          <!-- <li>
            <a href="javascript:void(0)" @click="$router.push('/shipping-methods')">
              <div class="parent-icon">
                <i class="fa-solid fa-truck-fast"></i>
              </div>
              <div class="menu-title">Shipping Methods</div>
            </a>
          </li>-->
        </ul>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/coupon')">
          <i class="bx bx-right-arrow-alt"></i>Coupons
        </a>
      </li>
        </ul>
      </li>
      <li v-if="isAdmin">
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="fa-solid fa-folder-open"></i>
          </div>
          <div class="menu-title">Reports</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/sales-report')">
              <i class="bx bx-right-arrow-alt"></i>Sales Report
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/sales-tax-report')">
              <i class="bx bx-right-arrow-alt"></i>Sales Tax Report
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/profit-report')">
              <i class="bx bx-right-arrow-alt"></i>Profit Report
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/refund-report')">
              <i class="bx bx-right-arrow-alt"></i>Refund Report
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/search-records')">
              <i class="bx bx-right-arrow-alt"></i>Search Records
            </a>
          </li>
        </ul>
      </li>
      <li v-if="isAdmin">
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="fa-solid fa-folder-open"></i>
          </div>
          <div class="menu-title">Data</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/contact-message')">
              <i class="bx bx-right-arrow-alt"></i>Contact Messages
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/enquiry-list')">
              <i class="bx bx-right-arrow-alt"></i>Location Enquires
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/franchise')">
              <i class="bx bx-right-arrow-alt"></i>Franchise List
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/quotation')">
              <i class="bx bx-right-arrow-alt"></i>Quotation List
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/career')">
              <i class="bx bx-right-arrow-alt"></i>Career List
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/subscriber')">
              <i class="bx bx-right-arrow-alt"></i>Subscriber
            </a>
          </li>
        </ul>
      </li>
      <li v-if="isAdmin">
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa-sharp fa-solid fa-images"></i>Mail Template
            </a>
            <ul>
              <!-- <li>
                <a href="javascript:void(0);" @click="$router.push('/create-account-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> Create Account Email
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/create-account-by-admin-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> Create Account By Admin Email
                </a>
              </li>-->
              <!-- <li>
                <a href="javascript:void(0);" @click="$router.push('/order-placed-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> Order Placed Email
                </a>
              </li>-->
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/create-customer-signup-email-template-elementor')"
                >
                  <i class="bx bx-right-arrow-alt"></i> Create Customer Signup Email
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/forgot-password-email-template')"
                >
                  <i class="bx bx-right-arrow-alt"></i> Customer Forgot Password Email
                </a>
              </li>
              <!-- <li>
                <a href="javascript:void(0);" @click="$router.push('/create-admin-signup-email-template-elementor')">
                  <i class="bx bx-right-arrow-alt"></i> Create Admin Signup Email</a>
              </li>-->
              <li>
                <a href="javascript:void(0);" @click="$router.push('/place-order-email-template')">
                  <i class="bx bx-right-arrow-alt"></i> Placed Order Email Template
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/processing-order-email-template')"
                >
                  <i class="bx bx-right-arrow-alt"></i> Processing Order Email Template
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/shipped-order-email-template')"
                >
                  <i class="bx bx-right-arrow-alt"></i> Shipped Order Email Template
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/delivered-order-email-template')"
                >
                  <i class="bx bx-right-arrow-alt"></i> Delivered Order Email Template
                </a>
              </li>
            </ul>
      </li>
      <li v-if="isAdmin">
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-popup"></i>Popup Module
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/ad-pop-up-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Ad Popup Module
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/newsletter-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Newsletter Popup Module CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/pop-up-settings')">
                  <i class="bx bx-right-arrow-alt"></i>Popup Settings
                </a>
              </li>
            </ul>
      </li>

      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8H4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H3m11 0h-4V8h4m-3 4h2m4-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1z"/></svg>
          <div class="menu-title">SEO</div>
        </a>
        <ul>
          <li>
            <a href="/category-seo-content" @click.prevent="$router.push('/category-seo-content')">
              <i class="bx bx-right-arrow-alt"></i>Category Seo Content
            </a>
          </li>
          <li>
            <a href="/subcategory-seo-content" @click.prevent="$router.push('/subcategory-seo-content')">
              <i class="bx bx-right-arrow-alt"></i>Subcategory Seo Content
            </a>
          </li>
          <li>
            <a href="/child-category-seo-content" @click.prevent="$router.push('/child-category-seo-content')">
              <i class="bx bx-right-arrow-alt"></i>Child category Seo Content
            </a>
          </li>
          <li>
            <a href="/brand-seo-content" @click.prevent="$router.push('/brand-seo-content')">
              <i class="bx bx-right-arrow-alt"></i>Brand Seo Content
            </a>
          </li>
          <li>
            <a href="/product-meta" @click.prevent="$router.push('/product-meta')">
              <i class="bx bx-right-arrow-alt"></i>Product Meta
            </a>
          </li>
          <li>
            <a href="/category-meta" @click.prevent="$router.push('/category-meta')">
              <i class="bx bx-right-arrow-alt"></i>Category Meta
            </a>
          </li>
          <li>
            <a href="/subcategory-meta" @click.prevent="$router.push('/subcategory-meta')">
              <i class="bx bx-right-arrow-alt"></i>Subcategory Meta
            </a>
          </li>
          <li>
            <a href="/child-category-meta" @click.prevent="$router.push('/child-category-meta')">
              <i class="bx bx-right-arrow-alt"></i>Child Category Meta
            </a>
          </li>
          <li>
            <a href="/brand-meta" @click.prevent="$router.push('/brand-meta')">
              <i class="bx bx-right-arrow-alt"></i>Brand Meta
            </a>
          </li>
          <li>
                <a href="javascript:void(0);" @click="$router.push('/page-title-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Page Title CMS
                </a>
          </li>
          <li>
             <a href="javascript:void(0);" @click="$router.push('/blog-meta')">
                  <i class="bx bx-right-arrow-alt"></i>Blog Meta
                </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/sitemap')">
              <i class="bx bx-right-arrow-alt"></i>Sitemap Generator
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/user-log')">
              <i class="bx bx-right-arrow-alt"></i>User Log
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="lni lni-cog"></i>
          </div>
          <div class="menu-title">CMS Settings</div>
        </a>
        <ul>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-producthunt"></i>Product
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/product-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Product CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/sale-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Sale CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/product-details-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Product Details CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa-brands fa-blogger"></i>Blog
            </a>
            <ul>
              
                <li>
                    <a href="javascript:void(0);" @click="$router.push('/blog-category')">
                    <i class="bx bx-right-arrow-alt"></i>Blog Category
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" @click="$router.push('/blog-tags')">
                    <i class="bx bx-right-arrow-alt"></i>Blog Tags
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" @click="$router.push('/blog-post')">
                    <i class="bx bx-right-arrow-alt"></i>Blog Post
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" @click="$router.push('/blog-comments')">
                    <i class="bx bx-right-arrow-alt"></i>Blog Comments
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" @click="$router.push('/blog-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Blog CMS
                    </a>
                </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Franchise
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/franchise-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Franchise CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Quotation
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/quotation-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Quotation CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Career
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/career-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Career CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa-solid fa-bars-progress"></i>Header
              Footer
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/header-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Header CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/footer-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Footer CMS
                </a>
              </li>
             
              <li>
                <a href="javascript:void(0);" @click="$router.push('/popular-search')">
                  <i class="bx bx-right-arrow-alt"></i>Popular Search
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/why-choose-us')">
                  <i class="bx bx-right-arrow-alt"></i>Why Choose Us
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/footer-branding')">
                  <i class="bx bx-right-arrow-alt"></i>Footer Branding
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-home"></i>Home
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/home-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Home Page CMS
                </a>
              </li>
              <!-- <li>
                <a href="javascript:void(0)" @click="$router.push('/homeIntro')">
                  <i class="bx bx-right-arrow-alt"></i>Home Intro
                </a>
              </li> -->
              <li>
                <a href="javascript:void(0);" @click="$router.push('/home-section-two')">
                  <i class="bx bx-right-arrow-alt"></i>Home Section Two
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/home-brand')">
                  <i class="bx bx-right-arrow-alt"></i>Home Brand
                </a>
              </li>
              <!-- <li>
                <a href="javascript:void(0)" @click="$router.push('/slider')">
                  <i class="bx bx-right-arrow-alt"></i>Slider Settings
                </a>
              </li> -->
              <!-- <li>
                <a href="javascript:void(0)" @click="$router.push('/brands')">
                  <i class="bx bx-right-arrow-alt"></i>Instagram Slider
                </a>
              </li>-->
              <li>
                <a href="javascript:void(0)" @click="$router.push('/start-your-account')">
                  <i class="bx bx-right-arrow-alt"></i> Start Your Account
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/review-slider')">
                  <i class="bx bx-right-arrow-alt"></i>Review Slider
                </a>
              </li>
              <!-- <li>
                <a href="/location-home" @click.prevent="$router.push('/location-home')">
                  <i class="bx bx-right-arrow-alt"></i>Location Home
                </a>
              </li> -->
              <li>
                <a href="/services-section" @click.prevent="$router.push('/services-section')">
                  <i class="bx bx-right-arrow-alt"></i>Services Section
                </a>
              </li>
              <li>
                <a href="/shopping-journey" @click.prevent="$router.push('/shopping-journey')">
                  <i class="bx bx-right-arrow-alt"></i>Shopping Journey
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/hero-section')">
                  <i class="bx bx-right-arrow-alt"></i>Hero Section
                </a>
              </li>
            </ul>
          </li>
         
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-link"></i>Social Links
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/social-links')">
                  <i class="bx bx-right-arrow-alt"></i>Social Links
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-message-detail"></i>Contact
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/contact-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Contact Page CMS
                </a>
              </li>
              <li>
                    <a href="javascript:void(0);" @click="$router.push('/contact')">
                    <i class="bx bx-right-arrow-alt"></i>Contact Content
                    </a>
                </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa-solid fa-location-pin"></i>Location
            </a>
            <ul>
              <li>
                    <a href="javascript:void(0);" @click="$router.push('/location-component-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Location CMS
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" @click="$router.push('/location-list')">
                    <i class="bx bx-right-arrow-alt"></i>Location List
                    </a>
                </li>
                
                <li>
                    <a href="/location-home-cms" @click.prevent="$router.push('/location-home-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Location Home CMS
                    </a>
                </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/location-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Location CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa fa-shopping-cart"></i>Shopping Cart
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/cart-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Cart CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-timer"></i>Shipping Cutoff Time
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/shipping-cutoff-time')">
                  <i class="bx bx-right-arrow-alt"></i>Cutoff Time CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="lni lni-timer"></i>Track Order
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/track-order-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Track Order CMS
                </a>
              </li>
            </ul>
          </li>

          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fa-solid fa-headset"></i>Help
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/help-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Help Page CMS
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" @click="$router.push('/help-content')">
                  <div class="parent-icon">
                    <i class="bx bx-right-arrow-alt"></i>
                  </div>
                  <div class="menu-title">Help Us Content</div>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class='bx bxl-microsoft-teams'></i>Team Member
            </a>
            <ul>
              <li>
            <a href="javascript:void(0)" @click="$router.push('/team-member')">
              <i class="bx bx-right-arrow-alt"></i>Team Member List
            </a>
          </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-info-circle"></i>About Us
            </a>
            <ul>
              <li>
                    <a href="javascript:void(0);" @click="$router.push('/about-content')">
                    <i class="bx bx-right-arrow-alt"></i>About Content
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);" @click="$router.push('/about-services')">
                    <i class="bx bx-right-arrow-alt"></i>About Services
                    </a>
                </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/about-page-cms')">
                  <i class="bx bx-right-arrow-alt"></i>About Page CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-check-shield"></i>Policies
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/terms&condition')">
                  <i class="bx bx-right-arrow-alt"></i>Terms&Condition
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/privacy-policy')">
                  <i class="bx bx-right-arrow-alt"></i>Privacy Policy
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/return-policy')">
                  <i class="bx bx-right-arrow-alt"></i>Return Policy
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/cancellation-policy')">
                  <i class="bx bx-right-arrow-alt"></i>Cancellation Policy
                </a>
              </li>
            </ul>
          </li>
         
        </ul>
      </li>


      <!-- <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="fa-brands fa-blogger"></i>
          </div>
          <div class="menu-title">Blog</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/blog-category')">
              <i class="bx bx-right-arrow-alt"></i>Blog Category
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/blog-tags')">
              <i class="bx bx-right-arrow-alt"></i>Blog Tags
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/blog-post')">
              <i class="bx bx-right-arrow-alt"></i>Blog Post
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/blog-comments')">
              <i class="bx bx-right-arrow-alt"></i>Blog Comments
            </a>
          </li>
        </ul>
      </li> -->
    
      <!-- <li v-if="isAdmin">
        <a href="javascript:void(0)" @click="$router.push('/sales-tax')">
          <div class="parent-icon">
            <i class="fa-solid fa-circle-minus"></i>
          </div>
          <div class="menu-title">Sales Tax</div>
        </a>
      </li> -->
      <!-- <li>
          <a href="javascript:void(0)" @click="$router.push('/instagram-feed')">
            <div class="parent-icon">
              <i class="fa-brands fa-instagram"></i>
            </div>
            <div class="menu-title">Instagram Feed</div>
          </a>
      </li>-->
      <!-- <li>
        <a href="javascript:void(0)" @click="$router.push('/tax-exempt')">
          <div class="parent-icon">
            <i class="fa-solid fa-circle-minus"></i>
          </div>
          <div class="menu-title">Tax Exempt</div>
        </a>
      </li>-->


      <!-- <li>
          <a href="javascript:void(0)" @click="$router.push('/payment-method')">
            <div class="parent-icon">
              <i class="fadeIn animated bx bx-money"></i>
            </div>
            <div class="menu-title">Payment Method</div>
          </a>
      </li>-->

      <!-- <li>
          <a href="javascript:void(0)" @click="$router.push('/slider')">
            <div class="parent-icon">
              <i class="fadeIn animated bx bx-carousel"></i>
            </div>
            <div class="menu-title">Slider</div>
          </a>
      </li>-->

      <!-- <li>
        <a href="javascript:void(0)" @click="$router.push('/shipping-methods')">
          <div class="parent-icon">
            <i class="fa-solid fa-truck-fast"></i>
          </div>
          <div class="menu-title">Shipping Methods</div>
        </a>
      </li>-->
      <!-- <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="fadeIn animated bx bx-info-circle"></i>
          </div>
          <div class="menu-title">Return Products</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/return-product-unapproved')">
              <i class="bx bx-right-arrow-alt"></i>Unapproved
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/return-product-approved')">
              <i class="bx bx-right-arrow-alt"></i>Approved
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/return-product-rejected')">
              <i class="bx bx-right-arrow-alt"></i>Rejected
            </a>
          </li>
        </ul>
      </li> -->
    

      <!-- <li>
                  <a class="has-arrow" href="javascript:void(0)">
                      <div class="parent-icon">
                          <i class="lni lni-cog"></i>
                      </div>
                      <div class="menu-title">Component CMS Settings</div>
                  </a>
                  <ul>
                      <li>
                          <a class="has-arrow" href="javascript:void(0)"><i
                                  class="fadeIn animated bx bx-store-alt"></i>Career</a>
                          <ul>
                              <li>
                                  <a href="javascript:void(0);" @click="$router.push('/career-component-cms')"><i
                                          class="bx bx-right-arrow-alt"></i>Career CMS</a>
                              </li>
                          </ul>
                      </li>
                      <li>
                          <a class="has-arrow" href="javascript:void(0)"><i class="fadeIn animated bx bx-message-detail"></i>Contact</a>
                          <ul>
                              <li>
                                  <a href="javascript:void(0);" @click="$router.push('/contact-component-cms')"><i
                                          class="bx bx-right-arrow-alt"></i>Contact CMS</a>
                              </li>
                          </ul>
                      </li>
                      <li>
                          <a class="has-arrow" href="javascript:void(0)"><i class="fadeIn animated bx bx-info-circle"></i>About Us</a>
                          <ul>
                              <li>
                                  <a href="javascript:void(0);" @click="$router.push('/about-component-cms')"><i
                                          class="bx bx-right-arrow-alt"></i>About Us CMS</a>
                              </li>
                          </ul>
                      </li>
                  </ul>
      </li>-->
      <!-- <li>
          <a class="has-arrow" href="javascript:void(0)">
            <div class="parent-icon">
              <i class="fadeIn animated bx bx-info-circle"></i>
            </div>
            <div class="menu-title">About Us</div>
          </a>
          <ul>
            <li>
              <a href="javascript:void(0);" @click="$router.push('/about-content')">
                <i class="bx bx-right-arrow-alt"></i>About Content
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" @click="$router.push('/about-services')">
                <i class="bx bx-right-arrow-alt"></i>About Services
              </a>
            </li>
          </ul>
      </li>-->
      <!-- <li>
          <a href="javascript:void(0)" @click="$router.push('/shipping-methods')">
            <div class="parent-icon">
              <i class="fa-solid fa-truck-fast"></i>
            </div>
            <div class="menu-title">Shipping Methods</div>
          </a>
      </li>-->
      <!-- <li>
          <a class="has-arrow" href="javascript:void(0)">
            <div class="parent-icon">
              <i class="fa-solid fa-money-bill"></i>
            </div>
            <div class="menu-title">Payment</div>
          </a>
          <ul>
            <li>
              <a href="javascript:void(0);" @click="$router.push('/payment-method')">
                <i class="bx bx-right-arrow-alt"></i>Payment Methods
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" @click="$router.push('/payment-list')">
                <i class="bx bx-right-arrow-alt"></i>Payment List
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" @click="$router.push('/payment-settings')">
                <i class="bx bx-right-arrow-alt"></i>Payment Settings
              </a>
            </li>
          </ul>
      </li>-->
      <!-- <li>
          <a class="has-arrow" href="javascript:void(0)">
            <div class="parent-icon">
              <i class="fa-solid fa-truck"></i>
            </div>
            <div class="menu-title">Shipping Settings</div>
          </a>
          <ul>
            <li>
              <a href="javascript:void(0);" @click="$router.push('/fedex-settings')">
                <i class="bx bx-right-arrow-alt"></i>Fedex Settings
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" @click="$router.push('/ups-settings')">
                <i class="bx bx-right-arrow-alt"></i>Ups Settings
              </a>
            </li>
          </ul>
      </li>-->
      <!-- <li>
          <a class="has-arrow" href="javascript:void(0)">
            <div class="parent-icon">
              <i class="lni lni-cog"></i>
            </div>
            <div class="menu-title">CMS Settings</div>
          </a>
          <ul>
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="lni lni-producthunt"></i>Product
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/product-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Product CMS
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/product-details-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Product Details CMS
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="fa-brands fa-blogger"></i>Blog
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/blog-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Blog CMS
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="fadeIn animated bx bx-store-alt"></i>Franchise
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/franchise-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Franchise CMS
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="fadeIn animated bx bx-store-alt"></i>Career
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/career-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Career CMS
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="fa-solid fa-bars-progress"></i>Header
                Footer
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/header-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Header CMS
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/footer-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Footer CMS
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="lni lni-home"></i>Home
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/home-page-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Home Page CMS
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="$router.push('/slider')">
                    <i class="bx bx-right-arrow-alt"></i>Slider Settings
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="$router.push('/brands')">
                    <i class="bx bx-right-arrow-alt"></i>Brands
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/review-slider')">
                    <i class="bx bx-right-arrow-alt"></i>Review Slider
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="lni lni-link"></i>Social Links
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/social-links')">
                    <i class="bx bx-right-arrow-alt"></i>Social Links
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="fadeIn animated bx bx-message-detail"></i>Contact
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/contact-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Contact Page CMS
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="fa-solid fa-location-pin"></i>Location
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/location-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Location CMS
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="lni lni-timer"></i>Track Order
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/track-order-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Track Order CMS
                  </a>
                </li>
              </ul>
            </li>
  
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="fa-solid fa-headset"></i>Help
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/help-page-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Help Page CMS
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="$router.push('/help-content')">
                    <div class="parent-icon">
                      <i class="bx bx-right-arrow-alt"></i>
                    </div>
                    <div class="menu-title">Help Us Content</div>
                  </a>
                </li>
              </ul>
            </li>
  
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="fadeIn animated bx bx-info-circle"></i>About Us
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/about-page-cms')">
                    <i class="bx bx-right-arrow-alt"></i>About Page CMS
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="lni lni-popup"></i>Pop Up Modal
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/ad-pop-up-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Ad Pop Up Modal CMS
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/newsletter-cms')">
                    <i class="bx bx-right-arrow-alt"></i>Newsletter Modal CMS
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/pop-up-settings')">
                    <i class="bx bx-right-arrow-alt"></i>Pop Up Settings
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a class="has-arrow" href="javascript:void(0)">
                <i class="fadeIn animated bx bx-check-shield"></i>Policies
              </a>
              <ul>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/terms&condition')">
                    <i class="bx bx-right-arrow-alt"></i>Terms&Condition
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/privacy-policy')">
                    <i class="bx bx-right-arrow-alt"></i>Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/return-policy')">
                    <i class="bx bx-right-arrow-alt"></i>Return Policy
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);" @click="$router.push('/cancellation-policy')">
                    <i class="bx bx-right-arrow-alt"></i>Cancellation Policy
                  </a>
                </li>
              </ul>
            </li>
          </ul>
      </li>-->

      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="lni lni-cog"></i>
          </div>
          <div class="menu-title">Component CMS Settings</div>
        </a>
        <ul>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Home
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/home-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Home CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Product
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/product-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Product CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Product Details
            </a>
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/product-details-component-cms')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Product Details CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Career
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/career-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Career CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Contact
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/contact-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Contact CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>About Us
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/about-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>About Us CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Franchise
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/franchise-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Franchise CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Quotation
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/quotation-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Quotation CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Location
            </a>
            <ul>
              <li>
                <a
                  href="javascript:void(0);"
                  @click="$router.push('/location-details-component-cms')"
                >
                  <i class="bx bx-right-arrow-alt"></i>Location Details CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Blog
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/blog-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Blog CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Help
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/help-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Help CMS
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <i class="fadeIn animated bx bx-store-alt"></i>Policy
            </a>
            <ul>
              <li>
                <a href="javascript:void(0);" @click="$router.push('/policy-component-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Policy CMS
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <li v-if="isAdmin">
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="lni lni-cog"></i>
          </div>
          <div class="menu-title">Other Settings</div>
        </a>
        <ul>
            <li>
              <a href="/order-dump-log" @click.prevent="$router.push('/order-dump-log')">
                    <i class="bx bx-right-arrow-alt"></i>Order Dump Log
                  </a>
                </li>
            <li>
                <a href="javascript:void(0);" @click="$router.push('/add-custom-script')">
                  <i class="bx bx-right-arrow-alt"></i>Add Custom Script
                </a>
            </li>
        </ul>
      </li>

      <!-- <li>
          <a class="has-arrow" href="javascript:void(0)">
            <div class="parent-icon">
              <i class="fa-solid fa-folder-open"></i>
            </div>
            <div class="menu-title">Reports</div>
          </a>
          <ul>
            <li>
              <a href="javascript:void(0);" @click="$router.push('/sales-report')">
                <i class="bx bx-right-arrow-alt"></i>Sales Report
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" @click="$router.push('/profit-report')">
                <i class="bx bx-right-arrow-alt"></i>Profit Report
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" @click="$router.push('/refund-report')">
                <i class="bx bx-right-arrow-alt"></i>Refund Report
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a href="javascript:void(0);" @click="$router.push('/add-custom-script')">
            <div class="parent-icon">
              <i class="fa-solid fa-folder-open"></i>
            </div>
            <div class="menu-title">Add Custom Script</div>
          </a>
      </li>-->
    </ul>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
export default {
  name: "Header",
  data(){
    return {
      role: 0,
      isAdmin: false
    }
  },
  created() {
    const token = localStorage?.getItem('token');
    const decoded = jwt_decode(token);
    this.role = decoded.role;
    this.isAdmin = this.role == 2;
  }
}
</script>