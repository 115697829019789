<template>
  <div class="page-wrapper">
    <div class="page-content">
      <!-- <div class="row">
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Total Order</p>
                  <h4 class="my-1">{{ totalOrders }}</h4>
                </div>
                <div class="widgets-icons bg-light-success text-success ms-auto">
                  <img src="/core/assets/svg/1.svg" alt="Icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Order Pending</p>
                  <h4 class="my-1">{{ totalPendingOrders }}</h4>
                </div>
                <div class="widgets-icons bg-light-success text-success ms-auto">
                  <img src="/core/assets/svg/track-blue.svg" alt="Icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Order Delivered</p>
                  <h4 class="my-1">{{ totalDeliveredOrders }}</h4>
                </div>
                <div class="widgets-icons bg-light-success text-success ms-auto">
                  <img src="/core/assets/svg/track-green.svg" alt="Icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm">
          <div class="card radius-10">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div>
                  <p class="mb-0 text-secondary">Order Cancelled</p>
                  <h4 class="my-1">{{ totalCancelledOrders }}</h4>
                </div>
                <div class="widgets-icons bg-light-success text-success ms-auto">
                  <img src="/core/assets/svg/order-cancel.svg" alt="Icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>-->
      <div class="row">
        <div class="col-6">
          <h6 class="mb-0 text-uppercase">Approved Return Products</h6>
        </div>
        <div class="col-6 float-right">
          <a role="button" class="btn btn-warning btn-sm m-1 mb-md-0" style="float: right" @click="exportData()">
            <i class="fa-solid fa-file-export"></i>
            Export
          </a>
          <!-- <a
            role="button"
            data-bs-toggle="modal"
            data-bs-target="#filterModal"
            id="filterTable"
            class="btn btn-success btn-sm m-1 mb-md-0"
            style="float: right"
          >
            <i class="bx bx-filter text-white float-left"></i>
            Filter
          </a> -->
        </div>
      </div>

      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Order Id</th>
                  <th>Customer Name</th>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Item Price</th>
                  <th>Total Price</th>
                  <th>Return Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in productList" :key="index">
                  <td>{{ index+ 1}}</td>
                  <td>
                    <a href="javascript:void(0)" @click="$router.push('/order-details/' + data.orderId)">{{
                      data.orderId
                    }}</a>
                  </td>
                  <td>{{ data.customerName }}</td>
                  <td>{{ data.productName }}</td>
                  <td>{{ data.quantity }}</td>
                  <td>{{ data.actualPrice }}</td>
                  <td>{{ data.totalPrice }}</td>
                  <td>{{ data.status }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Order Id</th>
                  <th>Customer Name</th>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Item Price</th>
                  <th>Total Price</th>
                  <th>Return Status</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <!-- filter -->

      <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="filterModalLabel">Filtering</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form class="filter_form">
                <div class="row mb-3">
                  <div class="col-6 col-sm-6">
                    <label class="form-label">Payment Status</label>
                    <select v-model="filter.paymentStatus" class="form-select">
                      <option value>All</option>
                      <option value="PENDING">PENDING</option>
                      <option value="Paid">PAID</option>
                    </select>
                  </div>
                  <div class="col-6 col-sm-6">
                    <label class="form-label">Delivery Status</label>
                    <select v-model="filter.deliveryStatus" class="form-select">
                      <option value>All</option>
                      <option value="PENDING">PENDING</option>
                      <option value="PROCESSING">PROCESSING</option>
                      <option value="SHIPPED">SHIPPED</option>
                      <option value="DELIVERED">DELIVERED</option>
                    </select>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Start Date:</label>
                    <input v-model="filter.startDate" type="date" class="form-control" />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">End Date</label>
                    <input v-model="filter.endDate" type="date" class="form-control" />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Customer Name:{{ userName }}</label>
                    <Multiselect v-model="filter.userName" id="inputVendor" :options="userList" :searchable="true" />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Order Id: {{ filterOrderId }}</label>
                    <Multiselect v-model="filter.orderId" id="inputVendor" :options="filteredOrderIdList"
                      :searchable="true" />
                  </div>
                </div>

                <div class="mb-3">
                  <div class="text-center">
                    <button type="submit" @click="clearFilter()"
                      class="btn btn-sm btn-warning rounded-pill ps-3 pe-3 m-1" data-bs-dismiss="modal">Clear</button>

                    <button type="button" class="btn btn-sm btn-success rounded-pill ps-3 pe-3 m-1"
                      data-bs-dismiss="modal" @click="validateFilter">Search</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="pickUpModal" tabindex="-1" aria-labelledby="pickUpModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="filterModalLabel">Checking</h5>
              <button type="button" id="closePickUp" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form class="filter_form">
                <div class="row mb-3">
                  <div class="col-12 col-sm-12">
                    <label class="form-label">Address Line</label>
                    <textarea class="form-control" id="floatingTextarea" v-model="pickUpAddress"
                      placeholder="Enter street address line"></textarea>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12 col-sm-12">
                    <label class="form-label">City</label>
                    <input type="text" class="form-control" id="inputProductTitle" v-model="pickUpCity"
                      placeholder="Enter city" />
                  </div>
                  <div class="col-12 col-sm-6" hidden>
                    <label class="form-label">Country</label>
                    <Multiselect v-model="pickUpCountry" id="inputVendor" :options="countryList" :searchable="true" />
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12 col-sm-6">
                    <label class="form-label">State or Provenance Code</label>
                    <input type="text" class="form-control" id="inputProductTitle" v-model="pickUpStateCode"
                      placeholder="Enter state code" />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Postal Code</label>
                    <input type="text" class="form-control" id="inputProductTitle" v-model="pickUpPostCode"
                      placeholder="Enter postal code" />
                  </div>
                </div>
                <div class="row mb-3" v-if="pickUpRate">
                  <div class="col-12 col-sm-12">
                    <hr />
                    <p style="font-size: 15px" class="text-center">
                      Pickup Rate :
                      <b>${{ pickUpRate }}</b>
                    </p>
                    <hr />
                  </div>
                </div>

                <div class="mb-3">
                  <div class="text-center">
                    <button type="button" @click="clearPickUp()"
                      class="btn btn-sm btn-warning rounded-pill ps-3 pe-3 m-1">Clear</button>

                    <button type="button" class="btn btn-sm btn-success rounded-pill ps-3 pe-3 m-1"
                      @click="checkPickUpRate">Check</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end filter -->
    </div>
  </div>
</template>

<script src='../js/approved.js'></script>


<style scoped>
.edit-button {
  background-color: #49abab;
}

.confirm-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>