import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            brandList: [],
            siteUrl: this.$serverURL + this.$api.brandSeoContent.brandSeoContentURL,
            brandId: '',
            brandName: '',
            brandSeoContent: "",
            isActiveList: true
        }
    },
    async created() {
        document.title = "HGS - Brand Seo Content";
        await this.getBrandList(this.siteUrl);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getBrandList: async function (url) {
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.brandList = await response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        updateSeoContent: async function() {
            try {
                if (!this.brandSeoContent) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter seo content!"
                    });
                    return false;
                }
                let data = {
                    brandId: this.brandId,
                    brandName: this.brandName,
                    brandSeoContent: this.brandSeoContent
                };
                let config = {
                    method: 'POST',
                    url: this.siteUrl,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                await this.getBrandList(this.siteUrl);
                this.backToList(true);
                this.brandId = "";
                this.brandName = "";
                this.brandSeoContent = "";
            }
            catch(error) {
                console.log(error);
            }
        },
        gotoAddSeoContent(brandId, brandName, brandSeoContent, isActiveList) {
            this.brandId = brandId;
            this.brandName = brandName;
            this.brandSeoContent = brandSeoContent ?? "";
            this.isActiveList = isActiveList;
        },
        backToList(isActiveList) {
            this.isActiveList = isActiveList;
        }
    }
}