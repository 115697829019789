import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            setURL: this.$serverURL + this.$api.blog.singleBlogAPI,
            id: this.$route.params.id,
            imagePath: this.$imageURL + 'blog/',
            name: "",
            featuredImage: "",
            previewFeaturedImage: null,
            category: {},
            categoryList: [],
            tagValue: [],
            tagsList: [],
            shortDescription: "",
            longDescription: "",
            postedBy: "",
            uploaderImage: "",
            previesUploaderImage: "",
            singleBlog: {},
            title: "",
        }

    },
    async mounted() {
        await this.getBlogData();
        await this.getTagList();
        await this.getCategoryList();
    },
    methods: {
        uploadFeaturedImage: function (event) {
            this.singleBlog.photo = event.target.files[0];
            let input = this.$refs.featuredImageInput
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateUploaderImage: function (event) {
            this.singleBlog.uploaderPhoto = event.target.files[0];
            let input = this.$refs.uploaderImage
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previesUploaderImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        getBlogData: async function () {
            let config = {
                method: "GET",
                url: this.setURL + this.id,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.singleBlog = await response.data.data[0];
                    this.category = {
                        id: this.singleBlog.categoryId,
                        name: this.singleBlog.categoryName
                    };
                    if (this.singleBlog.tags) {
                        this.tagsValue = this.singleBlog.tags.map((el) => {
                            return el.tagName
                        });
                    }
                    this.tagsValue = this.singleBlog.tags.map((el) => {
                        return el.tagName
                    });
                    this.title = this.singleBlog.title;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getCategoryList: async function () {
            let url = this.$serverURL + this.$api.blog.blogCategoryAPI;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.categoryList = await response.data.data;

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getTagList: async function () {
            let url = this.$serverURL + this.$api.blog.blogTagAPI;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    let tagData = await response.data.data;
                    this.tagsList = tagData.map((el) => {
                        return { value: el.tagName, label: el.tagName }
                    });

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        validate() {
            if (!this.category) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog category."
                })
                return false;
            } else if (!this.singleBlog.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog name."
                })
            }
            return true;
        },
        updateBlogPost: async function () {
            if (this.validate()) {
                try {
                    let formData = new FormData();
                    formData.append('categoryId', this.category.id);
                    formData.append('categoryName', this.category.name)
                    formData.append('title', this.singleBlog.title);
                    formData.append('photo', this.singleBlog.photo);
                    formData.append('shortDetails', this.singleBlog.shortDetails);
                    formData.append('longDetails', this.singleBlog.longDetails);
                    formData.append('photoTitle', this.singleBlog.photoTitle);
                    formData.append('photoAlt', this.singleBlog.photoAlt);
                    formData.append('postedBy', this.singleBlog.postedBy);
                    formData.append('urlSlug', this.singleBlog.urlSlug);
                    formData.append('uploaderPhoto', this.singleBlog.uploaderPhoto);
                    formData.append('tags', JSON.stringify(this.tagsValue));
                    let config = {
                        method: 'PATCH',
                        url: this.setURL + this.id,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.updateDataToBackend(config);

                    // add log to database
                    const firstName = localStorage.getItem('firstname');
                    const lastName = localStorage.getItem('lastname');
                    const configLogData = {
                        moduleName: 'blog',
                        action: 'update',
                        moduleId: this.id,
                        logMessage: `${this.singleBlog.title} updated by ${firstName} ${lastName}`
                    }
                    await this.createUserLog(configLogData);

                    this.$router.push("/blog-post");
                } catch (error) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }

        }
    },
    watch: {
        title(newValue, oldValue) {
            if(!oldValue) return;

            this.singleBlog.title = newValue;
            const slug = this.createUrlSlug(newValue);
            this.singleBlog.urlSlug = slug;
        }
    }
}