<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Review Slider List
                        </li>
                    </ol>
                </nav>

                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                            <i class="bx bxs-plus-square"></i>Add Review Slider
                        </button>
                    </div>
                    <!-- Add Modal -->

                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel"
                        aria-hidden="true">
                        <form @submit.prevent="addSlider" enctype="multipart/form-data">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add Review Slider</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Reviewer Name:</label>
                                        <input class="form-control mb-1" type="text" v-model="reviewerName" placeholder="Enter Reviewer Name" aria-label="default input example" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Review Title:</label>
                                        <input class="form-control mb-1" type="text" v-model="reviewTitle" placeholder="Enter Review Title" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Review Description:</label>
                                        <textarea class="form-control mb-1" v-model="reviewDescription" placeholder="Enter Review Description:" id="" cols="30" rows="10"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Rating:</label>
                                        <input class="form-control mb-1" type="number" max="5" v-model="rating"
                                            placeholder="Enter Rating" aria-label="default input example" />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Add Modal -->
                    <!-- Edit Role Modal -->

                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel"
                        aria-hidden="true">
                        <form @submit.prevent="updateSlider">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add Review Slider</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Reviewer Name:</label>
                                        <input class="form-control mb-1" type="text" v-model="singleSlider.reviewerName" placeholder="Enter Reviewer Name" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Review Title:</label>
                                        <input class="form-control mb-1" type="text" v-model="singleSlider.reviewTitle" placeholder="Enter Review Title" />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Review Description:</label>
                                            <textarea class="form-control mb-1" v-model="singleSlider.reviewDescription" placeholder="Enter Review Description:" id="" cols="30" rows="10"></textarea>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Rating:</label>
                                        <input class="form-control mb-1" type="number" v-model="singleSlider.rating"
                                            placeholder="Enter Rating" aria-label="default input example" max="5" />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Edit Role Modal -->
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Review Slider List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Reviewer Name</th>
                                    <th>Reviewer Title</th>
                                    <th>Review Description</th>
                                    <th>Rating</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{index + 1}}</td>
                                    <td>{{data.reviewerName}}</td>
                                    <td>{{data.reviewTitle}}</td>
                                    <td>{{this.limitText(data.reviewDescription, 8)}}</td>
                                    <td>{{data.rating}}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" v-on:click="editSlider(data)"
                                                data-bs-toggle="modal" data-bs-target="#editModal"
                                                class="edit-button"><i class="bx bxs-edit text-white"></i></a>
                                            <a href="javascript:void(0);" v-on:click="deleteSlider(data.id)"
                                                class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Reviewer Name</th>
                                    <th>Reviewer Title</th>
                                    <th>Review Description</th>
                                    <th>Rating</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/review-slider.js'></script>


<style scoped>

    .edit-button {
        background-color: #17a00e;
    }

    .delete-button {
        background-color: #f41127;
    }
</style>