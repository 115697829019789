<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add New Blog
            </li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Add New Blog</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-8">
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label"
                        >Blog Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="inputProductTitle"
                        placeholder="blog name"
                        v-model="blogName"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Blog Url</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputProductTitle"
                        placeholder="blog url"
                        v-model="blogUrlSlug"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label" >Featured Image: <strong>[Preferred Image Size: 800X533, Max Upload Limit: 1MB]</strong></label>
                      <input
                        class="form-control mb-2"
                        type="file"
                        name="featured_image"
                        id="featuredImage"
                        ref="featuredImageInput"
                        aria-describedby="imageHelp"
                        accept="image/*"
                        @change="uploadFeaturedImage"
                      />
                      <img
                        v-if="previewFeaturedImage"
                        :src="previewFeaturedImage"
                        height="80"
                        width="130"
                      />
                      <img
                        v-else
                        src="/core/assets/images/400.png"
                        height="80"
                        width="130"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Featured image title</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputProductTitle"
                        placeholder="Featured image title"
                        v-model="photoTitle"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Featured image alt</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputProductTitle"
                        placeholder="Featured image alt"
                        v-model="photoAlt"
                      />
                    </div>
                  </div>
                  <div class="border border-3 p-4 rounded">
                    <div class="mb-3">
                      <label class="form-label">Short Description</label>
                      <ckeditor
                        :editor="editor"
                        v-model="shortDescription"
                        :config="editorConfig"
                      ></ckeditor>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Long Description</label>
                      <ckeditor
                        :editor="editor"
                        v-model="longDescription"
                        :config="editorConfig"
                      ></ckeditor>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="border border-3 p-4 rounded mb-2">
                    <div class="row g-3">
                      <div class="col-12">
                        <label for="inputVendor" class="form-label"
                          >Category</label
                        >
                        <select class="form-select" v-model="category" id="inputVendor">
                          <option v-for="(data, index) in categoryList" :key="index" :value="{id: data.id, name: data.categoryName}">
                          {{data.categoryName}}
                          </option>
                        </select>
                      </div>
                      <div class="col-12">
                        <label for="inputCollection" class="form-label"
                          >Related Tags</label
                        >
                        <Multiselect
                          v-model="tagsValue"
                          mode="tags"
                          :close-on-select="false"
                          :searchable="true"
                          :create-option="false"
                          :options="tagsList"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row g-3">
                      <div class="col-12">
                        <label for="inputVendor" class="form-label"
                          >Posted By</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="inputProductTitle"
                          placeholder="name"
                          v-model="postedBy"
                        />
                      </div>
                      <div class="col-12">
                        <label for="exampleInputEmail1" class="form-label">Uploader Image <strong>[Preferred Image Size: 150X150, Max Upload Limit: 1MB]</strong></label>
                        <input
                          class="form-control mb-2"
                          type="file"
                          name="featured_image"
                          id="featuredImage"
                          ref="uploaderImage"
                          aria-describedby="imageHelp"
                          accept="image/*"
                          @change="updateUploaderImage"
                        />
                        <img
                          v-if="previousUploaderImage"
                          :src="previousUploaderImage"
                          height="80"
                          width="130"
                        />
                        <img
                          v-else
                          src="/core/assets/images/400.png"
                          height="80"
                          width="130"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="d-grid">
                      <button type="button" @click="addBlogPost" class="btn btn-primary">
                        Save Blog
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src='../js/add-blog-post.js'></script>
