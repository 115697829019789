import { authHeader } from "../../../../auth";

export default {
    data() {
        return {
            setURL : this.$serverURL + this.$api.cms.careerCMS,
            pageCMSData: [],
            imagePath: this.$imageURL + 'career/',
            previewImage: ''
        }
    },
    async mounted() {
        document.title = "HGS - Career CMS";
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        uploadImage(event) {
            this.pageCMSData.image = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async update() {
            try {
                let formData = new FormData();
                formData.append('image', this.pageCMSData.image);
                formData.append('title', this.pageCMSData.title);
                formData.append('formTitle', this.pageCMSData.formTitle);
                formData.append('formSubTitle', this.pageCMSData.formSubTitle);
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data"
                    }
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        }
    }
}