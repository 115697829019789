import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "Start Your Account",
    data() {
        return {
            editor: ClassicEditor,
            setURL: this.$serverURL + this.$api.home.heroSectionAPI,
            singleData: {},
            titleOne: "",
            titleTwo: "",
            imageTitle: "",
            imageAlt: "",
            price: "",
            buttonText: "",
            buttonLink: '',
            image: '',
            mobileImage: '',
            discountName: '',
            previewImage:"",
            mobilePreviewImage:"",
            updateMobilePreviewImage:"",
            updatePreviewImage:"",
            updateImage:"",
            updateMobileImage:"",
        }
    },
    async created() {
        document.title = "HGS - Home Hero Section";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        uploadFeaturedImage: function(event) {
            this.image = event.target.files[0];
            let input = this.$refs.blockImage1;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadFeaturedImageTwo: function(event) {
            this.image = event.target.files[0];
            let input = this.$refs.blockImage2;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = e => {
                    this.previewImage = e.target.result;
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadMobileFeaturedImageTwo: function(event) {
            this.mobileImage = event.target.files[0];
            let input = this.$refs.blockmobileImage2;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = e => {
                    this.mobilePreviewImage = e.target.result;
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateFeaturedImage: function(event) {
            this.updateImage = event.target.files[0];
            let input = this.$refs.blockUpdateImage1;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = e => {
                    this.updatePreviewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateFeaturedImageTwo: function(event) {
            this.updateImage = event.target.files[0];
            let input = this.$refs.blockUpdateImage2;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = e => {
                    this.updatePreviewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateFeaturedMobileImageTwo: function(event) {
            this.updateMobileImage = event.target.files[0];
            let input = this.$refs.blockUpdateMobileImage2;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = e => {
                    this.updateMobilePreviewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        checkValidation: function(onlyGraphics){
          if(onlyGraphics) {
            if ( !this.titleOne ||  !this.buttonLink || !this.image || !this.mobileImage ) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title , Image Link, Desktop image, Mobile Image!"
                });
                return false;
            }
          } else {
            
            if ( !this.titleOne || !this.titleTwo || !this.price || !this.buttonText || !this.buttonLink || !this.image || !this.discountName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter title One, title Two, price, button Text, button Link, image, Discount Name!"
                });
                return false;
            }
          }
          return true;
        },
        addHeroSection: async function (onlyGraphics) {     
           if(this.checkValidation(onlyGraphics)) {
                let formData = new FormData();
                formData.append('titleOne', this.titleOne);
                formData.append('titleTwo', this.titleTwo);
                formData.append('price', this.price ? this.price : 0);
                formData.append('buttonText', this.buttonText);
                formData.append('imageTitle', this.imageTitle);
                formData.append('imageAlt', this.imageAlt);
                formData.append('buttonLink', this.buttonLink);
                formData.append('discountName', this.discountName);
                formData.append('image', this.image);

                if(onlyGraphics){
                    formData.append('type', "image");
                    formData.append('mobileImage', this.mobileImage);
                } else {
                    formData.append('type', "cms");
                }
                // console.log(Object.fromEntries(formData))

                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data",
                    }
                };
                await this.postDataToBackend(config);
                this.previewImage = '';
                this.titleOne = '';
                this.titleTwo = '';
                this.price = '';
                this.buttonText = '';
                this.buttonLink = '';
                this.image = '';
                this.discountName = '';
                await this.getDataList(this.setURL);

                window.$("#addModal").modal('hide');
                window.$("#addGraphics").modal('hide');
            }
        },
        editHeroSection: async function (data) {
            this.singleData = data;
        },
        checkValidationForUpdate: function(){
            if(this.singleData.type === "image") {
              if ( !this.singleData.titleOne ||  !this.singleData.buttonLink || !this.singleData.image ) {
                  this.$swal.fire({
                      icon: "error",
                      text: "Please enter title , Image Link, Image!"
                  });
                  return false;
              }
            } else {       
                if (!this.singleData.titleOne || !this.singleData.titleTwo || !this.singleData.buttonText || !this.singleData.buttonLink || !this.singleData.image || !this.singleData.discountName) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter title One, title Two, price, button Text, button Link, image, Discount Name!"
                    });
                    return false;
                }
            }
            return true;
          },
        updateHeroSection: async function () {
        if(this.checkValidationForUpdate()) {
            let formData = new FormData();
            formData.append('titleOne', this.singleData.titleOne);
            formData.append('titleTwo', this.singleData.titleTwo);
            formData.append('price', this.singleData.price);
            formData.append('imageAlt', this.singleData.imageAlt);
            formData.append('imageTitle', this.singleData.imageTitle);
            formData.append('buttonText', this.singleData.buttonText);
            formData.append('buttonLink', this.singleData.buttonLink);
            formData.append('discountName', this.singleData.discountName);
            formData.append('image', this.updateImage ? this.updateImage : this.singleData.image);
            formData.append('mobileImage', this.updateMobileImage ? this.updateMobileImage : this.singleData.mobileImage);

            // console.log(Object.fromEntries(formData))
            let config = {
                method: "PATCH",
                url: `${this.setURL}${this.singleData.id}` ,
                data: formData,
                headers: {
                    "Authorization": authHeader(),
                    "Content-Type": "multipart/form-data",
                }
            };
            await this.updateDataToBackend(config);
            this.singleData = {};
            this.currentImage = '';
            this.updatePreviewImage = '';
            this.previewImage = '';
            await this.getDataList(this.setURL);
            window.$("#editModal").modal('hide');
        }        
        },
        deleteHeroSection: function (id) {
            this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {
                if (result.isConfirmed) {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method: 'DELETE',
                                url: `${this.setURL}${id}`,
                                headers: {
                                    "Authorization": authHeader(),
                                }
                            }
                            await this.deleteDataToBackend(config);
                            await this.getDataList(this.setURL);
    
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong, please try agian later." + error
                            });
                        }
                    }
                }
            })
            
        }
    }
}