import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            subcategoryList: [],
            siteUrl: this.$serverURL + this.$api.productCategory.subcategorySeoContentURL,
            categoryId: '',
            categoryName: '',
            subcategoryId: '',
            subcategoryName: '',
            subcategorySeoContent: "",
            isActiveList: true
        }
    },
    async created() {
        document.title = "HGS - Subcategory Seo Content";
        await this.getSubcategoryList(this.siteUrl);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getSubcategoryList: async function (url) {
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.subcategoryList = await response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        updateSeoContent: async function() {
            try {
                if (!this.subcategorySeoContent) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter seo content!"
                    });
                    return false;
                }
                let data = {
                    categoryId: this.categoryId,
                    categoryName: this.categoryName,
                    subcategoryId: this.subcategoryId,
                    subcategoryName: this.subcategoryName,
                    subcategorySeoContent: this.subcategorySeoContent
                };
                let config = {
                    method: 'POST',
                    url: this.siteUrl,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                await this.getSubcategoryList(this.siteUrl);
                this.categoryId = "";
                this.categoryName = "";
                this.subcategoryId = "";
                this.subcategoryName = "";
                this.subcategorySeoContent = "";
                this.backToList(true);
            }
            catch(error) {
                console.log(error);
            }
        },
        gotoAddSeoContent(data, isActiveList) {
            this.categoryId = data.categoryId;
            this.categoryName = data.categoryName;
            this.subcategoryId = data.subcategoryId;
            this.subcategoryName = data.subcategoryName;
            this.subcategorySeoContent = data.subcategorySeoContent;
            this.isActiveList = isActiveList;
        },
        backToList(isActiveList) {
            this.isActiveList = isActiveList;
        }
    }
}