import Treeselect from 'vue3-treeselect';
import 'vue3-treeselect/dist/vue3-treeselect.css'
import { authHeader } from "../../../auth";
import Mixin from '../../../mixin';
// import $ from 'jquery';
export default {
    components: { Treeselect },
    mixins: [Mixin],
    data() {
        return {
            productURL: this.$serverURL + this.$api.product.productDiscountURL,
            singleDiscount: {},
            id: this.$route.params.id
        }

    },
    async created() {

        await this.getDiscountData();
    },
    methods: {
        async getDiscountData() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productDiscountURL + '/' + this.id,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.singleDiscount = await response.data.data;

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        updateDiscount: async function () {
            this.isLoading = true;
            let discountedPrice = 0;
            if (!this.singleDiscount.discountType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select a discount type!"
                });
            } else if (!this.singleDiscount.discountAmount) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please select discount amount!"
                });
            }
            else {
                if (this.singleDiscount.discountType == "Amount") {
                    discountedPrice = this.singleDiscount.productPrice - parseFloat(this.singleDiscount.discountAmount);
                } else if (this.singleDiscount.discountType == "Percentage") {
                    discountedPrice = this.singleDiscount.productPrice - (this.singleDiscount.productPrice * (parseFloat(this.singleDiscount.discountAmount) / 100));
                }
                let data = {
                    discountType: this.singleDiscount.discountType,
                    discountAmount: this.singleDiscount.discountAmount,
                    userDiscountedPrice: discountedPrice
                }
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.product.productDiscountURL + '/' + this.id,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                this.$swal.showLoading();
                await this.$axios(config).then((response) => {
                    this.$swal.hideLoading();
                    if (response.data.statusCode == 200 || response.data.statusCode == 201) {
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                        this.$router.push('/user-wise-product-discount');
                    }
                    else {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.$swal.hideLoading();
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
    },
}
