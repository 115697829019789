import { authHeader } from "../../../auth";
import { states } from '../../../../config/states-list';

export default {
    name: "SalesTax",
    data() {
        return {
            setURL: '',
            pageCMSData: [],
            stateName: "",
            taxAmount: 0,
            taxTitle: '',
            dataList: [],
            singleData: {},
            isEdit: false,
            states: states
        }
    },
    async mounted() {
        document.title = "HGS - Sales Tax";
        this.setURL = this.$serverURL + this.$api.salesTax.salseTaxCMS;
        await this.getSalesTaxData();
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        async updateCms() {
            try {
                if(!this.pageCMSData.name) {
                    this.$swal.fire({
                        icon:"info",
                        text: "Enter tax title!"
                    })
                    return;
                } else if(!this.pageCMSData.salesTax) {
                    this.$swal.fire({
                        icon:"info",
                        text: "Enter tax parcentage!"
                    })
                    return;
                }
                let data = {
                    name: this.pageCMSData.name,
                    salesTax: Number(this.pageCMSData.salesTax)
                }
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.salesTax.salseTaxCMS,
                    data: data,
                    headers: {
                        "Authorization":  authHeader()
                    }
                };
                this.$swal.showLoading();
                await this.updateDataToBackend(config);
                this.$swal.hideLoading();
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        async addSalesTax() {
            try {
                if(!this.stateName) {
                    this.$swal.fire({
                        icon:"info",
                        text: "Select state name!"
                    })
                    return;
                } else if(!this.taxTitle) {
                    this.$swal.fire({
                        icon:"info",
                        text: "Enter tax title!"
                    })
                    return;
                } else if(this.taxAmount < 0) {
                    this.$swal.fire({
                        icon:"info",
                        text: "Enter valid tax amount!"
                    })
                    return;
                }
                let data = {
                    stateName: this.stateName,
                    taxAmount: Number(this.taxAmount),
                    taxTitle: this.taxTitle
                }
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.salesTax.salesTaxURL,
                    data: data,
                    headers: {
                        "Authorization":  authHeader()
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.stateName = "";
                        this.taxAmount = 0;
                        this.taxTitle = '';
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
                await this.getSalesTaxData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        },
        getSalesTaxData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.salesTax.salesTaxURL,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.dataList = await response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        deleteSalesTax: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.salesTax.salesTaxURL + '/' + id,
                            headers: {
                                "Authorization":authHeader()
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getSalesTaxData();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                }
            })
        },
        editSalesTax: async function (data) {
            this.singleData = data;
            this.isEdit = true;
        },
        updateValidation: function () {
            if (!this.singleData.stateName) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select state name!"
                })
                return false;
            }
            if (!this.singleData.taxTitle) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter tax title!"
                })
                return false;
            }
            if (this.singleData.taxAmount < 0) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please enter valid price!"
                })
                return false;
            }
            return true;
        },
        updateSalesTax: async function () {
            if (this.updateValidation() == true) {
                let data = {
                    stateName: this.singleData.stateName,
                    taxAmount: this.singleData.taxAmount,
                    taxTitle: this.singleData.taxTitle
                };
                let config = {
                    method: "PATCH",
                    url: this.$serverURL + this.$api.salesTax.salesTaxURL + '/' + this.singleData.id,
                    data: data,
                    headers: {
                        "Authorization":authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                this.isEdit = false;
                await this.getSalesTaxData();
            }
        }
    }
}