import { authHeader } from "../../../auth";

export default {
    name: "EnquiryList",
    data() {
        return {
            dataList: []
        }
    },
    async created() {
        document.title = "HGS - Enquiry";
        await this.fetchData();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        fetchData: async function() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.enquiry.requestURL,
                headers: {
                    "Authorization": authHeader()
                }
            };
            await this.$axios(config).then((response) => {
                if(response.status == 200) {
                    this.dataList = response.data.data;
                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again later!"
                });
            });
        },
        deleteContent: async function(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async(result) => {
                    if (result.isConfirmed) {
                        try {
                            let config = {
                                method:  "DELETE",
                                url: this.$serverURL + this.$api.enquiry.requestURL + '/' + id,
                                headers: {
                                    "Authorization": authHeader()
                                }
                            };
                            await this.$axios(config).then((response) => {
                                if(response.status == 200) {
                                    this.$swal.fire({
                                        icon: "success",
                                        text: "Successfully deleted data!"
                                    });
                                }
                                this.fetchData();
                            }).catch(error => {
                                console.log(error);
                                this.$swal.fire({
                                    icon: "error",
                                    text: error.response.data.message
                                });
                            });
                        } catch (error) {
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong. Please try again!"
                            });
                        }
                    }
            })
        }
    }
}