import { authHeader } from "../../../auth";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
    name: "Top Selling Products",
    data() {
        return {
            editor: ClassicEditor,
            setURL: this.$serverURL + this.$api.topSellingProducts.topSellingProductsAPI,
            singleTopSellingProductsAPI: this.$serverURL + this.$api.topSellingProducts.singleTopSellingProductsAPI,
            productImage: "",
            previewProductImage: null,
            imageAlt: "",
            title: "",
            buttonText: "SHOP NOW",
            buttonLink: "",
            singleTopSellingProduct: {
                id: "",
                image: "",
                imageAlt: "",
                buttonText: "SHOP NOW",
                buttonLink: ""
            },
        };
    },

    async created() {
        document.title = "HGS - Top Selling Product";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js",
        ];
        scripts.forEach((script) => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
        document.head.appendChild(style);
    },

    methods: {
        addTopSellingProduct: async function () {
            if (!this.productImage && !this.imageAlt && !this.buttonText && !this.buttonLink && !this.title) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter top selling product image imageAlt buttonText buttonLink title",
                });
            } else {
                let formData = new FormData();
                formData.append("image", this.productImage);
                formData.append("imageAlt", this.imageAlt);
                formData.append("title", this.title);
                formData.append("buttonText", this.buttonText);
                formData.append("buttonLink", this.buttonLink);

                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: {
                        Authorization: authHeader(),
                    },
                };
                await this.postDataToBackend(config);
                this.productImage = "";
                this.previewProductImage = null;
                this.imageAlt = "";
                this.title = "";
                this.buttonText = "";
                this.buttonLink = "";
                await this.getDataList(this.setURL);
            }
        },

        editTopSellingProduct: async function (data) {
            this.singleTopSellingProduct = data;
            this.previewProductImage = data.image;
        },

        updateTopSellingProduct: async function () {
            if (
                !this.productImage &&
                !this.singleTopSellingProduct.imageAlt &&
                !this.singleTopSellingProduct.buttonText &&
                !this.singleTopSellingProduct.buttonLink &&
                !this.singleTopSellingProduct.title
            )
            {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter top selling product image imageAlt buttonText buttonLink title",
                });
            } else {
                let formData = new FormData();
                formData.append("image", this.productImage || this.singleTopSellingProduct.image);
                formData.append("imageAlt", this.singleTopSellingProduct.imageAlt);
                formData.append("title", this.singleTopSellingProduct.title);
                formData.append("buttonText", this.singleTopSellingProduct.buttonText);
                formData.append("buttonLink", this.singleTopSellingProduct.buttonLink);

                let config = {
                    method: "PATCH",
                    url: this.singleTopSellingProductsAPI + this.singleTopSellingProduct.id,
                    data: formData,
                    headers: {
                        Authorization: authHeader(),
                    },
                };
                await this.updateDataToBackend(config);
                this.productImage = "";
                this.previewProductImage = null;
                this.singleTopSellingProduct.imageAlt = "";
                this.singleTopSellingProduct.title = "";
                this.singleTopSellingProduct.buttonText = "";
                this.singleTopSellingProduct.buttonLink = "";
                await this.getDataList(this.setURL);
            }
        },

        deleteTopSellingProduct: function (id) {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        if (result.isConfirmed) {
                            try {
                                let config = {
                                    method: "DELETE",
                                    url: this.singleTopSellingProductsAPI + id,
                                    headers: {
                                        Authorization: authHeader(),
                                    },
                                };
                                await this.deleteDataToBackend(config);
                                await this.getDataList(this.setURL);
                            } catch (error) {
                                this.$swal.fire({
                                    icon: "error",
                                    text: "Something went wrong, please try agian later." + error,
                                });
                            }
                        }
                    }
                });
        },

        uploadProductImageAdd: function () {
            this.productImage = event.target.files[0];
            let input = this.$refs.productImageInputAdd
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewProductImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },

        uploadProductImageUpdate: function () {
            this.productImage = event.target.files[0];
            let input = this.$refs.productImageInputUpdate
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewProductImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        }
    },
};
