import Loading from "vue-loading-overlay";
import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx'

export default {
    components: {
        Loading,
    },
    data() {
        return {
            dataList: [],
            sheets: [],
        }
    },
    async created() {
        document.title = "HGS - Products";
        //await this.getExcelProducts();
        await this.getProducts();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getProducts: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productDiscountURL,
                headers: {
                    "Authorization": authHeader(),
                }
            };
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.dataList = response.data.data;
                    // console.log("product list", this.productList);
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            });
        },
        getExcelProducts: async function () {
            // this.isLoading = true;
            // let config = {
            //     method: "GET",
            //     url: this.$serverURL + this.$api.product.productDiscountURL
            //     ,
            //     headers: {
            //         "Authorization":authHeader(),
            //     }
            // };
            // await this.$axios(config).then((response) => {
            //     if (response.data.statusCode === 200) {
            //         //this.sheets = response.data.products;
            //         response.data.data.map((el) => {
            //             this.sheets.push({
            //                 'Product Name': el.productName,
            //                 'Customer Name': el.name,
            //                 'Discount Type': el.discountType,
            //                 'Discount Amount': el.discountAmount,
            //             });
            //         })
            //         const data = XLSX.utils.json_to_sheet(this.sheets)
            //         const wb = XLSX.utils.book_new()
            //         const date = new Date().toLocaleString();
            //         XLSX.utils.book_append_sheet(wb, data, 'data')
            //         XLSX.writeFile(wb,'user-wise-product-discount-export('+date+').xlsx')
            //     }
            //     this.isLoading = false;
            // }).catch(error => {
            //     console.log(error);
            //     this.isLoading = false;
            //     this.$swal.fire({
            //         icon: "error",
            //         text: "Something went wrong. Please try again!"
            //     });
            // });
        },
        deleteDiscount: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then(async(result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.$serverURL + this.$api.product.productDiscountURL + '/' + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.$axios(config).then((response) => {
                            if (response.data.data == true) {
                                this.getProducts();
                                this.$swal.fire({
                                    icon: "warning",
                                    text: response.data.message
                                });
                            }
                            this.isLoading = false;
                        }).catch(error => {
                            console.log(error);
                            this.isLoading = false;
                            this.$swal.fire({
                                icon: "error",
                                text: "Something went wrong. Please try again!"
                            });
                        });
                    } catch (error) {
                        console.log(error)
                    }
                }
            })
        },
        // download : function() {
        //     const data = XLSX.utils.json_to_sheet(this.sheets)
        //     const wb = XLSX.utils.book_new()
        //     XLSX.utils.book_append_sheet(wb, data, 'data')
        //     XLSX.writeFile(wb,'product-export.xlsx')
        // }
        exportData: function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    'Id': el.id,
                    'Product Name': el.productName,
                    "Customer Name": el.name,
                    "Discount Type": el.discountType,
                    "Discount Amount": el.discountAmount,
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'order_report(' + date + ').xlsx')
            this.sheets = [];
        },
    }
}
