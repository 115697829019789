import { authHeader } from "../../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            setURL : this.$serverURL + this.$api.cms.productCMS,
            pageCMSData: [],
            imagePath: this.$imageURL + 'product-page-cms/',
            previewImage: ''
        }
    },
    async mounted() {
        document.title = "HGS - Product CMS";

        let config = {
            method: "GET",
            url: this.setURL,
            headers: {
                "Authorization": authHeader()
            }
        }
        await this.$axios(config).then(async (response) => {
            if (response.status == 200) {

                console.log();
                this.pageCMS = await response.data.data;
            }
            else {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again."
                });
            }
        }).catch(error => {

            this.$swal.fire({
                icon: "error",
                text: "Failed! please try again." + error
            });
        });
      console.log("this.pageCMS", this.pageCMS);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
        console.log("pageCMSData", this.pageCMSData);

    },
    methods: {
        uploadImage(event) {
            this.pageCMSData.image = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async update() {
            try {
                if(!this.pageCMSData.image){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter Image!"
                    });
                }
                else if(!this.pageCMSData.title) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter title!"
                    });
                }
                else {
                    let formData = new FormData();
                    formData.append('image', this.pageCMSData.image);
                    formData.append('title', this.pageCMSData.title);
                    formData.append('productSeoContent', this.pageCMSData.productSeoContent);
                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.createUpdateCMS(config);
                    await this.getCmsData(this.setURL);
                    
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}