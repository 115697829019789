<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Location</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard"
                  ><i class="bx bx-home-alt"></i
                ></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Location Page CMS
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Location Page CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-8">
                <div class="border border-3 p-4 rounded">
                  <!-- <div class="mb-3">
                                        <label class="form-label">Button Text</label>
                                        <input type="text" v-model="pageCMSData.buttonText" class="form-control" id="inputPageHeaderPageName" placeholder="Enter Button Text">
                                    </div> -->
                  <div class="mb-3">
                    <label class="form-label">Location Page Title</label>
                    <input
                      type="text"
                      v-model="pageCMSData.locationPageTitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Button Link"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label"
                      >Location Details Page Title</label
                    >
                    <input
                      type="text"
                      v-model="pageCMSData.locationDetailsPageTitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Button Link"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Hear about us option</label>
                    <input
                      type="text"
                      v-model="pageCMSData.hearAboutUsOptions"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Button Link"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Enquery Title</label>
                    <input
                      type="text"
                      v-model="pageCMSData.enqueryTitle"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Button Link"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Button Text</label>
                    <input
                      type="text"
                      v-model="pageCMSData.buttonText"
                      class="form-control"
                      id="inputPageHeaderPageName"
                      placeholder="Enter Button Link"
                    />
                  </div>
                  <div class="mb-3">
                    <label class="form-label"
                      >Page Background Image:
                      <strong
                        >[Preferred Image Size: 500X500, Max Upload Limit:
                        1MB]</strong
                      ></label
                    >
                    <input
                      class="form-control"
                      type="file"
                      @change="uploadImage"
                      ref="addImage"
                      id="formFile"
                    />
                  </div>
                  <div class="mb-3">
                    <div class="col-md-12">
                      <img
                        v-if="previewImage"
                        :src="previewImage"
                        height="90"
                        width="150"
                      />
                      <img
                        v-else
                        :src="imagePath + pageCMSData.backgroundImage"
                        height="90"
                        width="150"
                      />
                    </div>
                  </div>
                  <div class="d-grid">
                    <button
                      v-on:click="update()"
                      type="button"
                      class="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/location-cms.js"></script>
