import { authHeader } from "../../../auth";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
    name: "Featured Brands",
    data() {
        return {
            editor: ClassicEditor,
            setURL: this.$serverURL + this.$api.homeBrand.homeBrandAPI,
            singleHomeBrandAPI: this.$serverURL + this.$api.homeBrand.singleHomeBrandAPI,
            brandImage: "",
            previewBrandImage: null,
            imageAlt: "",
            imageLink: "",
            singleHomeBrand: {
                id: "",
                image: "",
                imageAlt: "",
                imageLink: ""
            },
        };
    },

    async created() {
        document.title = "HGS - Featured Brands";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js",
        ];
        scripts.forEach((script) => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
        document.head.appendChild(style);
    },

    methods: {
        addHomeBrand: async function () {
            if (!this.brandImage && !this.imageAlt && !this.imageLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter brand image imageAlt imageLink",
                });
            } else {
                let formData = new FormData();
                formData.append("image", this.brandImage);
                formData.append("imageAlt", this.imageAlt);
                formData.append("imageLink", this.imageLink);

                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: {
                        Authorization: authHeader(),
                    },
                };
                await this.postDataToBackend(config);
                this.brandImage = "";
                this.previewBrandImage = null;
                this.imageAlt = "";
                this.imageLink = "";
                await this.getDataList(this.setURL);
            }
        },

        editHomeBrand: async function (data) {
            this.singleHomeBrand = data;
            this.previewBrandImage = data.image;
        },

        updateHomeBrand: async function () {
            if (!this.brandImage && !this.singleHomeBrand.imageAlt && !this.singleHomeBrand.imageLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter brand image imageAlt imageLink",
                });
            } else
            {
                let formData = new FormData();
                formData.append("imageAlt", this.singleHomeBrand.imageAlt);
                formData.append("imageLink", this.singleHomeBrand.imageLink);
                formData.append("image", this.brandImage);

                let config = {
                    method: "PATCH",
                    url: this.singleHomeBrandAPI + this.singleHomeBrand.id,
                    data: formData,
                    headers: {
                        Authorization: authHeader(),
                    },
                };
                await this.updateDataToBackend(config);
                this.brandImage = "";
                this.previewBrandImage = null;
                this.singleHomeBrand.imageAlt = "";
                this.singleHomeBrand.imageLink = "";
                await this.getDataList(this.setURL);
            }
        },

        deleteHomeBrand: function (id) {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        if (result.isConfirmed) {
                            try {
                                let config = {
                                    method: "DELETE",
                                    url: this.singleHomeBrandAPI + id,
                                    headers: {
                                        Authorization: authHeader(),
                                    },
                                };
                                await this.deleteDataToBackend(config);
                                await this.getDataList(this.setURL);
                            } catch (error) {
                                this.$swal.fire({
                                    icon: "error",
                                    text: "Something went wrong, please try agian later." + error,
                                });
                            }
                        }
                    }
                });
        },

        uploadBrandImageForAdd: function () {
            this.brandImage = event.target.files[0];
            let input = this.$refs.brandImageInputAdd
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewBrandImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },

        uploadBrandImageForUpdate: function () {
            this.brandImage = event.target.files[0];
            let input = this.$refs.brandImageInputUpdate
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewBrandImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        }
    },
};
