<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Popular Search List</li>
                    </ol>
                </nav>

                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                            <i class="bx bxs-plus-square"></i>Add New Popular Search
                        </button>
                    </div>

                    <!-- Add Modal Start-->
                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form @submit.prevent="addPopularSearch" enctype="multipart/form-data">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add Popular Search</h5>
                                        <button
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Search Title:</label>
                                        <input
                                            class="form-control mb-1"
                                            type="text"
                                            v-model="searchTitle"
                                            placeholder="Search Title"
                                            aria-label="default input example"
                                        />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Search Link:</label>
                                        <input
                                            class="form-control mb-1"
                                            type="text"
                                            v-model="searchLink"
                                            placeholder="Search Link"
                                            aria-label="default input example"
                                        />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Add</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Add Modal End-->

                    <!-- Edit Role Modal Start -->
                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form @submit.prevent="updatePopularSearch">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Update Popular Search</h5>
                                        <button
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Search Title:</label>
                                        <input
                                            class="form-control mb-1"
                                            type="text"
                                            v-model="singlePopularSearch.searchTitle"
                                            placeholder="Search Title"
                                            aria-label="default input example"
                                        />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Search Link:</label>
                                        <input
                                            class="form-control mb-1"
                                            type="text"
                                            v-model="singlePopularSearch.searchLink"
                                            placeholder="Search Link"
                                            aria-label="default input example"
                                        />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Edit Role Modal End -->
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Popular Search List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Search Title</th>
                                    <th>Search Link</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.searchTitle }}</td>
                                    <td>{{ data.searchLink }}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a
                                                href="javascript:void(0);"
                                                @click="editPopularSearch(data)"
                                                data-bs-toggle="modal"
                                                data-bs-target="#editModal"
                                                class="edit-button"
                                                ><i class="bx bxs-edit text-white"></i
                                            ></a>
                                            <a
                                                href="javascript:void(0);"
                                                @click="deletePopularSearch(data.id)"
                                                class="ms-3 delete-button"
                                                ><i class="bx bxs-trash text-white"></i
                                            ></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Search Title</th>
                                    <th>Search Link</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/popularSearch"></script>

<style scoped>
    .edit-button {
        background-color: #17a00e;
    }

    .delete-button {
        background-color: #f41127;
    }
</style>
