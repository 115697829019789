<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Quotation List
            </li>
          </ol>
        </nav>

        <div class="ms-auto">
            <div class="btn-group">
            <button type="button" class="btn btn-secondary btn-sm m-1" @click="getExcelProducts">
              <i class="bx bxs-plus-square"></i>Export
            </button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Quotation List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Company</th>
                  <th>Quote</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{index + 1}}</td>
                  <td>{{data.firstName}}</td>
                  <td>{{data.lastName}}</td>
                  <td>{{data.email}}</td>
                  <td>{{data.phone}}</td>
                  <td>{{data.company}}</td>
                  <td>
                    {{data.quote.slice(0, 50)}}
                    <a href="javascript:void(0)" v-if="data.quote.length > 10" @click="quoteBody(data.quote)" data-bs-toggle="modal" data-bs-target="#exampleModal" style="padding: 3px;border-radius: 5px;" class="edit-button"><i class="fa-solid fa-eye text-white"></i></a>
                  </td>
                  <td>{{$filters.formatDate(data.createdAt)}}</td>

                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteQuotation(data.id)"
                        class="ms-3 delete-button"
                        ><i class="bx bxs-trash text-white"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Company</th>
                  <th>Quote</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Quotation Message</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{ message }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script src='../js/quotation.js'></script>


<style scoped>
@import "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>
