import { authHeader } from "../../../../auth";

export default {
    data() {
        return {
            BrandList: [],
            siteUrl: this.$serverURL + this.$api.brandMeta.brandMetaURL,
            brandId: '',
            brandName: '',
            keywords: "",
            title: "",
            description: ""
        }
    },
    async created() {
        document.title = "HGS - Category Meta";
        await this.getProductList(this.siteUrl);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getProductList: async function (url) {
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.BrandList = await response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        updateMetaTitle: async function () {
            try {
                if (!this.title) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter title!"
                    });
                    return false;
                }
                let data = {
                    brandId: this.brandId,
                    metaTitle: this.title,
                    metaKeyword: this.keywords,
                    metaDescription: this.description,
                    brandName: this.brandName
                };
                let config = {
                    method: 'POST',
                    url: this.siteUrl,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                await this.getProductList(this.siteUrl);
                this.brandId = "";
                this.brandName = "";
                this.keywords = "";
                this.title = "";
                this.description = "";
            }
            catch (error) {
                console.log(error);
            }
        },
        updateMetaKeyword: async function () {
            try {
                if (!this.keywords) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter keywords!"
                    });
                    return false;
                }
                let data = {
                    brandId: this.brandId,
                    metaKeyword: this.keywords,
                    metaDescription: this.description,
                    brandName: this.brandName,
                    metaTitle: this.title,
                };
                let config = {
                    method: 'POST',
                    url: this.siteUrl,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                await this.getProductList(this.siteUrl);
                this.brandId = "";
                this.brandName = "";
                this.keywords = "";
                this.title = "";
                this.description = "";
            }
            catch (error) {
                console.log(error);
            }
        },
        updateMetaDescription: async function () {
            try {
                if (!this.description) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter description!"
                    });
                    return false;
                }
                let data = {
                    brandId: this.brandId,
                    metaTitle: this.title,
                    metaKeyword: this.keywords,
                    metaDescription: this.description,
                    brandName: this.brandName
                };
                let config = {
                    method: 'POST',
                    url: this.siteUrl,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                await this.getProductList(this.siteUrl);
                this.brandId = "";
                this.brandName = "";
                this.title = "";
                this.keywords = "";
                this.description = "";
            }
            catch (error) {
                console.log(error);
            }
        },
        updateModalValue(data) {
            this.brandId = data.id;
            this.brandName = data.name;
            this.title = data?.metaTitle;
            this.keywords = data.metaKeyword;
            this.description = data.metaDescription;
        }
    }
}