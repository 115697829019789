<template>
   <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Create Customer Account - Email Template - Elementor</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Create Customer Account - Email Template - Elementor</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body p-4">
          <h5 class="card-title">Create Customer Account - Email Template - Elementor</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row" style="position: relative;">
              <div class="col-lg-9 col-md-6" style="overflow:hidden;">
                <div class="border border-3 p-4 rounded">
                  <div class="mb-3 d-flex justify-center" style="text-align:center; display: flex !important;justify-content: center; flex-direction: column;" id="emailBody">
                    
                    <table id="u_body" class="u-body" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 520px;margin: auto;background-color: #ffffff;width:100%; display: flex !important; justify-content: center !important;" cellpadding="0" cellspacing="0">
                        <tbody>
                            <tr style="vertical-align: top">
                                <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
                                    
                                    <!-- Start Logo Section -->
                                    <div class="u-row-container" :style="'padding: 0px;background-color: '+template.logo.backgroundColor+'; position: relative;'" @mouseenter="handleLogoCustomizationShow"  @mouseleave="handleLogoCustomizationClose">
                                        <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleLogoCustomizationAction"><i class='bx bxs-edit-alt'></i></span>
                                        <div class="u-row" :style="'Margin: 0 auto;min-width: 320px;max-width: '+template.sectionOne.background.width+'px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word; background-color: '+template.logo.backgroundColor+' !important;'">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                                <div class="u-col u-col-100" :style="'max-width: 320px;min-width: '+template.sectionOne.background.width+'px;display: table-cell;vertical-align: top;'">
                                                    <div style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table border="0" cellpadding="0" cellspacing="0" class="image_block block-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                <tr>
                                                                    <td class="pad" style="width:100%;padding-right:0px;padding-left:0px;">
                                                                        <div :align="template.logo.align" class="alignment" style="line-height:10px"><img alt="Alternate text" :src="template.logo.url" :style="'display: block; height: '+template.logo.height+'px; border: '+ template.logo.borderSize +'px '+template.logo.borderStyle +' '+template.logo.borderColor+'; width: '+template.logo.width+'px; max-width: 100%; padding-top: '+template.logo.paddingTop+'px; padding-right: '+template.logo.paddingRight+'px; padding-bottom: '+template.logo.paddingBottom+'px; padding-left: '+template.logo.paddingLeft+'px; margin-top: '+template.logo.marginTop+'px; margin-right: '+template.logo.marginRight+'px;margin-bottom: '+template.logo.marginBottom+'px; margin-left: '+template.logo.marginLeft+'px; object-fit: '+template.logo.objectFit+';'" title="Alternate text" width="200"/></div>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!-- End Logo Section -->
                                    
                                    <!-- Start Section One -->
                                    <div class="u-row-container" style="padding: 0px;background-color: transparent; position: relative;" @mouseenter="handleSectionCustomizationShow"  @mouseleave="handleSectionCustomizationClose">

                                        <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                        <div class="u-row" :style="'Margin: 0 auto;min-width: 320px;max-width: '+template.sectionOne.background.width+'px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;'">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                                <div class="u-col u-col-100" :style="'max-width: 320px;min-width: '+template.sectionOne.background.width+'px;display: table-cell;vertical-align: top;'">
                                                    <div :style="'background-color: '+template.sectionOne.background.bgColor+';height: 100%;width: 100% !important;border-radius: '+template.sectionOne.background.borderRadius+'px;-webkit-border-radius: '+template.sectionOne.background.borderRadius+'px; -moz-border-radius: '+template.sectionOne.background.borderRadius+'px;'">
                                                        
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:40px 10px 10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                                                            <tr>
                                                                                <td style="padding-right: 0px;padding-left: 0px; position: relative;" :align="template.sectionOne.image.align" @mouseenter="handleIconCustomizationShow"  @mouseleave="handleIconCustomizationClose">
                                                                                    <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionOneIconCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                    <img align="center" border="0" :src="template.sectionOne.image.url" alt="Cart Icon" title="Cart Icon" :style="'outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: '+template.sectionOne.image.width+'px; height: '+template.sectionOne.image.height+'px; object-fit: '+template.sectionOne.image.objectFit+'; border: '+template.sectionOne.image.borderSize+'px '+template.sectionOne.image.borderStyle+' '+template.sectionOne.image.borderColor+'; border-radius: '+template.sectionOne.image.borderRadius+'%; padding-top: '+template.sectionOne.image.paddingTop+'px; padding-right: '+template.sectionOne.image.paddingRight+'px; padding-bottom: '+template.sectionOne.image.paddingBottom+'px; padding-left: '+template.sectionOne.image.paddingLeft+'px; margin-top: '+template.sectionOne.image.marginTop+'px; margin-right: '+template.sectionOne.image.marginRight+'px;margin-bottom: '+template.sectionOne.image.marginBottom+'px; margin-left: '+template.sectionOne.image.marginLeft+'px;'" :width="template.sectionOne.image.width"/>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif; position: relative;" align="left" @mouseenter="handleSectionTitleOneCustomizationShow"  @mouseleave="handleSectionTitleOneCustomizationClose">

                                                                        <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionTitleOneCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                        <div :style="'line-height: 140%; text-align: '+template.sectionOne.titleOne.align+'; word-wrap: break-word;'">
                                                                            <p v-if="titleOneStatus" style="font-size: 14px; line-height: 140%;">
                                                                                <input type="text" v-model="template.sectionOne.titleOne.text" 
                                                                                :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.sectionOne.titleOne.align+'; line-height: 25.2px; font-size: '+template.sectionOne.titleOne.fontSize+'px;  font-weight: bold; padding-top: '+template.sectionOne.titleOne.paddingTop+'px; padding-right: '+template.sectionOne.titleOne.paddingRight+'px; padding-bottom: '+template.sectionOne.titleOne.paddingBottom+'px; padding-left: '+template.sectionOne.titleOne.paddingLeft+'px; margin-top: '+template.sectionOne.titleOne.marginTop+'px; margin-right: '+template.sectionOne.titleOne.marginRight+'px;margin-bottom: '+template.sectionOne.titleOne.marginBottom+'px; margin-left: '+template.sectionOne.titleOne.marginLeft+'px;'"
                                                                                @blur="handleTitleOneTextClose">
                                                                            </p>
                                                                            <p v-else @click="handleTitleOneTextShow" style="font-size: 14px; line-height: 140%;"><span :style="'display: block; font-size: '+template.sectionOne.titleOne.fontSize+'px; line-height: '+template.sectionOne.titleOne.lineHeight+'px; font-family: '+template.sectionOne.titleOne.fontFamily+'; padding-top: '+template.sectionOne.titleOne.paddingTop+'px; padding-right: '+template.sectionOne.titleOne.paddingRight+'px; padding-bottom: '+template.sectionOne.titleOne.paddingBottom+'px; padding-left: '+template.sectionOne.titleOne.paddingLeft+'px; margin-top: '+template.sectionOne.titleOne.marginTop+'px; margin-right: '+template.sectionOne.titleOne.marginRight+'px;margin-bottom: '+template.sectionOne.titleOne.marginBottom+'px; margin-left: '+template.sectionOne.titleOne.marginLeft+'px;'"><strong><span :style="'line-height: 25.2px; font-size: '+template.sectionOne.titleOne.fontSize+'px; color: '+template.sectionOne.titleOne.color+';'">{{ template.sectionOne.titleOne.text }}</span></strong></span></p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:0px 10px 10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <div :style="'line-height: 140%; word-wrap: break-word;'">
                                                                            <div style="position: relative;" @mouseenter="handleSectionTitleTwoCustomizationShow"  @mouseleave="handleSectionTitleTwoCustomizationClose">
                                                                                <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionTitleTwoCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <p v-if="titleTwoStatus" style="font-size: 14px; line-height: 140%;">
                                                                                    <input type="text" v-model="template.sectionOne.titleTwo.text" 
                                                                                    :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.sectionOne.titleTwo.align+'; line-height: 19.6px; font-size: '+template.sectionOne.titleTwo.fontSize+'px;  font-weight: bold; padding-top: '+template.sectionOne.titleTwo.paddingTop+'px; padding-right: '+template.sectionOne.titleTwo.paddingRight+'px; padding-bottom: '+template.sectionOne.titleTwo.paddingBottom+'px; padding-left: '+template.sectionOne.titleTwo.paddingLeft+'px; margin-top: '+template.sectionOne.titleTwo.marginTop+'px; margin-right: '+template.sectionOne.titleTwo.marginRight+'px;margin-bottom: '+template.sectionOne.titleTwo.marginBottom+'px; margin-left: '+template.sectionOne.titleTwo.marginLeft+'px;'"
                                                                                    @blur="handleTitleTwoTextClose">
                                                                                </p>
                                                                                <p v-else @click="handleTitleTwoTextShow" :style="'font-size: 14px; line-height: 140%; text-align: '+template.sectionOne.titleTwo.align+';'">
                                                                                    <span :style="'display: block; font-size: '+template.sectionOne.titleTwo.fontSize+'px; line-height: '+template.sectionOne.titleTwo.lineHeight+'px; font-family: '+template.sectionOne.titleTwo.fontFamily+'; padding-top: '+template.sectionOne.titleTwo.paddingTop+'px; padding-right: '+template.sectionOne.titleTwo.paddingRight+'px; padding-bottom: '+template.sectionOne.titleTwo.paddingBottom+'px; padding-left: '+template.sectionOne.titleTwo.paddingLeft+'px; margin-top: '+template.sectionOne.titleTwo.marginTop+'px; margin-right: '+template.sectionOne.titleTwo.marginRight+'px;margin-bottom: '+template.sectionOne.titleTwo.marginBottom+'px; margin-left: '+template.sectionOne.titleTwo.marginLeft+'px; color: '+template.sectionOne.titleTwo.color+';'">{{ template.sectionOne.titleTwo.text }}</span>
                                                                                </p>
                                                                            </div>
                                                                            
                                                                            <div style="position: relative;" @mouseenter="handleSectionTitleThreeCustomizationShow"  @mouseleave="handleSectionTitleThreeCustomizationClose">
                                                                                <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionTitleThreeCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <p v-if="titleThreeStatus" style="font-size: 14px; line-height: 140%;">
                                                                                    <input type="text" v-model="template.sectionOne.titleThree.text" 
                                                                                    :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.sectionOne.titleThree.align+'; line-height: 19.6px; font-size: '+template.sectionOne.titleThree.fontSize+'px;  font-weight: bold; padding-top: '+template.sectionOne.titleThree.paddingTop+'px; padding-right: '+template.sectionOne.titleThree.paddingRight+'px; padding-bottom: '+template.sectionOne.titleThree.paddingBottom+'px; padding-left: '+template.sectionOne.titleThree.paddingLeft+'px; margin-top: '+template.sectionOne.titleThree.marginTop+'px; margin-right: '+template.sectionOne.titleThree.marginRight+'px;margin-bottom: '+template.sectionOne.titleThree.marginBottom+'px; margin-left: '+template.sectionOne.titleThree.marginLeft+'px;'"
                                                                                    @blur="handleTitleThreeTextClose">
                                                                                </p>
                                                                                <p v-else @click="handleTitleThreeTextShow" :style="'font-size: 14px; line-height: 140%; text-align: '+template.sectionOne.titleThree.align+';'">
                                                                                    <span :style="'display: block; font-size: '+template.sectionOne.titleThree.fontSize+'px; line-height: '+template.sectionOne.titleThree.lineHeight+'px; font-family: '+template.sectionOne.titleThree.fontFamily+'; padding-top: '+template.sectionOne.titleThree.paddingTop+'px; padding-right: '+template.sectionOne.titleThree.paddingRight+'px; padding-bottom: '+template.sectionOne.titleThree.paddingBottom+'px; padding-left: '+template.sectionOne.titleThree.paddingLeft+'px; margin-top: '+template.sectionOne.titleThree.marginTop+'px; margin-right: '+template.sectionOne.titleThree.marginRight+'px;margin-bottom: '+template.sectionOne.titleThree.marginBottom+'px; margin-left: '+template.sectionOne.titleThree.marginLeft+'px; color: '+template.sectionOne.titleThree.color+';'">{{ template.sectionOne.titleThree.text }}</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td :style="'overflow-wrap:break-word;word-break:break-word; padding:10px 10px 20px;font-family: '+template.sectionOne.button.fontFamily+'; position: relative;'" align="left" @mouseenter="handleSectionButtonCustomizationShow"  @mouseleave="handleSectionButtonCustomizationClose">

                                                                        <span style="position: absolute; top: 0; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionButtonCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                        <div :align="template.sectionOne.button.align" :style="'margin-top: '+template.sectionOne.button.marginTop+'px; margin-right: '+template.sectionOne.button.marginRight+'px;margin-bottom: '+template.sectionOne.button.marginBottom+'px; margin-left: '+template.sectionOne.button.marginLeft+'px;'">
                                                                            <a :href="template.sectionOne.button.link" target="_blank" class="v-button" :style="'box-sizing: border-box;display: inline-block;font-family:'+template.sectionOne.button.fontFamily+';text-decoration: none;-webkit-text-size-adjust: none;text-align: '+template.sectionOne.button.align+';color: '+template.sectionOne.button.color+'; background-color: '+template.sectionOne.button.backgroundColor+'; border-radius: '+template.sectionOne.button.borderRadius+'px;-webkit-border-radius: '+template.sectionOne.button.borderRadius+'px; -moz-border-radius: '+template.sectionOne.button.borderRadius+'px; width:auto; max-width:100%; overflow-wrap: break-word; word-break: break-word; word-wrap:break-word; mso-border-alt: none;'">
                                                                            <span :style="'display:block;padding-top: '+template.sectionOne.button.paddingTop+'px; padding-right: '+template.sectionOne.button.paddingRight+'px; padding-bottom: '+template.sectionOne.button.paddingBottom+'px; padding-left: '+template.sectionOne.button.paddingLeft+'px; line-height:120%;'"><span :style="'font-family: '+template.sectionOne.button.fontFamily+'; font-size: '+template.sectionOne.button.fontSize+'px; line-height: 16.8px;'"><strong>{{ template.sectionOne.button.text }}</strong></span></span>
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 2px solid #e7e7e7;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                            <tbody>
                                                                            <tr style="vertical-align: top">
                                                                                <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                                    <span>&#160;</span>
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!-- End Section One -->

                                    <!-- Start Order Status Section -->
                                    <div class="u-row-container" style="padding: 0px;background-color: transparent; position: relative;" @mouseenter="handleSectionOrderStatusCustomizationShow"  @mouseleave="handleSectionOrderStatusCustomizationClose">

                                        <span style="position: absolute; top: -10px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionOrderStatusCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                        <div class="u-row" :style="'Margin: 0 auto;min-width: 320px;max-width: '+template.orderStatusSection.background.width+'px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;'">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                                <div class="u-col u-col-100" :style="'max-width: 320px;min-width: '+template.orderStatusSection.background.width+'px;display: table-cell;vertical-align: top;'">
                                                    <div :style="'background-color: '+template.orderStatusSection.background.bgColor+';height: 100%;width: 100% !important;border-radius: '+template.orderStatusSection.background.borderRadius+'px;-webkit-border-radius: '+template.orderStatusSection.background.borderRadius+'px; -moz-border-radius: '+template.orderStatusSection.background.borderRadius+'px;'">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            

                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="center">
                                                                            <div :style="'line-height: 140%; text-align: '+template.orderStatusSection.orderTitle.align+'; word-wrap: break-word;'">
                                                                                <p style="font-size: 14px; line-height: 140%; margin-bottom: 0px; position: relative;" @mouseenter="handleOrderStatusCustomizationShow"  @mouseleave="handleOrderStatusCustomizationClose">

                                                                                    <span style="position: absolute; top: 0px; right: 10px; display: none; font-size: 20px;cursor: pointer;" @click="handleOrderStatusCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                    <strong>
                                                                                        <span v-if="OrderStatusT" style="font-size: 14px; line-height: 140%;">
                                                                                            <input type="text" v-model="template.orderStatusSection.orderTitle.text" 
                                                                                            :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.orderStatusSection.orderTitle.align+'; line-height: '+template.orderStatusSection.orderTitle.lineHeight+'px; font-size: '+template.orderStatusSection.orderTitle.fontSize+'px;  font-weight: bold; padding-top: '+template.orderStatusSection.orderTitle.paddingTop+'px; padding-right: '+template.orderStatusSection.orderTitle.paddingRight+'px; padding-bottom: '+template.orderStatusSection.orderTitle.paddingBottom+'px; padding-left: '+template.orderStatusSection.orderTitle.paddingLeft+'px; margin-top: '+template.orderStatusSection.orderTitle.marginTop+'px; margin-right: '+template.orderStatusSection.orderTitle.marginRight+'px;margin-bottom: '+template.orderStatusSection.orderTitle.marginBottom+'px; margin-left: '+template.orderStatusSection.orderTitle.marginLeft+'px;'"
                                                                                            @blur="handleOrderStatusTextClose">
                                                                                        </span>
                                                                                        <span v-else @click="handleOrderStatusTextShow" :style="'display: block; font-size: '+template.orderStatusSection.orderTitle.fontSize+'px; line-height: '+template.orderStatusSection.orderTitle.lineHeight+'px; font-family: '+template.orderStatusSection.orderTitle.fontFamily+'; padding-top: '+template.orderStatusSection.orderTitle.paddingTop+'px; padding-right: '+template.orderStatusSection.orderTitle.paddingRight+'px; padding-bottom: '+template.orderStatusSection.orderTitle.paddingBottom+'px; padding-left: '+template.orderStatusSection.orderTitle.paddingLeft+'px; margin-top: '+template.orderStatusSection.orderTitle.marginTop+'px; margin-right: '+template.orderStatusSection.orderTitle.marginRight+'px;margin-bottom: '+template.orderStatusSection.orderTitle.marginBottom+'px; margin-left: '+template.orderStatusSection.orderTitle.marginLeft+'px; color: '+template.orderStatusSection.orderTitle.color+';'">{{ template.orderStatusSection.orderTitle.text }}</span>
                                                                                    </strong>
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 2px solid #e7e7e7;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                            <tbody>
                                                                            <tr style="vertical-align: top">
                                                                                <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                                    <span>&#160;</span>
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!-- End Order Status Section -->

                                    <!-- Start Order No and Tracking No Section -->
                                    <div class="u-row-container" style="padding: 0px;background-color: transparent; position: relative;" @mouseenter="handleOrderNoAndTrakingNoCustomizationShow"  @mouseleave="handleOrderNoAndTrakingNoCustomizationClose">

                                        <span style="position: absolute; top: -10px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleOrderNoAndTrakingNoCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                        <div class="u-row" :style="'Margin: 0 auto;min-width: 320px;max-width: '+template.orderNoAndTrakingSection.background.width+'px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;'">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                                <div class="u-col u-col-100" :style="'max-width: 320px;min-width: '+template.orderNoAndTrakingSection.background.width+'px;display: table-cell;vertical-align: top;'">
                                                    <div :style="'background-color: '+template.orderNoAndTrakingSection.background.bgColor+';height: 100%;width: 100% !important;border-radius: '+template.orderNoAndTrakingSection.background.borderRadius+'px;-webkit-border-radius: '+template.orderNoAndTrakingSection.background.borderRadius+'px; -moz-border-radius: '+template.orderNoAndTrakingSection.background.borderRadius+'px;'">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            

                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                            <div :style="'line-height: 140%; text-align: '+template.orderNoAndTrakingSection.orderNoTitle.align+'; word-wrap: break-word; position: relative;'" @mouseenter="handleOrderNoCustomizationShow"  @mouseleave="handleOrderNoCustomizationClose">

                                                                                <span style="position: absolute; top: 0px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleOrderNoCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <p style="font-size: 14px; line-height: 140%; margin-bottom: 0px;">
                                                                                    <strong>
                                                                                        <span v-if="OrderNoT" style="font-size: 14px; line-height: 140%;">
                                                                                            <input type="text" v-model="template.orderNoAndTrakingSection.orderNoTitle.text" 
                                                                                            :style="'display: block; width: 90%; border: none; background: transparent; text-align: '+template.orderNoAndTrakingSection.orderNoTitle.align+'; line-height: '+template.orderNoAndTrakingSection.orderNoTitle.lineHeight+'px; font-size: '+template.orderNoAndTrakingSection.orderNoTitle.fontSize+'px;  font-weight: bold; padding-top: '+template.orderNoAndTrakingSection.orderNoTitle.paddingTop+'px; padding-right: '+template.orderNoAndTrakingSection.orderNoTitle.paddingRight+'px; padding-bottom: '+template.orderNoAndTrakingSection.orderNoTitle.paddingBottom+'px; padding-left: '+template.orderNoAndTrakingSection.orderNoTitle.paddingLeft+'px; margin-top: '+template.orderNoAndTrakingSection.orderNoTitle.marginTop+'px; margin-right: '+template.orderNoAndTrakingSection.orderNoTitle.marginRight+'px;margin-bottom: '+template.orderNoAndTrakingSection.orderNoTitle.marginBottom+'px; margin-left: '+template.orderNoAndTrakingSection.orderNoTitle.marginLeft+'px;'"
                                                                                            @blur="handleOrderNoTextClose">
                                                                                        </span>
                                                                                        <span v-else @click="handleOrderNoTextShow" :style="'display: block; font-size: '+template.orderNoAndTrakingSection.orderNoTitle.fontSize+'px; line-height: '+template.orderNoAndTrakingSection.orderNoTitle.lineHeight+'px; font-family: '+template.orderNoAndTrakingSection.orderNoTitle.fontFamily+'; padding-top: '+template.orderNoAndTrakingSection.orderNoTitle.paddingTop+'px; padding-right: '+template.orderNoAndTrakingSection.orderNoTitle.paddingRight+'px; padding-bottom: '+template.orderNoAndTrakingSection.orderNoTitle.paddingBottom+'px; padding-left: '+template.orderNoAndTrakingSection.orderNoTitle.paddingLeft+'px; margin-top: '+template.orderNoAndTrakingSection.orderNoTitle.marginTop+'px; margin-right: '+template.orderNoAndTrakingSection.orderNoTitle.marginRight+'px;margin-bottom: '+template.orderNoAndTrakingSection.orderNoTitle.marginBottom+'px; margin-left: '+template.orderNoAndTrakingSection.orderNoTitle.marginLeft+'px; color: '+template.orderNoAndTrakingSection.orderNoTitle.color+';'">{{ template.orderNoAndTrakingSection.orderNoTitle.text }}</span>
                                                                                    </strong>
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                            <div :style="'line-height: 140%; text-align: '+template.orderNoAndTrakingSection.orderTrakingTitle.align+'; word-wrap: break-word; position: relative;'" @mouseenter="handleTrackingNoCustomizationShow"  @mouseleave="handleTrackingNoCustomizationClose">

                                                                                <span style="position: absolute; top: 0px; right: 20px; display: none; font-size: 20px;cursor: pointer;" @click="handleTrackingNoCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <p style="font-size: 14px; line-height: 140%; margin-bottom: 0px;">
                                                                                    <strong>
                                                                                        <span v-if="OrderTrakingT" style="font-size: 14px; line-height: 140%;">
                                                                                            <input type="text" v-model="template.orderNoAndTrakingSection.orderTrakingTitle.text" 
                                                                                            :style="'display: block; width: 90%; border: none; background: transparent; text-align: '+template.orderNoAndTrakingSection.orderTrakingTitle.align+'; line-height: '+template.orderNoAndTrakingSection.orderTrakingTitle.lineHeight+'px; font-size: '+template.orderNoAndTrakingSection.orderTrakingTitle.fontSize+'px;  font-weight: bold; padding-top: '+template.orderNoAndTrakingSection.orderTrakingTitle.paddingTop+'px; padding-right: '+template.orderNoAndTrakingSection.orderTrakingTitle.paddingRight+'px; padding-bottom: '+template.orderNoAndTrakingSection.orderTrakingTitle.paddingBottom+'px; padding-left: '+template.orderNoAndTrakingSection.orderTrakingTitle.paddingLeft+'px; margin-top: '+template.orderNoAndTrakingSection.orderTrakingTitle.marginTop+'px; margin-right: '+template.orderNoAndTrakingSection.orderTrakingTitle.marginRight+'px;margin-bottom: '+template.orderNoAndTrakingSection.orderTrakingTitle.marginBottom+'px; margin-left: '+template.orderNoAndTrakingSection.orderTrakingTitle.marginLeft+'px;'"
                                                                                            @blur="handleOrderTrakingTextClose">
                                                                                        </span>
                                                                                        <span v-else @click="handleOrderTrakingTextShow" :style="'display: block; font-size: '+template.orderNoAndTrakingSection.orderTrakingTitle.fontSize+'px; line-height: '+template.orderNoAndTrakingSection.orderTrakingTitle.lineHeight+'px; font-family: '+template.orderNoAndTrakingSection.orderTrakingTitle.fontFamily+'; padding-top: '+template.orderNoAndTrakingSection.orderTrakingTitle.paddingTop+'px; padding-right: '+template.orderNoAndTrakingSection.orderTrakingTitle.paddingRight+'px; padding-bottom: '+template.orderNoAndTrakingSection.orderTrakingTitle.paddingBottom+'px; padding-left: '+template.orderNoAndTrakingSection.orderTrakingTitle.paddingLeft+'px; margin-top: '+template.orderNoAndTrakingSection.orderTrakingTitle.marginTop+'px; margin-right: '+template.orderNoAndTrakingSection.orderTrakingTitle.marginRight+'px;margin-bottom: '+template.orderNoAndTrakingSection.orderTrakingTitle.marginBottom+'px; margin-left: '+template.orderNoAndTrakingSection.orderTrakingTitle.marginLeft+'px; color: '+template.orderNoAndTrakingSection.orderTrakingTitle.color+';'">{{ template.orderNoAndTrakingSection.orderTrakingTitle.text }}</span>
                                                                                    </strong>
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>

                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 2px solid #e7e7e7;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                            <tbody>
                                                                            <tr style="vertical-align: top">
                                                                                <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                                    <span>&#160;</span>
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!-- End Order No and Tracking No Section -->

                                    <!-- Start Shipping and Payment Section -->
                                    <div class="u-row-container" style="padding: 0px;background-color: transparent; position: relative;" @mouseenter="handleShippingAndPaymentCustomizationShow"  @mouseleave="handleShippingAndPaymentCustomizationClose">

                                        <span style="position: absolute; top: -10px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleShippingAndPaymentCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                        <div class="u-row" :style="'Margin: 0 auto;min-width: 320px;max-width: '+template.shippingAandPaymentMSection.background.width+'px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;'">
                                        <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                            <div class="u-col u-col-50" :style="'max-width: 320px;min-width: 250px;display: table-cell;vertical-align: top;'">
                                                <div :style="'background-color: '+template.shippingAandPaymentMSection.background.bgColor+';height: 100%;width: 100% !important;border-radius: '+template.shippingAandPaymentMSection.background.borderRadius+'px;-webkit-border-radius: '+template.shippingAandPaymentMSection.background.borderRadius+'px; -moz-border-radius: '+template.shippingAandPaymentMSection.background.borderRadius+'px;'">
                                                    <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                        <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                            <tbody>
                                                            <tr>
                                                                <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                    <div :style="'line-height: 140%; text-align: '+template.shippingAandPaymentMSection.shippingATitle.align+'; word-wrap: break-word; position: relative;'" @mouseenter="handleShippingCustomizationShow"  @mouseleave="handleShippingCustomizationClose">

                                                                        <span style="position: absolute; top: 0px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleShippingCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                        <p style="font-size: 14px; line-height: 140%;">
                                                                            <strong>
                                                                                <span v-if="ShippingT" style="font-size: 14px; line-height: 140%;">
                                                                                    <input type="text" v-model="template.shippingAandPaymentMSection.shippingATitle.text" 
                                                                                    :style="'display: block; width: 90%; border: none; background: transparent; text-align: '+template.shippingAandPaymentMSection.shippingATitle.align+'; line-height: '+template.shippingAandPaymentMSection.shippingATitle.lineHeight+'px; font-size: '+template.shippingAandPaymentMSection.shippingATitle.fontSize+'px;  font-weight: bold; padding-top: '+template.shippingAandPaymentMSection.shippingATitle.paddingTop+'px; padding-right: '+template.shippingAandPaymentMSection.shippingATitle.paddingRight+'px; padding-bottom: '+template.shippingAandPaymentMSection.shippingATitle.paddingBottom+'px; padding-left: '+template.shippingAandPaymentMSection.shippingATitle.paddingLeft+'px; margin-top: '+template.shippingAandPaymentMSection.shippingATitle.marginTop+'px; margin-right: '+template.shippingAandPaymentMSection.shippingATitle.marginRight+'px;margin-bottom: '+template.shippingAandPaymentMSection.shippingATitle.marginBottom+'px; margin-left: '+template.shippingAandPaymentMSection.shippingATitle.marginLeft+'px;'"
                                                                                    @blur="handleShippingTextClose">
                                                                                </span>
                                                                                <span v-else @click="handleShippingTextShow" :style="'display: block; font-size: '+template.shippingAandPaymentMSection.shippingATitle.fontSize+'px; line-height: '+template.shippingAandPaymentMSection.shippingATitle.lineHeight+'px; font-family: '+template.shippingAandPaymentMSection.shippingATitle.fontFamily+'; padding-top: '+template.shippingAandPaymentMSection.shippingATitle.paddingTop+'px; padding-right: '+template.shippingAandPaymentMSection.shippingATitle.paddingRight+'px; padding-bottom: '+template.shippingAandPaymentMSection.shippingATitle.paddingBottom+'px; padding-left: '+template.shippingAandPaymentMSection.shippingATitle.paddingLeft+'px; margin-top: '+template.shippingAandPaymentMSection.shippingATitle.marginTop+'px; margin-right: '+template.shippingAandPaymentMSection.shippingATitle.marginRight+'px;margin-bottom: '+template.shippingAandPaymentMSection.shippingATitle.marginBottom+'px; margin-left: '+template.shippingAandPaymentMSection.shippingATitle.marginLeft+'px; color: '+template.shippingAandPaymentMSection.shippingATitle.color+';'">{{ template.shippingAandPaymentMSection.shippingATitle.text }}</span>
                                                                            </strong>
                                                                            <br />
                                                                            <span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">&nbsp;</span></p>
                                                                            <p style="font-size: 14px; line-height: 140%;"><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">[shippingName]</span><br /><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">[streetAddressOne], [streetAddressTwo]</span><br /><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">[city], [stateCode] [postCode]</span><br /><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">[shippingCountryCode], [shippingCountry]</span></p>
                                                                            <p style="font-size: 14px; line-height: 140%;"><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">[shippingEmail]</span><br>
                                                                            <span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">[shippingPhone]</span>
                                                                            </p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="u-col u-col-50" style="max-width: 320px;min-width: 250px;display: table-cell;vertical-align: top;">
                                                <div :style="'background-color: '+template.shippingAandPaymentMSection.background.bgColor+';height: 100%;width: 100% !important;border-radius: '+template.shippingAandPaymentMSection.background.borderRadius+'px;-webkit-border-radius: '+template.shippingAandPaymentMSection.background.borderRadius+'px; -moz-border-radius: '+template.shippingAandPaymentMSection.background.borderRadius+'px;'">
                                                    <div style="height: 100%; padding: 0px 0px 29px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                        <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                            <tbody>
                                                            <tr>
                                                                <td style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 2px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                    <div :style="'line-height: 140%; text-align: '+template.shippingAandPaymentMSection.paymentMTitle.align+'; word-wrap: break-word; position: relative;'" @mouseenter="handlePaymentCustomizationShow"  @mouseleave="handlePaymentCustomizationClose">

                                                                        <span style="position: absolute; top: 0px; right: 20px; display: none; font-size: 20px;cursor: pointer;" @click="handlePaymentCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                        <p style="font-size: 14px; line-height: 140%;">
                                                                            <strong>
                                                                                <span v-if="PaymentT" style="font-size: 14px; line-height: 140%;">
                                                                                    <input type="text" v-model="template.shippingAandPaymentMSection.paymentMTitle.text" 
                                                                                    :style="'display: block; width: 90%; border: none; background: transparent; text-align: '+template.shippingAandPaymentMSection.paymentMTitle.align+'; line-height: '+template.shippingAandPaymentMSection.paymentMTitle.lineHeight+'px; font-size: '+template.shippingAandPaymentMSection.paymentMTitle.fontSize+'px;  font-weight: bold; padding-top: '+template.shippingAandPaymentMSection.paymentMTitle.paddingTop+'px; padding-right: '+template.shippingAandPaymentMSection.paymentMTitle.paddingRight+'px; padding-bottom: '+template.shippingAandPaymentMSection.paymentMTitle.paddingBottom+'px; padding-left: '+template.shippingAandPaymentMSection.paymentMTitle.paddingLeft+'px; margin-top: '+template.shippingAandPaymentMSection.paymentMTitle.marginTop+'px; margin-right: '+template.shippingAandPaymentMSection.paymentMTitle.marginRight+'px;margin-bottom: '+template.shippingAandPaymentMSection.paymentMTitle.marginBottom+'px; margin-left: '+template.shippingAandPaymentMSection.paymentMTitle.marginLeft+'px;'"
                                                                                    @blur="handlePaymentTextClose">
                                                                                </span>
                                                                                <span v-else @click="handlePaymentTextShow" :style="'display: block; font-size: '+template.shippingAandPaymentMSection.paymentMTitle.fontSize+'px; line-height: '+template.shippingAandPaymentMSection.paymentMTitle.lineHeight+'px; font-family: '+template.shippingAandPaymentMSection.paymentMTitle.fontFamily+'; padding-top: '+template.shippingAandPaymentMSection.paymentMTitle.paddingTop+'px; padding-right: '+template.shippingAandPaymentMSection.paymentMTitle.paddingRight+'px; padding-bottom: '+template.shippingAandPaymentMSection.paymentMTitle.paddingBottom+'px; padding-left: '+template.shippingAandPaymentMSection.paymentMTitle.paddingLeft+'px; margin-top: '+template.shippingAandPaymentMSection.paymentMTitle.marginTop+'px; margin-right: '+template.shippingAandPaymentMSection.paymentMTitle.marginRight+'px;margin-bottom: '+template.shippingAandPaymentMSection.paymentMTitle.marginBottom+'px; margin-left: '+template.shippingAandPaymentMSection.paymentMTitle.marginLeft+'px; color: '+template.shippingAandPaymentMSection.paymentMTitle.color+';'">{{ template.shippingAandPaymentMSection.paymentMTitle.text }}</span>
                                                                            </strong>
                                                                        </p>
                                                                        <p style="font-size: 14px; line-height: 140%;">&nbsp;</p>
                                                                        <p style="font-size: 14px; line-height: 140%;"><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">[PaymentMethod]</span></p>
                                                                        <p style="font-size: 14px; line-height: 140%;">&nbsp;</p>
                                                                        <p style="font-size: 14px; line-height: 140%;">&nbsp;</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div> <!-- End Shipping and Payment Section -->

                                    <!-- Start Order Summary Section -->
                                    <div class="u-row-container" style="padding: 0px;background-color: transparent; position: relative;" @mouseenter="handleSectionOrderSummaryCustomizationShow"  @mouseleave="handleSectionOrderSummaryCustomizationClose">

                                        <span style="position: absolute; top: -10px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionOrderSummaryCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                        <div class="u-row" :style="'Margin: 0 auto;min-width: 320px;max-width: '+template.orderSummarySection.background.width+'px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;'">
                                        <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                            <div class="u-col u-col-100" :style="'max-width: 320px;min-width: '+template.orderSummarySection.background.width+'px;display: table-cell;vertical-align: top;'">
                                            <div :style="'background-color: '+template.orderSummarySection.background.bgColor+';height: 100%;width: 100% !important;border-radius: '+template.orderSummarySection.background.borderRadius+'px;-webkit-border-radius: '+template.orderSummarySection.background.borderRadius+'px; -moz-border-radius: '+template.orderSummarySection.background.borderRadius+'px;'">
                                                <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                    <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                        <tbody>
                                                        <tr>
                                                            <td style="overflow-wrap:break-word;word-break:break-word;padding:20px 10px 10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                <div :style="'line-height: 140%; text-align: '+template.orderSummarySection.orderTitle.align+'; word-wrap: break-word; position: relative;'" @mouseenter="handleOrderSummaryCustomizationShow"  @mouseleave="handleOrderSummaryCustomizationClose">

                                                                    <span style="position: absolute; top: 0px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleOrderSummaryCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                    <p style="font-size: 14px; line-height: 140%;">
                                                                        <strong>
                                                                            <span v-if="OrderSummaryStatus" style="font-size: 14px; line-height: 140%;">
                                                                                <input type="text" v-model="template.orderSummarySection.orderTitle.text" 
                                                                                :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.orderSummarySection.orderTitle.align+'; line-height: '+template.orderSummarySection.orderTitle.lineHeight+'px; font-size: '+template.orderSummarySection.orderTitle.fontSize+'px;  font-weight: bold; padding-top: '+template.orderSummarySection.orderTitle.paddingTop+'px; padding-right: '+template.orderSummarySection.orderTitle.paddingRight+'px; padding-bottom: '+template.orderSummarySection.orderTitle.paddingBottom+'px; padding-left: '+template.orderSummarySection.orderTitle.paddingLeft+'px; margin-top: '+template.orderSummarySection.orderTitle.marginTop+'px; margin-right: '+template.orderSummarySection.orderTitle.marginRight+'px;margin-bottom: '+template.orderSummarySection.orderTitle.marginBottom+'px; margin-left: '+template.orderSummarySection.orderTitle.marginLeft+'px;'"
                                                                                @blur="handleOrderSummaryTextClose">
                                                                            </span>
                                                                            <span v-else @click="handleOrderSummaryTextShow" :style="'display: block; font-size: '+template.orderSummarySection.orderTitle.fontSize+'px; line-height: '+template.orderSummarySection.orderTitle.lineHeight+'px; font-family: '+template.orderSummarySection.orderTitle.fontFamily+'; padding-top: '+template.orderSummarySection.orderTitle.paddingTop+'px; padding-right: '+template.orderSummarySection.orderTitle.paddingRight+'px; padding-bottom: '+template.orderSummarySection.orderTitle.paddingBottom+'px; padding-left: '+template.orderSummarySection.orderTitle.paddingLeft+'px; margin-top: '+template.orderSummarySection.orderTitle.marginTop+'px; margin-right: '+template.orderSummarySection.orderTitle.marginRight+'px;margin-bottom: '+template.orderSummarySection.orderTitle.marginBottom+'px; margin-left: '+template.orderSummarySection.orderTitle.marginLeft+'px; color: '+template.orderSummarySection.orderTitle.color+';'">{{ template.orderSummarySection.orderTitle.text }}</span>
                                                                        </strong>
                                                                    </p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                        <tbody>
                                                        <tr>
                                                            <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 2px solid #e7e7e7;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                    <tbody>
                                                                    <tr style="vertical-align: top">
                                                                        <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                            <span>&#160;</span>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div> <!-- End Order Summary Section -->

                                    <div class="u-row-container" style="padding: 0px;background-color: transparent">
                                        <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                                <div class="u-col u-col-54p33" style="max-width: 100%;min-width: 271.65px;display: table-cell;vertical-align: top;">
                                                <div style="background-color: #ffffff;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                    <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                        <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                            <tbody>
                                                            [orderItemsStart]
                                                            <tr>
                                                                <td style="padding-right: 0px;padding-left: 0px;" align="center">
                                                                    <img align="center" border="0" src='http://202.133.91.38:7777/productImage/[featuredImage]' alt="Jewelry Bracelet " title="Jewelry Bracelet " style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 85%;max-width: 98.34px;" width="98.34"/>
                                                                </td>
                                                                <td style="overflow-wrap:break-word;word-break:break-word;padding:30px 10px 67px 20px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                    <div style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                        <p style="font-size: 14px; line-height: 140%;"><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;"><span style="color: #000000; font-size: 14px; line-height: 19.6px;"><strong><span style="font-size: 14px; line-height: 19.6px;">[name] </span></strong></span><span style="color: #666666; font-size: 14px; line-height: 19.6px;"> x [quantity]</span></span></p>
                                                                    </div>
                                                                </td>
                                                                <td style="overflow-wrap:break-word;word-break:break-word;padding:30px 10px 47px 20px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                    <div style="line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                        <p style="font-size: 14px; line-height: 140%;">$[itemPrice]</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            [orderItemsEnd]
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="u-row-container" style="padding: 0px;background-color: transparent">
                                        <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                                <div class="u-col u-col-100" style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
                                                    <div style="background-color: #ffffff;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <table height="0px" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 2px solid #e7e7e7;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                            <tbody>
                                                                            <tr style="vertical-align: top">
                                                                                <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                                    <span>&#160;</span>
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="u-row-container" style="padding: 0px;background-color: transparent">
                                        <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                                <div class="u-col u-col-54p13" style="max-width: 320px;min-width: 270.65px;display: table-cell;vertical-align: top;">
                                                    <div style="background-color: #ffffff;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <div style="color: #ffffff; line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                            <p style="font-size: 14px; line-height: 140%;">.</p>
                                                                            <p style="font-size: 14px; line-height: 140%;">&nbsp;</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="u-col u-col-23p13" style="max-width: 320px;min-width: 115.65px;display: table-cell;vertical-align: top;">
                                                    <div style="background-color: #ffffff;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <div style="line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                            <p style="font-size: 14px; line-height: 140%;"><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">Subtotal</span></p>
                                                                            <p style="font-size: 14px; line-height: 140%;"><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">Your savings</span></p>
                                                                            <p style="font-size: 14px; line-height: 140%;"><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">Shipping Cost</span></p>
                                                                            <p style="font-size: 14px; line-height: 140%;"><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">Tax</span></p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="u-col u-col-22p74" style="max-width: 320px;min-width: 113.7px;display: table-cell;vertical-align: top;">
                                                    <div style="background-color: #ffffff;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <div style="line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                            <p style="font-size: 14px; line-height: 140%;"><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">$[subTotal]</span></p>
                                                                            <p style="font-size: 14px; line-height: 140%;"><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">$[itemTotalDiscount]</span></p>
                                                                            <p style="font-size: 14px; line-height: 140%;"><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">$[shippingMethodCost]</span></p>
                                                                            <p style="font-size: 14px; line-height: 140%;"><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">$[salesTaxAmount]</span></p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="u-row-container" style="padding: 0px;background-color: transparent">
                                        <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                                <div class="u-col u-col-100" style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
                                                    <div style="background-color: #ffffff;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <table height="0px" align="right" border="0" cellpadding="0" cellspacing="0" width="47%" style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;border-top: 2px solid #e7e7e7;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                            <tbody>
                                                                            <tr style="vertical-align: top">
                                                                                <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top;font-size: 0px;line-height: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%">
                                                                                    <span>&#160;</span>
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="u-row-container" style="padding: 0px;background-color: transparent">
                                        <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                                <div class="u-col u-col-54p13" style="max-width: 320px;min-width: 270.65px;display: table-cell;vertical-align: top;">
                                                    <div style="background-color: #ffffff;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 11px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <div style="color: #ffffff; line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                            <p style="font-size: 14px; line-height: 140%;">.</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="u-col u-col-23p13" style="max-width: 320px;min-width: 115.65px;display: table-cell;vertical-align: top;">
                                                    <div style="background-color: #ffffff;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 11px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <div style="line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                            <p style="font-size: 14px; line-height: 140%;"><strong><span style="font-family: Montserrat, sans-serif; font-size: 14px; line-height: 19.6px;">Total</span></strong></p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="u-col u-col-22p74" style="max-width: 320px;min-width: 113.7px;display: table-cell;vertical-align: top;">
                                                    <div style="background-color: #ffffff;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 11px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <div style="line-height: 140%; text-align: left; word-wrap: break-word;">
                                                                            <p style="font-size: 14px; line-height: 140%;"><span style="font-size: 16px; line-height: 22.4px;"><strong><span style="font-family: Montserrat, sans-serif; line-height: 22.4px; font-size: 16px;">$[totalAmount]</span></strong></span></p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Start Contact Us Section -->
                                    <div class="u-row-container" style="padding: 0px;background-color: transparent; position: relative;" @mouseenter="handleSectionContactUsCustomizationShow"  @mouseleave="handleSectionContactUsCustomizationClose">

                                        <span style="position: absolute; top: -10px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionContactUsCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                        <div class="u-row" :style="'Margin: 0 auto;min-width: 320px;max-width: '+template.contactUsSection.background.width+'px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;'">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                                                <div class="u-col u-col-100" :style="'max-width: 320px;min-width: '+template.contactUsSection.background.width+'px;display: table-cell;vertical-align: top;'">
                                                <div :style="'background-color: '+template.contactUsSection.background.bgColor+';height: 100%;width: 100% !important;border-radius: '+template.contactUsSection.background.borderRadius+'px;-webkit-border-radius: '+template.contactUsSection.background.borderRadius+'px; -moz-border-radius: '+template.contactUsSection.background.borderRadius+'px;'">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="overflow-wrap:break-word;word-break:break-word;padding:20px 10px 10px;font-family:arial,helvetica,sans-serif;" align="left">
                                                                        <div :style="'line-height: 140%; word-wrap: break-word;'">
                                                                            <div style="position: relative;" @mouseenter="handleContactUsTitleOneCustomizationShow"  @mouseleave="handleContactUsTitleOneCustomizationClose">

                                                                                <span style="position: absolute; top: 0px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleContactUsTitleOneCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <p :style="'font-size: 14px; line-height: 140%;text-align: '+template.contactUsSection.orderTitleOne.align+';'">
                                                                                    <span v-if="ContactUsTOStatus" :style="'display: block; width: 100%; font-size: 14px; line-height: 140%;'">
                                                                                        <input type="text" v-model="template.contactUsSection.orderTitleOne.text" 
                                                                                        :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.contactUsSection.orderTitleOne.align+'; line-height: '+template.contactUsSection.orderTitleOne.lineHeight+'px; font-size: '+template.contactUsSection.orderTitleOne.fontSize+'px;  font-weight: bold; padding-top: '+template.contactUsSection.orderTitleOne.paddingTop+'px; padding-right: '+template.contactUsSection.orderTitleOne.paddingRight+'px; padding-bottom: '+template.contactUsSection.orderTitleOne.paddingBottom+'px; padding-left: '+template.contactUsSection.orderTitleOne.paddingLeft+'px; margin-top: '+template.contactUsSection.orderTitleOne.marginTop+'px; margin-right: '+template.contactUsSection.orderTitleOne.marginRight+'px;margin-bottom: '+template.contactUsSection.orderTitleOne.marginBottom+'px; margin-left: '+template.contactUsSection.orderTitleOne.marginLeft+'px;'"
                                                                                        @blur="handleContactUsTOTextClose">
                                                                                    </span>
                                                                                    <span v-else @click="handleContactUsTOTextShow" :style="'display: block; font-size: '+template.contactUsSection.orderTitleOne.fontSize+'px; line-height: '+template.contactUsSection.orderTitleOne.lineHeight+'px; font-family: '+template.contactUsSection.orderTitleOne.fontFamily+'; padding-top: '+template.contactUsSection.orderTitleOne.paddingTop+'px; padding-right: '+template.contactUsSection.orderTitleOne.paddingRight+'px; padding-bottom: '+template.contactUsSection.orderTitleOne.paddingBottom+'px; padding-left: '+template.contactUsSection.orderTitleOne.paddingLeft+'px; margin-top: '+template.contactUsSection.orderTitleOne.marginTop+'px; margin-right: '+template.contactUsSection.orderTitleOne.marginRight+'px;margin-bottom: '+template.contactUsSection.orderTitleOne.marginBottom+'px; margin-left: '+template.contactUsSection.orderTitleOne.marginLeft+'px; color: '+template.contactUsSection.orderTitleOne.color+';'">{{ template.contactUsSection.orderTitleOne.text }}</span>
                                                                                </p>
                                                                            </div>
                                                                            
                                                                            <div style="position: relative;" @mouseenter="handleContactUsTitleTwoCustomizationShow"  @mouseleave="handleContactUsTitleTwoCustomizationClose">

                                                                                <span style="position: absolute; top: 0px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleContactUsTitleTwoCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <p :style="'font-size: 14px; line-height: 140%;text-align: '+template.contactUsSection.orderTitleTwo.align+';'">
                                                                                    <span v-if="ContactUsTTStatus" :style="'display: block; width: 100%; font-size: 14px; line-height: 140%;'">
                                                                                        <input type="text" v-model="template.contactUsSection.orderTitleTwo.text" 
                                                                                        :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.contactUsSection.orderTitleTwo.align+'; line-height: '+template.contactUsSection.orderTitleTwo.lineHeight+'px; font-size: '+template.contactUsSection.orderTitleTwo.fontSize+'px;  font-weight: bold; padding-top: '+template.contactUsSection.orderTitleTwo.paddingTop+'px; padding-right: '+template.contactUsSection.orderTitleTwo.paddingRight+'px; padding-bottom: '+template.contactUsSection.orderTitleTwo.paddingBottom+'px; padding-left: '+template.contactUsSection.orderTitleTwo.paddingLeft+'px; margin-top: '+template.contactUsSection.orderTitleTwo.marginTop+'px; margin-right: '+template.contactUsSection.orderTitleTwo.marginRight+'px;margin-bottom: '+template.contactUsSection.orderTitleTwo.marginBottom+'px; margin-left: '+template.contactUsSection.orderTitleTwo.marginLeft+'px;'"
                                                                                        @blur="handleContactUsTTTextClose">
                                                                                    </span>
                                                                                    <span v-else @click="handleContactUsTTTextShow" :style="'display: block; font-size: '+template.contactUsSection.orderTitleTwo.fontSize+'px; line-height: '+template.contactUsSection.orderTitleTwo.lineHeight+'px; font-family: '+template.contactUsSection.orderTitleTwo.fontFamily+'; padding-top: '+template.contactUsSection.orderTitleTwo.paddingTop+'px; padding-right: '+template.contactUsSection.orderTitleTwo.paddingRight+'px; padding-bottom: '+template.contactUsSection.orderTitleTwo.paddingBottom+'px; padding-left: '+template.contactUsSection.orderTitleTwo.paddingLeft+'px; margin-top: '+template.contactUsSection.orderTitleTwo.marginTop+'px; margin-right: '+template.contactUsSection.orderTitleTwo.marginRight+'px;margin-bottom: '+template.contactUsSection.orderTitleTwo.marginBottom+'px; margin-left: '+template.contactUsSection.orderTitleTwo.marginLeft+'px; color: '+template.contactUsSection.orderTitleTwo.color+';'">{{ template.contactUsSection.orderTitleTwo.text }}</span>
                                                                                </p>
                                                                            </div>

                                                                            <div style="position: relative;" @mouseenter="handleContactUsTitleThreeCustomizationShow"  @mouseleave="handleContactUsTitleThreeCustomizationClose">

                                                                                <span style="position: absolute; top: 0px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleContactUsTitleThreeCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                                                                <p :style="'font-size: 14px; line-height: 140%;text-align: '+template.contactUsSection.orderTitleThree.align+';'">
                                                                                    <span v-if="ContactUsTThStatus" :style="'display: block; width: 100%; font-size: 14px; line-height: 140%;'">
                                                                                        <input type="text" v-model="template.contactUsSection.orderTitleThree.text" 
                                                                                        :style="'display: block; width: 100%; border: none; background: transparent; text-align: '+template.contactUsSection.orderTitleThree.align+'; line-height: '+template.contactUsSection.orderTitleThree.lineHeight+'px; font-size: '+template.contactUsSection.orderTitleThree.fontSize+'px;  font-weight: bold; padding-top: '+template.contactUsSection.orderTitleThree.paddingTop+'px; padding-right: '+template.contactUsSection.orderTitleThree.paddingRight+'px; padding-bottom: '+template.contactUsSection.orderTitleThree.paddingBottom+'px; padding-left: '+template.contactUsSection.orderTitleThree.paddingLeft+'px; margin-top: '+template.contactUsSection.orderTitleThree.marginTop+'px; margin-right: '+template.contactUsSection.orderTitleThree.marginRight+'px;margin-bottom: '+template.contactUsSection.orderTitleThree.marginBottom+'px; margin-left: '+template.contactUsSection.orderTitleThree.marginLeft+'px;'"
                                                                                        @blur="handleContactUsTThTextClose">
                                                                                    </span>
                                                                                    <span v-else @click="handleContactUsTThTextShow" :style="'display: block; font-size: '+template.contactUsSection.orderTitleThree.fontSize+'px; line-height: '+template.contactUsSection.orderTitleThree.lineHeight+'px; font-family: '+template.contactUsSection.orderTitleThree.fontFamily+'; padding-top: '+template.contactUsSection.orderTitleThree.paddingTop+'px; padding-right: '+template.contactUsSection.orderTitleThree.paddingRight+'px; padding-bottom: '+template.contactUsSection.orderTitleThree.paddingBottom+'px; padding-left: '+template.contactUsSection.orderTitleThree.paddingLeft+'px; margin-top: '+template.contactUsSection.orderTitleThree.marginTop+'px; margin-right: '+template.contactUsSection.orderTitleThree.marginRight+'px;margin-bottom: '+template.contactUsSection.orderTitleThree.marginBottom+'px; margin-left: '+template.contactUsSection.orderTitleThree.marginLeft+'px; color: '+template.contactUsSection.orderTitleThree.color+';'">{{ template.contactUsSection.orderTitleThree.text }}</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!-- End Contact Us Section -->

                                    <!-- Start Copyright Section -->
                                    <div class="u-row-container" style="padding: 0px;background-color: transparent; position: relative;" @mouseenter="handleSectionCopyrightCustomizationShow"  @mouseleave="handleSectionCopyrightCustomizationClose">

                                        <span style="position: absolute; top: -10px; right: 0px; display: none; font-size: 20px;cursor: pointer;" @click="handleSectionCopyrightCustomizationAction"><i class='bx bxs-edit-alt'></i></span>

                                        <div class="u-row" style="Margin: 0 auto;min-width: 320px;max-width: 500px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;">
                                            <div style="border-collapse: collapse;display: table;width: 100%;height: 100%;">
                                                <div class="u-col u-col-100" style="max-width: 320px;min-width: 500px;display: table-cell;vertical-align: top;">
                                                    <div :style="'background-color: '+template.copyRight.bgColor+';height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;'">
                                                        <div style="height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                                                            <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="pad" style="vertical-align: middle; color: #9d9d9d; font-family: inherit; font-size: 15px; padding-bottom: 5px; padding-top: 5px; text-align: center;">
                                                                            <table cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                                                                                <tr>
                                                                                    <td class="alignment" :style="'vertical-align: middle; text-align: '+template.copyRight.align+';'">
                                                                                        <table cellpadding="0" cellspacing="0" class="icons-inner" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block; margin-right: -4px; padding-left: 0px; padding-right: 0px;">
                                                                                            <tr>
                                                                                                <td :style="'vertical-align: middle; text-align: '+template.copyRight.align+'; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 6px;'"><a :href="template.copyRight.copyRightText.link" :style="'display: block; text-decoration: none; text-align: '+template.copyRight.align+' padding-top: '+template.copyRight.paddingTop+'px; padding-right: '+template.copyRight.paddingRight+'px; padding-bottom: '+template.copyRight.paddingBottom+'px; padding-left: '+template.copyRight.paddingLeft+'px; margin-top: '+template.copyRight.marginTop+'px; margin-right: '+template.copyRight.marginRight+'px;margin-bottom: '+template.copyRight.marginBottom+'px; margin-left: '+template.copyRight.marginLeft+'px;'" target="_blank"><img align="center" alt="Designed By Neutrix" class="icon" :height="template.copyRight.copyRightImage.height" :src="template.copyRight.copyRightImage.url" :style="'display: block; height: '+template.copyRight.copyRightImage.height+'; margin: 0 auto; border: 0;'" :width="template.copyRight.copyRightImage.width"/></a></td>
                                                                                                <td :style="'font-family: '+template.copyRight.fontFamily+'; font-size: '+template.copyRight.copyRightText.fontSize+'px; color: '+template.copyRight.copyRightText.color+'; vertical-align: middle; letter-spacing: undefined; text-align: '+template.copyRight.align+';'"><a :href="template.copyRight.copyRightText.link" :style="'color: '+template.copyRight.copyRightText.color+'; text-decoration: '+template.copyRight.copyRightText.textDecoration+';'" target="_blank">{{ template.copyRight.copyRightText.text }}</a></td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> <!-- End Copyright Section -->

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                  </div>
                  <div class="mb-3" style="text-align: center;">
                        <button v-on:click="publishUpdateTemplate" type="button" class="btn btn-primary">Publish Updated Template</button> 
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6" style="position: absolute; right: 0px; top: 0px; max-height: 1800px; overflow: auto;">
                    <div class="border border-3 p-4 rounded my-2">
                        <code v-for="(level, index) in templateData" :key="index">{{ level.emailKeywordLabel }}</code>   
                        <hr />
                        <h6>How to use:</h6>
                        <p>Hi Mr <code style="color:black;">[FullName]</code>, Nice to meet you.</p>
                        
                    </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--start logo switcher-->
  <div class="switcher-wrapper logo-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Logo Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <label for="inputProductTitle" class="form-label" style="text-align:left; font-weight: bold;">Logo Image: <br /><strong>[Image Size: 200 X 66, Max: 200 KB]</strong></label>
                <input class="form-control mb-1" type="file" ref="backgroundImage" accept="image/*" @change="uploadFeaturedImage" />
                <div class="logo-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Width: [{{ template.logo.width }}] px</span></div>
                    <input v-model="template.logo.width" type="range" min="10" max="300" class="w-100">
                </div>
                <div class="logo-height" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Height: [{{ template.logo.height }}] px</span></div>
                    <input v-model="template.logo.height" type="range" min="10" max="300" class="w-100">
                </div>
                <div class="logo-object-fit" style="margin-top: 10px;">
                    <div style="display: flex; margin-top: 5px;">
                        <span style="width: 100%;">Object Fit: </span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.logo.objectFit" class="form-control" >
                                <option value="none">none</option>
                                <option value="fill">fill</option>
                                <option value="contain">contain</option>
                                <option value="cover">cover</option>
                                <option value="scale-down">scale-down</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="left"><input v-model="template.logo.align" type="radio" name="align" id="left" value="left"> Left</label>&nbsp;
                    <label for="center"><input v-model="template.logo.align" type="radio" name="align" id="center" value="center"> Center</label>&nbsp;
                    <label for="right"><input v-model="template.logo.align" type="radio" name="align" id="right" value="right"> Right</label>
                </div>

                <LvColorpicker label="Background Color:" v-model="template.logo.backgroundColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Border Size: </span>
                    <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.borderSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>

                    <div style="display: flex; margin-top: 10px; margin-bottom: 10px;">
                        <span style="width: 100%;">Border Style: </span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.logo.borderStyle" >
                                <option value="none">none</option>
                                <option value="hidden">hidden</option>
                                <option value="dotted">dotted</option>
                                <option value="dashed">dashed</option>
                                <option value="solid">solid</option>
                                <option value="double">double</option>
                                <option value="groove">groove</option>
                                <option value="inset">inset</option>
                                <option value="ridge">ridge</option>
                                <option value="outset">outset</option>
                                <option value="initial">initial</option>
                                <option value="inherit">inherit</option>
                            </select>
                        </span>
                    </div>

                    <LvColorpicker label="Border Color: " v-model="template.logo.borderColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                    
                    <div>
                        <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                        <hr style="margin: 0px !important;">
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    </div>
                    

                    <div>
                        <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                        <hr style="margin: 0px !important;">
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                        <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.logo.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    </div>
                </div>
            </div>
        </div>
  </div>
  <!--end logo switcher-->

  <!--start section one icon switcher-->
  <div class="switcher-wrapper section-one-icon-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Icon Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <label for="sectionTwoImage" class="form-label" style="text-align:left; margin-top: 10px; font-weight: bold;">Icon: <br /><strong>[Image Size: 72 X 72, Max: 200 KB]</strong></label>
                    <input class="form-control mb-1" type="file" ref="sectionOneImage" accept="image/*" @change="uploadSectionOneImage" />
                
                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Width: [{{ template.sectionOne.image.width }}] px</span></div>
                    <input v-model="template.sectionOne.image.width" type="range" min="10" max="500" class="w-100">
                </div>
                <div class="setion-image-height" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Height: [{{ template.sectionOne.image.height }}] px</span></div>
                    <input v-model="template.sectionOne.image.height" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSO"><input v-model="template.sectionOne.image.align" type="radio" name="align" id="leftSO" value="left"> Left</label>&nbsp;
                    <label for="centerSO"><input v-model="template.sectionOne.image.align" type="radio" name="align" id="centerSO" value="center"> Center</label>&nbsp;
                    <label for="rightSO"><input v-model="template.sectionOne.image.align" type="radio" name="align" id="rightSO" value="right"> Right</label>
                </div>

                <div class="section-image-height" style="margin-top: 10px;">
                    <div style="display: flex; margin-top: 5px;">
                        <span style="width: 100%;">Object Fit: </span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.sectionOne.image.objectFit" class="form-control" >
                                <option value="none">none</option>
                                <option value="fill">fill</option>
                                <option value="contain">contain</option>
                                <option value="cover">cover</option>
                                <option value="scale-down">scale-down</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-image-border" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Border Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.borderSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 10px;">
                        <span style="width: 100%;">Border Style:</span> 
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.sectionOne.image.borderStyle" class="form-control" >
                                <option value="none">none</option>
                                <option value="hidden">hidden</option>
                                <option value="dotted">dotted</option>
                                <option value="dashed">dashed</option>
                                <option value="solid">solid</option>
                                <option value="double">double</option>
                                <option value="groove">groove</option>
                                <option value="inset">inset</option>
                                <option value="ridge">ridge</option>
                                <option value="outset">outset</option>
                                <option value="initial">initial</option>
                                <option value="inherit">inherit</option>
                            </select>
                        </span>
                    </div>

                    <LvColorpicker label="Border Color: " v-model="template.sectionOne.image.borderColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 10px;" />

                    <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Border Radius: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.borderRadius" type="number" style="width: 100%; margin-right: 3px;"> %</span></div>
                    <input v-model="template.sectionOne.image.borderRadius" type="range" min="0" max="100" class="w-100">
                </div>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.image.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section one icon switcher-->

  <!--start section titleOne switcher-->
  <div class="switcher-wrapper section-title-one-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Title One Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.sectionOne.titleOne.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.titleOne.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.titleOne.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.sectionOne.titleOne.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTO"><input v-model="template.sectionOne.titleOne.align" type="radio" name="align" id="leftSTO" value="left"> Left</label>&nbsp;
                    <label for="centerSTO"><input v-model="template.sectionOne.titleOne.align" type="radio" name="align" id="centerSTO" value="center"> Center</label>&nbsp;
                    <label for="rightSTO"><input v-model="template.sectionOne.titleOne.align" type="radio" name="align" id="rightSTO" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleOne.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end section titleOne switcher-->

  <!--start section titleTwo switcher-->
  <div class="switcher-wrapper section-title-two-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Title Two Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.sectionOne.titleTwo.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-Two" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.titleTwo.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.titleTwo.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color" v-model="template.sectionOne.titleTwo.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTT"><input v-model="template.sectionOne.titleTwo.align" type="radio" name="align" id="leftSTT" value="left"> Left</label>&nbsp;
                    <label for="centerSTT"><input v-model="template.sectionOne.titleTwo.align" type="radio" name="align" id="centerSTT" value="center"> Center</label>&nbsp;
                    <label for="rightSTT"><input v-model="template.sectionOne.titleTwo.align" type="radio" name="align" id="rightSTT" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleTwo.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>               
            </div>
        </div>
  </div>
  <!--end section titleTwo switcher-->

  <!--start section titleThree switcher-->
  <div class="switcher-wrapper section-title-three-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Title Three Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.sectionOne.titleThree.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-Three" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.titleThree.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.titleThree.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.sectionOne.titleThree.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                    <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTTh"><input v-model="template.sectionOne.titleThree.align" type="radio" name="align" id="leftSTTh" value="left"> Left</label>&nbsp;
                    <label for="centerSTTh"><input v-model="template.sectionOne.titleThree.align" type="radio" name="align" id="centerSTTh" value="center"> Center</label>&nbsp;
                    <label for="rightSTTh"><input v-model="template.sectionOne.titleThree.align" type="radio" name="align" id="rightSTTh" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.titleThree.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>

            </div>
        </div>
  </div>
  <!--end section titleThree switcher-->

  <!--start section button switcher-->
  <div class="switcher-wrapper section-button-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Button Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="section-title-one my-3" style="margin-top: 6px;">
                    Text: 
                    <input v-model="template.sectionOne.button.text" type="text" class="form-control"  /><br/>
                    Link: 
                    <input v-model="template.sectionOne.button.link" type="text" class="form-control" />
                </div>
                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.button.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                    <LvColorpicker label="Background Color: " v-model="template.sectionOne.button.backgroundColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />

                    <LvColorpicker label="Color: " v-model="template.sectionOne.button.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTPTbtn"><input v-model="template.sectionOne.button.align" type="radio" name="align" id="leftSTPTbtn" value="left"> Left</label>&nbsp;
                    <label for="centerSTSPTbtn"><input v-model="template.sectionOne.button.align" type="radio" name="align" id="centerSTSPTbtn" value="center"> Center</label>&nbsp;
                    <label for="rightSTSPTbtn"><input v-model="template.sectionOne.button.align" type="radio" name="align" id="rightSTSPTbtn" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.button.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div> 

            </div>
        </div>
  </div>
  <!--end section button switcher-->

  <!--start section one switcher-->
  <div class="switcher-wrapper section-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section One Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <LvColorpicker label="Background Color: " v-model="template.sectionOne.background.bgColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 5px;" />

                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">BG Width:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.background.width" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.sectionOne.background.width" type="range" min="10" max="700" class="w-100">
                </div>

                <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Border Radius:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.sectionOne.background.borderRadius" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
            </div>
        </div>
  </div>
  <!--end section one switcher-->

  <!--start section order status switcher-->
  <div class="switcher-wrapper section-order-status-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Order Status Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <LvColorpicker label="Background Color: " v-model="template.orderStatusSection.background.bgColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 5px;" />

                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">BG Width:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderStatusSection.background.width" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.orderStatusSection.background.width" type="range" min="10" max="700" class="w-100">
                </div>

                <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Border Radius:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderStatusSection.background.borderRadius" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                <input v-model="template.orderStatusSection.background.borderRadius" type="range" min="10" max="700" class="w-100">
            </div>
        </div>
  </div>
  <!--end section order status switcher-->

  <!--start order status switcher-->
  <div class="switcher-wrapper order-status-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Order Status Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <label for="inputProductTitle" class="form-label" style="text-align:left; margin-top: 15px; font-weight: bold;">Order Status: </label>
                <hr style="margin: 0px;">

                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.orderStatusSection.orderTitle.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderStatusSection.orderTitle.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.orderStatusSection.orderTitle.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderStatusSection.orderTitle.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.orderStatusSection.orderTitle.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.orderStatusSection.orderTitle.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftOrderS"><input v-model="template.orderStatusSection.orderTitle.align" type="radio" name="align" id="leftOrderS" value="left"> Left</label>&nbsp;
                    <label for="centerOrderS
                    OrderS"><input v-model="template.orderStatusSection.orderTitle.align" type="radio" name="align" id="centerOrderS
                    OrderS" value="center"> Center</label>&nbsp;
                    <label for="rightOrderS
                    OrderS"><input v-model="template.orderStatusSection.orderTitle.align" type="radio" name="align" id="rightOrderS
                    OrderS" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderStatusSection.orderTitle.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderStatusSection.orderTitle.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderStatusSection.orderTitle.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderStatusSection.orderTitle.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderStatusSection.orderTitle.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderStatusSection.orderTitle.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderStatusSection.orderTitle.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderStatusSection.orderTitle.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end order status switcher-->

  <!--start order no and tracking section switcher-->
  <div class="switcher-wrapper order-no-and-tracking-no-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Order No Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <LvColorpicker label="Background Color: " v-model="template.orderNoAndTrakingSection.background.bgColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 5px;" />

                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">BG Width:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.background.width" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.orderNoAndTrakingSection.background.width" type="range" min="10" max="700" class="w-100">
                </div>

                <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Border Radius:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.background.borderRadius" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                 <input v-model="template.orderNoAndTrakingSection.background.borderRadius" type="range" min="10" max="700" class="w-100">
            </div>
        </div>
  </div>
  <!--end order no and tracking section switcher-->

  <!--start order no section switcher-->
  <div class="switcher-wrapper order-no-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Order Number Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.orderNoAndTrakingSection.orderNoTitle.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderNoTitle.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.orderNoAndTrakingSection.orderNoTitle.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderNoTitle.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.orderNoAndTrakingSection.orderNoTitle.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.orderNoAndTrakingSection.orderNoTitle.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftOrderNo"><input v-model="template.orderNoAndTrakingSection.orderNoTitle.align" type="radio" name="align" id="leftOrderNo" value="left"> Left</label>&nbsp;
                    <label for="centerOrderNo"><input v-model="template.orderNoAndTrakingSection.orderNoTitle.align" type="radio" name="align" id="centerOrderNo" value="center"> Center</label>&nbsp;
                    <label for="rightOrderNo"><input v-model="template.orderNoAndTrakingSection.orderNoTitle.align" type="radio" name="align" id="rightOrderNo" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderNoTitle.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderNoTitle.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderNoTitle.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderNoTitle.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderNoTitle.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderNoTitle.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderNoTitle.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderNoTitle.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end order no section switcher-->

  <!--start tracking no section switcher-->
  <div class="switcher-wrapper tracking-no-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Order Tracking Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.orderNoAndTrakingSection.orderTrakingTitle.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.orderNoAndTrakingSection.orderTrakingTitle.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftOrderTN"><input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.align" type="radio" name="align" id="leftOrderTN" value="left"> Left</label>&nbsp;
                    <label for="centerOrderTN
                    OrderS"><input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.align" type="radio" name="align" id="centerOrderTN
                    OrderS" value="center"> Center</label>&nbsp;
                    <label for="rightOrderTN
                    OrderS"><input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.align" type="radio" name="align" id="rightOrderTN
                    OrderS" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderNoAndTrakingSection.orderTrakingTitle.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end tracking no section switcher-->

  <!--start shipping and payment section switcher-->
  <div class="switcher-wrapper shipping-and-payment-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Shipping and Payment Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <LvColorpicker label="Background Color: " v-model="template.shippingAandPaymentMSection.background.bgColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 5px;" />

                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">BG Width:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.background.width" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.shippingAandPaymentMSection.background.width" type="range" min="10" max="700" class="w-100">
                </div>

                <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Border Radius:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.background.borderRadius" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                <input v-model="template.shippingAandPaymentMSection.background.borderRadius" type="range" min="10" max="700" class="w-100">
            </div>
        </div>
  </div>
  <!--end shipping and payment section switcher-->

  <!--start shipping section switcher-->
  <div class="switcher-wrapper shipping-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Shipping Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.shippingAandPaymentMSection.shippingATitle.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.shippingATitle.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.shippingAandPaymentMSection.shippingATitle.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.shippingATitle.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.shippingAandPaymentMSection.shippingATitle.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.shippingAandPaymentMSection.shippingATitle.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftShippingA"><input v-model="template.shippingAandPaymentMSection.shippingATitle.align" type="radio" name="align" id="leftShippingA" value="left"> Left</label>&nbsp;
                    <label for="centerShippingA"><input v-model="template.shippingAandPaymentMSection.shippingATitle.align" type="radio" name="align" id="centerShippingA" value="center"> Center</label>&nbsp;
                    <label for="rightShippingA"><input v-model="template.shippingAandPaymentMSection.shippingATitle.align" type="radio" name="align" id="rightShippingA" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.shippingATitle.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.shippingATitle.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.shippingATitle.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.shippingATitle.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.shippingATitle.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.shippingATitle.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.shippingATitle.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.shippingATitle.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end shipping section switcher-->

  <!--start payment section switcher-->
  <div class="switcher-wrapper payment-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Payment Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.shippingAandPaymentMSection.paymentMTitle.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.paymentMTitle.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.shippingAandPaymentMSection.paymentMTitle.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.paymentMTitle.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.shippingAandPaymentMSection.paymentMTitle.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.shippingAandPaymentMSection.paymentMTitle.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftPaymentM"><input v-model="template.shippingAandPaymentMSection.paymentMTitle.align" type="radio" name="align" id="leftPaymentM" value="left"> Left</label>&nbsp;
                    <label for="centerPaymentM
                    OrderS"><input v-model="template.shippingAandPaymentMSection.paymentMTitle.align" type="radio" name="align" id="centerPaymentM
                    OrderS" value="center"> Center</label>&nbsp;
                    <label for="rightPaymentM
                    OrderS"><input v-model="template.shippingAandPaymentMSection.paymentMTitle.align" type="radio" name="align" id="rightPaymentM
                    OrderS" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.paymentMTitle.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.paymentMTitle.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.paymentMTitle.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.paymentMTitle.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.paymentMTitle.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.paymentMTitle.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.paymentMTitle.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.shippingAandPaymentMSection.paymentMTitle.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end payment section switcher-->

  <!--start section order summary switcher-->
  <div class="switcher-wrapper section-order-summary-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Order Summary Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <LvColorpicker label="Background Color: " v-model="template.orderSummarySection.background.bgColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 5px;" />

                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">BG Width:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderSummarySection.background.width" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.orderSummarySection.background.width" type="range" min="10" max="700" class="w-100">
                </div>

                <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Border Radius:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderSummarySection.background.borderRadius" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                <input v-model="template.orderSummarySection.background.borderRadius" type="range" min="10" max="700" class="w-100">
            </div>
        </div>
  </div>
  <!--end section order summary switcher-->
  
  <!--start order summary switcher-->
  <div class="switcher-wrapper order-summary-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Order Summary Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.orderSummarySection.orderTitle.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderSummarySection.orderTitle.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.orderSummarySection.orderTitle.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderSummarySection.orderTitle.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.orderSummarySection.orderTitle.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.orderSummarySection.orderTitle.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftOrderSummary"><input v-model="template.orderSummarySection.orderTitle.align" type="radio" name="align" id="leftOrderSummary" value="left"> Left</label>&nbsp;
                    <label for="centerOrderSummary
                    OrderS"><input v-model="template.orderSummarySection.orderTitle.align" type="radio" name="align" id="centerOrderSummary
                    OrderS" value="center"> Center</label>&nbsp;
                    <label for="rightOrderSummary
                    OrderS"><input v-model="template.orderSummarySection.orderTitle.align" type="radio" name="align" id="rightOrderSummary
                    OrderS" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderSummarySection.orderTitle.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderSummarySection.orderTitle.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderSummarySection.orderTitle.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderSummarySection.orderTitle.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderSummarySection.orderTitle.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderSummarySection.orderTitle.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderSummarySection.orderTitle.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.orderSummarySection.orderTitle.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end order summary switcher-->
  
  <!--start section contact us switcher-->
  <div class="switcher-wrapper section-contact-us-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Section Contact Us Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <LvColorpicker label="Background Color: " v-model="template.contactUsSection.background.bgColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 5px;" />

                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">BG Width:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.background.width" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.background.width" type="range" min="10" max="700" class="w-100">
                </div>

                <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Border Radius:</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.background.borderRadius" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                <input v-model="template.contactUsSection.background.borderRadius" type="range" min="10" max="700" class="w-100">
            </div>
        </div>
  </div>
  <!--end section contact us switcher-->
  
  <!--start contact us title one switcher-->
  <div class="switcher-wrapper contact-us-title-one-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Contact Us Title One Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.contactUsSection.orderTitleOne.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.orderTitleOne.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.orderTitleOne.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.contactUsSection.orderTitleOne.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftContactTitleOne"><input v-model="template.contactUsSection.orderTitleOne.align" type="radio" name="align" id="leftContactTitleOne" value="left"> Left</label>&nbsp;
                    <label for="centerContactTitleOne
                    OrderS"><input v-model="template.contactUsSection.orderTitleOne.align" type="radio" name="align" id="centerContactTitleOne
                    OrderS" value="center"> Center</label>&nbsp;
                    <label for="rightContactTitleOne
                    OrderS"><input v-model="template.contactUsSection.orderTitleOne.align" type="radio" name="align" id="rightContactTitleOne
                    OrderS" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleOne.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end contact us title one switcher-->
  
  <!--start contact us title two switcher-->
  <div class="switcher-wrapper contact-us-title-two-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Contact Us Title Two Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.contactUsSection.orderTitleTwo.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.orderTitleTwo.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.orderTitleTwo.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.contactUsSection.orderTitleTwo.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftContactTitleTwo"><input v-model="template.contactUsSection.orderTitleTwo.align" type="radio" name="align" id="leftContactTitleTwo" value="left"> Left</label>&nbsp;
                    <label for="centerContactTitleTwo
                    OrderS"><input v-model="template.contactUsSection.orderTitleTwo.align" type="radio" name="align" id="centerContactTitleTwo
                    OrderS" value="center"> Center</label>&nbsp;
                    <label for="rightContactTitleTwo
                    OrderS"><input v-model="template.contactUsSection.orderTitleTwo.align" type="radio" name="align" id="rightContactTitleTwo
                    OrderS" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleTwo.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end contact us title two switcher-->
  
  <!--start contact us title three switcher-->
  <div class="switcher-wrapper contact-us-title-three-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Contact Us Title Three Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.contactUsSection.orderTitleThree.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="arial,helvetica,sans-serif">Arial</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.orderTitleThree.fontSize" type="range" min="10" max="500" class="w-100">
                </div>

                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Line Height: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.lineHeight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.contactUsSection.orderTitleThree.lineHeight" type="range" min="10" max="500" class="w-100">
                </div>

                <LvColorpicker label="Color: " v-model="template.contactUsSection.orderTitleThree.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />
                
                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftContactTitleThree"><input v-model="template.contactUsSection.orderTitleThree.align" type="radio" name="align" id="leftContactTitleThree" value="left"> Left</label>&nbsp;
                    <label for="centerContactTitleThree
                    OrderS"><input v-model="template.contactUsSection.orderTitleThree.align" type="radio" name="align" id="centerContactTitleThree
                    OrderS" value="center"> Center</label>&nbsp;
                    <label for="rightContactTitleThree
                    OrderS"><input v-model="template.contactUsSection.orderTitleThree.align" type="radio" name="align" id="rightContactTitleThree
                    OrderS" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.contactUsSection.orderTitleThree.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end contact us title three switcher-->
  
  <!--start copyright section switcher-->
  <div class="switcher-wrapper section-copyright-customize">
        <div class="switcher-body">
            <div class="d-flex align-items-center">
                <h6 class="mb-0">Copyright Change Area</h6>
                <button type="button" class="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr>
            <div class="col-md-12" style="max-height: 80vh; overflow: auto;">
                <LvColorpicker label="Background Color: " v-model="template.copyRight.bgColor" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%; margin-top: 5px;" />

                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Font Family: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.copyRight.fontFamily" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif">Montserrat</option>
                                <option value="Candara">Candara</option>
                                <option value="Verdana">Verdana</option>
                                <option value="Baskerville Old Face">Baskerville Old Face</option>
                                <option value="Monotype Corsiva">Monotype Corsiva</option>
                                <option value="French Script MT">French Script MT</option>
                                <option value="Algerian">Algerian</option>
                                <option value="Copperplate Gothic Bold">Copperplate Gothic Bold</option>
                                <option value="Ink Free">Ink Free</option>
                                <option value="Matura MT Script Capitals">Matura MT Script Capitals</option>
                            </select>
                        </span>
                    </div>
                </div>

                <label for="copyRightImage" class="form-label" style="text-align:left; margin-top: 10px;font-weight: bold;">CopyRight Image: <br /><strong>[Image Size: 34 X 32, Max: 200 KB]</strong></label>
                    <input class="form-control mb-1" type="file" ref="copyRightImage" accept="image/*" @change="uploadCopyRightImage" />
                
                <div class="section-image-width" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Width: [{{ template.copyRight.copyRightImage.width }}] px</span></div>
                    <input v-model="template.copyRight.copyRightImage.width" type="range" min="10" max="500" class="w-100">
                </div>
                <div class="setion-image-height" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Height: [{{ template.copyRight.copyRightImage.height }}] px</span></div>
                    <input v-model="template.copyRight.copyRightImage.height" type="range" min="10" max="500" class="w-100">
                </div>
                

                <label for="inputProductTitle" class="form-label" style="text-align:left; margin-top: 10px; font-weight: bold;">Copy Right Text: </label> <br />
                <div class="section-title-one">
                    <input v-model="template.copyRight.copyRightText.text" type="text" class="form-control" />
                </div>
                <label for="inputProductTitle" class="form-label" style="text-align:left; margin-top: 10px; font-weight: bold;">Copy Right Link: </label> <br />
                <div class="section-title-one">
                    <input v-model="template.copyRight.copyRightText.link" type="text" class="form-control" />
                </div>
                <div class="section-title-one" style="margin-top: 10px;">
                    <div style="display: flex;"><span style="width: 100%;">Font Size: </span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.copyRightText.fontSize" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <input v-model="template.copyRight.copyRightText.fontSize" type="range" min="10" max="500" class="w-100">
                </div>
                <LvColorpicker          label="Color" v-model="template.copyRight.copyRightText.color" :clearable="true"
                            :bottomBar="true" :colors="colors" style="width: 100%" />

                <div class="" style="margin-top: 10px;">
                    <div style="margin-top: 5px;">
                        <span style="width: 100%;">Text Decoration: </span><br>
                        <span style="width: 100%; display: flex; justify-content: flex-right;">
                            <select v-model="template.copyRight.copyRightText.textDecoration" class="form-control" >
                                <option value="">-Select-</option>
                                <option value="none">none</option>
                                <option value="overline">overline</option>
                                <option value="line-through">line-through</option>
                                <option value="underline">underline</option>
                                <option value="underline overline">underline overline</option>
                            </select>
                        </span>
                    </div>
                </div>

                <div class="logo-border" style="margin-top: 10px; margin-bottom: 10px;">
                    <label style="width: 100%;" for="">Align: </label>
                    <label for="leftSTPTFC"><input v-model="template.copyRight.align" type="radio" name="align" id="leftSTPTFC" value="left"> Left</label>&nbsp;
                    <label for="centerSTSPTFC"><input v-model="template.copyRight.align" type="radio" name="align" id="centerSTSPTFC" value="center"> Center</label>&nbsp;
                    <label for="rightSTSPTFC"><input v-model="template.copyRight.align" type="radio" name="align" id="rightSTSPTFC" value="right"> Right</label>
                </div>

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Padding: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.paddingTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.paddingRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.paddingBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.paddingLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
                

                <div>
                    <label style="width: 100%; margin-top: 10px;" for="">Margin: </label>
                    <hr style="margin: 0px !important;">
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Top</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.marginTop" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Right</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.marginRight" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Bottom</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.marginBottom" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                    <div style="display: flex; margin-top: 5px;"><span style="width: 100%;">Left</span> <span style="width: 100%; display: flex; justify-content: flex-right;"><input v-model="template.copyRight.marginLeft" type="number" style="width: 100%; margin-right: 3px;"> px</span></div>
                </div>
            </div>
        </div>
  </div>
  <!--end copyright section switcher-->

</template>

<script src="../../../js/elementor/DeliveredOrder/DeliveredOrderEmailTemplate.js"></script>

