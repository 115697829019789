<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Home Section Two List</li>
                    </ol>
                </nav>

                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                            <i class="bx bxs-plus-square"></i>Add New Home Section Two
                        </button>
                    </div>

                    <!-- Add Modal Start-->
                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form @submit.prevent="addTopSellingProduct" enctype="multipart/form-data">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add New Home Section Two</h5>
                                        <button
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="border border-3 p-4 rounded mb-3">
                                            <label for="productImage">Image: (W:740 X H:499, Max 500KB)</label>
                                            <input
                                                class="form-control mb-2"
                                                type="file"
                                                name="Product_image"
                                                id="productImage"
                                                ref="productImageInputAdd"
                                                aria-describedby="imageHelp"
                                                accept="image/*"
                                                @change="uploadProductImageAdd()"
                                            />
                                            <img
                                                v-if="previewProductImage"
                                                :src="previewProductImage"
                                                height="120"
                                            />
                                            <img v-else src="/core/assets/images/400.png" height="113" width="200" />
                                            <div id="display_image"></div>
                                        </div>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Image Alt:</label>
                                        <input
                                            class="form-control mb-1"
                                            type="text"
                                            v-model="imageAlt"
                                            placeholder="Image Alt"
                                            aria-label="default input example"
                                        />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Title:</label>
                                        <input
                                            class="form-control mb-1"
                                            type="text"
                                            v-model="title"
                                            placeholder="Title"
                                            aria-label="default input example"
                                        />
                                    </div>
                                    <!-- <div class="modal-body">
                                        <label class="form-label">Button Text:</label>
                                        <input
                                            class="form-control mb-1"
                                            type="text"
                                            v-model="buttonText"
                                            placeholder="Button Text"
                                            aria-label="default input example"
                                        />
                                    </div> -->
                                    <div class="modal-body">
                                        <label class="form-label">Button Link:</label>
                                        <input
                                            class="form-control mb-1"
                                            type="text"
                                            v-model="buttonLink"
                                            placeholder="Button Link"
                                            aria-label="default input example"
                                        />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Add</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Add Modal End-->

                    <!-- Edit Role Modal Start -->
                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form @submit.prevent="updateTopSellingProduct">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Update Home Section Two</h5>
                                        <button
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="border border-3 p-4 rounded mb-3">
                                            <label for="exampleInputEmail1">Image: (W:740 X H:499, Max 500KB)</label>
                                            <input
                                                class="form-control mb-2"
                                                type="file"
                                                name="Product_image"
                                                id="productImage"
                                                ref="productImageInputUpdate"
                                                aria-describedby="imageHelp"
                                                accept="image/*"
                                                @change="uploadProductImageUpdate()"
                                            />                                            
                                            <img
                                                v-if="previewProductImage"
                                                :src="previewProductImage"
                                                height="120"
                                            />
                                            <img v-else src="/core/assets/images/400.png" height="113" width="200" />
                                            <div id="display_image"></div>
                                        </div>
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Image Alt:</label>
                                        <input
                                            class="form-control mb-1"
                                            type="text"
                                            v-model="singleTopSellingProduct.imageAlt"
                                            placeholder="Search Link"
                                            aria-label="default input example"
                                        />
                                    </div>
                                    <div class="modal-body">
                                        <label class="form-label">Title:</label>
                                        <input
                                            class="form-control mb-1"
                                            type="text"
                                            v-model="singleTopSellingProduct.title"
                                            placeholder="Title"
                                            aria-label="default input example"
                                        />
                                    </div>
                                    <!-- <div class="modal-body">
                                        <label class="form-label">Button Text:</label>
                                        <input
                                            class="form-control mb-1"
                                            type="text"
                                            v-model="singleTopSellingProduct.buttonText"
                                            placeholder="Search Title"
                                            aria-label="default input example"
                                        />
                                    </div> -->
                                    <div class="modal-body">
                                        <label class="form-label">Button Link:</label>
                                        <input
                                            class="form-control mb-1"
                                            type="text"
                                            v-model="singleTopSellingProduct.buttonLink"
                                            placeholder="Search Link"
                                            aria-label="default input example"
                                        />
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- Edit Role Modal End -->
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Home Section Two List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Image</th>
                                    <th>Image Alt</th>
                                    <th>Title</th>
                                    <!-- <th>Button Text</th> -->
                                    <th>Link</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <img
                                            :src="data.image"
                                            height="120"
                                        />
                                    </td>
                                    <td>{{ data.imageAlt }}</td>
                                    <td>{{ data?.title }}</td>
                                    <!-- <td>{{ data.buttonText }}</td> -->
                                    <td>{{ data.buttonLink }}</td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a
                                                href="javascript:void(0);"
                                                @click="editTopSellingProduct(data)"
                                                data-bs-toggle="modal"
                                                data-bs-target="#editModal"
                                                class="edit-button"
                                                ><i class="bx bxs-edit text-white"></i
                                            ></a>
                                            <a
                                                href="javascript:void(0);"
                                                @click="deleteTopSellingProduct(data.id)"
                                                class="ms-3 delete-button"
                                                ><i class="bx bxs-trash text-white"></i
                                            ></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Image</th>
                                    <th>Image Alt</th>
                                    <th>Title</th>
                                    <!-- <th>Button Text</th> -->
                                    <th>Link</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/TopSellingProducts"></script>

<style scoped>
    .edit-button {
        background-color: #17a00e;
    }

    .delete-button {
        background-color: #f41127;
    }
</style>
