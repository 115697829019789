import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';
export default {
    data() {
        return {
            orderList: [],
            deliveryStatus: "",
            filter: {
                startDate: "",
                endDate: "",
                orderId: "",
            },
            data: {
                paymentStatus: "PENDING"
            },
            userList: [],
            filteredOrderIdList: [],
            setURL: this.$serverURL + this.$api.user.customerUserList,
            sheets: [],
            countryList: [],
            pickUpAddress: "",
            pickUpCity: "",
            pickUpStateCode: "",
            pickUpPostCode: "",
            pickUpCountry: {
                name: 'United States of America',
                code: 'US',
            },
            pickUpRate: "",
            cost: 0,
            costTotal: 0,
            itemPrice: 0,
            orderQuantity: 0,
            priceTotal: 0,
            profit: 0
        }
    },
    async mounted() {
        document.title = "HGS -Profit Report";
        await this.getOrderList();
        this.loadJs();
    },
    methods: {
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });

            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
        async getOrderList() {
            this.orderList = [];
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.report.profitReportURL + '?orderId=' + this.filter.orderId + '&startDate=' + this.filter.startDate + '&endDate=' + this.filter.endDate,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.orderList = await response.data.data.data;

                        let cost=0;
                        let costTotal=0;
                        let itemPrice=0;
                        let orderQuantity=0;
                        let priceTotal=0;
                        let profit=0;
                        for (let index = 0; index < this.orderList.length; index++) {
                            //const element = this.orderList[index];

                            const var_cost = this.orderList[index]['cost'];
                            const var_costTotal = this.orderList[index]['costTotal'];
                            const var_itemPrice = this.orderList[index]['itemPrice'];
                            const var_orderQuantity = this.orderList[index]['orderQuantity'];
                            const var_priceTotal = this.orderList[index]['priceTotal'];
                            const var_profit = this.orderList[index]['profit'];

                            //console.log('element = ', element);
                            // ts+=(total-0);
                            // tsq+=(totalQuantity-0);

                            cost+=(var_cost-0);
                            costTotal+=(var_costTotal-0);
                            itemPrice+=(var_itemPrice-0);
                            orderQuantity+=(var_orderQuantity-0);
                            priceTotal+=(var_priceTotal-0);
                            profit+=(var_profit-0);
                        }

                        this.cost=cost;
                        this.costTotal=costTotal;
                        this.itemPrice=itemPrice;
                        this.orderQuantity=orderQuantity;
                        this.priceTotal=priceTotal;
                        this.profit=profit;

                        if (this.filteredOrderIdList.length == 0) {
                            let uniqueOrder = this.orderList.filter((value, index, self) =>
                                index === self.findIndex((t) => (
                                    t.orderId === value.orderId
                                ))
                            )
                            this.filteredOrderIdList = uniqueOrder.map(el => {
                                return { value: el.orderId, label: el.orderId }
                            });
                        }
                    }

                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!" + error
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },

        async validateFilter() {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select end date"
                        });
                        return;
                    }
                }
                if (this.filter.endDate) {
                    if (!this.filter.startDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select start date"
                        });
                        return;
                    }
                }
                if (this.filter.orderId == null) {
                    this.filter.orderId = '';
                }
                window.$('#example').DataTable().destroy();
                await this.getOrderList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },
        // checkPickUpRate: async function(){
        //     let pickupAddress = {
        //         addressOne: this.pickUpAddress,
        //         city: this.pickUpCity,
        //         stateCode: this.pickUpStateCode,
        //         postCode: this.pickUpPostCode,
        //         countryCode: this.pickUpCountry.code,
        //     };
        //     let data = {
        //         pickupAddress: pickupAddress
        //     }
        //     let config = {
        //         method: "POST",
        //         url: this.$serverURL + this.$api.order.checkPickUpRate,
        //         data: data,
        //         headers: {
        //             "Authorization": authHeader()
        //         }
        //     };
        //     this.$swal.showLoading();
        //     await this.$axios(config).then((response) => {
        //         this.$swal.hideLoading();
        //         this.$swal.close();
        //         if (response.status == 200 && response.data.price) {
        //             this.pickUpRate = response.data.price;
        //         }
        //         else {
        //             this.$swal.fire({
        //                 icon: "error",
        //                 text: "Pick up rate not available"
        //             });
        //         }

        //     }).catch(error => {
        //         this.$swal.hideLoading();
        //         this.$swal.close();
        //         this.$swal.fire({
        //             icon: "error",
        //             text: error.response.data.message
        //         });
        //     });
        // },
        async clearFilter() {
            this.filter = {
                startDate: "",
                endDate: "",
                orderId: "",
            };
            window.$('#example').DataTable().destroy();
            await this.getOrderList();
            window.$('#example').DataTable();
        },
        // async clearPickUp() {
        //     this.pickUpAddress = "",
        //     this.pickUpCity = "",
        //     this.pickUpStateCode = "",
        //     this.pickUpPostCode = "",
        //     this.pickUpRate= "",
        //     document.getElementById("closePickUp").click();
        //     window.$('#example').DataTable().destroy();
        //     await this.getOrderList();
        //     window.$('#example').DataTable();
        // },
        exportOrders: function () {
            this.orderList.map((el) => {
                this.sheets.push({
                    'Order Id': el.orderId,
                    "Product Name": el.product_name,
                    "Cost": el.cost,
                    "Item Price": el.itemPrice,
                    "Order Quantity": el.orderQuantity,
                    "Cost Total": el.costTotal,
                    "Price Total": el.priceTotal,
                    "Profit": el.profit,
                    'Created At': this.$filters.formatDate(el.createdAt),
                });
            });

            this.sheets.push({
                'Order Id': "",
                "Product Name": "",
                "Cost":  this.cost,
                "Item Price": this.itemPrice,
                "Order Quantity": this.orderQuantity,
                "Cost Total":  this.costTotal,
                "Price Total": this.priceTotal,
                "Profit": this.profit,
                'Created At':"",
            });

            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'profit_report(' + date + ').xlsx')
            this.sheets = [];
        },

        // async deleteOrder(id) {
        //     this.$swal.fire({
        //         title: 'Are you sure?',
        //         text: "You won't be able to revert this!",
        //         icon: 'warning',
        //         showCancelButton: true,
        //         confirmButtonColor: '#3085d6',
        //         cancelButtonColor: '#d33',
        //         confirmButtonText: 'Yes, delete it!'
        //     }).then(async (result) => {
        //         if (result.isConfirmed) {
        //             try {
        //                 let config = {
        //                     method: 'DELETE',
        //                     url: this.$serverURL + this.$api.order.orderURL + '/' + id,
        //                     headers: {
        //                         "Authorization": authHeader(),
        //                     }
        //                 }
        //                 await this.$axios(config).then((response) => {
        //                     if (response.data.deleted == true) {
        //                         this.getOrderList();
        //                         this.$swal.fire({
        //                             icon: "warning",
        //                             text: "Order deleted Successfully."
        //                         });
        //                     }

        //                 }).catch(error => {

        //                     this.$swal.fire({
        //                         icon: "error",
        //                         text: "Something went wrong. Please try again!" + error
        //                     });
        //                 });
        //             } catch (error) {
        //                 this.$swal.fire({
        //                     icon: "error",
        //                     text: "Something went wrong, please try agian later." + error
        //                 });
        //             }
        //         }
        //     })
        // }
    }

}