import { authHeader } from "../../../../../../auth";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LvColorpicker from 'lightvue/color-picker';


export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            type: '',
            template: {
                logo: {
                    image: '',
                    url: 'https://hgshydro.nyc3.cdn.digitaloceanspaces.com/email-template/hgshydro.18a749b4-00d9-4a46-85f6-d6698679481b_do.png',
                    width: 200,
                    height: 66,
                    borderSize: 0,
                    borderStyle: null,
                    borderColor: null,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    align: 'center',
                    objectFit: null,
                    backgroundColor: '#FFF',
                },
                sectionOne: {
                    background: {
                        bgColor: '#f7fbfc',
                        fontFamily: 'arial,helvetica,sans-serif',
                        width: 500,
                        borderRadius: 0,
                    },
                    image: {
                        sectionOneImageRaw: '',
                        url: 'https://i.ibb.co/xhvH7Th/image-3.png',
                        width: 72,
                        height: 72,
                        border: 0,
                        objectFit: null,
                        borderSize: 0,
                        borderStyle: null,
                        borderColor: null,
                        borderRadius: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    titleOne: {
                        text: 'Thank you for your purchase!',
                        color: '#000',
                        fontSize: 18,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                        fontFamily: 'arial,helvetica,sans-serif',
                        lineHeight: '25.2',
                    },
                    titleTwo: {
                        text: "Hi John, we're getting your order ready to be shipped.",
                        color: '#000',
                        fontSize: 14,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                        fontFamily: 'arial,helvetica,sans-serif',
                        lineHeight: '19.6',
                    },
                    titleThree: {
                        text: 'We will notify you when it has been sent.',
                        color: '#000',
                        fontSize: 14,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                        fontFamily: 'arial,helvetica,sans-serif',
                        lineHeight: '19.6',
                    },
                    button: {
                        text: 'View More Order',
                        link: 'https://www.unlayer.com',
                        fontSize: 14,
                        backgroundColor: '#000000',
                        color: '#FFFFFF',
                        borderRadius: '4',
                        paddingTop: 10,
                        paddingRight: 20,
                        paddingBottom: 10,
                        paddingLeft: 20,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                        fontFamily: 'arial,helvetica,sans-serif',
                    },

                },
                orderStatusSection: {
                    background: {
                        bgColor: '#f7fbfc',
                        fontFamily: 'arial,helvetica,sans-serif',
                        width: 500,
                        borderRadius: 0,
                    },
                    orderTitle: {
                        text: 'Order Status:',
                        color: '#000',
                        fontSize: 14,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                        fontFamily: 'arial,helvetica,sans-serif',
                        lineHeight: '19.6',
                    },
                },
                orderNoAndTrakingSection: {
                    background: {
                        bgColor: '#f7fbfc',
                        fontFamily: 'arial,helvetica,sans-serif',
                        width: 500,
                        borderRadius: 0,
                    },
                    orderNoTitle: {
                        text: 'Order No:',
                        color: '#000',
                        fontSize: 14,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'left',
                        fontFamily: 'arial,helvetica,sans-serif',
                        lineHeight: '19.6',
                    },
                    orderTrakingTitle: {
                        text: 'Order Tracking No:',
                        color: '#000',
                        fontSize: 14,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'left',
                        fontFamily: 'arial,helvetica,sans-serif',
                        lineHeight: '19.6',
                    },
                },
                shippingAandPaymentMSection: {
                    background: {
                        bgColor: '#f7fbfc',
                        fontFamily: 'arial,helvetica,sans-serif',
                        width: 500,
                        borderRadius: 0,
                    },
                    shippingATitle: {
                        text: 'Shipping address',
                        color: '#000',
                        fontSize: 14,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'left',
                        fontFamily: 'arial,helvetica,sans-serif',
                        lineHeight: '19.6',
                    },
                    paymentMTitle: {
                        text: 'Payment method',
                        color: '#000',
                        fontSize: 14,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'left',
                        fontFamily: 'arial,helvetica,sans-serif',
                        lineHeight: '19.6',
                    },
                },
                orderSummarySection: {
                    background: {
                        bgColor: '#ffffff',
                        fontFamily: 'arial,helvetica,sans-serif',
                        width: 500,
                        borderRadius: 0,
                    },
                    orderTitle: {
                        text: 'Order summary',
                        color: '#000',
                        fontSize: 14,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                        fontFamily: 'arial,helvetica,sans-serif',
                        lineHeight: '19.6',
                    },
                },
                contactUsSection: {
                    background: {
                        bgColor: '#f7fbfc',
                        fontFamily: 'arial,helvetica,sans-serif',
                        width: 500,
                        borderRadius: 0,
                    },
                    orderTitleOne: {
                        text: 'If you have any questions, reply to this',
                        color: '#000',
                        fontSize: 14,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                        fontFamily: 'arial,helvetica,sans-serif',
                        lineHeight: '19.6',
                    },
                    orderTitleTwo: {
                        text: 'email or contact us at abc@gmail.com',
                        color: '#000',
                        fontSize: 14,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                        fontFamily: 'arial,helvetica,sans-serif',
                        lineHeight: '19.6',
                    },
                    orderTitleThree: {
                        text: '2261 Market Street #4667 San Francisco, CA 94114',
                        color: '#000',
                        fontSize: 14,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                        fontFamily: 'arial,helvetica,sans-serif',
                        lineHeight: '19.6',
                    }
                },
                
                copyRight: {
                    fontFamily: 'Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif',
                    copyRightImage: {
                        copyRightImageRow: '',
                        url: 'https://neutrix.co/uploads/images/20230213-63ea88220b1bf.png',
                        width: 44,
                        height: 44
                    },
                    copyRightText: {
                        text: 'Designed By Neutrix',
                        link: 'https://neutrix.co/',
                        fontSize: 15,
                        color: '#9d9d9d',
                        textDecoration: 'none'
                    },
                    bgColor: '#f9f9f9',
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    align: 'center',
                }
            },
            colors: [
                "#E91E63", "#F44336", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50"
                , "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800", "#795548"],
            titleOneStatus: false,
            titleTwoStatus: false,
            titleThreeStatus: false,
            OrderStatusT: false,
            OrderNoT: false,
            OrderTrakingT: false,
            ShippingT: false,
            PaymentT: false,
            OrderSummaryStatus: false,
            ContactUsTOStatus: false,
            ContactUsTTStatus: false,
            ContactUsTThStatus: false,
            templateData: [],
            emailSiteTemplate: "",
        }
    },

    async mounted() {
        document.title = "Shipped Order";
        await this.getDataList();
        await this.getTemplate();
    },
    methods: {
        getTemplate: async function () {
            try {
                this.$swal.showLoading();
                let config = {
                    method: 'GET',
                    url: this.$serverURL + this.$api.emailTemplate.OrderShippedEmail,
                    headers: {
                        'Authorization': authHeader()
                    },
                };

                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        if (response?.data?.data.template != null && JSON.parse(response?.data?.data.template)?.template != undefined) {
                            this.template = JSON.parse(response?.data?.data.template)?.template;
                        }
                        this.$swal.close();
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });

            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }

        },
        publishUpdateTemplate: async function () {
            let emailHead = `<!DOCTYPE html>

                    <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
                    <head>
                    <title></title>
                    <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
                    <meta content="width=device-width, initial-scale=1.0" name="viewport"/>
                    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
                    <style>
                        @media only screen and (min-width: 520px) {
                        .u-row {
                            width: 500px !important;
                        }
                        .u-row .u-col {
                            vertical-align: top;
                        }

                        .u-row .u-col-22p53 {
                            width: 112.65px !important;
                        }

                        .u-row .u-col-22p74 {
                            width: 113.7px !important;
                        }

                        .u-row .u-col-23p13 {
                            width: 115.65px !important;
                        }

                        .u-row .u-col-23p14 {
                            width: 115.7px !important;
                        }

                        .u-row .u-col-50 {
                            width: 250px !important;
                        }

                        .u-row .u-col-54p13 {
                            width: 270.65px !important;
                        }

                        .u-row .u-col-54p33 {
                            width: 271.65px !important;
                        }

                        .u-row .u-col-100 {
                            width: 500px !important;
                        }

                        }

                        @media (max-width: 520px) {
                        .u-row-container {
                            max-width: 100% !important;
                            padding-left: 0px !important;
                            padding-right: 0px !important;
                        }
                        .u-row .u-col {
                            min-width: 320px !important;
                            max-width: 100% !important;
                            display: block !important;
                        }
                        .u-row {
                            width: 100% !important;
                        }
                        .u-col {
                            width: 100% !important;
                        }
                        .u-col > div {
                            margin: 0 auto;
                        }
                        }
                        body {
                        margin: 0;
                        padding: 0;
                        }

                        table,
                        tr,
                        td {
                        vertical-align: top;
                        border-collapse: collapse;
                        }

                        p {
                        margin: 0;
                        }

                        .ie-container table,
                        .mso-container table {
                        table-layout: fixed;
                        }

                        * {
                        line-height: inherit;
                        }

                        a[x-apple-data-detectors='true'] {
                        color: inherit !important;
                        text-decoration: none !important;
                        }

                        table, td { color: #000000; } #u_body a { color: #0000ee; text-decoration: underline; }

                        .u-body {
                            transform: translate(25%, 0);
                        }

                        .a3s > div > table {
                            transform: translate(25%, 0);
                        }
                    </style>
                    </head>
                    <body style="margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">`;
            let emailFotter = `</body></html>`;
            let emailBody = document.querySelector("#emailBody").innerHTML;
            console.log('email Body ', emailBody);

            try {
                let formData = {
                    'emailHead': emailHead,
                    'emailBody': emailBody,
                    'emailFotter': emailFotter,
                    'template': this.template,
                };
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.OrderShippedEmail,
                    data: formData,
                    headers: {
                        'Authorization': authHeader()
                    },
                };

                await this.updateDataToBackend(config);
                console.log(config);
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }

        },

        uploadFeaturedImage: async function () {
            try {
                let input = this.$refs.backgroundImage;
                let formData = new FormData();
                formData.append('image', input.files[0]);
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.imageCdnEmail,
                    data: formData,
                    headers: {
                        'Authorization': authHeader()
                    },
                };

                const response = await this.createImageCdnForEmailTemplate(config);
                if (response) {
                    this.template.logo.url = response
                    this.$swal.close();
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! "
                    });
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },
        uploadSectionOneImage: async function () {
            try {
                let input = this.$refs.sectionOneImage;
                let formData = new FormData();
                formData.append('image', input.files[0]);
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.imageCdnEmail,
                    data: formData,
                    headers: {
                        'Authorization': authHeader()
                    },
                };

                const response = await this.createImageCdnForEmailTemplate(config);
                if (response) {
                    this.template.sectionOne.image.url = response
                    this.$swal.close();
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! "
                    });
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },
        uploadCopyRightImage: async function () {
            try {
                let input = this.$refs.copyRightImage;
                let formData = new FormData();
                formData.append('image', input.files[0]);
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.imageCdnEmail,
                    data: formData,
                    headers: {
                        'Authorization': authHeader()
                    },
                };

                const response = await this.createImageCdnForEmailTemplate(config);
                if (response) {
                    this.template.copyRight.copyRightImage.url = response
                    this.$swal.close();
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! "
                    });
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },

        handleTitleOneTextShow: function () {
            this.titleOneStatus = true;
        },

        handleTitleOneTextClose: function () {
            this.titleOneStatus = false;
        },

        handleTitleTwoTextShow: function () {
            this.titleTwoStatus = true;
        },

        handleTitleTwoTextClose: function () {
            this.titleTwoStatus = false;
        },

        handleTitleThreeTextShow: function () {
            this.titleThreeStatus = true;
        },

        handleTitleThreeTextClose: function () {
            this.titleThreeStatus = false;
        },

        handleOrderStatusTextShow: function () {
            this.OrderStatusT = true;
        },

        handleOrderStatusTextClose: function () {
            this.OrderStatusT = false;
        },

        handleOrderNoTextShow: function () {
            this.OrderNoT = true;
        },

        handleOrderNoTextClose: function () {
            this.OrderNoT = false;
        },

        handleOrderTrakingTextShow: function () {
            this.OrderTrakingT = true;
        },

        handleOrderTrakingTextClose: function () {
            this.OrderTrakingT = false;
        },
        
        handleShippingTextShow: function () {
            this.ShippingT = true;
        },

        handleShippingTextClose: function () {
            this.ShippingT = false;
        },

        handlePaymentTextShow: function () {
            this.PaymentT = true;
        },

        handlePaymentTextClose: function () {
            this.PaymentT = false;
        },

        handleOrderSummaryTextShow: function () {
            this.OrderSummaryStatus = true;
        },

        handleOrderSummaryTextClose: function () {
            this.OrderSummaryStatus = false;
        },

        handleContactUsTOTextShow: function () {
            this.ContactUsTOStatus = true;
        },

        handleContactUsTOTextClose: function () {
            this.ContactUsTOStatus = false;
        },

        handleContactUsTTTextShow: function () {
            this.ContactUsTTStatus = true;
        },

        handleContactUsTTTextClose: function () {
            this.ContactUsTTStatus = false;
        },

        handleContactUsTThTextShow: function () {
            this.ContactUsTThStatus = true;
        },

        handleContactUsTThTextClose: function () {
            this.ContactUsTThStatus = false;
        },

        handleLogoCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleLogoCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleLogoCustomizationAction: function () {
            document.querySelector('.logo-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleIconCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleIconCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOneIconCustomizationAction: function () {
            document.querySelector('.section-one-icon-customize').classList.add('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionTitleOneCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionTitleOneCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionTitleOneCustomizationAction: function () {
            document.querySelector('.section-title-one-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionTitleTwoCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionTitleTwoCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionTitleTwoCustomizationAction: function () {
            document.querySelector('.section-title-two-customize').classList.add('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionTitleThreeCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionTitleThreeCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionTitleThreeCustomizationAction: function () {
            document.querySelector('.section-title-three-customize').classList.add('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionButtonCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionButtonCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionButtonCustomizationAction: function () {
            document.querySelector('.section-button-customize').classList.add('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionCustomizationAction: function () {
            document.querySelector('.section-customize').classList.add('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOrderStatusCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOrderStatusCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOrderStatusCustomizationAction: function () {
            document.querySelector('.section-order-status-customize').classList.add('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleOrderStatusCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleOrderStatusCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleOrderStatusCustomizationAction: function () {
            document.querySelector('.order-status-customize').classList.add('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleOrderNoAndTrakingNoCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleOrderNoAndTrakingNoCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleOrderNoAndTrakingNoCustomizationAction: function () {
            document.querySelector('.order-no-and-tracking-no-customize').classList.add('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleOrderNoCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleOrderNoCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleOrderNoCustomizationAction: function () {
            document.querySelector('.order-no-customize').classList.add('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleTrackingNoCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleTrackingNoCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleTrackingNoCustomizationAction: function () {
            document.querySelector('.tracking-no-customize').classList.add('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleShippingAndPaymentCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleShippingAndPaymentCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleShippingAndPaymentCustomizationAction: function () {
            document.querySelector('.shipping-and-payment-customize').classList.add('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleShippingCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleShippingCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleShippingCustomizationAction: function () {
            document.querySelector('.shipping-customize').classList.add('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handlePaymentCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handlePaymentCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handlePaymentCustomizationAction: function () {
            document.querySelector('.payment-customize').classList.add('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOrderSummaryCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOrderSummaryCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOrderSummaryCustomizationAction: function () {
            document.querySelector('.section-order-summary-customize').classList.add('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleOrderSummaryCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleOrderSummaryCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleOrderSummaryCustomizationAction: function () {
            document.querySelector('.order-summary-customize').classList.add('switcher-toggled');
            document.querySelector('.section-order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionContactUsCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionContactUsCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionContactUsCustomizationAction: function () {
            document.querySelector('.section-contact-us-customize').classList.add('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleContactUsTitleOneCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleContactUsTitleOneCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleContactUsTitleOneCustomizationAction: function () {
            document.querySelector('.contact-us-title-one-customize').classList.add('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleContactUsTitleTwoCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleContactUsTitleTwoCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleContactUsTitleTwoCustomizationAction: function () {
            document.querySelector('.contact-us-title-two-customize').classList.add('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleContactUsTitleThreeCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleContactUsTitleThreeCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleContactUsTitleThreeCustomizationAction: function () {
            document.querySelector('.contact-us-title-three-customize').classList.add('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionCopyrightCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionCopyrightCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionCopyrightCustomizationAction: function () {
            document.querySelector('.section-copyright-customize').classList.add('switcher-toggled');
            document.querySelector('.contact-us-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.contact-us-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-contact-us-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-summary-customize').classList.remove('switcher-toggled');
            document.querySelector('.payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-customize').classList.remove('switcher-toggled');
            document.querySelector('.shipping-and-payment-customize').classList.remove('switcher-toggled');
            document.querySelector('.tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-no-and-tracking-no-customize').classList.remove('switcher-toggled');
            document.querySelector('.order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-order-status-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-icon-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
        },

        getDataList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.emailTemplate.elementor.emailKeyword,
                headers: {
                        'Authorization': authHeader()
                    }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.templateData = response.data.data;
                    console.log('email-keyword: ', response)
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },

        fetchEditData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.emailTemplate.elementor.emailKeyword,
                headers: {
                        'Authorization': authHeader()
                    }
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    // console.log(response.data.data.template);
                    // let template = JSON.parse(response.data.data.template);
                    // this.template = template;
                    // this.previewImage = this.template.logo;
                    // this.previewSectionTwoImage = this.template.sectionTwoImage;
                    // //this.previewImage = this.template.logo; //sectionTwoImage
                    // console.log('this.template =', this.template);
                    // // this.type = response.data.data.type;
                    // // this.template = response.data.data.template;
                    console.log('email-keyword: ', response)
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        save: async function () {
            try {
                if (!this.type) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please Select Type!"
                    });
                    return;
                }
                else if (!this.template) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter content!"
                    });
                    return;
                }
                let data = {
                    type: this.type,
                    template: this.template,
                }
                let config = {
                    method: 'PATCH',
                    url: this.$serverURL + this.$api.cms.mailTemplate.requestURL + '/' + this.id,
                    data: data,
                    headers: {
                        'Authorization': authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                this.$router.push("/email-template");
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        async updateCms() {
            try {
                let formData = new FormData();
                formData.append('logo', this.image ? this.image : this.template.logo);
                formData.append('logoBG', this.template.logoBG ? this.template.logoBG : '');

                formData.append('sectionTwoImage', this.sectionTwoImageRaw ? this.sectionTwoImageRaw : this.template.sectionTwoImage);
                formData.append('sectionTwoBG', this.template.sectionTwoBG ? this.template.sectionTwoBG : '');
                formData.append('sectionTwoFontColor', this.template.sectionTwoFontColor ? this.template.sectionTwoFontColor : '');
                formData.append('sectionTwoHeadingContent', this.template.sectionTwoHeadingContent ? this.template.sectionTwoHeadingContent : '');
                formData.append('sectionTwoShortDetail', this.template.sectionTwoShortDetail ? this.template.sectionTwoShortDetail : '');

                formData.append('sectionThreeSideImage', this.sectionThreeSideImageRaw ? this.sectionThreeSideImageRaw : this.template.sectionThreeSideImage);
                formData.append('sectionThreeBG', this.template.sectionThreeBG ? this.template.sectionThreeBG : '');
                formData.append('sectionThreeFontColor', this.template.sectionThreeFontColor ? this.template.sectionThreeFontColor : '');
                formData.append('sectionThreeHeadingContent', this.template.sectionThreeHeadingContent ? this.template.sectionThreeHeadingContent : '');
                formData.append('sectionThreeShortTitle', this.template.sectionThreeShortTitle ? this.template.sectionThreeShortTitle : '');
                formData.append('sectionThreeShortDetail', this.template.sectionThreeShortDetail ? this.template.sectionThreeShortDetail : '');
                formData.append('sectionThreeUsername', this.template.sectionThreeUsername ? this.template.sectionThreeUsername : '');
                formData.append('sectionThreePassword', this.template.sectionThreePassword ? this.template.sectionThreePassword : '');
                formData.append('sectionThreeFotterContent', this.template.sectionThreeFotterContent ? this.template.sectionThreeFotterContent : '');
                formData.append('sectionFourContent', this.template.sectionFourContent ? this.template.sectionFourContent : '');
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.elementor.emailKeyword,
                    data: formData,
                    headers: {
                        'Authorization': authHeader()
                    },
                };

                await this.updateDataToBackend(config);
                await this.fetchEditData();
                console.log(config);
                //this.$refs.backgroundImage.value = null;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}