import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const PLACEHOLDER = "Search Accessories";

export default {
    data() {
        return {
            dataList: [],
            editor: ClassicEditor,
            featuredImage: "",
            data: [],
            brandList: [],
            brandValue: "",
            discountList: [],
            colorValue: [],
            colorsList: [],
            sizeValue: [],
            sizesList: [],
            previewFeaturedImage: null,
            productImageList: [],
            previewProductImages: [],
            productImageAltList: [],
            disableButton: false,
            id: this.$route.params.id,
            siteUrl: this.$serverURL + this.$api.product.productAPI,
            imageUrl: this.$imageURL,            
            singleProduct: {
                name: '',
                sku: '',
                price: '',
                stock: '',
                quantityReorder: '',
                discountedPrice: '',
                cost: '',
                weight: '',
                isFeatured: '',
                title: '',
                urlSlug: '',
                productCanonical: '',
            },
            name: '',
            discount: {
                id: '',
                name: ''
            },
            subCategory: "",
            parentCategory: "",
            childCategory: "",
            subCategoryList: [],
            childCategoryList: [],
            deletedImage: [],
            shortDescription: "",
            longDescription: "",
            tagList: [],
            tagValue: [],
            accessories: [],
            accessoriesId: [],
            accessoryValue: PLACEHOLDER,
            IsAccessoriesAddForOpen: false
        }

    },
    async created() {
        await this.getDataList(this.siteUrl);
        this.dataList.map((el) => {
            el.flag = false;
            el.count = false;
        })
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    async mounted() {
        await this.getProductDetails();
        await this.getAllVarient();
        await this.getParentCategoriesWithSubcategory();
        await this.getTagList();
    },
    methods: {
        getTagList() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productTag,
                headers: {
                    "Authorization": authHeader(),
                }
            };
            this.$axios(config).then(response => {
                if (response.data.statusCode == 200) {
                    this.tagList = response.data.data.map(el => {
                        return { value: el.name, label: el.name }
                    });
                }
            }).catch(error => {
                console.log(error);
            })

        },
        checkName(data) {
            if (data.startsWith("hgs")) {
                return true;
            } else {
                return false;
            }
        },
        getProductDetails: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.siteUrl + '/' + this.id,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data?.products.length > 0) {

                            this.singleProduct = response.data.products[0];

                            this.tagValue = this.singleProduct.productTagVariants.map(el => {
                                return el.tagName
                            })

                            if (this.singleProduct.shortDescription) {
                                this.shortDescription = this.singleProduct.shortDescription;
                            }
                            if (this.singleProduct.longDescription) {
                                this.longDescription = this.singleProduct.longDescription;
                            }
                            if (this.singleProduct.parentCategoryId) {
                                await this.getSubCategoryList(this.singleProduct.parentCategoryId);
                                //this.parentCategory = { name: this.singleProduct.parentCategoryName, id: this.singleProduct.parentCategoryId }
                            }
                            if (this.singleProduct.subCategoryId) {
                                await this.getChildCategoryList(this.singleProduct.subCategoryId);
                                //this.subCategory = { name: this.singleProduct.subCategoryName, id: this.singleProduct.subCategoryId };
                            }
                            if (this.singleProduct.childCategoryId) {
                                //this.childCategory = { name: this.singleProduct.childCategoryName, id: this.singleProduct.childCategoryId };
                            }
                            this.productImageList = this.singleProduct.photos.map(el => {
                                return el.photoUrl;
                            });
                            this.previewProductImages = this.singleProduct.photos.map(el => {
                                return el.photoUrl;
                            });
                            this.productImageAltList = this.singleProduct.photos.map(el => {
                                return {
                                    id: el.id,
                                    productImageAlt: el.productImageAlt,
                                }
                            });
                            if (this.singleProduct.colors)
                            {
                                this.colorValue = this.singleProduct?.colors.map(el => {
                                    return el.colorName;
                                });                                
                            }
                            if (this.singleProduct.sizes)
                            {
                                this.sizeValue = this.singleProduct.sizes.map(el => {
                                    return el.sizeName;
                                });                                
                            }

                            this.discount = {
                                id: this.singleProduct.discountId,
                                name: this.singleProduct.discountTitle
                            };
                            
                            if (this.singleProduct.productAccessories) {
                                this.accessories = this.singleProduct.productAccessories;                                
                                this.accessoriesId = this.singleProduct.productAccessories.map(data => data.id);                                
                            }

                            this.name = this.singleProduct.name;
                        }
                    }
                }).catch(error => {
                    console.log(error.response.data.message);
                });

            } catch (error) {
                console.log(error);
            }
        },
        uploadFeaturedImage() {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.featuredImageInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },

        uploadProductImages() {
            this.productImageList.push(this.$refs.productImages.files[0]);
            if (this.productImageList.length > 3) {
                this.disableButton = true;
            }
            let input = this.$refs.productImages;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader;
                reader.onload = e => {
                    this.previewProductImages.push(e.target.result);
                }
                reader.readAsDataURL(file[0]);
                this.productImageAltList.push({ id: "", originalname: file?.[0]?.name, productImageAlt: "" });
            }
            this.$refs.productImages.value = null;
        },

        getAllVarient: async function () {
            let url = this.$serverURL + this.$api.product.getVarient;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    // this.colorsList = response.data.colorData.map((el) => {
                    //     return { value: el.colorName, label: el.colorName }
                    // });
                    // this.sizesList = response.data.sizeData.map(el => {
                    //     return { value: el.sizeName, label: el.sizeName }
                    // });
                    this.brandList = response.data.brandData;
                    this.discountList = response.data.discountData;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },

        getSubCategoryList: async function (id, bool) {
            if (bool) {
                this.singleProduct.subCategoryName = "";
                this.singleProduct.childCategoryName = "";
            }
            this.subCategory = "";
            this.subCategoryList = [];
            this.childCategory = "";
            this.childCategoryList = [];
            let url = this.$serverURL + this.$api.productCategory.getSubCategoriesURL + '?parentCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.subCategoryList = response.data.data;
                    if (this.subCategoryList.length > 0) {
                        this.showSubCategory = true;
                    } else {
                        this.showSubCategory = false;
                    }
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getChildCategoryList: async function (id, bool) {
            if (bool) {
                this.singleProduct.childCategoryName = "";
            }
            this.childCategoryList = [];
            this.childCategory = "";
            let url = this.$serverURL + this.$api.productCategory.childCategoryURL + '?subCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.childCategoryList = response.data.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        addDiscount: async function () {

            if (!this.discountTitle) {

                this.$swal.fire({
                    icon: "error",
                    text: "Please enter discount title!"
                });
            }
            else {
                let data = {
                    discountTitle: this.discountTitle,
                };
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.product.productDiscountAPI,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.status == 201) {

                        this.discount = {
                            id: response.data.data.id,
                            name: response.data.data.discountTitle
                        };
                        this.discountTitle = '';
                        await this.getAllVarient();
                        await this.changePrice();
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again." + error
                    });
                });

            }
        },
        changePrice: function () {
            if (this.discount.name != 'None') {
                if (this.discount.name.includes("%")) {
                    let converted_discount = parseFloat(this.discount.name) / 100.0;
                    this.singleProduct.discountedPrice = this.singleProduct.price - this.singleProduct.price * converted_discount;
                }
                else {
                    try {
                        let converted_discount = parseInt(this.discount.name)
                        this.singleProduct.discountedPrice = this.singleProduct.price - converted_discount;
                    }
                    catch (error) {
                        console.log(error);
                        this.$swal.fire({
                            icon: "error",
                            text: "Please enter am amount or percentage"
                        });
                    }
                }
            } else {
                this.singleProduct.discountedPrice = 0;
            }
        },

        validate: function () {
            if (!this.singleProduct.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product name!"
                });
                return false;
            }
            if (!this.parentCategory && !this.singleProduct.parentCategoryName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Select category."
                });
                return;
            }
            // if (!this.subCategory && !this.singleProduct.subCategoryName) {
            //     this.$swal.fire({
            //         icon: "error",
            //         text: "Select sub category."
            //     });
            //     return;
            // }
            // if (!this.childCategory && !this.singleProduct.childCategoryName) {
            //     this.$swal.fire({
            //         icon: "error",
            //         text: "Select child category."
            //     });
            //     return;
            // }
            if (!this.singleProduct.featuredImage && !this.featuredImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter featured image!"
                });
                return false;
            }
            if (!this.singleProduct.price) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product price!"
                });
                return false;
            }
            if (!this.singleProduct.cost) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product cost!"
                });
                return false;
            }
            if (!this.singleProduct.brandName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter brand!"
                });
                return false;
            }
            return true;
        },
        updateProduct: async function () {
            this.$swal.showLoading();
            if (this.validate()) {
                let formData = new FormData();
                formData.append("name", this.singleProduct.name);
                formData.append("shortDescription", this.shortDescription);
                formData.append("longDescription", this.longDescription);
                if (this.parentCategory) {
                    formData.append("parentCategoryId", this.parentCategory.id);
                    formData.append("parentCategoryName", this.parentCategory.name);
                } else {
                    formData.append("parentCategoryId", this.singleProduct.parentCategoryId);
                    formData.append("parentCategoryName", this.singleProduct.parentCategoryName);
                }
                if (this.subCategory) {
                    if (this.subCategory.name != null) {
                        formData.append("subCategoryId", this.subCategory.id);
                        formData.append("subCategoryName", this.subCategory.name);
                    }
                } else {
                    if (this.singleProduct.subCategoryName != null) {
                        formData.append("subCategoryId", this.singleProduct.subCategoryId);
                        formData.append("subCategoryName", this.singleProduct.subCategoryName);
                    }

                }
                if (this.childCategory) {
                    if (this.childCategory.name.length != null) {
                        formData.append("childCategoryId", this.childCategory.id);
                        formData.append("childCategoryName", this.childCategory.name);
                    }

                } else {
                    if (this.singleProduct.childCategoryName != null) {
                        formData.append("childCategoryId", this.singleProduct.childCategoryId);
                        formData.append("childCategoryName", this.singleProduct.childCategoryName);
                    }
                }
                if (this.featuredImage) {
                    formData.append("featuredImage", this.featuredImage);
                } else {
                    formData.append("featuredImage", this.singleProduct.featuredImage);
                }
                formData.append("sku", this.singleProduct.sku);
                formData.append("price", this.singleProduct.price);
                formData.append("stock", this.singleProduct.stock);
                formData.append("featuredImageTitle", this.singleProduct.featuredImageTitle);
                formData.append("quantityReorder", this.singleProduct.quantityReorder);
                formData.append("discountedPrice", this.singleProduct.discountedPrice);
                if (this.brandValue) {
                    formData.append("brandName", this.brandValue);
                } else {
                    formData.append("brandName", this.singleProduct.brandName);
                }

                if (this.discount) {
                    formData.append("discountId", this.discount.id != null ? this.discount.id : '');
                    formData.append("discountTitle", (this.discount?.name && this.discount.name != 'None') ? this.discount.name : '');
                } else {
                    formData.append("discountId", this.singleProduct.discountId);
                    formData.append("discountTitle", this.singleProduct.discountTitle);
                }
                this.productImageList.map((el) => {
                    formData.append("productImages", el);
                });
                this.deletedImage.map((el) => {
                    formData.append("deletedImage", el);
                });
                // this.colorValue.map((el) => {
                //     formData.append("color[]", el);
                // });
                // this.sizeValue.map((el) => {
                //     formData.append("size[]", el);
                // });
                this.tagValue.map((el) => {
                    formData.append("tag[]", el);
                });
                formData.append("cost", this.singleProduct.cost);
                formData.append("weight", this.singleProduct.weight);
                formData.append("isOutOfStock", this.singleProduct.isOutOfStock ? 1 : 0);
                formData.append("isFeatured", this.singleProduct.isFeatured ? 1 : 0);
                // this.accessoriesId.map((el) =>
                // {
                //     formData.append("accessoriesId[]", el);
                // });
                if(this.accessoriesId && this.accessoriesId.length > 0) {
                    this.accessoriesId.forEach((el) => {
                        formData.append("accessoriesId[]", el);
                    });
                } else {
                    formData.append("accessoriesId[]", "");
                }
                formData.append("title", this.singleProduct.title);
                formData.append("urlSlug", this.singleProduct.urlSlug);
                formData.append("productCanonical", this.singleProduct.productCanonical);
                formData.append("featuredImageAlt", this.singleProduct.featuredImageAlt);
                formData.append("productImageAlt", JSON.stringify(this.productImageAltList));
                formData.append("isLTLFreight", this.singleProduct.isLTLFreight ? this.singleProduct.isLTLFreight : false);
                let config = {
                    method: "PATCH",
                    data: formData,
                    url: this.siteUrl + '/' + this.id,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    this.$swal.hideLoading();
                    if (response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });

                        // add log to database
                        const firstName = localStorage.getItem('firstname');
                        const lastName = localStorage.getItem('lastname');
                        const configLogData = {
                            moduleName: 'product',
                            action: 'update',
                            moduleId: this.id,
                            logMessage: `${this.singleProduct.name} updated by ${firstName} ${lastName}`
                        }
                        await this.createUserLog(configLogData);

                        this.$router.push("/product-list");
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again." + error
                    });
                });
            }
        },

        removeUploadedProductImage(index) {
            this.previewProductImages.splice(index, 1);
            this.productImageAltList.splice(index, 1);
            if (typeof this.productImageList[index] === "string") {
                if (this.productImageList[index].startsWith("hgs")) {
                    this.deletedImage.push(this.productImageList[index]);
                }
            }
            this.productImageList.splice(index, 1);
            if (this.productImageList.length > 3) {
                this.disableButton = true;
            } else {
                this.disableButton = false;
            }
        },

        addAccessory: function() {
            if (!this.accessoryValue || this.accessoryValue === "" || this.accessoryValue === PLACEHOLDER) {
                this.IsAccessoriesAddForOpen = false;
                this.accessoryValue = PLACEHOLDER;
                return;
            }

            if (this.accessoriesId.some(x => x === this.accessoryValue.id)) {
                this.$swal.fire({
                    icon: "error",
                    text: "This product already added"
                });
                return;
            }

            
            this.accessoriesId.push(this.accessoryValue.id);
            this.accessories.push(this.accessoryValue);

            this.IsAccessoriesAddForOpen = false;
            this.accessoryValue = PLACEHOLDER;
        },

        deleteAccessory: function (id) {
            let c = confirm("Are you sure to delete this item.");
            if (c) {
                this.accessories = this.accessories.filter(x => x.id !== id);
                this.accessoriesId = this.accessoriesId.filter(x => x !== id);
            }
        },

        addMoreAccessories: function() {
            this.IsAccessoriesAddForOpen = true;
        },

        test: function() {
            console.log(this.accessoryValue);
        }
    },
    watch: {
        name: function(newValue, oldValue) {
            if(!oldValue) return;

            this.singleProduct.name = newValue;
            const slug = this.createUrlSlug(newValue);
            this.singleProduct.urlSlug = slug;
            this.singleProduct.productCanonical = slug;
        }
    }
}