import { authHeader } from "../../../../../../auth";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LvColorpicker from 'lightvue/color-picker';


export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            type: '',
            template: {
                logo: {
                    image: '',
                    url: 'https://golden-gal.nyc3.cdn.digitaloceanspaces.com/FooterCms/goldengal.a75cc544-501e-45ce-a766-534589c368da_do.png',
                    width: 200,
                    height: 66,
                    borderSize: 0,
                    borderStyle: null,
                    borderColor: null,
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    align: 'center',
                    objectFit: null,
                    backgroundColor: '#f9f9f9',
                },
                welcome: {
                    background: {
                        bgColor: '#ffd3e0',
                        textColor: '#000000',
                        fontFamily: 'Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif',
                        width: 700,
                        borderRadius: 0,
                    },
                    image: {
                        sectionOneImageRaw: '',
                        url: 'https://i.ibb.co/Yf1v0Mf/Welcome-Email.png',
                        width: 315,
                        height: 297,
                        border: 0,
                        objectFit: null,
                        borderSize: 0,
                        borderStyle: null,
                        borderColor: null,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    titleOne: {
                        text: 'Hi John Doe,',
                        color: null,
                        fontSize: 38,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    titleTwo: {
                        text: 'welcome to GoldenGal',
                        color: null,
                        fontSize: 38,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    titleThree: {
                        text: 'Jewelry!',
                        color: null,
                        fontSize: 38,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    paragraph: {
                        text: 'Thank you for creating account!',
                        color: null,
                        fontSize: 22,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    }

                },
                sectionTwo: {
                    fontFamily: 'Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif',
                    title: {
                        text: "Let's Get Started",
                        fontSize: 28,
                        color: null,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    subTitle: {
                        text: 'Call to action 1',
                        fontSize: 20,
                        color: null,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'left',
                    },
                    paragraph: {
                        text: 'Sit proident cillum magna qui anim amet ut ea dolore exercitation cupidatat sit culpa adipisicing.',
                        fontSize: 14,
                        color: null,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'left',
                    },
                    button: {
                        text: 'Login now →',
                        link: '',
                        fontSize: 14,
                        color: '#a96b7d',
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'left',
                    },
                    image: {
                        sectionThreeSideImageRaw: "",
                        url: 'https://serving.photos.photobox.com/6972900774b111a33d68c259a410f8993718c49ac2e723aa5cdf7da91b35df585744fe3b.jpg',
                        width: 193,
                        height: 217,
                        objectFit: null,
                        borderSize: 0,
                        borderStyle: null,
                        borderColor: null,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    }
                },
                footer: {
                    fontFamily: 'Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif',
                    paragraph: {
                        text: 'If you have any questions, feel free message us at support@mailus.com. All right reserved. Update',
                        fontSize: 14,
                        color: '#555555',
                        email: {
                            text: 'email preferences',
                            link: '',
                            color: '#555555',
                        },
                        unsubscribe: {
                            text: 'unsubscribe',
                            link: '',
                            color: '#555555',
                        },
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    },
                    termsPolicy: {
                        termsText: 'Terms of use',
                        policyText: 'Privacy Policy',
                        fontSize: 12,
                        color: '#555555',
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        marginTop: 0,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        align: 'center',
                    }
                },
                copyRight: {
                    fontFamily: 'Montserrat, Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif',
                    whiteDown: {
                        whiteDownImageRow: '',
                        url: 'https://ibb.co/xj86D8M',
                        width: 700,
                        height: 21,
                    },
                    copyRightImage: {
                        copyRightImageRow: '',
                        url: 'https://neutrix.co/uploads/images/20230213-63ea88220b1bf.png',
                        link: '',
                        width: 34,
                        height: 32
                    },
                    copyRightText: {
                        text: 'Designed By Neutrix',
                        link: 'https://neutrix.co/',
                        fontSize: 15,
                        color: '#9d9d9d',
                        textDecoration: 'none'
                    },
                    paddingTop: 0,
                    paddingRight: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    marginTop: 0,
                    marginRight: 0,
                    marginBottom: 0,
                    marginLeft: 0,
                    align: 'center',
                }
            },
            colors: [
                "#E91E63", "#F44336", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50"
                , "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800", "#795548"],
            titleOneStatus: false,
            titleTwoStatus: false,
            titleThreeStatus: false,
            welcomeParagraphStatus: false,
            sectionTwoTitleStatus: false,
            sectionTwoSubTitleStatus: false,
            sectionTwoParagraphStatus: false,
            sectionTwoButtonTextStatus: false,
            footerParagraphStatus: false,
            footerTermsTextStatus: false,
            footerPolicyTextStatus: false,
            templateData: [],
        }
    },

    async mounted() {
        document.title = "HGS - Create Customer Email Signup Template";
        await this.getDataList();
        await this.getTemplate();
    },
    methods: {
        getTemplate: async function () {

            //let emailBody = document.querySelector("#emailBody").innerHTML="";
            //console.log('email Body ', emailBody);

            try {
                this.$swal.showLoading();
                let config = {
                    method: 'GET',
                    url: this.$serverURL + this.$api.emailTemplate.AdminSignupEmail,
                    headers: authHeader(),
                };

                await this.$axios(config).then((response) => {
                    console.log('response', response);
                    if (response.status == 200) {
                        if (response?.data?.data.template != null && JSON.parse(response?.data?.data.template)?.template != undefined) {
                            this.template = JSON.parse(response?.data?.data.template)?.template;
                        }
                        this.$swal.close();
                    }
                }).catch(error => {
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });

            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }

        },
        publishUpdateTemplate: async function () {
            let emailHead = `<!DOCTYPE html>

                    <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
                    <head>
                    <title></title>
                    <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
                    <meta content="width=device-width, initial-scale=1.0" name="viewport"/>
                    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
                    <style>
                        * {
                            box-sizing: border-box;
                        }

                        body {
                            margin: 0;
                            padding: 0;
                        }

                        a[x-apple-data-detectors] {
                            color: inherit !important;
                            text-decoration: inherit !important;
                        }

                        #MessageViewBody a {
                            color: inherit;
                            text-decoration: none;
                        }

                        p {
                            line-height: inherit
                        }

                        .desktop_hide,
                        .desktop_hide table {
                            mso-hide: all;
                            display: none;
                            max-height: 0px;
                            overflow: hidden;
                        }

                        @media (max-width:720px) {
                            .desktop_hide table.icons-inner {
                                display: inline-block !important;
                            }

                            .icons-inner {
                                text-align: center;
                            }

                            .icons-inner td {
                                margin: 0 auto;
                            }

                            .image_block img.big,
                            .row-content {
                                width: 100% !important;
                            }

                            .mobile_hide {
                                display: none;
                            }

                            .stack .column {
                                width: 100%;
                                display: block;
                            }

                            .mobile_hide {
                                min-height: 0;
                                max-height: 0;
                                max-width: 0;
                                overflow: hidden;
                                font-size: 0px;
                            }

                            .desktop_hide,
                            .desktop_hide table {
                                display: table !important;
                                max-height: none !important;
                            }
                        }
                    </style>
                    </head>
                    <body style="margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">`;
            let emailFotter = `</body></html>`;
            let emailBody = document.querySelector("#emailBody").innerHTML;
            console.log('email Body ', emailBody);

            try {
                let formData = {
                    'emailHead': emailHead,
                    'emailBody': emailBody,
                    'emailFotter': emailFotter,
                    'template': this.template,

                };
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.AdminSignupEmail,
                    data: formData,
                    headers: authHeader(),
                };

                await this.updateDataToBackend(config);
                console.log(config);
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }

        },

        uploadFeaturedImage: async function () {
            // this.template.logo.image = event.target.files[0];
            // let input = this.$refs.backgroundImage;
            // let file = input.files;
            // if (file && file[0]) {
            //     let reader = new FileReader
            //     reader.onload = e => {
            //         this.template.logo.url = e.target.result
            //     }
            //     reader.readAsDataURL(file[0])
            // }

            try {
                let input = this.$refs.backgroundImage;
                let formData = new FormData();
                formData.append('image', input.files[0]);
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.imageCdnEmail,
                    data: formData,
                    headers: authHeader(),
                };

                const response = await this.createImageCdnForEmailTemplate(config);
                if (response) {
                    this.template.logo.url = response
                    this.$swal.close();
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! "
                    });
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }

        },
        uploadSectionTwoImage: async function () {
            // this.template.welcome.image.sectionOneImageRaw = event.target.files[0];
            // let input = this.$refs.sectionTwoImage;
            // let file = input.files;
            // if (file && file[0]) {
            //     let reader = new FileReader
            //     reader.onload = e => {
            //         this.template.welcome.image.url = e.target.result
            //     }
            //     reader.readAsDataURL(file[0])
            // }

            try {
                let input = this.$refs.sectionTwoImage;
                let formData = new FormData();
                formData.append('image', input.files[0]);
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.imageCdnEmail,
                    data: formData,
                    headers: authHeader(),
                };

                const response = await this.createImageCdnForEmailTemplate(config);
                if (response) {
                    this.template.welcome.image.url = response
                    this.$swal.close();
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! "
                    });
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },
        uploadSectionThreeSideImage: async function () {
            // this.template.sectionTwo.image.sectionThreeSideImageRaw = event.target.files[0];
            // let input = this.$refs.sectionThreeSideImage;
            // let file = input.files;
            // if (file && file[0]) {
            //     let reader = new FileReader
            //     reader.onload = e => {
            //         this.template.sectionTwo.image.url = e.target.result
            //     }
            //     reader.readAsDataURL(file[0])
            // }

            try {
                let input = this.$refs.sectionThreeSideImage;
                let formData = new FormData();
                formData.append('image', input.files[0]);
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.imageCdnEmail,
                    data: formData,
                    headers: authHeader(),
                };

                const response = await this.createImageCdnForEmailTemplate(config);
                if (response) {
                    this.template.sectionTwo.image.url = response
                    this.$swal.close();
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! "
                    });
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },
        uploadCopyRightImage: async function () {
            // this.template.copyRight.copyRightImageRow= event.target.files[0];
            // let input = this.$refs.copyRightImage;
            // let file = input.files;
            // if (file && file[0]) {
            //     let reader = new FileReader
            //     reader.onload = e => {
            //         this.template.copyRight.copyRightImage.url = e.target.result
            //     }
            //     reader.readAsDataURL(file[0])
            // }

            try {
                let input = this.$refs.copyRightImage;
                let formData = new FormData();
                formData.append('image', input.files[0]);
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.imageCdnEmail,
                    data: formData,
                    headers: authHeader(),
                };

                const response = await this.createImageCdnForEmailTemplate(config);
                if (response) {
                    this.template.copyRight.copyRightImage.url = response
                    this.$swal.close();
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! "
                    });
                }

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        },

        handleTitleOneTextShow: function () {
            this.titleOneStatus = true;
        },

        handleTitleOneTextClose: function () {
            this.titleOneStatus = false;
        },

        handleTitleTwoTextShow: function () {
            this.titleTwoStatus = true;
        },

        handleTitleTwoTextClose: function () {
            this.titleTwoStatus = false;
        },

        handleTitleThreeTextShow: function () {
            this.titleThreeStatus = true;
        },

        handleTitleThreeTextClose: function () {
            this.titleThreeStatus = false;
        },

        handleWelcomeParagraphTextShow: function () {
            this.welcomeParagraphStatus = true;
        },

        handleWelcomeParagraphTextClose: function () {
            this.welcomeParagraphStatus = false;
        },

        handleSectionTwoTitleTextShow: function () {
            this.sectionTwoTitleStatus = true;
        },

        handleSectionTwoTitleTextClose: function () {
            this.sectionTwoTitleStatus = false;
        },

        handleSectionTwoSubTitleTextShow: function () {
            this.sectionTwoSubTitleStatus = true;
        },

        handleSectionTwoSubTitleTextClose: function () {
            this.sectionTwoSubTitleStatus = false;
        },

        handleSectionTwoParagraphTextShow: function () {
            this.sectionTwoParagraphStatus = true;
        },

        handleSectionTwoParagraphTextClose: function () {
            this.sectionTwoParagraphStatus = false;
        },

        handleSectionTwoButtonTextShow: function () {
            this.sectionTwoButtonTextStatus = true;
        },

        handleSectionTwoButtonTextClose: function () {
            this.sectionTwoButtonTextStatus = false;
        },

        handleFooterParagraphTextShow: function () {
            this.footerParagraphStatus = true;
        },

        handleFooterParagraphTextClose: function () {
            this.footerParagraphStatus = false;
        },

        handleFooterTermsTextShow: function () {
            this.footerTermsTextStatus = true;
        },

        handleFooterTermsTextClose: function () {
            this.footerTermsTextStatus = false;
        },

        handleFooterPolicyTextShow: function () {
            this.footerPolicyTextStatus = true;
        },

        handleFooterPolicyTextClose: function () {
            this.footerPolicyTextStatus = false;
        },

        handleBackgroundCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleBackgroundCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleBackgroundCustomizationAction: function () {
            document.querySelector('.background-customize').classList.add('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleLogoCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleLogoCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleLogoCustomizationAction: function () {
            document.querySelector('.logo-customize').classList.add('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOneCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOneCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOneCustomizationAction: function () {
            document.querySelector('.section-one-customize').classList.add('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOneImageCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOneImageCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOneImageCustomizationAction: function () {
            document.querySelector('.section-one-image-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOneTitleOneCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOneTitleOneCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOneTitleOneCustomizationAction: function () {
            document.querySelector('.section-one-title-one-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOneTitleTwoCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOneTitleTwoCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOneTitleTwoCustomizationAction: function () {
            document.querySelector('.section-one-title-two-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOneTitleThreeCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOneTitleThreeCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOneTitleThreeCustomizationAction: function () {
            document.querySelector('.section-one-title-three-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionOneParagraphCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionOneParagraphCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionOneParagraphCustomizationAction: function () {
            document.querySelector('.section-one-paragraph-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionTwoTitleCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionTwoTitleCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionTwoTitleCustomizationAction: function () {
            document.querySelector('.section-two-title-customize').classList.add('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionTwoSubTitleCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionTwoSubTitleCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionTwoSubTitleCustomizationAction: function () {
            document.querySelector('.section-two-subtitle-customize').classList.add('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionTwoParagraphCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionTwoParagraphCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionTwoParagraphCustomizationAction: function () {
            document.querySelector('.section-two-paragraph-customize').classList.add('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionTwoButtonCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionTwoButtonCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionTwoButtonCustomizationAction: function () {
            document.querySelector('.section-two-button-customize').classList.add('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleSectionTwoImageCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleSectionTwoImageCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleSectionTwoImageCustomizationAction: function () {
            document.querySelector('.section-two-image-customize').classList.add('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleFooterParagraphCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleFooterParagraphCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleFooterParagraphCustomizationAction: function () {
            document.querySelector('.footer-paragraph-customize').classList.add('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleFooterParagraphEmailCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleFooterParagraphEmailCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleFooterParagraphEmailCustomizationAction: function () {
            document.querySelector('.footer-paragraph-email-customize').classList.add('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleFooterParagraphUnsubscribeCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleFooterParagraphUnsubscribeCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleFooterParagraphUnsubscribeCustomizationAction: function () {
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.add('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleTermsPolicyCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleTermsPolicyCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleTermsPolicyCustomizationAction: function () {
            document.querySelector('.terms-policy-customize').classList.add('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
            document.querySelector('.copyright-customize').classList.remove('switcher-toggled');
        },

        handleCopyrightCustomizationShow: function (event) {
            event.target.children[0].style.display = 'block';
        },

        handleCopyrightCustomizationClose: function (event) {
            event.target.children[0].style.display = 'none';
        },

        handleCopyrightCustomizationAction: function () {
            document.querySelector('.copyright-customize').classList.add('switcher-toggled');
            document.querySelector('.terms-policy-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-unsubscribe-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-email-customize').classList.remove('switcher-toggled');
            document.querySelector('.footer-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-button-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-subtitle-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-two-title-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-paragraph-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-three-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-two-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-title-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-image-customize').classList.remove('switcher-toggled');
            document.querySelector('.section-one-customize').classList.remove('switcher-toggled');
            document.querySelector('.logo-customize').classList.remove('switcher-toggled');
            document.querySelector('.background-customize').classList.remove('switcher-toggled');
        },


        getDataList: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.emailTemplate.elementor.emailKeyword,
                headers: authHeader()
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.templateData = response.data.data;
                }
            }).catch(error => {
                // this.$swal.fire({
                //     icon: "error",
                //     text: error.response.data.message
                // });
                console.log(error);
            });
        },

        fetchEditData: async function () {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.emailTemplate.createCustomerAccount,
                headers: authHeader()
            };
            await this.$axios(config).then((response) => {
                if (response.status == 200) {
                    console.log(response.data.data.template);
                    let template = JSON.parse(response.data.data.template);
                    this.template = template;
                    this.previewImage = this.template.logo;
                    this.previewSectionTwoImage = this.template.sectionTwoImage;
                    //this.previewImage = this.template.logo; //sectionTwoImage
                    console.log('this.template =', this.template);
                    // this.type = response.data.data.type;
                    // this.template = response.data.data.template;
                }
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        save: async function () {
            try {
                if (!this.type) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please Select Type!"
                    });
                    return;
                }
                else if (!this.template) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter content!"
                    });
                    return;
                }
                let data = {
                    type: this.type,
                    template: this.template,
                }
                let config = {
                    method: 'PATCH',
                    url: this.$serverURL + this.$api.cms.mailTemplate.requestURL + '/' + this.id,
                    data: data,
                    headers: authHeader()
                };
                await this.updateDataToBackend(config);
                this.$router.push("/email-template");
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        },
        async updateCms() {
            try {
                let formData = new FormData();
                formData.append('logo', this.image ? this.image : this.template.logo);
                formData.append('logoBG', this.template.logoBG ? this.template.logoBG : '');

                formData.append('sectionTwoImage', this.sectionTwoImageRaw ? this.sectionTwoImageRaw : this.template.sectionTwoImage);
                formData.append('sectionTwoBG', this.template.sectionTwoBG ? this.template.sectionTwoBG : '');
                formData.append('sectionTwoFontColor', this.template.sectionTwoFontColor ? this.template.sectionTwoFontColor : '');
                formData.append('sectionTwoHeadingContent', this.template.sectionTwoHeadingContent ? this.template.sectionTwoHeadingContent : '');
                formData.append('sectionTwoShortDetail', this.template.sectionTwoShortDetail ? this.template.sectionTwoShortDetail : '');

                formData.append('sectionThreeSideImage', this.sectionThreeSideImageRaw ? this.sectionThreeSideImageRaw : this.template.sectionThreeSideImage);
                formData.append('sectionThreeBG', this.template.sectionThreeBG ? this.template.sectionThreeBG : '');
                formData.append('sectionThreeFontColor', this.template.sectionThreeFontColor ? this.template.sectionThreeFontColor : '');
                formData.append('sectionThreeHeadingContent', this.template.sectionThreeHeadingContent ? this.template.sectionThreeHeadingContent : '');
                formData.append('sectionThreeShortTitle', this.template.sectionThreeShortTitle ? this.template.sectionThreeShortTitle : '');
                formData.append('sectionThreeShortDetail', this.template.sectionThreeShortDetail ? this.template.sectionThreeShortDetail : '');
                formData.append('sectionThreeUsername', this.template.sectionThreeUsername ? this.template.sectionThreeUsername : '');
                formData.append('sectionThreePassword', this.template.sectionThreePassword ? this.template.sectionThreePassword : '');
                formData.append('sectionThreeFotterContent', this.template.sectionThreeFotterContent ? this.template.sectionThreeFotterContent : '');
                formData.append('sectionFourContent', this.template.sectionFourContent ? this.template.sectionFourContent : '');
                this.$swal.showLoading();
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.emailTemplate.createCustomerAccount,
                    data: formData,
                    headers: authHeader(),
                };

                await this.updateDataToBackend(config);
                await this.fetchEditData();
                console.log(config);
                //this.$refs.backgroundImage.value = null;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}