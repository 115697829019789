import { authHeader } from "../../../auth";
import Loading from "vue-loading-overlay";
import Chart from 'chart.js/auto'
import jwt_decode from "jwt-decode";
export default {
    components: {
        Loading
    },
    data() {
        return {
            dashBoardData: {},
            setURL: this.$serverURL + this.$api.dashBoard.dashboardAPI,
            totalProducts: 0,
            totalCustomers: 0,
            totalSubscribers: 0,
            totalOrders: 0,
            totalDeliveredOrders: 0,
            totalCancelledOrders: 0,
            totalPendingOrders: 0,
            todayProducts: [],
            chartLoaded: false,
            monthlyFranchise: {},
            topCategories: {},
            orderList: [],
            role: 0,
            isAdmin: false
        }
    },
    async created() {
        const token = localStorage.getItem('token');
        const decoded = jwt_decode(token);
        this.role = decoded.role;
        this.isAdmin = this.role == 2;

        document.title = "HGS - Dashboard";
        await this.getDashBoardData();
        await this.loadJS();
        await this.getBarChart();
        await this.getPieChart();
        this.isAdmin ? await this.getOrderList() : null;
    },
    methods: {
        async loadJS() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js",
                "/core/assets/plugins/metismenu/js/metisMenu.min.js",
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });
        },
        async getBarChart() {
            const ctx = document.getElementById('myChart').getContext('2d');
            new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.monthlyFranchise.labels,
                    datasets: [{
                        label: '# of Orders',
                        data: this.monthlyFranchise.data,
                        backgroundColor: [
                            '#3292a8',
                            '#3257a8',
                            '#32a84c',
                            '#a8a432',
                            '#a84832',
                            '#a832a8',
                            '#3273a8)',
                            '#329da8',
                            '#32a86d',
                            '#7532a8',
                            '#a83251',
                            '#8ba832'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    barPercentage: 0.6,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: 'Monthly Order data',
                            padding: {
                                top: 10,
                                bottom: 30
                            }
                        }
                    }
                }
            });
        },
        async getPieChart() {
            new Chart(document.getElementById("categoryChart"), {
                type: 'doughnut',
                data: {
                    labels: this.topCategories.labels,
                    datasets: [{
                        label: 'Top Categories',
                        data: this.topCategories.data,
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(50, 168, 68)',
                            'rgb(50, 131, 168)'
                        ],
                        hoverOffset: 4
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    parsing: {
                        key: 'nested.value'
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: 'Top Five Categories',
                            padding: {
                                top: 10,
                                bottom: 30
                            }
                        }
                    }
                }
            });
        },
        async getDashBoardData() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.totalProducts = response.data.totalProducts;
                    this.totalCustomers = response.data.totalCustomers;
                    this.totalSubscribers = response.data.totalSubscribers;
                    this.totalOrders = response.data.totalOrders;
                    this.totalDeliveredOrders = response.data.totalDeliveredOrders;
                    this.totalCancelledOrders = response.data.totalCancelledOrders;
                    this.totalPendingOrders = response.data.totalPendingOrders;
                    this.todayProducts = response.data.todayProducts;
                    this.monthlyFranchise = response.data.monthlyFranchise;
                    this.topCategories = response.data.topCategories;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        async getOrderList() {
            this.orderList = [];
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.order.orderURL ,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.orderList = await response.data.data.data;
                    }

                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!" + error
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },
    },
}