<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                <div class="col">
                    <div class="card radius-10">
                        <div class="card-body" @click="$router.push('/product-list')" style="cursor: pointer;">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Products</p>
                                    <h4 class="my-1">{{ totalProducts }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-success text-success ms-auto">
                                    <i class="bx bxs-wallet"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card radius-10">
                        <div class="card-body" @click="$router.push('/customers')" style="cursor: pointer;">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Customers</p>
                                    <h4 class="my-1">{{ totalCustomers }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-warning text-warning ms-auto">
                                    <i class="bx bxs-group"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card radius-10">
                        <div class="card-body" @click="$router.push('/subscriber')" style="cursor: pointer;">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Subscriber</p>
                                    <h4 class="my-1">{{ totalSubscribers }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                                    <i class="bx bx-user-circle"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <div class="card radius-10">
                        <div class="card-body" @click="$router.push('/orders')" style="cursor: pointer;">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Total Order</p>
                                    <h4 class="my-1">{{ totalOrders }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-success text-success ms-auto">
                                    <span class="iconify" data-icon="akar-icons:shipping-box-v2"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="card radius-10">
                        <div class="card-body" @click="$router.push('/orders')" style="cursor: pointer;">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Order Pending</p>
                                    <h4 class="my-1">{{ totalPendingOrders }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-success text-success ms-auto">
                                    <span class="iconify" data-icon="fa6-solid:truck-fast"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="card radius-10">
                        <div class="card-body" @click="$router.push('/orders')" style="cursor: pointer;">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Order Delivered</p>
                                    <h4 class="my-1">{{ totalDeliveredOrders }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-success text-success ms-auto">
                                    <span class="iconify" data-icon="bxs:truck"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="card radius-10">
                        <div class="card-body" @click="$router.push('/orders')" style="cursor: pointer;">
                            <div class="d-flex align-items-center">
                                <div>
                                    <p class="mb-0 text-secondary">Order Cancelled</p>
                                    <h4 class="my-1">{{ totalCancelledOrders }}</h4>
                                </div>
                                <div class="widgets-icons bg-light-danger text-danger ms-auto">
                                    <span class="iconify" data-icon="fluent:calendar-cancel-16-filled"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="chart-container1">
                                <canvas id="categoryChart"
                                    style="position: relative; height: 80vh; width: 80vw"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 mx-auto">
                    <div class="card">
                        <div class="card-body">
                            <div class="chart-container1">
                                <canvas id="myChart" style="position: relative; height: 80vh; width: 80vw"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end row-->
            <div class="row" v-if="isAdmin">
                <div class="col-xl-12 d-flex">
                    <div class="card radius-10 w-100">
                        <div class="card-body">
                            <div class="d-flex align-items-center">
                                <div>
                                    <h5 class="mb-1">Recent Order History</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive mt-4">
                                        <table class="table table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Order Id</th>
                                                    <th>Customer Name</th>
                                                    <th>Total Price</th>
                                                    <th>Total Quantity</th>
                                                    <th>Order Date</th>
                                                    <th>Payment Method</th>
                                                    <th>Payment Status</th>
                                                    <th>Shipping Method</th>
                                                    <th>Shipping Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data, index) in orderList.slice(0, 10)" :key="index">
                                                    <td>{{ index + 1 }}</td>
                                                    <td>
                                                        <a href="javascript:void(0)" @click="
                                                            $router.push('/order-details/' + data.orderId)
                                                        ">{{ data.orderId }}</a>
                                                    </td>
                                                    <td>
                                                            <span v-if="data.customerName !== 'Guest'">{{ data.customerName }}</span>
                                                            <span v-else-if="JSON.parse(data.billingAddress).name !== 'Guest'"> {{ JSON.parse(data.billingAddress).name }}</span>
                                                            <span v-else> {{ JSON.parse(data.shippingAddress).name }}</span>    
                                                    </td>
                                                    <td>
                                                        {{
                                                                parseFloat(data.couponDiscount != "0"
                                                                    ? data.total - parseInt(data.couponDiscount)
                                                                    : data.total).toFixed(2)
                                                        }}
                                                    </td>
                                                    <td>{{ data.totalQuantity }}</td>
                                                    <td>{{ $filters.dateFormat(data.createdAt) }}</td>
                                                    <td>{{ data.paymentMethodName }}</td>
                                                    <td>
                                                        {{ data.paymentStatus }}
                                                    </td>
                                                    <td>{{ data.shippingType.toUpperCase() }}</td>
                                                    <td>
                                                        {{ data.status }}
                                                    </td>
                                                    <td>
                                                        <div class="d-flex order-actions">
                                                            <a href="javascript:void(0)" @click="
                                $router.push('/order-details/' + data.orderId)
                                                            " class="order-button"><i class="bx bxs-show text-white"></i></a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/dashboard.js"></script>

<style scoped>
.edit-button {
    background-color: #17a00e;
}

.order-button {
    background-color: #0d6efd;
}

.delete-button {
    background-color: #f41127;
}
</style>