import { authHeader } from "../../../../auth";
export default {
    data() {
        return {
            productList: [],
            brandDataList: [],
            productUrl: this.$serverURL + this.$api.product.productAPI,
            brandURL: this.$serverURL + this.$api.product.productBrandAPI,
            staticSiteMapUrl: this.$serverURL + this.$api.cms.StaticSiteMapCms,
            generateSitemapUrl: this.$serverURL + this.$api.cms.SitemapGenerate,
            blogUrl: this.$serverURL + this.$api.blog.blogAPI,
            dynamicSitemap: '',
            staticSitemap: '',
            urlList: []
        }
    },
    async mounted() {
        document.title = "HGS - Site Map CMS";
    },
    async created() {
        await this.getProductList();
        await this.getAllParentCategories();
        await this.getSubCategories();
        await this.getCmsData(this.staticSiteMapUrl);
        await this.getDataList(this.blogUrl);
        await this.getBrandDataList(this.brandURL);

        if(this.pageCMS) {
            this.staticSitemap = this.pageCMS.siteMap;
        }

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const dateString = `${year}-${month}-${day}`;

        let dataList = this.productList.map(x => {
            return x.productCanonical ? x.productCanonical : this.createUrlSlug(x.name);
        });
        dataList = new Set(dataList);
        dataList = [...dataList];
        const products = dataList.map(slug => {
            this.urlList.push(`product-details/${slug}`);

            return `<url>\n\t<loc>https://hgshydro.com/product-details/${slug}</loc>\n\t<lastmod>${dateString}</lastmod>\n</url>`;
        });

        const categoryList = this.parentCategoriesList.map(x => {
            const url = `category/${x.categorySlug ? x.categorySlug : this.createUrlSlug(x.name)}`;
            this.urlList.push(url);

            return `<url>\n\t<loc>https://hgshydro.com/${url}</loc>\n\t<lastmod>${dateString}</lastmod>\n</url>`;
        });

        const subcategoryList = this.subCategoriesList.map(x => {
            const url = `sub-category/${x.subCategorySlug ? x.subCategorySlug : this.createUrlSlug(x.name)}`;
            this.urlList.push(url);

            return `<url>\n\t<loc>https://hgshydro.com/${url}</loc>\n\t<lastmod>${dateString}</lastmod>\n</url>`;
        });

        const blogList = this.dataList.map(x => {
            const url = `blog-details/${x.urlSlug ? x.urlSlug : this.createUrlSlug(x.title)}`;
            this.urlList.push(url);

            return `<url>\n\t<loc>https://hgshydro.com/${url}</loc>\n\t<lastmod>${dateString}</lastmod>\n</url>`;
        });

        const brandList = this.brandDataList.map(x => {
            const url = `brand/${x.brandSlug ? x.brandSlug : this.createUrlSlug(x.brandName)}`;
            this.urlList.push(url);

            return `<url>\n\t<loc>https://hgshydro.com/${url}</loc>\n\t<lastmod>${dateString}</lastmod>\n</url>`;
        })
        
        this.dynamicSitemap = products.join('\n');
        this.dynamicSitemap += categoryList.join('\n');
        this.dynamicSitemap += subcategoryList.join('\n');
        this.dynamicSitemap += blogList.join('\n');
        this.dynamicSitemap += brandList.join('\n');
    },
    methods: {
        getProductList: async function () {
            let config = {
                method: "GET",
                url: this.productUrl,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    if (response.data.data) {
                        this.productList = await response.data.data;
                    } else if (response.data.products) {
                        this.productList = await response.data.products;
                    }
                } else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        async updateStaticSitemap() {
            try {
                let data = {
                    siteMap: this.staticSitemap,
                    dynamicUrls: JSON.stringify(this.urlList)
                };
                let config = {
                    method: 'POST',
                    url: this.staticSiteMapUrl,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.staticSiteMapUrl);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        },
        getBrandDataList: async function (url) {
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    if (response.data.data) {
                        this.brandDataList = await response.data.data;
                    } else if (response.data.products) {
                        this.brandDataList = await response.data.products;
                    }
                }
                else {
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        async generateSitemap() {
            await this.updateStaticSitemap();

            const finalResult =
`<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" 
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" 
        xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
            http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
${this.staticSitemap}
${this.dynamicSitemap}
</urlset>`;

            try {
                if(!finalResult){
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter static sitemap data!"
                    });
                } else {
                    let data = {
                        siteMap: finalResult
                    };
                    let config = {
                        method: 'POST',
                        url: this.generateSitemapUrl,
                        data: data,
                        headers: {
                            "Authorization": authHeader(),
                        }
                    };
                    await this.postDataToBackend(config);
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}