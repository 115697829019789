import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';
export default {
    data() {
        return {
            searchRecordList: [],
            filter: {
                transId: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            },
            userList: [],
            sheets: [],
        }
    },
    async mounted() {
        document.title = "HGS - Search Records";
        await this.getSearchRecords();
        this.loadJs();
    },
    methods: {
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });

            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
        async getSearchRecords() {
            this.searchRecordList = [];
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.searchRecord.searchRecordURL,
                    data: {
                        search: this.searchText,
                    },
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        console.log(response)
                        this.searchRecordList = response.data.data;
                    }
                }).catch(error => {
                    console.log(error);
                });
            } catch (error) {
                console.log(error)
            }
        },
        async validateFilter() {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select end date"
                        });
                        return;
                    }
                }
                if (this.filter.endDate) {
                    if (!this.filter.startDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select start date"
                        });
                        return;
                    }
                }
                window.$('#example').DataTable().destroy();
                await this.getRefundList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },
        async clearFilter() {
            this.filter = {
                transId: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            };
            window.$('#example').DataTable().destroy();
            await this.getOrderList();
            window.$('#example').DataTable();
        },
        exportData: function () {
            this.searchRecordList.map((el, index) => {
                this.sheets.push({
                    'SL': index+1,
                    'KeyWord': el.search,
                    "Time": this.$filters.formatTime(el.createdAt),
                    "Date": this.$filters.dateFormat(el.createdAt),
                });
            });
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'search_records(' + date + ').xlsx');
            this.sheets = [];
        },
    }

}