<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="bx bx-home-alt"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Add New Product</li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form @submit.prevent="addProduct" method="POST">
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Add New Product</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-8">
                  <div class="border border-3 p-4 rounded mb-4">
                    <div class="mb-3">
                      <label for="inputProductName" class="form-label">Product Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputProductName"
                        v-model="name"
                        placeholder="Enter product name"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductDescription" class="form-label">
                        Short Description
                      </label>
                      <ckeditor :editor="editor" v-model="shortDescription" :config="editorConfig"></ckeditor>
                    </div>
                    <div class="mb-3">
                      <label for="inputProductDescription" class="form-label">
                        Long Description
                      </label>
                      <ckeditor :editor="editor" v-model="longDescription" :config="editorConfig"></ckeditor>
                    </div>
                  </div>
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row">
                      <div class="col-sm-4">
                        <label for="inputVendor" class="form-label">Category</label>
                        <select
                          class="form-select"
                          v-model="parentCategory"
                          id="inputVendor"
                          @change="getSubCategoryList(parentCategory.id)"
                        >
                          <option style="display: none" selected value>Select Category</option>
                          <option
                            v-for="(data, index) in parentCategoriesList"
                            :key="index"
                            :value="{ id: data.id, name: data.name }"
                          >{{ data.name }}</option>
                        </select>
                      </div>
                      <div class="col-sm-4">
                        <label for="inputVendor" class="form-label">Sub Category</label>
                        <select
                          v-model="subCategory"
                          class="form-select"
                          id="inputVendor"
                          @change="() => { getChildCategoryList(subCategory.id); }"
                        >
                          <option style="display: none" selected value>Select Sub Category</option>
                          <option
                            v-for="(data, index) in subCategoryList"
                            :key="index"
                            :value="{ id: data.id, name: data.name }"
                          >{{ data.name }}</option>
                        </select>
                      </div>
                      <div class="col-sm-4">
                        <label for="inputVendor" class="form-label">Child Category</label>
                        <select v-model="childCategory" class="form-select" id="inputVendor">
                          <option style="display: none" selected value>Select Child Category</option>
                          <option
                            v-for="(data, index) in childCategoryList"
                            :key="index"
                            :value="{ id: data.id, name: data.name }"
                          >{{ data.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="border border-3 p-4 rounded mb-3">
                    <label for="exampleInputEmail1">
                      Featured Image:
                      <strong>
                        [Preferred Image Size: 400X400, Max Upload Limit:
                        1MB]
                      </strong>
                    </label>
                    <input
                      class="form-control mb-2"
                      type="file"
                      name="featured_image"
                      id="featuredImage"
                      ref="featuredImageInput"
                      aria-describedby="imageHelp"
                      accept="image/*"
                      @change="uploadFeaturedImage()"
                    />
                    <img
                      v-if="previewFeaturedImage"
                      :src="previewFeaturedImage"
                      height="113"
                      width="200"
                    />
                    <img v-else src="/core/assets/images/400.png" height="113" width="200" />
                    <div id="display_image"></div>
                    <div class="mt-3">
                      <label for="inputFeaturedImageAlt" class="form-label">Featured Image Alt</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputFeaturedImageAlt"
                        v-model="featuredImageAlt"
                        placeholder="Enter featured image alt"
                      />
                    </div>
                    <div class="mt-3">
                      <label for="inputFeaturedImageAlt" class="form-label">Featured Image Title</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputFeaturedImageAlt"
                        v-model="featuredImageTitle"
                        placeholder="Enter featured image Title"
                      />
                    </div>
                  </div>
                  <div class="border border-3 p-4 rounded mb-4">
                    <label for="exampleInputEmail1" class="mb-3">
                      Product Images (Max Limit 4)
                      <strong>
                        [Preferred Image Size: 900X550, Max Upload Limit:
                        1MB]
                      </strong>
                    </label>
                    <input
                      type="file"
                      style="display: none"
                      @change="uploadProductImages"
                      ref="productImages"
                    />

                    <div class="card text-center">
                      <div
                        @click="$refs.productImages.click()"
                        class="card-body btn btn-outline-primary"
                        :style="[disableButton == true ? { 'pointer-events': 'none' } : '']"
                      >
                        <h5>Select Image</h5>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-sm-3 mb-2"
                        v-for="(data, index) in previewProductImages"
                        :key="index"
                      >
                        <i
                          class="fa-solid fa-circle-xmark close-button"
                          @click="removeUploadedProductImage(index)"
                        ></i>
                        <img :src="data" height="90" width="150" />
                        <!-- <label class="form-label">Product Image Alt {{ index + 1 }}</label> -->
                        <input
                          type="text"
                          class="form-control mt-3"
                          v-model="productImageAltList[index].productImageAlt"
                          :placeholder="`Enter product image alt ${index + 1}`"
                        />
                      </div>                      
                    </div>
                  </div>                 
                  <div class="border border-3 p-4 rounded mb-4">
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Product Title</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputProductTitle"
                        v-model="title"
                        placeholder="Enter product title"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductUrl" class="form-label">Product URL</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputProductUrl"
                        v-model="urlSlug"
                        placeholder="Enter product url"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductCanonical" class="form-label">Product Canonical</label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputProductCanonical"
                        v-model="productCanonical"
                        placeholder="Enter product Canonical"
                      />
                    </div>
                  </div>

                </div>
                <div class="col-lg-4">
                  <div class="border border-3 p-4 rounded">
                    <div class="row g-3">
                      <div class="col-md-6">
                        <label for="inputPrice" class="form-label">Price</label>
                        <input
                          type="number"
                          class="form-control"
                          id="inputPrice"
                          v-model="price"
                          placeholder="00.00"
                        />
                      </div>
                      <div class="col-6">
                        <label for="inputProductType" class="form-label">
                          Offer
                          Percentage:
                        </label>
                        <div class="input-group">
                          <select
                            v-model="discount"
                            @change="changePrice()"
                            class="form-select"
                            id="inputGroupSelect04"
                          >
                            <option :value="{ id: null, name: 'None' }">None</option>
                            <option
                              v-for="(data, index) in discountList"
                              :key="index"
                              :value="{ id: data.id, name: data.discountTitle }"
                            >{{ data.discountTitle }}</option>
                          </select>
                          <div class="input-group-append">
                            <button
                              class="btn btn-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#addModal"
                              type="button"
                            >
                              <i class="bx bxs-plus-square"></i>New
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-12">
                                                <label for="inputCollection" class="form-label">Color</label>
                                                <Multiselect v-model="colorValue" mode="tags" :close-on-select="false" :searchable="true"
                                                :create-option="true" :options="colorsList" />
                                            </div>
                                            <div lass="col-12">
                                                <label for="inputCollection" class="form-label">Sizes</label>
                                                <Multiselect v-model="sizeValue" mode="tags" :close-on-select="false" :searchable="true"
                                                :create-option="true" :options="sizesList" />
                      </div>-->
                      <div class="col-md-6">
                        <label for="inputCompareatprice" class="form-label">
                          Discounted Price
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="inputCompareatprice"
                          placeholder="00.00"
                          v-model="discounted_price"
                          disabled
                        />
                      </div>

                      <div class="col-md-6">
                        <label for="inputStarPoints" class="form-label">SKU</label>
                        <input
                          type="text"
                          class="form-control"
                          id="inputStarPoints"
                          v-model="sku"
                          placeholder="SKU"
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPrice" class="form-label">Stock</label>
                        <input
                          type="number"
                          class="form-control"
                          id="inputPrice"
                          v-model="stock"
                          placeholder="stock"
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputPrice" class="form-label">Quantity Reorder</label>
                        <input
                          type="number"
                          class="form-control"
                          id="inputPrice"
                          v-model="quantityReorder"
                          placeholder="Quantity Reorder"
                        />
                      </div>
                      <div class="col-md-6">
                        <label class="form-label">Cost</label>
                        <input
                          type="number"
                          class="form-control"
                          pattern="[0-9]*"
                          v-model="cost"
                          placeholder="00.00"
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="inputCompareatprice" class="form-label">Weight</label>
                        <input
                          type="text"
                          class="form-control"
                          id="inputCompareatprice"
                          v-model="weight"
                          placeholder="00.00"
                        />
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="exampleInputEmail1">Featured:</label>
                              <div class="form-check form-switch mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="flexSwitchCheckChecked"
                                  v-model="isFeatured"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label for="exampleInputEmail1">Stock Out:</label>
                              <div class="form-check form-switch mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id="flexSwitchCheckChecked"
                                  v-model="stockOut"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6 mt-3">
                            <div class="form-group">
                              <label>LTL Freight:</label>
                              <div class="form-check form-switch mt-3">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  :checked="isLTLFreight"
                                  v-model="isLTLFreight"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <label for="inputVendor" class="form-label">Brand</label>
                        <select v-model="brand" class="form-select" id="inputVendor">
                          <option
                            v-for="(data, index) in brandList"
                            :key="index"
                            :value="{ id: data.id, name: data.brandName }"
                          >{{ data.brandName }}</option>
                        </select>
                      </div>
                      <div class="col-sm-12">
                        <label for="inputCollection" class="form-label">Tag:</label>
                        <Multiselect
                          v-model="tagValue"
                          mode="tags"
                          :close-on-select="false"
                          :searchable="true"
                          :create-option="true"
                          :options="tagList"
                        />
                      </div>                      
                    </div>
                  </div>

                  <div class="border border-3 p-4 rounded mt-3">
                    <label class="form-label">Accessories</label>
                    <hr />
                    <div class="row g-5" v-for="(data, index) in accessories" :key="index">
                      <div class="col-7">{{ data.name }}</div>
                      <div class="col-2">
                        <img
                          :src="imageUrl + 'productImage/' + data.featuredImage"
                          height="35"
                        />
                      </div>
                      <div class="col-3 mb-3 d-flex order-actions">
                        <a
                          href="javascript:void(0);"
                          type="button"
                          @click="deleteAccessory(data.id)"
                          class="ms-3 delete-button"
                        >
                          <i class="bx bxs-trash text-white"></i>
                        </a>
                      </div>
                    </div>
                    <div class="row g-3">
                      <div class="col-8" v-if="IsAccessoriesAddForOpen">
                        <v-select :options="dataList" v-model="accessoryValue" label="name" ></v-select>
                      </div>
                      <div class="col-4" v-if="IsAccessoriesAddForOpen">
                        <button type="button" class="btn btn-secondary" @click="addAccessory()">
                          Add Item
                        </button>
                      </div>
                      <div class="col-12">
                        <div class="d-grid">
                          <button type="button" class="btn btn-success" @click="addMoreAccessories()">
                            Add More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 mt-3">
                    <div class="d-grid">
                      <button type="submit" class="btn btn-primary">
                        Save Product
                      </button>
                    </div>
                  </div>

                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="addModalLabel"
      aria-hidden="true"
    >
      <form @submit.prevent="addDiscount" enctype="multipart/form-data">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addModalLabel">Add Discount</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <label class="form-label">Discount Percentage or Amount:</label>
              <input
                class="form-control mb-1"
                type="text"
                v-model="discountTitle"
                placeholder="e.g 25%, 30%, 10, 20 etc"
                aria-label="default input example"
              />
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Add</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src='../js/add-product.js'></script>

<style scoped>
@import "../css/add-product.css";

.delete-button {
	background-color: #f41127;
}
</style>