<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              About Us Services List
            </li>
          </ol>
        </nav>

        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add New About Us Services
            </button>
          </div>
          <!-- Add Modal -->

          <div
            class="modal fade"
            id="addModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form
              @submit.prevent="addAboutUsService"
              enctype="multipart/form-data"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">
                      Add About Us Services
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">About Services Title:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="title"
                      placeholder="About Services Title"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="form-group m-3">
                    <label for="exampleInputEmail1">Icon: [<strong>Get icons from <a href="https://fontawesome.com/" target="_blank">Font Awsome</a>, pick only the class name</strong>]</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="iconClass"
                      placeholder="Enter about service content icon class"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="form-group m-3">
                    <label>About Services Description</label>
                    <ckeditor
                      :editor="editor"
                      v-model="description"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Add Modal -->
          <!-- Edit Role Modal -->

          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updateAboutUsService">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">
                      Update About Services
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">About Services Name:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleAboutUsService.title"
                      placeholder="About Services Title"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="form-group m-3">
                    <label for="exampleInputEmail1">Icon [<strong>Get icons from <a href="https://fontawesome.com/" target="_blank">Font Awsome</a>, pick only the class name</strong>]</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleAboutUsService.iconClass"
                      placeholder="Enter about service content icon class"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="form-group m-3">
                    <label>About Services Description</label>
                    <ckeditor
                      :editor="editor"
                      v-model="singleAboutUsService.description"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Edit Role Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">About Services List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Title</th>
                  <th>Icon Class</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.title }}</td>
                  <td>{{ data.iconClass }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        v-on:click="editAboutUsService(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        class="edit-button"
                        ><i class="bx bxs-edit text-white"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteAboutUsService(data.id)"
                        class="ms-3 delete-button"
                        ><i class="bx bxs-trash text-white"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Title</th>
                  <th>Icon Class</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/about-services.js'></script>


<style scoped>

.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>