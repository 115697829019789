<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Footer CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard"
                  ><i class="bx bx-home-alt"></i
                ></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Footer CMS
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <form action="">
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Footer CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12">
                  <div class="border border-3 p-4 rounded mb-4">
                    <div class="row mb-3">
                      <div class="col-sm-6">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Menu One Title</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputTitle"
                            placeholder="Enter title"
                            v-model="pageCMSData.menuOneTitle"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Menu Two Title</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputTitle"
                            placeholder="Enter title"
                            v-model="pageCMSData.menuTwoTitle"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Menu Three Title</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputTitle"
                            placeholder="Enter title"
                            v-model="pageCMSData.menuThreeTitle"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Menu Four Title</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputTitle"
                            placeholder="Enter title"
                            v-model="pageCMSData.menuFourTitle"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="row mb-3">
                      <div class="col-sm-6">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Contact Address</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputTitle"
                            placeholder="Enter address"
                            v-model="pageCMSData.contactAddress"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Contact Number</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputTitle"
                            placeholder="Enter number"
                            v-model="pageCMSData.contactNumber"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Contact Email</label
                          >
                          <input
                            type="email"
                            class="form-control"
                            id="inputTitle"
                            placeholder="Enter email"
                            v-model="pageCMSData.contactEmail"
                          />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Email PlaceHolder</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputTitle"
                            placeholder="Enter place holder text"
                            v-model="pageCMSData.emailPlaceHolder"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="row mb-3">
                      <div class="col-sm-6">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Follow Us Text</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputTitle"
                            placeholder="Follow Us text"
                            v-model="pageCMSData.followUsText"
                          />
                        </div>
                      </div>
                      <!-- <div class="col-sm-6">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Privacy Policy Text</label
                          >
                          <input
                            type="email"
                            class="form-control"
                            id="inputTitle"
                            placeholder="Privacy policy text"
                            v-model="pageCMSData.privacyPolicyText"
                          />
                        </div>
                      </div> -->
                      <!-- <div class="col-sm-6">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Privacy Policy Link</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputTitle"
                            placeholder="Privacy policy link"
                            v-model="pageCMSData.privacyPolicyLink"
                          />
                        </div>
                      </div> -->
                      <!-- <div class="col-sm-6">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Terms And Condition Text</label
                          >
                          <input
                            type="email"
                            class="form-control"
                            id="inputTitle"
                            placeholder="Terms and condition text"
                            v-model="pageCMSData.termsAndConditionText"
                          />
                        </div>
                      </div> -->
                      <!-- <div class="col-sm-6">
                        <div class="mb-3">
                          <label for="inputProductTitle" class="form-label"
                            >Terms and Condition Link</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="inputTitle"
                            placeholder="Terms and condition link"
                            v-model="pageCMSData.termsAndConditionLink"
                          />
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <label
                    ><h6><strong>Image Section</strong></h6></label
                  >
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row">
                      <div class="col-lg-6">
                        <label
                          ><h6>
                            Payment Related Image:<strong>[Image Size: 50X30, Max Upload Limit: 1MB]</strong>
                          </h6></label
                        >
                        <div class="border border-3 p-4 rounded">
                          <div class="row g-3">
                            <div class="col-sm-6">
                              <label for="exampleInputEmail1"
                                >Payment Image1</label
                              >
                              <input
                                class="form-control mb-2"
                                type="file"
                                name="featured_image"
                                id="featuredImage"
                                ref="paymentImage1"
                                aria-describedby="imageHelp"
                                accept="image/*"
                                @change="uploadpaymentImage1($event)"
                              />
                              <img
                                v-if="previewPaymentImage1"
                                :src="previewPaymentImage1"
                                height="70"
                                width="100"
                              />
                              <img
                                v-else
                                :src="imagePath + pageCMSData.paymentImageOne"
                                height="70"
                                width="100"
                              />
                            </div>
                            <div class="col-sm-6">
                              <label for="exampleInputEmail1"
                                >Payment Image2</label
                              >
                              <input
                                class="form-control mb-2"
                                type="file"
                                name="featured_image"
                                id="featuredImage"
                                ref="paymentImage2"
                                aria-describedby="imageHelp"
                                accept="image/*"
                                @change="uploadpaymentImage2($event)"
                              />
                              <img
                                v-if="previewPaymentImage2"
                                :src="previewPaymentImage2"
                                height="70"
                                width="100"
                              />
                              <img
                                v-else
                                :src="imagePath + pageCMSData.paymentImageTwo"
                                height="70"
                                width="100"
                              />
                            </div>
                            <div class="col-sm-6">
                              <label for="exampleInputEmail1"
                                >Payment Image3</label
                              >
                              <input
                                class="form-control mb-2"
                                type="file"
                                name="featured_image"
                                id="featuredImage"
                                ref="paymentImage3"
                                aria-describedby="imageHelp"
                                accept="image/*"
                                @change="uploadpaymentImage3($event)"
                              />
                              <img
                                v-if="previewPaymentImage3"
                                :src="previewPaymentImage3"
                                height="70"
                                width="100"
                              />
                              <img
                                v-else
                                :src="imagePath + pageCMSData.paymentImageThree"
                                height="70"
                                width="100"
                              />
                            </div>
                            <div class="col-sm-6">
                              <label for="exampleInputEmail1"
                                >Payment Image4</label
                              >
                              <input
                                class="form-control mb-2"
                                type="file"
                                name="featured_image"
                                id="paymentImage4"
                                ref="paymentImage4"
                                aria-describedby="imageHelp"
                                accept="image/*"
                                @change="uploadpaymentImage4($event)"
                              />
                              <img
                                v-if="previewPaymentImage4"
                                :src="previewPaymentImage4"
                                height="70"
                                width="100"
                              />
                              <img
                                v-else
                                :src="imagePath + pageCMSData.paymentImageFour"
                                height="70"
                                width="100"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <label
                          ><h6>
                            <strong>Contact Related Icon  [<strong>Get icons from <a href="https://fontawesome.com/" target="_blank">Font Awsome</a>, pick only the class name</strong>]</strong>
                          </h6></label
                        >
                        <div class="border border-3 p-4 rounded">
                          <div class="row g-3">
                            <div class="col-sm-6">
                              <label for="exampleInputEmail1"
                                >Contact Address Icon</label
                              >
                              
                              <input
                                type="text"
                                class="form-control"
                                id="inputTitle"
                                placeholder="Enter contact address icon class"
                                v-model="pageCMSData.contactAddressIcon"
                              />
                            </div>
                            <div class="col-sm-6">
                              <label for="exampleInputEmail1"
                                >Contact Number Icon</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                id="inputTitle"
                                placeholder="Enter contact number icon class"
                                v-model="pageCMSData.contactNumberIcon"
                              />
                            </div>
                            <div class="col-sm-6">
                              <label for="exampleInputEmail1"
                                >Contact Email Icon</label
                              >
                              <input
                                type="text"
                                class="form-control"
                                id="inputTitle"
                                placeholder=" Enter contact email icon class"
                                v-model="pageCMSData.contactEmailIcon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-grid">
                <button
                  v-on:click="updateCms()"
                  type="button"
                  class="btn btn-primary"
                >
                  Save
                </button>
              </div>

              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/footer-cms.js"></script>