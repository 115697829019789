<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Bulk Product Price Update
                        </li>
                    </ol>
                </nav>
            </div>
            <div class="text-center">
                <a href="javascript:void(0)" @click="downloadData()" class="btn btn-info text-white"><i
                        class="fas fa-file-download"> </i> Download Bulk Product Price Template </a>
                <form ref="resetForm" class="d-flex justify-content-center align-items-center gap-3 mt-4 mb-4"
                    @submit.prevent="excelImport">
                    <input type="file" class="form-control bulk_custom_file_input" accept=".xlsx, .xls">
                    <button type="submit" class="btn btn-success">Upload</button>
                </form>
            </div>
            <div v-if="productList.length > 0" class="card">
                <div class="card-body">
                    <h6 class="mb-0 text-uppercase">Product List</h6>
                    <hr />
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Name</th>
                                    <th>SKU</th>
                                    <th>Cost</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in productList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.Name }}</td>
                                    <td>{{ data.Sku }}</td>
                                    <td>{{ data.Cost }}</td>
                                    <td>{{ data.Price }}</td>
                                    <td>{{ data.Quantity }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-end mt-3">
                        <button class="btn btn-primary" @click="confirmExcelImport">
                            Confirm Bulk Product Price
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/bulk-price-update.js">
</script>

<style scoped>
.bulk_custom_file_input {
    max-width: 500px;
    width: 100%;
}

.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
</style>
