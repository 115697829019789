<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Home Component CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="bx bx-home-alt"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">Home Component CMS</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Home Component CMS</h5>
          <hr />
          <div class="form-body mt-4" v-if="dataLoaded">
            <div class="row mb-3">
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker
                    label="Featured Product Title Font Color:"
                    v-model="pageCMSData.featuredProductTitleFontColor"
                    :clearable="true"
                    :bottomBar="true"
                    :colors="colors"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker
                    label="Featured Product Sub Title Font Color:"
                    v-model="pageCMSData.featuredProductSubTitleFontColor"
                    :clearable="true"
                    :bottomBar="true"
                    :colors="colors"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker
                    label="Top Selling Title Font Color:"
                    v-model="pageCMSData.topSellingProductTitleFontColor"
                    :clearable="true"
                    :bottomBar="true"
                    :colors="colors"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker
                    label="Top Selling Sub Title Font Color:"
                    v-model="pageCMSData.topSellingProductSubTitleFontColor"
                    :clearable="true"
                    :bottomBar="true"
                    :colors="colors"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker
                    label="Instagram Title Font Color:"
                    v-model="pageCMSData.instagramTitleFontColor"
                    :clearable="true"
                    :bottomBar="true"
                    :colors="colors"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker
                    label="Instagram Sub Title Font Color:"
                    v-model="pageCMSData.instagramSubTitleFontColor"
                    :clearable="true"
                    :bottomBar="true"
                    :colors="colors"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker
                    label="Review Title Font Color:"
                    v-model="pageCMSData.reviewTitleFontColor"
                    :clearable="true"
                    :bottomBar="true"
                    :colors="colors"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker
                    label="Review Sub Title Font Color:"
                    v-model="pageCMSData.reviewSubTitleFontColor"
                    :clearable="true"
                    :bottomBar="true"
                    :colors="colors"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker
                    label="News Title Font Color:"
                    v-model="pageCMSData.newsTitleFontColor"
                    :clearable="true"
                    :bottomBar="true"
                    :colors="colors"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker
                    label="News Sub Title Font Color:"
                    v-model="pageCMSData.newsSubTitleFontColor"
                    :clearable="true"
                    :bottomBar="true"
                    :colors="colors"
                  />
                </div>
              </div>
              <div class="col-md-3">
                <div class="input-group">
                  <LvColorpicker
                    label="Page Background Color:"
                    v-model="pageCMSData.pageBackgroundColor"
                    :clearable="true"
                    :bottomBar="true"
                    :colors="colors"
                  />
                </div>
              </div>
            </div>
            <div class="row justify-content-center mt-3">
              <!-- <div class="d-grid gap-2 d-md-block">
                                <button @click="updateCms" type="button" class="btn btn-primary">Save</button>
              </div>-->
              <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                <button class="btn btn-primary" @click="updateCms" type="button">Update</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/home-cms.js"></script>