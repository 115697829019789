import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            setURL: this.$serverURL + this.$api.blog.blogAPI,
            singleAPI: this.$serverURL + this.$api.blog.singleBlogAPI,
            blogName: "",
            blogUrlSlug: "",
            featuredImage: "",
            previewFeaturedImage: '',
            category: "",
            photoAlt: "",
            photoTitle: "",
            tagsValue: [],
            tagsList: [],
            shortDescription: "",
            longDescription: "",
            postedBy: localStorage.getItem('name'),
            uploaderImage: "",
            previousUploaderImage: "",
            categoryList: []
        }

    },
    async mounted() {
        await this.getTagList();
        await this.getCategoryList();

    },
    methods: {
        uploadFeaturedImage: function (event) {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.featuredImageInput;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateUploaderImage: function (event) {
            this.uploaderImage = event.target.files[0];
            let input = this.$refs.uploaderImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previousUploaderImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        getCategoryList: async function () {
            let url = this.$serverURL + this.$api.blog.blogCategoryAPI;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.categoryList = await response.data.data;

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getTagList: async function () {
            let url = this.$serverURL + this.$api.blog.blogTagAPI;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    let tagData = await response.data.data;
                    this.tagsList = tagData.map((el) => {
                        return { value: el.tagName, label: el.tagName }
                    });

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        validate() {
            if (!this.category) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog category."
                })
                return false;
            } else if (!this.blogName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Add blog name."
                })
                return false;
            }
            return true;
        },
        addBlogPost: async function () {
            if (this.validate()) {
                try {
                    this.$swal.showLoading();
                    let formData = new FormData();
                    formData.append('categoryId', this.category.id);
                    formData.append('categoryName', this.category.name)
                    formData.append('title', this.blogName);
                    formData.append('photo', this.featuredImage);
                    formData.append('shortDetails', this.shortDescription);
                    formData.append('longDetails', this.longDescription);
                    formData.append('photoAlt', this.photoAlt);
                    formData.append('photoTitle', this.photoTitle);
                    formData.append('postedBy', this.postedBy);
                    formData.append('uploaderPhoto', this.uploaderImage);
                    formData.append('urlSlug', this.blogUrlSlug);
                    formData.append('tags', JSON.stringify(this.tagsValue));
                    let config = {
                        method: 'POST',
                        url: this.setURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    const res = await this.postDataToBackend(config);

                    // add log to database
                    const firstName = localStorage.getItem('firstname');
                    const lastName = localStorage.getItem('lastname');
                    const configLogData = {
                        moduleName: 'blog',
                        action: 'add',
                        moduleId: res.data.data.id,
                        logMessage: `${res.data.data.title} added by ${firstName} ${lastName}`
                    }
                    await this.createUserLog(configLogData);

                    this.$router.push("/blog-post");
                } catch (error) {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again! " + error
                    });
                }
            }

        },
    },
    watch: {
        blogName(newValue) {
            this.blogUrlSlug = this.createUrlSlug(newValue);
        }
    }
}