<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <a href="javascript:;"><i class="bx bx-home-alt"></i></a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Payment Settings
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <center>
        <div class="card" style="width: 60%; border-radius: 10px;">
          <div class="card-header" style="background-color:#f9f5f5">
            <p
              style="
                font-weight: 600;
                font-size: 22px;
                top: 10px;
                position: relative;
              "
            >
              NMI Secret Key
            </p>
          </div>
          <div class="card-body">
            <!-- <div class="alert alert-primary" role="alert">
              You have already completed your shift for today.
            </div> -->
            <div class="m-3">
              <input
                type="text"
                v-model="securityKey"
                class="form-control form-control-lg"
                placeholder="Secret key"
              />
            </div>
          </div>
          <div class="card-footer text-muted">
            <button @click="updateSettings" class="btn btn-success" style="float: right">Save</button>
          </div>
        </div>
      </center>
    </div>
  </div>
</template>

<script src="../js/payment-settings.js"></script>


<style scoped>
@import "../css/payment-settings.css";
</style>