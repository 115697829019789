import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';
export default {
    data() {
        return {
            productList: [],
            filter: {
                paymentStatus: "",
                deliveryStatus: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            },
            note: "",
            userList: [],
            salesTotal: 0,
            salesTotalQuantity: 0,
            filteredOrderIdList: [],
            setURL: this.$serverURL + this.$api.returnProducts.requestURL + "?status=Unapproved",
            sheets: [],
            selectAll: "",
            selected: [],
            productForUpdateList: []
        }
    },
    async mounted() {
        document.title = "HGS - Unapproved Return Product";
        this.loadJs();
        await this.getReturnList();
        await this.getCustomerList();
    },
    methods: {
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });

            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
        async getReturnList() {
            try {
                let config = {
                    method: "GET",
                    url: this.setURL,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.productList = response.data.data;
                    }
                }).catch(error => {
                    console.log(error)
                });
            } catch (error) {
                console.log(error)
            }
        },
        async getCustomerList() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    // this.userList = await response.data.data;
                    this.userList = await response.data.data.map(el => {
                        return { value: el.firstName + el.lastName, label: el.firstName + el.lastName }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        async validateFilter() {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select end date"
                        });
                        return;
                    }
                }
                if (this.filter.endDate) {
                    if (!this.filter.startDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select start date"
                        });
                        return;
                    }
                }
                window.$('#example').DataTable().destroy();
                await this.getOrderList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },

        async clearFilter() {
            this.filter = {
                paymentStatus: "",
                deliveryStatus: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            };
            window.$('#example').DataTable().destroy();
            await this.getOrderList();
            window.$('#example').DataTable();
        },

        viewNote: function (data) {
            this.note = data.note;
        },

        handleSelectAll: function () {
            if (this.selectAll == true) {
                this.productList.map((el, index) => {
                    this.selected[index] = true;
                    this.productForUpdateList.push(el);
                })
            } else if (this.selectAll == false) {
                this.productList.map((el, index) => {
                    this.selected[index] = false;
                    this.productForUpdateList = [];
                })
            }
        },
        handleSelect: function (index, status, data) {
            if (status == true) {
                this.productForUpdateList[index] = data
            } else if (status == false) {
                this.productForUpdateList[index] = 0
            }
        },

        bulkStatusUpdate: async function (status) {
            if (this.selected.length == 0 || this.productForUpdateList.length == 0) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select a product first."
                })
                return;
            } else if (!status) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select a action to perform."
                })
            }
            if (this.productForUpdateList.length > 0) {

                this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Update Status!'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        let requests = [...new Set(this.productForUpdateList)];
                        let confirmedRequest = requests.filter(item => !(item == 0 || item == undefined));
                        let productList = confirmedRequest.map(el => {
                            return {
                                id: el.id,
                                status: status,
                            }
                        })
                        let config = {
                            method: "POST",
                            url: this.$serverURL + this.$api.returnProducts.bulkUpdate,
                            data: {
                                bulkUpdate: productList
                            },
                            headers: {
                                "Authorization": authHeader()
                            }
                        };
                        this.$swal.fire({
                            title: 'Please Wait...'
                        });
                        this.$swal.showLoading();
                        await this.$axios(config).then((response) => {
                            this.$swal.close();
                            if (response.data.statusCode == 200) {
                                this.$swal.fire({
                                    icon: 'success',
                                    text: response.data.message
                                });
                                this.productForUpdateList = [];
                                this.selected = [];
                                this.selectAll = false;
                                this.getReturnList();
                            }
                        }).catch(error => {
                            this.$swal.close();
                            console.log(error);
                        });
                    }
                })
            }
        },

        updateStatus: async function (item, status) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Update Status!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let data = {
                            status: status
                        }
                        let config = {
                            method: "PATCH",
                            url: this.$serverURL + this.$api.returnProducts.requestURL + '/' + item.id,
                            data: data,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        };
                        this.$swal.fire({
                            title: 'Please Wait...'
                        });
                        this.$swal.showLoading();
                        await this.$axios(config).then(async (response) => {
                            this.$swal.close();
                            if (response.data.statusCode == 200) {
                                this.$swal.fire({
                                    icon: 'success',
                                    text: response.data.message
                                })
                                this.getReturnList();
                            }
                        }).catch(error => {
                            this.$swal.close();
                            console.log(error)
                        });
                    } catch (error) {
                        this.$swal.close();
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        },

        exportData: function () {
            this.productList.map((el) => {
                this.sheets.push({
                    'OrderId': el.orderId,
                    "Customer Name": el.customerName,
                    "Product Name": el.productName,
                    "Quantity": el.quantity,
                    "Actual Price": el.actualPrice,
                    "Status": el.status,
                });
            });

            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'unapproved_product_return_report(' + date + ').xlsx');
            this.sheets = [];
        },
    }

}