<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add Site Custom Javascript 
            </li>
          </ol>
        </nav>

        <div class="ms-auto"></div>
      </div>
      <h6 class="mb-0 text-uppercase">Site Custom Javascript  Settings</h6>
      <hr />

      <div>
        <form @submit.prevent="updateCms()">
          <div class="card">
            <div class="card-header"><strong>Site Custom Javascript  Settings</strong></div>
            <div class="card-body">
              <div class="row">

                <div class="col-12">
                  <div class="form-group m-3">
                    <textarea rows="20" id="textbox01" v-model="genScriptContent" cols="20" style="width:100%;"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script src='../js/genScript.js'></script>
