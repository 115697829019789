import { authHeader } from "../../../../auth";
import html2pdf from "html2pdf.js";
export default {
  data() {
    return {
      id: this.$route.params.id,
      orderDetails: {},
      billingAddress: {},
      shippingAddress: {},
      pageCMSData: [],
      totalRefunded: 0,
      trackingNumber: "",
      returnProductList: [],
      note: "",
      selectAll: "",
      selected: [],
      productForUpdateList: [],
      bulkUpdate: false,
      singleReturnProduct: {},
      imagePath: this.$imageURL + "productImage/",
      serviceUPS: {
        "02": "2nd Day Air",
        59: "2nd Day Air A.M.",
        12: "3 Day Select",
        "03": "Ground UPS",
        "01": "Next Day Air",
        14: "Next Day Air Early AM",
        13: "Next Day Air Saver",
      },
    };
  },
  async created() {
    this.loadJs();
    await this.getOrderDetails();
    await this.getFooterCMS();
    await this.getReturnProductsList();
  },
  methods: {
    loadJs() {
      var scripts = [
        "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
        "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
        "/core/assets/js/table-datatable.js",
      ];
      scripts.forEach((script) => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
      });
    },
    getFooterCMS: async function () {
      this.setURL = this.$serverURL + this.$api.cms.footerPageCMS;
      await this.getCmsData(this.setURL);
      if (this.pageCMS) {
        this.pageCMSData = this.pageCMS;
      } else {
        this.pageCMSData = [];
      }
    },
    async getOrderDetails() {
      try {
        let config = {
          method: "GET",
          url: this.$serverURL + this.$api.order.orderURL + "/" + this.id,
          headers: {
            Authorization: authHeader(),
          },
        };
        await this.$axios(config)
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.orderDetails = response.data.Orders[0];
              if (
                this.orderDetails.refundAmount &&
                this.orderDetails.refundAmount > 0
              ) {
                this.totalRefunded = this.orderDetails.refundAmount;
              }
              // if(this.orderDetails?.orders && this.orderDetails?.orders.length > 0){
              //     this.totalRefunded = 0;
              //     this.orderDetails?.orders.map(el=>{
              //         if(el.isRefunded == true){
              //             let itemTotal = parseFloat(el.itemTotal);
              //             this.totalRefunded = this.totalRefunded + itemTotal;
              //         }
              //     })
              // }
              this.billingAddress = this.orderDetails?.billingAddress
                ? JSON.parse(this.orderDetails.billingAddress)
                : null;
              this.shippingAddress = this.orderDetails?.shippingAddress
                ? JSON.parse(this.orderDetails.shippingAddress)
                : null;
              // console.log("Billing: ", this.billingAddress);
              // console.log("Shipping: ", this.shippingAddress);

              this.trackingNumber = this.orderDetails.trackingNumber;
            }
          })
          .catch((error) => {
            this.$swal.fire({
              icon: "error",
              text: "Something went wrong. Please try again!" + error,
            });
          });
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          text: "Something went wrong, please try agian later." + error,
        });
      }
    },
    async getReturnProductsList() {
      try {
        let config = {
          method: "GET",
          url:
            this.$serverURL +
            this.$api.returnProducts.requestByOrderIdURL +
            "/" +
            this.orderDetails.orderId,
          headers: {
            Authorization: authHeader(),
          },
        };
        await this.$axios(config)
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.returnProductList = response.data.data;
              for (let i = 0; i < this.returnProductList.length; i++) {
                if (this.returnProductList[i].status == "Unapproved") {
                  this.bulkUpdate = true;
                  break;
                } else {
                  this.bulkUpdate = false;
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },
    viewNote: function (data) {
      this.note = data.note;
    },
    updateStatus: async function (item, status) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Update Status!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              let data = {
                status: status,
              };
              let config = {
                method: "PATCH",
                url:
                  this.$serverURL +
                  this.$api.returnProducts.requestURL +
                  "/" +
                  item.id,
                data: data,
                headers: {
                  Authorization: authHeader(),
                },
              };
              this.$swal.fire({
                title: "Please Wait...",
              });
              this.$swal.showLoading();
              await this.$axios(config)
                .then(async (response) => {
                  this.$swal.close();
                  if (response.data.statusCode == 200) {
                    this.$swal.fire({
                      icon: "success",
                      text: response.data.message,
                    });
                    this.getReturnProductsList();
                    this.getOrderDetails();
                  }
                })
                .catch((error) => {
                  this.$swal.close();
                  console.log(error);
                });
            } catch (error) {
              this.$swal.close();
              this.$swal.fire({
                icon: "error",
                text: "Something went wrong, please try agian later." + error,
              });
            }
          }
        });
    },
    generatePDF: function () {
      const doc = document.getElementById("doc");
      var opt = {
        filename: this.orderDetails.orderId + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        pagebreak: { mode: "avoid-all", before: "#page2el" },
      };
      html2pdf().from(doc).set(opt).save();
    },
    async refund(item) {
      try {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Refund!",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              let salesTax = 0;
              let amount = 0;
              let actualPrice = parseFloat(item.actualPrice) * item.quantity;
              //let itemPrice = actualPrice > 0 ? actualPrice : item.itemPrice;
              if (this.orderDetails.salesTaxRate) {
                salesTax = parseFloat(this.orderDetails.salesTaxRate);
              }
              if (salesTax > 0) {
                if (actualPrice > 0) {
                  amount = (actualPrice * (salesTax / 100)).toFixed(2);
                } else {
                  amount = (item.itemTotal * (salesTax / 100)).toFixed(2);
                }
              }
              let finalAmount = parseFloat(
                (item.itemSubTotal + parseFloat(amount)).toFixed(2)
              );
              //console.log(finalAmount);
              let data = {
                amount: finalAmount,
                orderId: item.orderId,
              };
              let config = {
                method: "PATCH",
                url:
                  this.$serverURL +
                  this.$api.order.refundItemPrice +
                  item.productId,
                data: data,
                headers: {
                  Authorization: authHeader(),
                },
              };
              this.$swal.showLoading();
              await this.$axios(config)
                .then(async (response) => {
                  this.$swal.hideLoading();
                  if (response.status == 200) {
                    this.$swal.fire({
                      toast: true,
                      position: "top-end",
                      text: response.data.message,
                      icon: "success",
                      showConfirmButton: false,
                      timer: 3000,
                      animation: false,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener(
                          "mouseenter",
                          this.$swal.stopTimer
                        );
                        toast.addEventListener(
                          "mouseleave",
                          this.$swal.resumeTimer
                        );
                      },
                    });
                    await this.getOrderDetails();
                    //document.getElementById('refundCloseBtn').click();
                  }
                })
                .catch((error) => {
                  this.$swal.hideLoading();
                  this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message,
                  });
                });
            }
          });
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          icon: "error",
          text: "Something went wrong, please try agian later.",
        });
      }
    },
    async addorUpdateOrderTracking() {
      try {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "This will reflect to the customer dashboard aswell.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Update order info",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              let data = {
                trackingNumber: this.trackingNumber,
                id: this.id,
              };
              let config = {
                method: "POST",
                url: this.$serverURL + this.$api.order.updateOrAddTrackingNo,
                data: data,
                headers: {
                  Authorization: authHeader(),
                },
              };
              this.$swal.showLoading();
              await this.$axios(config)
                .then(async (response) => {
                  this.$swal.hideLoading();
                  if (response.status == 200) {
                    this.$swal.fire({
                      toast: true,
                      position: "top-end",
                      text: response.data.message,
                      icon: "success",
                      showConfirmButton: false,
                      timer: 3000,
                      animation: false,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener(
                          "mouseenter",
                          this.$swal.stopTimer
                        );
                        toast.addEventListener(
                          "mouseleave",
                          this.$swal.resumeTimer
                        );
                      },
                    });
                    await this.getOrderDetails();
                    //document.getElementById('refundCloseBtn').click();
                  }
                })
                .catch((error) => {
                  this.$swal.hideLoading();
                  this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message,
                  });
                });
            }
          });
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          icon: "error",
          text: "Something went wrong, please try agian later.",
        });
      }
    },
    handleReturn: function (data) {
      console.log("Data = ", data);
      this.singleReturnProduct = data;
      this.singleReturnProduct.note = "";
      if (
        this.singleReturnProduct.quantity >
        this.singleReturnProduct.returnQuantity
      ) {
        this.singleReturnProduct.remainingQty =
          this.singleReturnProduct.quantity -
          this.singleReturnProduct.returnQuantity;
        this.singleReturnProduct.insertQty =
          this.singleReturnProduct.remainingQty;
      } else {
        this.singleReturnProduct.remainingQty = 0;
        this.singleReturnProduct.insertQty = 0;
      }
    },
    increaseQuantity: function () {
      if (
        this.singleReturnProduct.insertQty >=
        this.singleReturnProduct.remainingQty
      ) {
        this.singleReturnProduct.insertQty =
          this.singleReturnProduct.remainingQty;
      } else {
        this.singleReturnProduct.insertQty =
          this.singleReturnProduct.insertQty + 1;
      }
    },
    decreaseQuantity: function () {
      if (this.singleReturnProduct.insertQty <= 1) {
        this.singleReturnProduct.insertQty = 1;
      } else {
        this.singleReturnProduct.insertQty =
          this.singleReturnProduct.insertQty - 1;
      }
    },
    //bulk return product update section
    handleSelectAll: function () {
      if (this.selectAll == true) {
        this.returnProductList.map((el, index) => {
          if (el.status == "Unapproved") {
            this.selected[index] = true;
            this.productForUpdateList.push(el);
          }
        });
      } else if (this.selectAll == false) {
        this.returnProductList.map((el, index) => {
          this.selected[index] = false;
          this.productForUpdateList = [];
        });
      }
    },
    handleSelect: function (index, status, data) {
      if (status == true) {
        this.productForUpdateList[index] = data;
      } else if (status == false) {
        this.productForUpdateList[index] = 0;
      }
    },
    bulkStatusUpdate: async function (status) {
      if (this.selected.length == 0 || this.productForUpdateList.length == 0) {
        this.$swal.fire({
          icon: "info",
          text: "Please select a product first.",
        });
        return;
      } else if (!status) {
        this.$swal.fire({
          icon: "info",
          text: "Please select a action to perform.",
        });
        return;
      }
      if (this.productForUpdateList.length > 0) {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Update Status!",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              let requests = [...new Set(this.productForUpdateList)];
              let confirmedRequest = requests.filter(
                (item) => !(item == 0 || item == undefined)
              );
              let productList = confirmedRequest.map((el) => {
                return {
                  id: el.id,
                  status: status,
                };
              });
              let config = {
                method: "POST",
                url: this.$serverURL + this.$api.returnProducts.bulkUpdate,
                data: {
                  bulkUpdate: productList,
                },
                headers: {
                  Authorization: authHeader(),
                },
              };
              this.$swal.fire({
                title: "Please Wait...",
              });
              this.$swal.showLoading();
              await this.$axios(config)
                .then((response) => {
                  this.$swal.close();
                  if (response.data.statusCode == 200) {
                    this.$swal.fire({
                      icon: "success",
                      text: response.data.message,
                    });
                    this.productForUpdateList = [];
                    this.selected = [];
                    this.selectAll = false;
                    this.getReturnProductsList();
                    this.getOrderDetails();
                  }
                })
                .catch((error) => {
                  this.$swal.close();
                  console.log(error);
                });
            }
          });
      }
    },
    confirmReturn: async function () {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Return Product!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              let data = {
                orderId: this.singleReturnProduct.orderId,
                customerId: this.singleReturnProduct.customerId,
                customerName: this.orderDetails.customerName,
                productId: this.singleReturnProduct.productId,
                productName: this.singleReturnProduct.name,
                quantity: this.singleReturnProduct.insertQty,
                actualPrice:
                  this.singleReturnProduct.actualPrice == 0
                    ? this.singleReturnProduct.itemPrice
                    : this.singleReturnProduct.actualPrice,
                totalPrice:
                  (this.singleReturnProduct.actualPrice == 0
                    ? this.singleReturnProduct.itemPrice
                    : this.singleReturnProduct.actualPrice) *
                  this.singleReturnProduct.insertQty,
                note: this.singleReturnProduct.note,
                status: "Approved",
              };
              let config = {
                method: "POST",
                url:
                  this.$serverURL +
                  this.$api.returnProducts.adminReturnRequestURL,
                data: data,
                headers: {
                  Authorization: authHeader(),
                },
              };
              this.$swal.fire({
                title: "Please Wait...",
              });
              this.$swal.showLoading();
              await this.$axios(config)
                .then(async (response) => {
                  this.$swal.close();
                  if (response.data.statusCode == 201) {
                    this.$swal.fire({
                      icon: "success",
                      text: "Item returned successfully.",
                    });
                    document.getElementById("return-close-btn").click();
                    this.getOrderDetails();
                    this.getReturnProductsList();
                  }
                })
                .catch((error) => {
                  this.$swal.close();
                  console.log(error);
                });
            } catch (error) {
              this.$swal.close();
              this.$swal.fire({
                icon: "error",
                text: "Something went wrong, please try agian later." + error,
              });
            }
          }
        });
    },
  },
};
