import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';
export default {

    data() {
        return {
            url: this.$serverURL + this.$api.subscriber.franchiseAPI,
            sheets: []
        }
    },
    async created() {
        document.title = "HGS - Franchise";
        await this.getDataList(this.url);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        getExcelProducts: async function () {
            this.dataList.map((el) => {
                this.sheets.push({
                    'Franchise Name': el.franchiseName, 
                    'Name': el.firstName + " " + el.lastName,
                    'Email': el.email,
                    'Phone': el.phone,
                    'State': el.state,
                    'City': el.city,
                    'Zip Code': el.zipCode,
                    'Country': el.country,
                    'Notes': el.notes
                });
            })
            const data = XLSX.utils.json_to_sheet(this.sheets);
            const wb = XLSX.utils.book_new();
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            XLSX.writeFile(wb,'franchise-report('+date+').xlsx');    
        },
        deleteFranchise: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.url + "/" + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.url);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })

        }
    }
}