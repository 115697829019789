import { authHeader } from "../../../../auth";
export default {
    data() {
        return {
            setCmsURL: this.$serverURL + this.$api.cms.fedxCutoffTImeCms,
            requestUrl: this.$serverURL + this.$api.fedxCutoffTime.requestUrl,
            requestUpdateUrl: this.$serverURL + this.$api.fedxCutoffTime.requestUpdateUrl,
            pageCMSData: {},
            imagePath: this.$imageURL + 'product-page-cms/',
            previewImage: '',
            timeList: [{ item: '' }],
            timeValue: [
                {
                    title: '',
                    subTitle: '',
                    default: false,
                    time: new Date().toISOString().slice(0, 16),
                }
            ],
            singleTimeValue: {},
            checkForUpdate: false
        }
    },
    async created() {
        document.title = "HGS - Shipping Cut-off Time CMS";
        await this.getCmsData(this.setCmsURL);
        await this.getFedxCutoffTimes();
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = {};
        }
    },
    computed: {
        checkHasKey() {
            return function (data) {
                if (Object.keys(data).includes('item')) {
                    return true;
                }
                return false;
            }

        },

        hasIteminTimeListMoreThanOne() {
            if (this.timeList.length > 1) {
                return true;
            }
            return false;
        },

        checkForNewItem() {
            let hasNewItem = false;
            for (let i = 0; i < this.timeList.length; i++) {
                if (Object.keys(this.timeList[i]).includes('item')) {
                    hasNewItem = true;
                    break
                }
            }
            return hasNewItem;
        },

        getCurrentLocalDate() {
            const now = new Date();
            const timezoneOffset = now.getTimezoneOffset();
            now.setMinutes(now.getMinutes() - timezoneOffset);
            const formattedDateTime = now.toISOString().slice(0, 16);
            return formattedDateTime;
        }
    },
    methods: {
        uploadImage(event) {
            this.pageCMSData.image = event.target.files[0];
            let input = this.$refs.addImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async update() {
            try {
                if (!this.pageCMSData.image) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter Image!"
                    });
                }
                else if (!this.pageCMSData.title) {
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter title!"
                    });
                }
                else {
                    let formData = new FormData();
                    formData.append('title', this.pageCMSData.title);
                    formData.append('subTitle', this.pageCMSData.subTitle);
                    formData.append('image', this.pageCMSData.image);
                    let config = {
                        method: 'POST',
                        url: this.setCmsURL,
                        data: formData,
                        headers: {
                            "Authorization": authHeader(),
                            "Content-Type": "multipart/form-data",
                        }
                    };
                    await this.createUpdateCMS(config);
                    await this.getCmsData(this.setCmsURL);

                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        },

        async getFedxCutoffTimes() {
            try {
                this.timeList = [];
                this.timeValue = [];
                await this.getDataList(this.requestUrl).then(() => {
                    this.dataList.map((el, index) => {
                        el.noUpdate = true;
                        this.timeList[index] = el;
                        let value = {
                            title: el.title,
                            subTitle: el.subTitle,
                            time: el.time,
                        }
                        this.timeValue[index] = value;
                    });
                })
                if (this.timeList.length == 0) {
                    this.timeList = [{ item: '' }];
                }
                if (this.timeValue.length == 0) {
                    this.timeValue = [
                        {
                            title: '',
                            subTitle: '',
                            default: false,
                            time: new Date().toISOString().slice(0, 16),
                        }
                    ]
                }
            } catch (error) {
                console.log(error);
            }
        },

        addMore() {
            this.timeList.push({ item: '' });
            this.timeValue.push({ title: '', subTitle: '', default: false, time: new Date().toISOString().slice(0, 16), });
            if(this.checkForUpdate && this.singleTimeValue.id) {
                this.timeList[this.singleTimeValue.index].noUpdate = true;
                this.checkForUpdate = false;
                this.singleTimeValue = {};
            }
        },
        removeFromTimeList(index) {
            this.timeList.splice(index, 1);
            this.timeValue.splice(index, 1);
        },

        showMsg(msg) {
            this.$swal.fire({
                toast: true,
                position: 'top-end',
                text: msg,
                icon: "error",
                showConfirmButton: false,
                timer: 3000,
                animation: false,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            });
        },

        validateData() {
            let isValid = true;
            this.timeValue.map(el => {
                if (!el.title) {
                    isValid = false;
                    this.showMsg("Title required!");
                } else if (!el.subTitle) {
                    isValid = false;
                    this.showMsg("Sub title required!");
                } else if (!el.time) {
                    isValid = false;
                    this.showMsg("Time required!");
                }
            });
            return isValid;
        },


        createTimes() {
            try {
                if (this.validateData()) {
                    const config = {
                        method: 'POST',
                        url: this.requestUrl,
                        data: {
                            cutOffTime: this.timeValue,
                        },
                        headers: {
                            "Authorization": authHeader(),
                        }
                    }
                    this.postDataToBackend(config).then(() => {
                        this.getFedxCutoffTimes();
                    });
                }

            } catch (error) {
                console.log(error);
            }
        },

        updateTimes() {
            try {
                if (this.validateData()) {
                    const data = this.timeValue[this.singleTimeValue.index];
                    const config = {
                        method: 'PATCH',
                        url: this.requestUpdateUrl + this.singleTimeValue.id,
                        data,
                        headers: {
                            "Authorization": authHeader(),
                        }
                    }
                    this.updateDataToBackend(config).then(() => {
                        this.getFedxCutoffTimes();
                    });
                    this.checkForUpdate = false;
                }
            } catch (error) {
                console.log(error);
            }
        },

        handleDefault(data) {
            try {
                const config = {
                    method: 'PATCH',
                    url: this.requestUrl + '/' + data.id,
                    data: {
                        default: true,
                    },
                    headers: {
                        "Authorization": authHeader(),
                    }
                }
                this.updateDataToBackend(config).then(() => {
                    this.getFedxCutoffTimes();
                });
            } catch (error) {
                console.log(error);
            }
        },

        handleDelete(data) {
            try {
                const config = {
                    method: 'DELETE',
                    url: this.requestUrl + '/' + data.id,
                    headers: {
                        "Authorization": authHeader(),
                    }
                }
                this.deleteDataToBackend(config).then(() => {
                    this.getFedxCutoffTimes();
                });
            } catch (error) {
                console.log(error);
            }
        },

        setSingleTimeValue(data, index) {
            // remove add more if there any
            if(this.hasIteminTimeListMoreThanOne){
                for (let i = this.timeList.length - 1; i >= 0; i--) {
                    if(this.checkHasKey(this.timeList[i])) {
                        this.removeFromTimeList(i);
                    }
                }
            }

            if(this.checkForUpdate && this.singleTimeValue.id) {
                this.timeList[this.singleTimeValue.index].noUpdate = true;
            }
            this.singleTimeValue = {...data, index };
            this.checkForUpdate = true;
            data.noUpdate = false;
        }
    }
}