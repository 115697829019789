<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Users Log List
            </li>
          </ol>
        </nav>
      </div>
      <h6 class="mb-0 text-uppercase">Users Log List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Order State Name</th>
                  <th>Total Cost</th>
                  <th>Shipping Cost</th>
                  <th>Tax</th>
                  <th>Shipping Type</th>
                  <th>Shipping Method</th>
                  <th>Item Count</th>
                  <th>Payment Method Name</th>
                  <th>Card Holder Name</th>
                  <th>Card Number</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.customerName }}</td>
                  <td>{{ data.state }}</td>
                  <td>{{ data.total }}</td>
                  <td>{{ data.shippingCost }}</td>
                  <td>{{ data.tax }}</td>
                  <td>{{ data.shippingType }}</td>
                  <td>{{ data.shippingMethod }}</td>
                  <td>{{ JSON.parse(data.products)?.length }}</td>
                  <td>{{ data.paymentMethodName }}</td>
                  <td>{{ data.cardHolderName }}</td>
                  <td>{{ data.cardNumber }}</td>
                  <td>{{ $filters.dateFormat(data.createdAt) }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Order State Name</th>
                  <th>Total Cost</th>
                  <th>Shipping Cost</th>
                  <th>Tax</th>
                  <th>Shipping Type</th>
                  <th>Shipping Method</th>
                  <th>Item Count</th>
                  <th>Payment Method Name</th>
                  <th>Card Holder Name</th>
                  <th>Card Number</th>
                  <th>Created At</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/order-dump-log.js'></script>


<style scoped>
  .edit-button {
    background-color: #17a00e;
  }

  .delete-button {
    background-color: #f41127;
  }
</style>