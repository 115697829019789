import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL
                }
            },
            categoryList: [],
            siteUrl: this.$serverURL + this.$api.productCategory.categorySeoContentURL,
            categoryId: '',
            categoryName: '',
            categorySeoContent: "",
            isActiveList: true
        }
    },
    async created() {
        document.title = "HGS - Category Seo Content";
        await this.getCategoryList(this.siteUrl);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getCategoryList: async function (url) {
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.categoryList = await response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        updateSeoContent: async function() {
            try {
                if (!this.categorySeoContent) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter seo content!"
                    });
                    return false;
                }
                let data = {
                    categoryId: this.categoryId,
                    categoryName: this.categoryName,
                    categorySeoContent: this.categorySeoContent
                };
                let config = {
                    method: 'POST',
                    url: this.siteUrl,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                await this.getCategoryList(this.siteUrl);
                this.backToList(true);
                this.categoryId = "";
                this.categoryName = "";
                this.categorySeoContent = "";
            }
            catch(error) {
                console.log(error);
            }
        },
        gotoAddSeoContent(categoryId, categoryName, categorySeoContent, isActiveList) {
            this.categoryId = categoryId;
            this.categoryName = categoryName;
            this.categorySeoContent = categorySeoContent;
            this.isActiveList = isActiveList;
        },
        backToList(isActiveList) {
            this.isActiveList = isActiveList;
        }
    }
}