export default {
    data() {
        return {
            setURL: '',
            pageCMSData: {
                newUserWelcomeMessage: null,
                regularUserWelcomeMessage: null,
                footerMessage: null
            }
        }
    },
    async mounted() {
        document.title = "HGS - Shopping Cart Cms";
        this.setURL = this.$serverURL + this.$api.cms.shoppingCartCmsURL;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
    },
    methods: {
        async update() {
            try {
                let token = localStorage.getItem('token');
                let data = {
                    newUserWelcomeMessage: this.pageCMSData.newUserWelcomeMessage,
                    regularUserWelcomeMessage: this.pageCMSData.regularUserWelcomeMessage,
                    footerMessage: this.pageCMSData.footerMessage
                }
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}