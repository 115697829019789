import { authHeader } from './auth';
export default {
    data() {
        return {
            parentCategoriesList: [],
            subCategoriesList: [],
            childCategoryList: [],
            dataList: [],
            pageCMS: [],
            userLogUrl: this.$serverURL + this.$api.userLog.userLogAPI,
        }
    },
    methods: {
        getDataList: async function (url) {
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    if (response.data.data) {
                        this.dataList = await response.data.data;
                    } else if (response.data.products) {
                        this.dataList = await response.data.products;
                    }

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });

        },
        updateDataToBackend: async function (config) {
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        deleteDataToBackend: async function (config) {
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }

            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        postDataToBackend: async function (config) {
            this.$swal.showLoading();
            let res;
            await this.$axios(config).then((response) => {
                this.$swal.hideLoading();
                if (response.status == 201) {
                    res = response;

                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.data.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                else {
                    this.$swal.hideLoading();
                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.hideLoading();
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
                return;
            });
            return res;
        },
        getCmsData: async function (url) {
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.pageCMS = await response.data.data[0];

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        createUpdateCMS: async function (config) {
            try {
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200 || response.data.statusCode == 201) {

                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                return error;
            }
        },
        getAllParentCategories: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.productCategory.getAllParentCategorisesURL,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.data.statusCode == 200) {
                        this.parentCategoriesList = response.data.data;
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                return error;
            }
        },
        getParentCategoriesWithSubcategory: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.productCategory.getParentCategoriesWithSubCategory,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 200) {
                        this.parentCategoriesList = response.data;
                    }
                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                return error;
            }
        },
        getSubCategories: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.productCategory.getSubCategoriesURL,
                    headers: {
                        "Content-Type": "application/json"
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.subCategoriesList = await response.data.data;
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                return error;
            }
        },
        getChildCategory: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.productCategory.childCategoryURL,
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        this.childCategoryList = await response.data.data;
                    }
                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                return error;
            }
        },
        getOrderTotal(data){
            let grandTotal = parseFloat(data.subTotal) - parseFloat(data.totalDiscount);
            if(data.shippingCost){
                grandTotal = grandTotal + parseFloat(data.shippingCost)
            }
            if(data.salesTaxAmount){
                grandTotal = grandTotal + parseFloat(data.salesTaxAmount)
            }
            if(data.couponNumber){
                grandTotal = grandTotal - parseFloat(data.couponDiscount)
            }
            if(data.refundAmount && data.refundAmount > 0){
                grandTotal = grandTotal - data.refundAmount
            }
            return grandTotal.toFixed(2);
        },
        createImageCdnForEmailTemplate: async function (config) {
            const response = await this.$axios(config);
            if (response.status == 200 || response.data.data.statusCode == 200) {
                return response.data.data;
            }
        },
        limitText(text, limit) {
            const words = text.trim().split(' ');
            if (words.length > limit) {
                return words.slice(0, limit).join(' ') + '...';
            } else {
                return text;
            }
        },
        cleanText(text) {
            // Remove HTML tags
            const strippedText = text.replace(/(<([^>]+)>)/gi, "");
            // Remove special characters
            const cleanedText = strippedText.replace(/[^\w\s]/gi, "");
            // Trim whitespace
            const trimmedText = cleanedText.trim();
            return trimmedText;
        },
        createUrlSlug(title) {
            let slug = "";
        
            // Convert to lower case
            slug = title.toLowerCase();
        
            // Remove special characters
            slug = slug.replace(/`|~|!|@|#|\||\$|%|\^|&|\*|\(|\)|\+|=|,|\?|>|<|'|"|:|;|_/gi, '');
        
            // Replace spaces with dash symbols
            slug = slug.replace(/ /gi, "-");
        
            // Replace forward slash with dash symbols
            slug = slug.replace(/\//gi, "-");
        
            // Replace dot with dash symbols
            slug = slug.replace(/\./gi, "-");
        
            // Remove consecutive dash symbols 
            slug = slug.replace(/-----/gi, '-');
            slug = slug.replace(/----/gi, '-');
            slug = slug.replace(/---/gi, '-');
            slug = slug.replace(/--/gi, '-');
        
            // Remove the unwanted dash symbols at the beginning and the end of the slug
            slug = '@' + slug + '@';
            slug = slug.replace(/@-|-@|@/gi, '');
        
            return slug;
        },
        async createUserLog(data) {
            const configLog = {
                method: "POST",
                data,
                url: this.userLogUrl,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(configLog);
        }
    }
}