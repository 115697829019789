import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LvColorpicker from 'lightvue/color-picker';
export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    //uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            setURL: '',
            pageCMSData: {},
            image: "",
            previewImageOne: "",
            titleColor: "",
            dataLoaded: false,
            colors: [
                "#E91E63", "#F44336", "#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#4CAF50"
                , "#8BC34A", "#CDDC39", "#FFEB3B", "#FFC107", "#FF9800", "#795548"]
        }
    },
    async created() {
        document.title = "HGS -  Home Component Cms";
        this.setURL = this.$serverURL + this.$api.ComponentCms.homePageURL;

        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = {};
        }
        this.dataLoaded = true;
    },
    methods: {
        async updateCms() {
            try {
                let data = {
                    featuredProductTitleFontColor: this.pageCMSData.featuredProductTitleFontColor ? this.pageCMSData.featuredProductTitleFontColor : '#000000',
                    featuredProductSubTitleFontColor: this.pageCMSData.featuredProductSubTitleFontColor ? this.pageCMSData.featuredProductSubTitleFontColor : '#000000',
                    topSellingProductTitleFontColor: this.pageCMSData.topSellingProductTitleFontColor ? this.pageCMSData.topSellingProductTitleFontColor : '#000000',
                    topSellingProductSubTitleFontColor: this.pageCMSData.topSellingProductSubTitleFontColor ? this.pageCMSData.topSellingProductSubTitleFontColor : '#000000',
                    instagramTitleFontColor: this.pageCMSData.instagramTitleFontColor ? this.pageCMSData.instagramTitleFontColor : '#000000',
                    instagramSubTitleFontColor: this.pageCMSData.instagramSubTitleFontColor ? this.pageCMSData.instagramSubTitleFontColor : '#000000',
                    reviewTitleFontColor: this.pageCMSData.reviewTitleFontColor ? this.pageCMSData.reviewTitleFontColor : '#000000',
                    reviewSubTitleFontColor: this.pageCMSData.reviewSubTitleFontColor ? this.pageCMSData.reviewSubTitleFontColor : '#000000',
                    newsTitleFontColor: this.pageCMSData.newsTitleFontColor ? this.pageCMSData.newsTitleFontColor : '#000000',
                    newsSubTitleFontColor: this.pageCMSData.newsSubTitleFontColor ? this.pageCMSData.newsSubTitleFontColor : '#000000',
                    pageBackgroundColor: this.pageCMSData.pageBackgroundColor ? this.pageCMSData.pageBackgroundColor : '#fff',
                }
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    },
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                await this.getCmsData(this.setURL);
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! "
                });
            }
        }
    }
}