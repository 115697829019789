import { authHeader } from "../../../auth";
export default {
    data() {
        return {
            catURL: this.$serverURL + this.$api.productCategory.getAllParentCategorisesURL,
            setURL: this.$serverURL + this.$api.productCategory.featuredCategoryAPI,
            categoryList: [],
            featuredCategory: {},
            firstCategory: {},
            secondCategory: {},
            thirdCategory: {},
            featuredImage1: "",
            featuredImage2: "",
            featuredImage3: "",
            previewImage1: "",
            previewImage2: "",
            previewImage3: "",
        }

    },
    async created() {
        await this.getDataList(this.catURL);
        this.categoryList = this.dataList;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.featuredCategory = this.pageCMS;
            this.firstCategory = {
                id: this.featuredCategory.categoryIdOne,
                name: this.featuredCategory.categoryNameOne,
            };
            this.secondCategory = {
                id: this.featuredCategory.categoryIdTwo,
                name: this.featuredCategory.categoryNameTwo,
            };
            this.thirdCategory = {
                id: this.featuredCategory.categoryIdThree,
                name: this.featuredCategory.categoryNameThree,
            };
        }
        else {
            this.featuredCategory = {
                categoryIdOne: "",
                categoryNameOne: "",
                featuredImage1: "",

                categoryIdTwo: "",
                categoryNameTwo: "",
                featuredImage2: "",

                categoryIdThird: "",
                categoryNameThird: "",
                featuredImage3: "",

            };
        }
    },
    methods: {
        uploadFeaturedImage1: function (event) {
            this.featuredImage1 = event.target.files[0];
            let input = this.$refs.featuredImage1;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage1 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadFeaturedImage2: function (event) {
            this.featuredImage2 = event.target.files[0];
            let input = this.$refs.featuredImage2;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage2 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadFeaturedImage3: function (event) {
            this.featuredImage3 = event.target.files[0];
            let input = this.$refs.featuredImage3;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage3 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCategories() {
            try {
                let formData = new FormData();
                formData.append('categoryIdOne', this.firstCategory.id);
                formData.append('categoryNameOne', this.firstCategory.name);
                formData.append('featuredImage1', this.featuredImage1 ? this.featuredImage1 : this.featuredCategory.featuredImage1);
                formData.append('categoryIdTwo', this.secondCategory.id);
                formData.append('categoryNameTwo', this.secondCategory.name);
                formData.append('featuredImage2', this.featuredImage2 ? this.featuredImage2 : this.featuredCategory.featuredImage2);
                formData.append('categoryIdThree', this.thirdCategory.id);
                formData.append('categoryNameThree', this.thirdCategory.name);
                formData.append('featuredImage3', this.featuredImage3 ? this.featuredImage3 : this.featuredCategory.featuredImage3);
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(''),
                    }
                };
                this.$swal.showLoading();
                await this.createUpdateCMS(config);
                this.$swal.hideLoading();
                this.featuredImage1 = "";
                this.featuredImage2 = "";
                this.featuredImage3 = "";
                this.$refs.featuredImage1.value = "";
                this.$refs.featuredImage2.value = "";
                this.$refs.featuredImage3.value = "";
                this.previewImage1 = "";
                this.previewImage2 = "";
                this.previewImage3 = "";
                await this.getCmsData(this.setURL);
                this.featuredCategory = this.pageCMS;
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    },
}