<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Home</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Home Page CMS
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Home Page CMS</h5>
          <hr />
          <div class="form-body mt-4">
            <div class="row">
              <div class="col-lg-8">
                <div class="border border-3 p-4 rounded">
                  <div class=" mb-5">
                    <h5>Section Two</h5>
                    <hr>
                    <div class="">
                      <label class="form-label">Title:</label>
                      <input type="text" v-model="pageCMSData.sectionTwoTitle" class="form-control"
                        id="startYourAccountTitle" placeholder="Enter Start Your Account Title" />
                    </div>
                    <div class=" mt-3">
                      <label class="form-label">Description:</label>
                      <ckeditor :editor="editor" v-model="pageCMSData.sectionTwoDescription" :config="editorConfig"></ckeditor>
                    </div>
                  </div>
                  <div class="row mb-5">
                    <h5>Start Your Account Section</h5>
                    <hr>
                    <div class="col-md-6">
                      <label class="form-label">
                         Title</label>
                      <input type="text" v-model="pageCMSData.startYourAccountTitle" class="form-control"
                        id="startYourAccountTitle" placeholder="Enter Start Your Account Title" />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">
                         Background Text</label>
                      <input type="text" v-model="pageCMSData.startYourAccountBackgroundText" class="form-control"
                        id="startYourAccountBackgroundText" placeholder="Enter Start Your Account Background Text" />
                    </div>
                  </div>

                  <div class=" mb-5">
                    <h5>Brand Section</h5>
                    <hr>
                    <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Title</label>
                      <input type="text" v-model="pageCMSData.brandTitle" class="form-control" id="brandTitle"
                        placeholder="Enter Brand Title" />
                    </div>
                    <!-- <div class="col-md-6">
                      <label class="form-label"> Brand Background Text</label>
                      <input type="text" v-model="pageCMSData.brandBackgroundText" class="form-control"
                        id="brandBackgroundText" placeholder="Enter Brand Background Text" />
                    </div> -->
                    <div class="col-md-6">
                      <label class="form-label">Subtitle</label>
                      <input type="text" v-model="pageCMSData.brandSubtitle" class="form-control"
                        placeholder="Enter Brand Subtitle" />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Button Text</label>
                      <input type="text" v-model="pageCMSData.brandButtonText" class="form-control"
                        placeholder="Enter Brand Button Text" />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Button Link</label>
                      <input type="text" v-model="pageCMSData.brandButtonLink" class="form-control"
                        placeholder="Enter Brand Button Link" />
                    </div>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Brand Background Image</label>
                    <img style="position: relative; left: 11px" v-if="previewImage" :src="previewImage" height="70"
                      width="100" />
                    <img style="position: relative; left: 11px" v-else-if="pageCMSData.brandBackgroundImage"
                      :src="pageCMSData.brandBackgroundImage" height="70" width="100" />
                    <img style="position: relative; left: 11px" v-else src="/core/assets/images/400.png" height="70"
                      width="100" />
                    <input class="form-control mt-3" type="file" ref="backgroundImage" accept="image/*"
                      @change="uploadFeaturedImage" />
                  </div>
                  </div>

                  <div class=" mb-5">
                    <h5>Indoor Store Section</h5>
                    <hr>
                    <div class=" mb-2">
                      <label class="form-label">Title</label>
                      <input type="text" v-model="pageCMSData.indoorStoreTitle" class="form-control" id="brandTitle"
                        placeholder="Enter Brand Title" />
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-6">
                      <label class="form-label">Image Title</label>
                      <input type="text" v-model="pageCMSData.indoorStoreImageTitle" class="form-control"
                        placeholder="Enter Brand Subtitle" />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Image Alt Tag</label>
                      <input type="text" v-model="pageCMSData.indoorStoreImageAlt" class="form-control"
                        placeholder="Enter Brand Subtitle" />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Button Text</label>
                      <input type="text" v-model="pageCMSData.indoorStoreButtonText" class="form-control"
                        placeholder="Enter Brand Button Text" />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Button Link</label>
                      <input type="text" v-model="pageCMSData.indoorStoreButtonLink" class="form-control"
                        placeholder="Enter Brand Button Link" />
                    </div>
                  </div>
                  <div class=" mt-3">
                      <label class="form-label">Description:</label>
                      <ckeditor :editor="editor" v-model="pageCMSData.indoorStoreDescription" :config="editorConfig"></ckeditor>
                    </div>
                  <div class="my-3">
                    <label class="form-label">Image</label>
                    <img style="position: relative; left: 11px" v-if="previewImage2" :src="previewImage2" height="70"
                      width="100" />
                    <img style="position: relative; left: 11px" v-else-if="pageCMSData.indoorStoreImage"
                      :src="pageCMSData.indoorStoreImage" height="70" width="100" />
                    <img style="position: relative; left: 11px" v-else src="/core/assets/images/400.png" height="70"
                      width="100" />
                    <input class="form-control mt-3" type="file" ref="indoorStoreImage" accept="image/*"
                      @change="uploadIndoorStoreImage" />
                  </div>
                  </div>

                  <div class="mb-5">
                    <h5>Top Selling Product Section</h5>
                    <hr>
                    <div class="row mb-2">
                      <div class="col-md-6">
                      <label class="form-label">Title</label>
                      <input type="text" v-model="pageCMSData.topSellingTitle" class="form-control"
                        id="inputPageHeaderPageName" placeholder="Enter Top Seling Title" />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Sub Title</label>
                      <input type="text" v-model="pageCMSData.topsellingSubtitle" class="form-control"
                        id="inputPageHeaderPageName" placeholder="Enter Top Seling Subtitle" />
                    </div>
                  </div>

                  <div class=" mb-2">
                    <div class="">
                      <label class="form-label">Background Text</label>
                      <input type="text" v-model="pageCMSData.topSellingBackgroundText" class="form-control"
                        id="inputPageHeaderPageName" placeholder="Enter Featured Product Background Text" />
                    </div>
                    <div class=" mt-3">
                      <label class="form-label">Description:</label>
                      <ckeditor :editor="editor" v-model="pageCMSData.topsellingDescription" :config="editorConfig"></ckeditor>
                    </div>
                  </div>
                  </div>

                  <div class="mb-5">
                    <h5>Featured Product Section</h5>
                    <hr>
                    <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Title</label>
                      <input type="text" v-model="pageCMSData.featuredProductTitle" class="form-control"
                        id="inputPageHeaderPageName" placeholder="Enter Title" />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Background Text</label>
                      <input type="text" v-model="pageCMSData.featuredProductBackgroundText" class="form-control"
                        id="inputPageHeaderPageName" placeholder="Enter Featured Product Subtitle" />
                    </div>
                  </div>
                  <div class=" mt-3">
                      <label class="form-label">Description:</label>
                      <ckeditor :editor="editor" v-model="pageCMSData.featuredProductDescription" :config="editorConfig"></ckeditor>
                    </div>
                  </div>

                  <div class="mb-5">
                    <h5>Customer Review Section</h5>
                    <hr>
                    <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Title</label>
                      <input type="text" v-model="pageCMSData.customerReviewTitle" class="form-control"
                        id="inputPageHeaderPageName" placeholder="Enter Featured Product Title" />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Background Text</label>
                      <input type="text" v-model="pageCMSData.customerReviewBackgroundText" class="form-control"
                        id="inputPageHeaderPageName" placeholder="Enter Featured Product Subtitle" />
                    </div>
                  </div>
                  <div class=" mt-3">
                      <label class="form-label">Description:</label>
                      <ckeditor :editor="editor" v-model="pageCMSData.customerReviewDescription" :config="editorConfig"></ckeditor>
                    </div>
                  </div>

                  <div class="mb-5">
                    <h5>Instagram Section</h5>
                    <hr>
                    <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Title</label>
                      <input type="text" v-model="pageCMSData.instaPostTitle" class="form-control"
                        id="inputPageHeaderPageName" placeholder="Enter Customer Review Title" />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Subtitle</label>
                      <input type="text" v-model="pageCMSData.instaPostSubtitle" class="form-control"
                        id="inputPageHeaderPageName" placeholder="Enter Customer Review Subtitle" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="form-label">Background Text</label>
                      <input type="text" v-model="pageCMSData.instaPostBackgroundText" class="form-control"
                        id="inputPageHeaderPageName" placeholder="Enter Customer Review Background Text" />
                    </div>
                  </div>
                  </div>

                  <div class="mb-5">
                    <h5>Blog Section</h5>
                    <hr>         
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Title</label>
                      <input type="text" v-model="pageCMSData.homeBlogTitle" class="form-control"
                        id="inputPageHeaderPageName" placeholder="Enter Home Blog Title" />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Background Text</label>
                      <input type="text" v-model="pageCMSData.homeBlogBackgroundText" class="form-control"
                        id="inputPageHeaderPageName" placeholder="Enter Home Blog Background Text" />
                    </div>
                  </div>
                  <div class=" mt-3">
                      <label class="form-label">Description:</label>
                      <ckeditor :editor="editor" v-model="pageCMSData.homeBlogDescription" :config="editorConfig"></ckeditor>
                    </div>
                  </div>

                  <div class="mb-5">
                    <h5>Shopping Journey Section</h5>
                    <hr>  
                    <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Title</label>
                      <input type="text" v-model="pageCMSData.shoppingJourneyTitle" class="form-control"
                        placeholder="Enter Shopping Journey Title" />
                    </div>
                    <div class="col-md-6">
                      <label class="form-label">Button Text</label>
                      <input type="text" v-model="pageCMSData.shoppingJourneyButtonText" class="form-control"
                        placeholder="Enter Shopping Journey Button Text" />
                    </div>
                  </div>
                  <div class="col-md-6">
                      <label class="form-label">Button Link</label>
                      <input type="text" v-model="pageCMSData.shoppingJourneyButtonLink" class="form-control"
                        placeholder="Enter Shopping Journey Button Link" />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <label class="form-label">Button Text</label>
                      <input type="text" v-model="pageCMSData.buttonText" class="form-control" id="inputPageHeaderTitle"
                        placeholder="ex: Shop Now" />
                    </div>
                  </div>



                  <div class="d-grid">
                    <button v-on:click="updateCms()" type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/home-page-cms.js"></script>