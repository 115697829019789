import { authHeader } from "../../../../auth";

export default {
    data() {
        return {
            setURL: '',
            pageCMSData: {
                homePageTitle: "",
                productPagetitle: "",
                salePagetitle: "",
                blogPageTitle: "",
                franchisePageTitle: "",
                aboutPageTitle: "",
                locationPageTitle: "",
                careerPageTitle: "",
                contactPageTitle: "",
                accountPageTitle: "",
                helpPageTitle: "",
                privacyPageTitle: "",
                termsPageTitle: "",
                returnPageTitle: "",
                cacellationPageTitle: "",
                cartPageTitle: "",
                wishlistPageTitle: "",
                checkoutPageTitle: "",
                profilePageTitle: "",
                homePageMetaKeyword: "",
                homePageMetaDescription: "",
                productPageMetaKeyword: "",
                productPageMetaDescription: "",
                salePageMetaKeyword: "",
                salePageMetaDescription: "",
                blogPageMetaKeyword: "",
                blogPageMetaDescription: "",
                franchisePageMetaKeyword: "",
                franchisePageMetaDescription: "",
                aboutPageMetaKeyword: "",
                aboutPageMetaDescription: "",
                locationPageMetaKeyword: "",
                locationPageMetaDescription: "",
                careerPageMetaKeyword: "",
                careerPageMetaDescription: "",
                contactPageMetaKeyword: "",
                contactPageMetaDescription: "",
                accountPageMetaKeyword: "",
                accountPageMetaDescription: "",
                helpPageMetaKeyword: "",
                helpPageMetaDescription: "",
                privacyPageMetaKeyword: "",
                privacyPageMetaDescription: "",
                termsPageMetaKeyword: "",
                termsPageMetaDescription: "",
                returnPageMetaKeyword: "",
                returnPageMetaDescription: "",
                cancellationPageMetaKeyword: "",
                cancellationPageMetaDescription: "",
                cartPageMetaKeyword: "",
                cartPageMetaDescription: "",
                wishlistPageMetaKeyword: "",
                wishlistPageMetaDescription: "",
                checkoutPageMetaKeyword: "",
                checkoutPageMetaDescription: "",
                profilePageMetaKeyword: "",
                profilePageMetaDescription: ""
            }
        }
    },
    async mounted() {
        document.title = "HGS - Page Title";
        this.setURL = this.$serverURL + this.$api.cms.pageTitleCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        } else {
            this.pageCMSData = {
                homePageTitle: "",
                productPagetitle: "",
                salePagetitle: "",
                blogPageTitle: "",
                franchisePageTitle: "",
                quotationPageTitle: "",
                aboutPageTitle: "",
                locationPageTitle: "",
                careerPageTitle: "",
                contactPageTitle: "",
                accountPageTitle: "",
                helpPageTitle: "",
                privacyPageTitle: "",
                termsPageTitle: "",
                returnPageTitle: "",
                cacellationPageTitle: "",
                cartPageTitle: "",
                wishlistPageTitle: "",
                checkoutPageTitle: "",
                profilePageTitle: "",
                homePageMetaKeyword: "",
                homePageMetaDescription: "",
                productPageMetaKeyword: "",
                productPageMetaDescription: "",
                salePageMetaKeyword: "",
                salePageMetaDescription: "",
                blogPageMetaKeyword: "",
                blogPageMetaDescription: "",
                franchisePageMetaKeyword: "",
                franchisePageMetaDescription: "",
                quotationPageMetaKeyword: "",
                quotationPageMetaDescription: "",
                aboutPageMetaKeyword: "",
                aboutPageMetaDescription: "",
                locationPageMetaKeyword: "",
                locationPageMetaDescription: "",
                careerPageMetaKeyword: "",
                careerPageMetaDescription: "",
                contactPageMetaKeyword: "",
                contactPageMetaDescription: "",
                accountPageMetaKeyword: "",
                accountPageMetaDescription: "",
                helpPageMetaKeyword: "",
                helpPageMetaDescription: "",
                privacyPageMetaKeyword: "",
                privacyPageMetaDescription: "",
                termsPageMetaKeyword: "",
                termsPageMetaDescription: "",
                returnPageMetaKeyword: "",
                returnPageMetaDescription: "",
                cancellationPageMetaKeyword: "",
                cancellationPageMetaDescription: "",
                cartPageMetaKeyword: "",
                cartPageMetaDescription: "",
                wishlistPageMetaKeyword: "",
                wishlistPageMetaDescription: "",
                checkoutPageMetaKeyword: "",
                checkoutPageMetaDescription: "",
                profilePageMetaKeyword: "",
                profilePageMetaDescription: ""
            };
        }
    },
    methods: {
        async update() {
            try {
                let data = {
                    homePageTitle: this.pageCMSData.homePageTitle,
                    productPagetitle: this.pageCMSData.productPagetitle,
                    salePagetitle: this.pageCMSData.salePagetitle,
                    blogPageTitle: this.pageCMSData.blogPageTitle,
                    franchisePageTitle: this.pageCMSData.franchisePageTitle,
                    quotationPageTitle: this.pageCMSData.quotationPageTitle,
                    aboutPageTitle: this.pageCMSData.aboutPageTitle,
                    locationPageTitle: this.pageCMSData.locationPageTitle,
                    careerPageTitle: this.pageCMSData.careerPageTitle,
                    contactPageTitle: this.pageCMSData.contactPageTitle,
                    accountPageTitle: this.pageCMSData.accountPageTitle,
                    helpPageTitle: this.pageCMSData.helpPageTitle,
                    privacyPageTitle: this.pageCMSData.privacyPageTitle,
                    termsPageTitle: this.pageCMSData.termsPageTitle,
                    returnPageTitle: this.pageCMSData.returnPageTitle,
                    cacellationPageTitle: this.pageCMSData.cacellationPageTitle,
                    cartPageTitle: this.pageCMSData.cartPageTitle,
                    wishlistPageTitle: this.pageCMSData.wishlistPageTitle,
                    checkoutPageTitle: this.pageCMSData.checkoutPageTitle,
                    profilePageTitle: this.pageCMSData.profilePageTitle,
                    homePageMetaKeyword: this.pageCMSData.homePageMetaKeyword,
                    homePageMetaDescription: this.pageCMSData.homePageMetaDescription,
                    productPageMetaKeyword: this.pageCMSData.productPageMetaKeyword,
                    productPageMetaDescription: this.pageCMSData.productPageMetaDescription,
                    salePageMetaKeyword: this.pageCMSData.salePageMetaKeyword,
                    salePageMetaDescription: this.pageCMSData.salePageMetaDescription,
                    blogPageMetaKeyword: this.pageCMSData.blogPageMetaKeyword,
                    blogPageMetaDescription: this.pageCMSData.blogPageMetaDescription,
                    franchisePageMetaKeyword: this.pageCMSData.franchisePageMetaKeyword,
                    franchisePageMetaDescription: this.pageCMSData.franchisePageMetaDescription,
                    quotationPageMetaKeyword: this.pageCMSData.quotationPageMetaKeyword,
                    quotationPageMetaDescription: this.pageCMSData.quotationPageMetaDescription,
                    aboutPageMetaKeyword: this.pageCMSData.aboutPageMetaKeyword,
                    aboutPageMetaDescription: this.pageCMSData.aboutPageMetaDescription,
                    locationPageMetaKeyword: this.pageCMSData.locationPageMetaKeyword,
                    locationPageMetaDescription: this.pageCMSData.locationPageMetaDescription,
                    careerPageMetaKeyword: this.pageCMSData.careerPageMetaKeyword,
                    careerPageMetaDescription: this.pageCMSData.careerPageMetaDescription,
                    contactPageMetaKeyword: this.pageCMSData.contactPageMetaKeyword,
                    contactPageMetaDescription: this.pageCMSData.contactPageMetaDescription,
                    accountPageMetaKeyword: this.pageCMSData.accountPageMetaKeyword,
                    accountPageMetaDescription: this.pageCMSData.accountPageMetaDescription,
                    helpPageMetaKeyword: this.pageCMSData.helpPageMetaKeyword,
                    helpPageMetaDescription: this.pageCMSData.helpPageMetaDescription,
                    privacyPageMetaKeyword: this.pageCMSData.privacyPageMetaKeyword,
                    privacyPageMetaDescription: this.pageCMSData.privacyPageMetaDescription,
                    termsPageMetaKeyword: this.pageCMSData.termsPageMetaKeyword,
                    termsPageMetaDescription: this.pageCMSData.termsPageMetaDescription,
                    returnPageMetaKeyword: this.pageCMSData.returnPageMetaKeyword,
                    returnPageMetaDescription: this.pageCMSData.returnPageMetaDescription,
                    cancellationPageMetaKeyword: this.pageCMSData.cancellationPageMetaKeyword,
                    cancellationPageMetaDescription: this.pageCMSData.cancellationPageMetaDescription,
                    cartPageMetaKeyword: this.pageCMSData.cartPageMetaKeyword,
                    cartPageMetaDescription: this.pageCMSData.cartPageMetaDescription,
                    wishlistPageMetaKeyword: this.pageCMSData.wishlistPageMetaKeyword,
                    wishlistPageMetaDescription: this.pageCMSData.wishlistPageMetaDescription,
                    checkoutPageMetaKeyword: this.pageCMSData.checkoutPageMetaKeyword,
                    checkoutPageMetaDescription: this.pageCMSData.checkoutPageMetaDescription,
                    profilePageMetaKeyword: this.pageCMSData.profilePageMetaKeyword,
                    profilePageMetaDescription: this.pageCMSData.profilePageMetaDescription
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.cms.pageTitleCMS,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            }
        }
    }
}