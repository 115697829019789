import { authHeader } from "../../../auth";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
    name: "Popular Search",
    data() {
        return {
            editor: ClassicEditor,
            setURL: this.$serverURL + this.$api.search.popularSearchAPI,
            singleSearchAPI: this.$serverURL + this.$api.search.singlePopularSearchAPI,
            searchTitle: "",
            searchLink: "",
            singlePopularSearch: { id: "", searchTitle: "", searchLink: "" },
        };
    },

    async created() {
        document.title = "HGS - Popular Search";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js",
        ];
        scripts.forEach((script) => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
        document.head.appendChild(style);
    },

    methods: {
        addPopularSearch: async function () {
            if (!this.searchTitle || !this.searchLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter popular search title and link.",
                });
            } else {
                const body = { searchTitle: this.searchTitle, searchLink: this.searchLink };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: body,
                    headers: {
                        Authorization: authHeader(),
                    },
                };
                await this.postDataToBackend(config);
                this.searchTitle = "";
                this.searchLink = "";
                await this.getDataList(this.setURL);
            }
        },

        editPopularSearch: async function (data) {
            this.singlePopularSearch = data;
        },

        updatePopularSearch: async function () {
            if (!this.singlePopularSearch.searchTitle || !this.singlePopularSearch.searchLink) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter popular search title and link.",
                });
            } else {
                const body = { ...this.singlePopularSearch };
                let config = {
                    method: "PATCH",
                    url: this.singleSearchAPI + this.singlePopularSearch.id,
                    data: body,
                    headers: {
                        Authorization: authHeader(),
                    },
                };
                await this.updateDataToBackend(config);
                this.searchTitle = "";
                this.searchLink = "";
                await this.getDataList(this.setURL);
            }
        },

        deletePopularSearch: function (id) {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        if (result.isConfirmed) {
                            try {
                                let config = {
                                    method: "DELETE",
                                    url: this.singleSearchAPI + id,
                                    headers: {
                                        Authorization: authHeader(),
                                    },
                                };
                                await this.deleteDataToBackend(config);
                                await this.getDataList(this.setURL);
                            } catch (error) {
                                this.$swal.fire({
                                    icon: "error",
                                    text: "Something went wrong, please try agian later." + error,
                                });
                            }
                        }
                    }
                });
        },
    },
};
