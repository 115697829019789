<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Career</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Career Component CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Career Component CMS</h5>
                    <hr />
                    <div class="form-body mt-4" v-if="dataLoaded">
                        <div class="row mb-3">
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Header Title Font Color:" v-model="pageCMSData.headerTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Form Title Font Color:" v-model="pageCMSData.formTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Form Sub Title Font Color:" v-model="pageCMSData.formSubTitleFontColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="input-group">
                                    <LvColorpicker label="Page Background Color:" v-model="pageCMSData.pageBackgroundColor" :clearable="true" :bottomBar="true" :colors="colors"/>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-3">
                            <div class="d-grid gap-2 d-md-flex justify-content-md-start"> <button class="btn btn-primary" @click="updateCms" type="button">Update</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/career.js"></script>