import { authHeader } from "../../../../auth";
export default {
    name: "Size",
    data() {
        return {
            sizeList: [],
            singleSize: {},
            sizeName: "",
            setURL: this.$serverURL + this.$api.product.sizeURL,
        }
    },
    async created() {
        document.title = "HGS- Size";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addProductSize: async function () {
            if (!this.sizeName) {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product size name!"
                });
            }
            else {
                let data = {
                    sizeName: this.sizeName
                };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.postDataToBackend(config);
                this.sizeName = '';
                await this.getDataList(this.setURL);
            }
        },
        editProductSize: function (data) {
            this.singleSize = data;
        },
        updateProductSize: async function () {
            if (!this.singleSize.sizeName) {
               
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product size name!"
                });
            }
            else {
                let data = {
                    sizeName: this.singleSize.sizeName
                };
                let config = {
                    method: "PATCH",
                    url: this.setURL + "/" + this.singleSize.id,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                this.singleSize = {};
                await this.getDataList(this.setURL);
            }
        },
        deleteProductSize: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + "/" + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })

        }
    }
}