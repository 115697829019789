<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Product List
            </li>
          </ol>
        </nav>

        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-warning btn-sm m-1"
              @click="$router.push('/bulk-price-update')"
            >
              <i class="bx bxs-plus-square"></i>Bulk Price Update
            </button>
            <button
              type="button"
              class="btn btn-success btn-sm m-1"
              @click="$router.push('/product-bulk-upload')"
            >
              <i class="bx bxs-plus-square"></i>Import Product
            </button>
            <button
              type="button"
              class="btn btn-warning btn-sm m-1"
              @click="$router.push('/stock-in-product')"
            >
              <i class="bx bxs-plus-square"></i>Stock In Product
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm m-1"
              @click="$router.push('/add-product')"
            >
              <i class="bx bxs-plus-square"></i>Add New Product
            </button>

            <button
              type="button"
              class="btn btn-secondary btn-sm m-1"
              @click="getExcelProducts"
            >
              <i class="bx bxs-plus-square"></i>Export
            </button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Product List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div class="d-flex col-6 mt-3 mb-3">
              <input
                type="text"
                class="form-control"
                id="inputSearchValue"
                v-model="searchValue"
                placeholder="Enter search value"
              />
              <button class="btn btn-primary ms-2" @click="searchProduct">Search</button>
              <button class="btn btn-warning ms-2" v-if="searchValue.length > 0" @click="searchProductClear">Clear</button>
            </div>
            <div class="col-4 col-md-2 mt-3 mb-3">
              <select id="page-size-dropdown" class="form-select" v-model="limit" @change="getProductsWithLimit">
                <option disabled selected value="">Select page size</option>
                <option v-for="(data, index) in pageSizeDropDown" :key="index" :value="data">{{ data }} units per page</option>
              </select>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Url</th>
                  <th>Date Time</th>
                  <th>No of Review</th>
                  <th>Avg Rating</th>
                  <th>Brand</th>
                  <th>Featured Image</th>
                  <th>Cost</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in productList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.name }}</td>
                  <td>{{ data.urlSlug }}</td>
                  <td>{{ $filters.formatProductDate(data.createdAt) }}</td>
                  <td>{{ data.review_count }}</td>
                  <td>{{ data.review_avg }}</td>
                  <td>{{ data.brandName }}</td>
                  <td>
                    <img
                      v-if="data.featuredImage"
                      :src="$imageURL + 'productImage/' + data.featuredImage"
                      alt=""
                      height="90"
                      width="110"
                    />
                    <img
                      v-else
                      src="/core/assets/images/400.png"
                      alt=""
                      height="90"
                      width="110"
                    />
                  </td>
                  <td v-if="data.count == false" v-on:dblclick="costShowHide(data)">{{ data.cost ? data.cost : 0 }}</td>
                  <td style="width: 12%;" v-else>
                    <div class="input-group">
                        <input type="text" v-model="data.cost" class="form-control">
                        <div class="input-group-append">
                            <button @click="updateCost(data, index)" class="btn btn-primary"><i class="fa-regular fa-circle-check"></i></button>
                        </div>
                    </div>
                  </td>
                  <td v-if="data.flag == false" v-on:dblclick="priceShowHide(data)">{{ data.price }}</td>
                  <td style="width: 12%;" v-else>
                    <div class="input-group">
                        <input type="text" v-model="data.price" class="form-control">
                        <div class="input-group-append">
                            <button @click="updatePrice(data, index)" class="btn btn-primary"><i class="fa-regular fa-circle-check"></i></button>
                        </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex order-actions">
                      <!-- <a
                        
                        @click="$router.push('/update-product/'+data.id)"
                        class="edit-button"
                        ><i class="bx bxs-edit text-white"></i
                      ></a> -->
                      <a
                        :href="$hostURL + 'update-product/' + data.id"
                        class="edit-button"
                        ><i class="bx bxs-edit text-white"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        @click="deleteProduct(data.id, data.name)"
                        class="ms-3 delete-button"
                        ><i class="bx bxs-trash text-white"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Url</th>
                  <th>Date Time</th>
                  <th>No of Review</th>
                  <th>Avg Rating</th>
                  <th>Brand</th>
                  <th>Featured Image</th>
                  <th>Cost</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
            <div class="m-2">
              <nav class="page-navigation justify-content-center d-flex" aria-label="page-navigation">
                <v-pagination v-if="productList" v-model="page" @update:modelValue="getProductsWithLimit" :pages="totalPage"
                  :range-size="1" active-color="#FF8400"></v-pagination>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/product.js'></script>


<style scoped>
@import url("../../../../assets/pagination.css");
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}

</style>