import { authHeader } from "../../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            setURL: '',
            pageCMSData: [],
            brandBackgroundImage: "",
            indoorStoreImage: "",
            previewImage: "",
            previewImage2: "",
        }
    },
    async mounted() {
        this.setURL = this.$serverURL + this.$api.cms.homePageCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
        console.log(this.pageCMSData.featuredProductTitle)
    },
    methods: {
        uploadFeaturedImage: function (event) {
            this.brandBackgroundImage = event.target.files[0];
            let input = this.$refs.backgroundImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadIndoorStoreImage: function (event) {
            console.log(event);
            this.indoorStoreImage = event.target.files[0];
            let input = this.$refs.indoorStoreImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage2 = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async updateCms() {
            try {

                let formData = new FormData();
                formData.append('sectionTwoTitle', this.pageCMSData.sectionTwoTitle);
                formData.append('sectionTwoDescription', this.pageCMSData.sectionTwoDescription);
                
                formData.append('startYourAccountTitle', this.pageCMSData.startYourAccountTitle);
                formData.append('startYourAccountBackgroundText', this.pageCMSData.startYourAccountBackgroundText);

                formData.append('brandTitle', this.pageCMSData.brandTitle);
                formData.append('brandSubtitle', this.pageCMSData.brandSubtitle);
                formData.append('brandButtonText', this.pageCMSData.brandButtonText);
                formData.append('brandButtonLink', this.pageCMSData.brandButtonLink);
                formData.append('brandBackgroundImage', this.brandBackgroundImage ? this.brandBackgroundImage : this.pageCMSData.brandBackgroundImage);
                // formData.append('brandBackgroundText', this.pageCMSData.brandBackgroundText);

                formData.append('indoorStoreTitle', this.pageCMSData.indoorStoreTitle);
                formData.append('indoorStoreDescription', this.pageCMSData.indoorStoreDescription);
                formData.append('indoorStoreButtonText', this.pageCMSData.indoorStoreButtonText);
                formData.append('indoorStoreButtonLink', this.pageCMSData.indoorStoreButtonLink);
                formData.append('indoorStoreImage', this.indoorStoreImage ? this.indoorStoreImage : this.pageCMSData.indoorStoreImage);
                formData.append('indoorStoreImageAlt', this.pageCMSData.indoorStoreImageAlt);
                formData.append('indoorStoreImageTitle', this.pageCMSData.indoorStoreImageTitle);

                formData.append('shoppingJourneyTitle', this.pageCMSData.shoppingJourneyTitle);
                formData.append('shoppingJourneyButtonText', this.pageCMSData.shoppingJourneyButtonText);
                formData.append('shoppingJourneyButtonLink', this.pageCMSData.shoppingJourneyButtonLink);

                formData.append('topSellingBackgroundText', this.pageCMSData.topSellingBackgroundText);
                formData.append('topSellingTitle', this.pageCMSData.topSellingTitle);
                formData.append('topsellingSubtitle', this.pageCMSData.topsellingSubtitle);
                formData.append('topsellingDescription', this.pageCMSData.topsellingDescription);

                formData.append('featuredProductBackgroundText', this.pageCMSData.featuredProductBackgroundText);
                formData.append('featuredProductTitle', this.pageCMSData.featuredProductTitle);
                formData.append('featuredProductDescription', this.pageCMSData.featuredProductDescription);

                formData.append('customerReviewBackgroundText', this.pageCMSData.customerReviewBackgroundText);
                formData.append('customerReviewTitle', this.pageCMSData.customerReviewTitle);
                formData.append('customerReviewDescription', this.pageCMSData.customerReviewDescription);

                formData.append('instaPostBackgroundText', this.pageCMSData.instaPostBackgroundText);
                formData.append('instaPostTitle', this.pageCMSData.instaPostTitle);
                formData.append('instaPostSubtitle', this.pageCMSData.instaPostSubtitle);

                formData.append('homeBlogBackgroundText', this.pageCMSData.homeBlogBackgroundText);
                formData.append('homeBlogTitle', this.pageCMSData.homeBlogTitle);
                formData.append('homeBlogDescription', this.pageCMSData.homeBlogDescription);

                formData.append('buttonText', this.pageCMSData.buttonText);
                

                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.cms.homePageCMS,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.createUpdateCMS(config);
                this.brandBackgroundImage = "";
                this.$refs.backgroundImage.value = "";
                this.previewImage = "";
                await this.getCmsData(this.setURL);
                if (this.pageCMS) {
                    this.pageCMSData = this.pageCMS;
                }
                else {
                    this.pageCMSData = [];
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}