import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';
export default {
    data() {
        return {
            orderList: [],
            filter: {
                paymentStatus: "",
                deliveryStatus: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            },
            userList: [],
            salesTotal: 0,
            salesTotalQuantity: 0,
            salesTaxTotal: 0,
            filteredOrderIdList: [],
            setURL: this.$serverURL + this.$api.user.customerUserList,
            sheets: [],
        }
    },
    async mounted() {
        document.title = "HGS - Sales Tax Report";
        await this.getOrderList();
        await this.getCustomerList();
        // this.countryList = this.countries.map(el => {
        //     return { value: { name: el.countryNameEn, code: el.countryCode }, label: el.countryNameEn }
        // });
        this.loadJs();
    },
    methods: {
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });

            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
        async getOrderList() {
            this.orderList = [];
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.order.orderURL + "?paymentStatus=" + this.filter.paymentStatus + "&deliveryStatus=" + this.filter.deliveryStatus + "&startDate=" + this.filter.startDate + "&endDate=" + this.filter.endDate + "&customerName=" + this.filter.userName + "&orderId=" + this.filter.orderId,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {

                        this.orderList = await response.data.data.data;

                        let ts = 0;
                        let tsq = 0;
                        let tsa = 0;
                        for (let index = 0; index < this.orderList.length; index++) {
                            const total = this.orderList[index]['total'];
                            const totalQuantity = this.orderList[index]['totalQuantity'];
                            let taxAmount = parseFloat(this.orderList[index].salesTaxAmount);
                            console.log(taxAmount);
                            ts += (total - 0);
                            tsq += (totalQuantity - 0);
                            tsa += (taxAmount - 0);
                        }

                        this.salesTotal = ts.toFixed(2);
                        this.salesTotalQuantity = tsq;
                        this.salesTaxTotal = tsa.toFixed(2);

                        if (this.filteredOrderIdList.length == 0) {
                            this.filteredOrderIdList = this.orderList.map(el => {
                                return { value: el.orderId, label: el.orderId }
                            });
                        }
                        this.totalOrders = response.data.data.totalOrders
                        this.totalPendingOrders = response.data.data.totalPendingOrders
                        this.totalDeliveredOrders = response.data.data.totalDeliveredOrders
                        this.totalCancelledOrders = response.data.data.totalCancelledOrders
                    }

                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again!" + error
                    });
                });
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },
        async getCustomerList() {
            let config = {
                method: "GET",
                url: this.setURL,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    // this.userList = await response.data.data;
                    this.userList = await response.data.data.map(el => {
                        return { value: el.firstName + el.lastName, label: el.firstName + el.lastName }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },

        async validateFilter() {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select end date"
                        });
                        return;
                    }
                }
                if (this.filter.endDate) {
                    if (!this.filter.startDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select start date"
                        });
                        return;
                    }
                }
                window.$('#example').DataTable().destroy();
                await this.getOrderList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },

        async clearFilter() {
            this.filter = {
                paymentStatus: "",
                deliveryStatus: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            };
            window.$('#example').DataTable().destroy();
            await this.getOrderList();
            window.$('#example').DataTable();
        },

        exportOrders: function () {
            this.orderList.map((el) => {
                let shippingInfo = JSON.parse(el.shippingAddress);
                this.sheets.push({
                    'Id': el.id,
                    'OrderId': el.orderId,
                    "Customer Name": el.customerName,
                    "Mobile": shippingInfo.mobile,
                    "Email": shippingInfo.email,
                    "Address": shippingInfo.address1,
                    "City": shippingInfo.city,
                    "Post Code": shippingInfo.postCode,
                    "Country": shippingInfo.country,
                    'Total Price': el.total,
                    'Total Quantity': el.totalQuantity,
                    'Order Date': this.$filters.formatDate(el.createdAt),
                    'Payment Method': el.paymentMethodName,
                    'Payment Status': el.paymentStatus,
                    'Shipping Status': el.status,
                    'Tracking Number': el.trackingNumber,
                });
            });


            this.sheets.push({
                'Id': "",
                'OrderId': "",
                "Customer Name": "",
                "Mobile": "",
                "Email": "",
                "Address": "",
                "City": "",
                "Post Code": "",
                "Country": "Total",
                'Total Price': this.salesTotal,
                'Total Quantity': this.salesTotalQuantity,
                'Order Date': "",
                'Payment Method': "",
                'Payment Status': "",
                'Shipping Status': "",
            });

            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'sales_report(' + date + ').xlsx');
            this.sheets = [];
        },
        // async deleteOrder(id) {
        //     this.$swal.fire({
        //         title: 'Are you sure?',
        //         text: "You won't be able to revert this!",
        //         icon: 'warning',
        //         showCancelButton: true,
        //         confirmButtonColor: '#3085d6',
        //         cancelButtonColor: '#d33',
        //         confirmButtonText: 'Yes, delete it!'
        //     }).then(async (result) => {
        //         if (result.isConfirmed) {
        //             try {
        //                 let config = {
        //                     method: 'DELETE',
        //                     url: this.$serverURL + this.$api.order.orderURL + '/' + id,
        //                     headers: {
        //                         "Authorization": authHeader(),
        //                     }
        //                 }
        //                 await this.$axios(config).then((response) => {
        //                     if (response.data.deleted == true) {
        //                         this.getOrderList();
        //                         this.$swal.fire({
        //                             icon: "warning",
        //                             text: "Order deleted Successfully."
        //                         });
        //                     }

        //                 }).catch(error => {

        //                     this.$swal.fire({
        //                         icon: "error",
        //                         text: "Something went wrong. Please try again!" + error
        //                     });
        //                 });
        //             } catch (error) {
        //                 this.$swal.fire({
        //                     icon: "error",
        //                     text: "Something went wrong, please try agian later." + error
        //                 });
        //             }
        //         }
        //     })
        // }
    }

}