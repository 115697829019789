import { authHeader }  from "../../../../auth"

export default {
    data(){
        return{
            blogList: [],
            siteUrl: this.$serverURL + this.$api.blogMeta.blogMetaURL,
            blogId: '',
            blogTitle: '',
            keywords: "",
            description: "",
            metaTitle: ""
        }
    },
    async created() {
        document.title = "HGS - Blog Meta";
        await this.getBlogList(this.siteUrl);

        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        getBlogList: async function (url) {
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.blogList = await response.data.data;
                }
            }).catch(error => {
                this.$swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
        },
        updateMetaKeyword: async function() {
            try {
                if (!this.keywords) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter keywords!"
                    });
                    return false;
                }
                let data = {
                    blogId: this.blogId,
                    blogTitle: this.blogTitle,
                    metaKeyword: this.keywords,
                    metaDescription: this.description,
                    metaTitle: this.metaTitle
                };
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.blogMeta.updateMetaDataURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                await this.getBlogList(this.siteUrl);
                this.blogId = "";
                this.blogTitle = "";
                this.metaKeyword = "";
                this.metaDescription = "";
                this.metaTitle = "";
            }
            catch(error) {
                console.log(error);
            }
        },
        updateMetaDescription: async function() {
            try {
                if (!this.description) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter description!"
                    });
                    return false;
                }
                let data = {
                    blogId: this.blogId,
                    metaKeyword: this.keywords,
                    metaDescription: this.description,
                    blogTitle: this.blogTitle,
                    metaTitle: this.metaTitle
                };
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.blogMeta.updateMetaDataURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                await this.getBlogList(this.siteUrl);
                this.blogId = "";
                this.blogTitle = "";
                this.keywords = "";
                this.description = "";
                this.metaTitle = "";
            }
            catch(error) {
                console.log(error);
            }
        },
        updateMetaTitle: async function() {
            try {
                if (!this.metaTitle) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Please enter meta title!"
                    });
                    return false;
                }
                let data = {
                    blogId: this.blogId,
                    metaKeyword: this.keywords,
                    metaDescription: this.description,
                    blogTitle: this.blogTitle,
                    metaTitle: this.metaTitle
                };
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.blogMeta.updateMetaDataURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                await this.getBlogList(this.siteUrl);
                this.blogId = "";
                this.blogTitle = "";
                this.keywords = "";
                this.description = "";
                this.metaTitle = "";
            }
            catch(error) {
                console.log(error);
            }
        },
        async updateModalValue(data) {
            this.blogId = data.id;
            this.blogTitle = data.title;
            this.keywords = data.metaKeyword;
            this.description = data.metaDescription;
            this.metaTitle = data.metaTitle;
        },
        
    }
}