import { authHeader } from "../../../auth";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
    name: "Why Choose Us",
    data() {
        return {
            editor: ClassicEditor,
            setURL: this.$serverURL + this.$api.whyChooseUs.whyChooseUsAPI,
            singleWhyChooseUsAPI: this.$serverURL + this.$api.whyChooseUs.singleWhyChooseUsAPI,
            title: "",
            content: "",
            singleWhyChoose: { id: "", title: "", content: "" },
        };
    },

    async created() {
        document.title = "HGS - Why Choose Us";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js",
        ];
        scripts.forEach((script) => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = "/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
        document.head.appendChild(style);
    },

    methods: {
        shortenString(str) {
            if (str.length > 10) {
                return str.substr(0, 30) + '...';
              } else {
                return str;
              }
        },
        addWhyChooseUs: async function () {
            if (!this.title || !this.content) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter popular search title and link.",
                });
            } else {
                const body = { title: this.title, content: this.content };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: body,
                    headers: {
                        Authorization: authHeader(),
                    },
                };
                await this.postDataToBackend(config);
                this.title = "";
                this.content = "";
                await this.getDataList(this.setURL);
            }
        },

        editWhyChooseUs: async function (data) {
            this.singleWhyChoose = data;
        },

        updateWhyChooseUs: async function () {
            if (!this.singleWhyChoose.title || !this.singleWhyChoose.content) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter popular search title and link.",
                });
            } else {
                const body = { ...this.singleWhyChoose };
                let config = {
                    method: "PATCH",
                    url: this.singleWhyChooseUsAPI + this.singleWhyChoose.id,
                    data: body,
                    headers: {
                        Authorization: authHeader(),
                    },
                };
                await this.updateDataToBackend(config);
                this.singleWhyChoose.title = "";
                this.singleWhyChoose.content = "";
                await this.getDataList(this.setURL);
            }
        },

        deleteWhyChooseUs: function (id) {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        if (result.isConfirmed) {
                            try {
                                let config = {
                                    method: "DELETE",
                                    url: this.singleWhyChooseUsAPI + id,
                                    headers: {
                                        Authorization: authHeader(),
                                    },
                                };
                                await this.deleteDataToBackend(config);
                                await this.getDataList(this.setURL);
                            } catch (error) {
                                this.$swal.fire({
                                    icon: "error",
                                    text: "Something went wrong, please try agian later." + error,
                                });
                            }
                        }
                    }
                });
        },
    },
};
