import Treeselect from 'vue3-treeselect';
import 'vue3-treeselect/dist/vue3-treeselect.css'
import { authHeader } from "../../../auth";
import Mixin from '../../../mixin';
// import $ from 'jquery';
export default {
    components: { Treeselect },
    mixins: [Mixin],
    data() {
        return {
            productURL: this.$serverURL + this.$api.product.productAPI,
            productList: [],
            userList: [],
            productValue: [],
            selectedProduct: [],
            userValue: [],
            addedDiscount: [{ id: "" }],
            types: ['Amount', 'Percentage'],
            discountType: [],
            discountAmount: [],
            confirmedDiscount: [],
            existedDiscounts: [],
        }

    },
    async created() {
        await this.getDataList(this.productURL);
        this.productList = this.dataList.map(el => {
            return { value: el, label: el.name }
        });
        await this.getCustomerList();
        await this.getProductsDiscount();
    },
    methods: {
        getProductsDiscount: async function () {
            this.isLoading = true;
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.product.productDiscountURL,
                headers: {
                    "Authorization": authHeader(),
                }
            };
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    this.existedDiscounts = response.data.data;
                }
                this.isLoading = false;
            }).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        async getCustomerList() {
            let config = {
                method: "GET",
                url: this.$serverURL + this.$api.user.customerUserList,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.userList = await response.data.data.map(el => {
                        return { value: el, label: `${el.firstName} ${el.lastName}` }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        pushData: function () {
            this.addedDiscount.push({ id: "" })
        },
        removeItem: function (index) {
            this.selectedProduct.splice(index, 1);
            this.addedDiscount.splice(index, 1);
            this.userValue.splice(index, 1);
            this.productValue.splice(index, 1);
            this.discountType.splice(index, 1);
            this.discountAmount.splice(index, 1);
        },
        productData(data, index) {

            if (this.selectedProduct[index] != undefined) {
                this.selectedProduct[index] = data;
            } else {
                this.selectedProduct.push(data);
            }
        },
        userData(data, index) {
            // if (this.selectedProduct[index] && Object.values(this.selectedProduct[index].length > 0)) {
            //     let exist = false;
            //     for (let i = 0; i < this.existedDiscounts.length; i++) {
            //         if (this.selectedProduct.length > 0) {
            //             if (data.id == this.existedDiscounts[i].userId) {
            //                 if (this.selectedProduct[index].id == this.existedDiscounts[i].productId) {
            //                     exist = true;
            //                 }
            //             }
            //         }
            //     }
            //     if (exist) {
            //         this.$swal.fire({
            //             icon: "info",
            //             text: "Discount for this product is already applied for this user"
            //         })
            //     }
            this.selectedProduct[index].userId = data.id;
            this.selectedProduct[index].userName = `${data.firstName} ${data.lastName}`;
            // } else {
            //     this.$swal.fire({
            //         icon: "info",
            //         text: "Please select product first"
            //     })
            // }
        },
        selectType(data, index) {
            this.selectedProduct[index].discountType = data;
        },
        selectAmount(data, index) {
            if (data) {
                let discountedPrice = 0;
                if (this.discountType[index] == "Amount") {
                    discountedPrice = parseFloat(data);
                    console.log(discountedPrice);
                } else if (this.discountType[index] == "Percentage") {
                    discountedPrice = (this.selectedProduct[index].price * (parseFloat(data) / 100));
                    console.log(discountedPrice);
                }
                if (discountedPrice > this.selectedProduct[index].price) {
                    this.$swal.fire({
                        icon: "info",
                        text: "Discounted price can not be greater than actual price."
                    })
                }
            }
            this.selectedProduct[index].discountAmount = data;
        },
        testDiscount() {
            this.confirmedProduct = this.selectedProduct.map((el) => {
                return {
                    productId: el.id,
                    productName: el.name,
                    userId: el.userId,
                    name: el.userName,
                    discountType: el.discountType,
                    discountAmount: parseFloat(el.discountAmount)
                };
            })
            console.log(this.confirmedProduct);
        },
        checkDiscount: function () {
            let counter = 0;
            this.selectedProduct.map((el) => {
                let discountedPrice = 0
                if (el.discountType == "Amount") {
                    discountedPrice = parseFloat(el.discountAmount);
                    console.log(discountedPrice);
                } else if (el.discountType == "Percentage") {
                    discountedPrice = (el.price * (parseFloat(el.discountAmount) / 100));
                    console.log(discountedPrice);
                }
                if (discountedPrice > el.price) {
                    counter = counter + 1;
                    this.$swal.fire({
                        icon: "info",
                        text: "Discounted price can not be greater than actual price."
                    })
                }
            })
            if (counter > 0) {
                return false;
            } else {
                return true;
            }
        },
        addDiscount: async function () {
            this.isLoading = true;
            if (this.selectedProduct.length == 0) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select a product!"
                });
            } else if (this.selectedProduct.length != this.userValue.length) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select user!"
                });
            } else if (this.selectedProduct.length != this.discountType.length) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please select discount type!"
                });
            } else if (this.selectedProduct.length != this.discountAmount.length) {
                this.$swal.fire({
                    icon: "info",
                    text: "Please Input discount amount!"
                });
            } else if (!this.checkDiscount()) {
                this.$swal.fire({
                    icon: "info",
                    text: "Discounted price can not be greater than actual price."
                });
            }
            else {
                this.confirmedProduct = this.selectedProduct.map((el) => {
                    let discountedPrice = 0;
                    if (el.discountType == "Amount") {
                        discountedPrice = el.price - parseFloat(el.discountAmount);
                    } else if (el.discountType == "Percentage") {
                        discountedPrice = el.price - (el.price * (parseFloat(el.discountAmount) / 100));
                    }
                    return {
                        productId: el.id,
                        productName: el.name,
                        userId: el.userId,
                        name: el.userName,
                        productPrice: el.price,
                        discountType: el.discountType,
                        discountAmount: parseFloat(el.discountAmount),
                        userDiscountedPrice: discountedPrice
                    };
                })
                let data = {
                    userDiscount: this.confirmedProduct
                }
                let config = {
                    method: "POST",
                    url: this.$serverURL + this.$api.product.productDiscountURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                this.$swal.showLoading();
                await this.$axios(config).then((response) => {
                    this.$swal.hideLoading();
                    if (response.data.statusCode == 200 || response.data.statusCode == 201) {
                        this.$swal.fire({
                            icon: "success",
                            text: response.data.message
                        });
                        this.$router.push('/user-wise-product-discount');
                    }
                    else {
                        this.isLoading = false;
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    this.$swal.hideLoading();
                    console.log(error);
                    this.isLoading = false;
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            }
        },
    },
    watch: {
        fileArrayLength: function () {
            if (this.fileArrayLength > 1) {
                this.showRemoveButton = true;
            }
            if (this.fileArrayLength < 2) {
                this.showRemoveButton = false;
            }
            if (this.fileArrayLength >= 1 && this.showAddButton <= 3) {
                this.showAddButton = true;
            }
            if (this.fileArrayLength > 3) {
                this.showAddButton = false;
            }

        }
    }
}
