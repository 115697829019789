<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Stock
                        </li>
                    </ol>
                </nav>
                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-secondary btn-sm m-1" @click="getExcelStockIn">
                            <i class="bx bxs-plus-square"></i>Export</button>
                    </div>
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Stock List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Name</th>
                                    <th>Cost</th>
                                    <th>Previous Stock</th>
                                    <th>New Stock</th>
                                    <th>Total Stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in productList" :key="index">
                                    <td>{{index + 1}}</td>
                                    <td>{{data.Name}}</td>
                                    <td>{{data.Cost}}</td>
                                    <td>{{data.CurrentStock}}</td>
                                    <td>{{data.NewStock}}</td>
                                    <td>{{data.NewTotalStock}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Name</th>
                                    <th>Cost</th>
                                    <th>Previous Stock</th>
                                    <th>New Stock</th>
                                    <th>Total Stock</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src='../js/stock-detail.js'></script>


<style scoped>

    .edit-button {
        background-color: #17a00e;
    }

    .delete-button {
        background-color: #f41127;
    }
</style>