import { authHeader } from "../../../auth";
import {pages} from '../../../../config/page';
export default {
    name: "Brand",
    data() {
        return {
            setURL: this.$serverURL + this.$api.popUp.popUpSettingsCMS,
            adPageList: pages,
            newsPageList: pages,
            pageList: pages,
            advertise: [],
            newsletter: [],
            popUp: []
        }
    },
    async created() {
        document.title = "HGS - Pop Up";
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            const ad = JSON.parse(this.pageCMS.advertise);
            const news = JSON.parse(this.pageCMS.newsletter);
            this.advertise = ad.map(el =>{
                return el
            });
            this.newsletter = news.map(el =>{
                return el
            })
            if(this.advertise) {
                this.adPageList = this.pageList;
                this.adPageList = this.adPageList.filter(x => !this.newsletter.includes(x));
            }
            if(this.newsletter) {
                this.newsPageList = this.pageList;
                this.newsPageList = this.newsPageList.filter(x => !this.advertise.includes(x));
            }
        }
        else {
            this.popUp = {
                advertise: [],
                newsletter: []
            };
        }
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        changeNewsletter: function() {
            this.adPageList = this.pageList;
            this.adPageList = this.adPageList.filter(x => !this.newsletter.includes(x));
        },
        removeNewsletter: function() {
            this.adPageList = this.pageList;
            this.adPageList = this.adPageList.filter(x => !this.newsletter.includes(x));
        },
        changeAd: function() {
            this.newsPageList = this.pageList;
            this.newsPageList = this.newsPageList.filter(x => !this.advertise.includes(x));
        },
        removeAd: function() {
            this.newsPageList = this.pageList;
            this.newsPageList = this.newsPageList.filter(x => !this.advertise.includes(x));
        },
        async updatePopUp() {
            try {
                let data = {
                    advertise: JSON.stringify(this.advertise),
                    newsletter: JSON.stringify(this.newsletter),
                };
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}