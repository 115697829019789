import { authHeader } from "../../../../auth";
import { countries } from "../../../../../config/country";
export default {
  data() {
    return {
      setURL: this.$serverURL + this.$api.shippingConfig.easyPostConfigURL,
      countryList: countries,
      pageCMSData: [],
      imagePath: this.$imageURL + "location-cms/",
      previewImage: "",
      credential: "sandbox",
      isLive: false,
      isSandbox: true,
      showPass: false,
      country: {
        name: "United States of America",
        code: "US",
      },
    };
  },
  async mounted() {
    await this.getCmsData();
  },
  methods: {
    async getCmsData() {
      let config = {
        method: "GET",
        url: this.setURL,
        headers: {
          Authorization: authHeader(),
          "Content-Type": "application/json",
        },
      };
      await this.$axios(config)
        .then((response) => {
          if (response.status == 200) {
            this.pageCMSData = response.data.data?.[0] || {};
            this.credential = this.pageCMSData?.credentialType ?? null;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire({
            icon: "error",
            text: "Something went wrong. Please try again later!",
          });
        });
    },
    validateData(data) {
      let fieldsToValidate = [];
      const checkFields = (fields) => {
        for (let field of fields) {
          if (
            data[field.key] === undefined ||
            data[field.key] === null ||
            data[field.key] === ""
          ) {
            this.$swal.fire({
              icon: "error",
              text: "The field '" + field.value + "' should not be empty.",
            });
            return false;
          }
        }
        return true;
      };

      if (data.credentialType === "sandbox") {
        fieldsToValidate = [
          {
            key: "sandboxAccessLicenseApiKey",
            value: "Sand Box Api Key",
          },
          {
            key: "sandboxUrl",
            value: "Sand Box url",
          },
          {
            key: "sandboxUserName",
            value: "Sand Box Username",
          },
          {
            key: "sandboxPassword",
            value: "Sand Box Password",
          },
          {
            key: "streetAddressOne",
            value: "Sand Box Street Address One",
          },
          {
            key: "name",
            value: "Name",
          },
          {
            key: "phone",
            value: "Phone",
          },
          {
            key: "email",
            value: "Email",
          },
          {
            key: "city",
            value: "City",
          },
          {
            key: "state",
            value: "State",
          },
          {
            key: "zip",
            value: "Zip",
          },
        ];
      } else if (data.credentialType === "live") {
        fieldsToValidate = [
          {
            key: "liveAccessLicenseApiKey",
            value: "Live Api Key",
          },
          {
            key: "liveUrl",
            value: "Live url",
          },
          {
            key: "liveUserName",
            value: "Live Username",
          },
          {
            key: "livePassword",
            value: "Live Password",
          },
          {
            key: "streetAddressOne",
            value: "Sand Box Street Address One",
          },
          {
            key: "name",
            value: "Name",
          },
          {
            key: "phone",
            value: "Phone",
          },
          {
            key: "email",
            value: "Email",
          },
          {
            key: "city",
            value: "City",
          },
          {
            key: "state",
            value: "State",
          },
          {
            key: "zip",
            value: "Zip",
          },
        ];
      } else {
        this.$swal.fire({
          icon: "error",
          text: "Invalid credential type.",
        });
        return false;
      }

      return checkFields(fieldsToValidate);
    },

    async update() {
      try {
        let data = {
          credentialType: this.credential,
          sandboxAccessLicenseApiKey:
            this.pageCMSData.sandboxAccessLicenseApiKey,
          liveAccessLicenseApiKey: this.pageCMSData.liveAccessLicenseApiKey,
          sandboxUrl: this.pageCMSData.sandboxUrl,
          liveUrl: this.pageCMSData.liveUrl,
          sandboxUserName: this.pageCMSData.sandboxUserName,
          liveUserName: this.pageCMSData.liveUserName,
          sandboxPassword: this.pageCMSData.livePassword,
          livePassword: this.pageCMSData.livePassword,
          streetAddressOne: this.pageCMSData.streetAddressOne,
          streetAddressTwo: this.pageCMSData.streetAddressTwo,
          name: this.pageCMSData.name,
          phone: this.pageCMSData.phone,
          email: this.pageCMSData.email,
          city: this.pageCMSData.city,
          state: this.pageCMSData.state,
          zip: this.pageCMSData.zip,
          // countryCode: this.country.code,
          country: this.country.name,
        };

        let config = {
          method: "POST",
          url: this.setURL,
          data: data,
          headers: {
            Authorization: authHeader(),
            "Content-Type": "application/json",
          },
        };
        if (this.validateData(data)) {
          await this.$axios(config)
            .then((response) => {
              if (response.status == 201 || response.status == 200) {
                this.$swal({
                  icon: "success",
                  text: response.data.message,
                });
                this.getCmsData();
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal.fire({
                icon: "error",
                text: "Something went wrong. Please try again later!",
              });
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
