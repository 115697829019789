<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Page Title CMS</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard"><i class="bx bx-home-alt"></i></router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Page Title CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Page Title CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="border border-3 p-4 rounded">
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Title:</label>
                                                <input type="text" v-model="pageCMSData.homePageTitle" class="form-control" placeholder="Home page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.homePageMetaKeyword" class="form-control" placeholder="Home page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Home Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.homePageMetaDescription" class="form-control" placeholder="Home page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Product Page Title:</label>
                                                <input type="text" v-model="pageCMSData.productPagetitle" class="form-control" placeholder="Product page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Product Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.productPageMetaKeyword" class="form-control" placeholder="Product page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Product Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.productPageMetaDescription" class="form-control" placeholder="Product page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Sale Page Title:</label>
                                                <input type="text" v-model="pageCMSData.salePagetitle" class="form-control" placeholder="Sale page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Sale Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.salePageMetaKeyword" class="form-control" placeholder="Sale page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Sale Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.salePageMetaDescription" class="form-control" placeholder="Sale page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Page Title:</label>
                                                <input type="text" v-model="pageCMSData.blogPageTitle" class="form-control" placeholder="Blog page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.blogPageMetaKeyword" class="form-control" placeholder="Blog page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Blog Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.blogPageMetaDescription" class="form-control" placeholder="Blog page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Franchise Page Title:</label>
                                                <input type="text" v-model="pageCMSData.franchisePageTitle" class="form-control" placeholder="Franchise page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Franchise Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.franchisePageMetaKeyword" class="form-control" placeholder="Franchise page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Franchise Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.franchisePageMetaDescription" class="form-control" placeholder="Franchise page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Quotation Page Title:</label>
                                                <input type="text" v-model="pageCMSData.quotationPageTitle" class="form-control" placeholder="Quotation page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Quotation Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.quotationPageMetaKeyword" class="form-control" placeholder="Quotation page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Quotation Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.quotationPageMetaDescription" class="form-control" placeholder="Quotation page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Title:</label>
                                                <input type="text" v-model="pageCMSData.aboutPageTitle" class="form-control" placeholder="About page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.aboutPageMetaKeyword" class="form-control" placeholder="About page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">About Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.aboutPageMetaDescription" class="form-control" placeholder="About page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Location Page Title:</label>
                                                <input type="text" v-model="pageCMSData.locationPageTitle" class="form-control" placeholder="Location page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Location Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.locationPageMetaKeyword" class="form-control" placeholder="Location page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Location Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.locationPageMetaDescription" class="form-control" placeholder="Location page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Title:</label>
                                                <input type="text" v-model="pageCMSData.careerPageTitle" class="form-control" placeholder="Career page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.careerPageMetaKeyword" class="form-control" placeholder="Career page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Career Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.careerPageMetaDescription" class="form-control" placeholder="Career page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Title:</label>
                                                <input type="text" v-model="pageCMSData.contactPageTitle" class="form-control" placeholder="Contact page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.contactPageMetaKeyword" class="form-control" placeholder="Contact page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Contact Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.contactPageMetaDescription" class="form-control" placeholder="Contact page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Account Page Title:</label>
                                                <input type="text" v-model="pageCMSData.accountPageTitle" class="form-control" placeholder="Account page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Account Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.accountPageMetaKeyword" class="form-control" placeholder="Account page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Account Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.accountPageMetaDescription" class="form-control" placeholder="Account page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Help Page Title:</label>
                                                <input type="text" v-model="pageCMSData.helpPageTitle" class="form-control" placeholder="Help page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Help Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.helpPageMetaKeyword" class="form-control" placeholder="Help page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Help Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.helpPageMetaDescription" class="form-control" placeholder="Help page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Title:</label>
                                                <input type="text" v-model="pageCMSData.privacyPageTitle" class="form-control" placeholder="Privacy page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.privacyPageMetaKeyword" class="form-control" placeholder="Privacy page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.privacyPageMetaDescription" class="form-control" placeholder="Privacy page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <!-- <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Title:</label>
                                                <input type="text" v-model="pageCMSData.privacyPageTitle" class="form-control" placeholder="Privacy page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.privacyPageMetaKeyword" class="form-control" placeholder="Privacy page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Privacy Policy Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.privacyPageMetaDescription" class="form-control" placeholder="Privacy page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/> -->
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Terms Policy Page Title:</label>
                                                <input type="text" v-model="pageCMSData.termsPageTitle" class="form-control" placeholder="Terms page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms Policy Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.termsPageMetaKeyword" class="form-control" placeholder="Terms page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Terms Policy Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.termsPageMetaDescription" class="form-control" placeholder="Terms page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Return Policy Page Title:</label>
                                                <input type="text" v-model="pageCMSData.returnPageTitle" class="form-control" placeholder="Return page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Return Policy Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.returnPageMetaKeyword" class="form-control" placeholder="Return page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Return Policy Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.returnPageMetaDescription" class="form-control" placeholder="Return page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Cancellation Policy Page Title:</label>
                                                <input type="text" v-model="pageCMSData.cacellationPageTitle" class="form-control" placeholder="Cancellation page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Cancellation Policy Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.cancellationPageMetaKeyword" class="form-control" placeholder="Cancellation page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Cancellation Policy Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.cancellationPageMetaDescription" class="form-control" placeholder="Cancellation page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page Title:</label>
                                                <input type="text" v-model="pageCMSData.cartPageTitle" class="form-control" placeholder="Cart page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.cartPageMetaKeyword" class="form-control" placeholder="Cart page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Cart Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.cartPageMetaDescription" class="form-control" placeholder="Cart page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Wishlist Page Title:</label>
                                                <input type="text" v-model="pageCMSData.wishlistPageTitle" class="form-control" placeholder="Wishlist page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Wishlist Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.wishlistPageMetaKeyword" class="form-control" placeholder="Wishlist page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Wishlist Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.wishlistPageMetaDescription" class="form-control" placeholder="Wishlist page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Checkout Page Title:</label>
                                                <input type="text" v-model="pageCMSData.checkoutPageTitle" class="form-control" placeholder="Checkout page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Checkout Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.checkoutPageMetaKeyword" class="form-control" placeholder="Checkout page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Checkout Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.checkoutPageMetaDescription" class="form-control" placeholder="Checkout page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label class="form-label">Profile Page Title:</label>
                                                <input type="text" v-model="pageCMSData.profilePageTitle" class="form-control" placeholder="Profile page title">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Profile Page Meta Keyword:</label>
                                                <textarea v-model="pageCMSData.profilePageMetaKeyword" class="form-control" placeholder="Profile page meta keyword"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="mb-3">
                                                <label class="form-label">Profile Page Meta Description:</label>
                                                <textarea v-model="pageCMSData.profilePageMetaDescription" class="form-control" placeholder="Profile page meta description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="d-grid">
                                        <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script src="../js/page-title-cms.js"></script>