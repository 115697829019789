import { authHeader } from "../../../auth";
import * as XLSX from 'xlsx';
export default {
    data() {
        return {
            refundList: [],
            filter: {
                transId: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            },
            transIdList: [],
            userList: [],
            salesTotal: 0,
            salesTotalQuantity: 0,
            filteredOrderIdList: [],
            setURL: this.$serverURL + this.$api.user.customerUserList,
            sheets: [],
            customerList: [],
        }
    },
    async mounted() {
        document.title = "HGS - Refund Report";
        await this.getRefundList();
        //await this.getCustomerList();
        // this.countryList = this.countries.map(el => {
        //     return { value: { name: el.countryNameEn, code: el.countryCode }, label: el.countryNameEn }
        // });
        this.loadJs();
    },
    methods: {
        loadJs() {
            var scripts = [
                "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
                "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
                "/core/assets/js/table-datatable.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                document.head.appendChild(tag);
            });

            let style = document.createElement('link');
            style.type = "text/css";
            style.rel = "stylesheet";
            style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
            document.head.appendChild(style);
        },
        async getRefundList() {
            this.refundList = [];
            try {
                let config = {
                    method: "GET",
                    url: this.$serverURL + this.$api.order.refundReportURL + "?transId=" + this.filter.transId + "&startDate=" + this.filter.startDate + "&endDate=" + this.filter.endDate + "&userName=" + this.filter.userName + "&orderId=" + this.filter.orderId,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {

                        this.refundList = await response.data.data;

                        if (this.filteredOrderIdList.length == 0) {
                            this.filteredOrderIdList = this.refundList.map(el => {
                                return { value: el.invoiceID, label: el.invoiceID }
                            });
                        }
                        if (this.transIdList.length == 0) {
                            this.transIdList = this.refundList.map(el => {
                                return { value: el.refundTransID, label: el.refundTransID }
                            });
                        }
                        if (this.customerList.length == 0) {
                            let names = this.refundList.map(el => {
                                return el.customerName
                            });
                            let uniqCustomer = [...new Set(names)];
                            
                            this.customerList = uniqCustomer.map(el => {
                                return { value: el, label: el }
                            });
                        }

                    }

                }).catch(error => {
                    this.$swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
            } catch (error) {
                console.log(error)
            }
        },
        // async getCustomerList() {
        //     let config = {
        //         method: "GET",
        //         url: this.setURL,
        //         headers: {
        //             "Authorization": authHeader()
        //         }
        //     }
        //     await this.$axios(config).then(async (response) => {
        //         if (response.status == 200) {
        //             this.userList = await response.data.data.map(el => {
        //                 return { value: el.firstName + ' ' + el.lastName, label: el.firstName + ' ' + el.lastName }
        //             });
        //         }
        //         else {

        //             this.$swal.fire({
        //                 icon: "error",
        //                 text: "Failed! please try again."
        //             });
        //         }
        //     }).catch(error => {

        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Failed! please try again." + error
        //         });
        //     });
        // },
        async validateFilter() {
            try {
                if (this.filter.startDate) {
                    if (!this.filter.endDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select end date"
                        });
                        return;
                    }
                }
                if (this.filter.endDate) {
                    if (!this.filter.startDate) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Need to select start date"
                        });
                        return;
                    }
                }
                window.$('#example').DataTable().destroy();
                await this.getRefundList();
                window.$('#example').DataTable();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong, please try agian later." + error
                });
            }
        },
        async clearFilter() {
            this.filter = {
                transId: "",
                startDate: "",
                endDate: "",
                userName: "",
                orderId: "",
            };
            window.$('#example').DataTable().destroy();
            await this.getOrderList();
            window.$('#example').DataTable();
        },
        exportOrders: function () {
            this.refundList.map((el) => {
                this.sheets.push({
                    'OrderId': el.invoiceID,
                    "Customer Name": el.customerName,
                    "Transection Id": el.paymentTransID,
                    "Refund Transectio Id": el.refundTransID,
                    "Total Price": el.paymentAmount,
                    "Transection Status": el.transStatus,
                    "Created At": this.$filters.formatDate(el.createdAt)
                });
            });
            const data = XLSX.utils.json_to_sheet(this.sheets)
            const wb = XLSX.utils.book_new()
            const date = new Date().toLocaleString();
            XLSX.utils.book_append_sheet(wb, data, 'data')
            XLSX.writeFile(wb, 'refund_report(' + date + ').xlsx');
            this.sheets = [];
        },
        // async deleteOrder(id) {
        //     this.$swal.fire({
        //         title: 'Are you sure?',
        //         text: "You won't be able to revert this!",
        //         icon: 'warning',
        //         showCancelButton: true,
        //         confirmButtonColor: '#3085d6',
        //         cancelButtonColor: '#d33',
        //         confirmButtonText: 'Yes, delete it!'
        //     }).then(async (result) => {
        //         if (result.isConfirmed) {
        //             try {
        //                 let config = {
        //                     method: 'DELETE',
        //                     url: this.$serverURL + this.$api.order.orderURL + '/' + id,
        //                     headers: {
        //                         "Authorization": authHeader(),
        //                     }
        //                 }
        //                 await this.$axios(config).then((response) => {
        //                     if (response.data.deleted == true) {
        //                         this.getOrderList();
        //                         this.$swal.fire({
        //                             icon: "warning",
        //                             text: "Order deleted Successfully."
        //                         });
        //                     }

        //                 }).catch(error => {

        //                     this.$swal.fire({
        //                         icon: "error",
        //                         text: "Something went wrong. Please try again!" + error
        //                     });
        //                 });
        //             } catch (error) {
        //                 this.$swal.fire({
        //                     icon: "error",
        //                     text: "Something went wrong, please try agian later." + error
        //                 });
        //             }
        //         }
        //     })
        // }
    }

}