import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            pageCMSData: [],
            setURL: this.$serverURL + this.$api.cms.trackOrder,
            previewIconImage: ''
        }
    },
    async mounted() {
        document.title = "HGS - Track Order CMS";
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        
        uploadIcon: function(event) {
            this.pageCMSData.image = event.target.files[0];
            let input = this.$refs.featuredIconImage
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewIconImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        async update() {
            try {
                let formData = new FormData();
                formData.append('image', this.pageCMSData.image);
                formData.append('title', this.pageCMSData.title);
                formData.append('buttonText', this.pageCMSData.buttonText);
                formData.append('placeholder', this.pageCMSData.placeholder);
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data",
                    }
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
                
            } catch (error) {
                return error;
            }
        }
    }
}