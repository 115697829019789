
import { authHeader } from "../../../../auth";
export default {
    components: {
        isCreated: false,
    },
    data() {
        return {
            singleContent: {},
            accordionItems: [],
            name: "",
            categoryTitle: "",
            featuredImage: "",
            previewImage: "",
            updateImage: "",
            previewUpdateImage: "",
            setURL: this.$serverURL + this.$api.productCategory.parentProductCategoryURL,
            imageURL: this.$imageURL + "category-image/"

        }
    },
    async created() {
        document.title = "HGS - Category";
        await this.getAllParentCategories();
        await this.getSubCategories();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js",
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        uploadFeaturedImage(event) {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.addFeaturedImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        updateFeaturedImage(event) {
            this.updateImage = event.target.files[0];
            let input = this.$refs.updateFeaturedImage;
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewUpdateImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        addCategory: async function () {
            if (!this.name) {
               return this.$swal.fire({
                    icon: "error",
                    text: "Please enter category name!"
                });
            } else if (!this.categoryTitle) {
              return  this.$swal.fire({
                    icon: "error",
                    text: "Please enter category title!"
                });
            }
            else {
                let formData = new FormData();
                formData.append('name', this.name);
                formData.append('categoryTitle', this.categoryTitle);
                formData.append('imageAlt', this.imageAlt);
                formData.append('imageTitle', this.imageTitle);
                if (this.featuredImage) {
                    formData.append('image', this.featuredImage);
                }
                if (this.accordionItems) {
                    this.accordionItems.map((data, index) => {
                        formData.append(`faq[${index}][title]`, data.title);
                        formData.append(`faq[${index}][answer]`, data.answer);
                    })
                }
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": authHeader(),
                        "Content-Type": "multipart/form-data",
                    }
                };
                await this.postDataToBackend(config);
                await this.getAllParentCategories();
                await this.getSubCategories();
                this.categoryType = null;
                this.parentCategoryValue = {};
                this.name = '';
                this.featuredImage = '';
                this.$refs.addFeaturedImage.value = "";
                this.previewImage = "";
            }
        },
        editCategory: async function (data) {

            let config = {
                method: "GET",
                url: this.setURL + "/" + data.id,
                headers: {
                    "Authorization": authHeader()
                }
            }
           await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.singleContent = await response.data?.data;

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
             
            // this.singleContent = data;
        },
        updateCategory: async function () {
            let formData = new FormData();
            formData.append('name', this.singleContent.name);
            formData.append('categoryTitle', this.singleContent.categoryTitle);
            formData.append('imageTitle', this.singleContent.imageTitle);
            formData.append('imageAlt', this.singleContent.imageAlt);
            if (this.updateImage) {
                formData.append('image', this.updateImage);
            } else {
                formData.append('image', this.singleContent.image);
            }
            if (this.singleContent.faq) {
                this.singleContent.faq.map((data, index) => {
                    formData.append(`faq[${index}][title]`, data.title);
                    formData.append(`faq[${index}][answer]`, data.answer);
                })
            }
            let config = {
                method: "PATCH",
                url: this.setURL + "/" + this.singleContent.id,
                data: formData,
                headers: {
                    "Authorization": authHeader(),
                    "Content-Type": "multipart/form-data",
                }
            };
            await this.updateDataToBackend(config);
            await this.getAllParentCategories();
            await this.getSubCategories();
            this.parentCategoryValue = "";
            this.updateImage = '';
            this.previewUpdateImage = '';
            this.singleContent = {};
            this.$refs.updateFeaturedImage.value = null;
        },
        deleteCategory: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + "/" + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getAllParentCategories();
                        await this.getSubCategories();
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })

        },
        addAccordionItem() {
            this.accordionItems.push({
              title: 'Edit Title',
              answer: 'Edit Content',
            });
          },
        addSingelAccordionItem() {
            this.singleContent.faq.push({
              title: 'Edit Title',
              answer: 'Edit Content',
            });
          },
          removeAccordionItem(index) {
            this.accordionItems.splice(index, 1);
          },
          removeSingelAccordionItem(index) {
            this.singleContent.faq.splice(index, 1);
          },
    }
}