import { authHeader } from "../../../../auth";
import LvColorpicker from 'lightvue/color-picker';

export default {
    components: {
        LvColorpicker
    },
    data() {
        return {
            setURL: '',
            pageCMSData: [],
            dataLoaded: false,
            colors: [
                "#E91E63", 
                "#F44336", 
                "#9C27B0", 
                "#673AB7", 
                "#3F51B5", 
                "#2196F3", 
                "#03A9F4", 
                "#00BCD4", 
                "#009688", 
                "#4CAF50", 
                "#8BC34A", 
                "#CDDC39", 
                "#FFEB3B", 
                "#FFC107", 
                "#FF9800", 
                "#795548"
            ]
        }
    },
    async created() {
        document.title = "HGS - Blog";
        this.setURL = this.$serverURL + this.$api.ComponentCms.blogPageURL;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
        this.dataLoaded = true;
    },
    methods: {
        async updateCms() {
            try {
                let data = {
                    headerTitleFontColor: this.pageCMSData.headerTitleFontColor ? this.pageCMSData.headerTitleFontColor : '#111111',
                    pageBackgroundColor: this.pageCMSData.pageBackgroundColor ? this.pageCMSData.pageBackgroundColor : '#FAFAFA'
                }
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
            } catch (error) {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            }
        }
    }
}