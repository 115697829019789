import { authHeader } from "../../../../auth";
import { countries } from "../../../../../config/country";
export default {
    data() {
        return {
            setURL: this.$serverURL + this.$api.shippingConfig.fedexConfigURL,
            countryList: countries,
            country: {
                name: 'United States of America',
                code: 'US',
            },
            pageCMSData: {},
            imagePath: this.$imageURL + 'location-cms/',
            previewImage: '',
            credential: "sandbox",
            isLive: false,
            isSandbox: true,
            showPass: false,
            shippingData: {},
            billingData: {},
        }
    },
    async mounted() {
        await this.getCmsData();
    },
    methods: {
        async getCmsData() {
            let config = {
                method: 'GET',
                url: this.setURL,
                headers: {
                    "Authorization": authHeader(),
                    "Content-Type": "application/json",
                }
            };
            await this.$axios(config).then((response) => {
                if (response.data.statusCode == 200) {
                    if (response.data.statusCode == 200) {
                        this.pageCMSData = response.data?.data;
                        if (this.pageCMSData.length > 0) {
                            this.pageCMSData = response.data?.data[0];
                            this.credential = this.pageCMSData.credentialType;
                            if (this.pageCMSData.shippingInfo) {
                                this.shippingData = JSON.parse(this.pageCMSData.shippingInfo);
                            }

                            if (this.pageCMSData.billingInfo) {
                                this.billingData = JSON.parse(this.pageCMSData.billingInfo);
                            }
                        } else {
                            this.pageCMSData = {
                                sandboxClientId: "",
                                sandboxClientSecret: "",
                                sandboxUrl: "",
                                sandboxShippingAccountNumber: "",
                                sandboxBillingAccountNumber: "",
                                liveClientId: "",
                                liveClientSecret: "",
                                liveUrl: "",
                                liveShippingAccountNumber: "",
                                liveBillingAccountNumber: "",
                            }
                            this.shippingData = {
                                shippingAddressLine: '',
                                shippingCity: '',
                                shippingStateProvianceCode: '',
                                shippingPostalCode: '',
                            }

                            this.billingData = {
                                billingAddressLine: '',
                                billingCity: '',
                                billingStateProvianceCode: '',
                                billingPostalCode: '',
                            }
                        }
                    }

                }
            }).catch(error => {
                console.log(error);
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again later!"
                });
            });
        },
        async update() {
            try {
                this.shippingData.country = this.country.name
                this.shippingData.countryCode = this.country.code
                this.billingData.country = this.country.name
                this.billingData.countryCode = this.country.code
                let data = {
                    credentialType: this.credential,
                    clientId: this.credential == 'sandbox' ? this.pageCMSData.sandboxClientId : this.pageCMSData.liveClientId,
                    clientSecret: this.credential == 'sandbox' ? this.pageCMSData.sandboxClientSecret : this.pageCMSData.liveClientSecret,
                    url: this.credential == 'sandbox' ? this.pageCMSData.sandboxUrl : this.pageCMSData.liveUrl,
                    // shippingAccountNumber: this.credential == 'sandbox' ? this.pageCMSData.sandboxShippingAccountNumber : this.pageCMSData.liveShippingAccountNumber,
                    // billingAccountNumber: this.credential == 'sandbox' ? this.pageCMSData.sandboxBillingAccountNumber : this.pageCMSData.liveBillingAccountNumber,
                    sandboxShippingAccountNumber: this.pageCMSData.sandboxShippingAccountNumber,
                    sandboxBillingAccountNumber: this.pageCMSData.sandboxBillingAccountNumber,
                    liveShippingAccountNumber: this.pageCMSData.liveShippingAccountNumber,
                    liveBillingAccountNumber: this.pageCMSData.liveBillingAccountNumber,
                    shippingInfo: JSON.stringify(this.shippingData),
                    billingInfo: JSON.stringify(this.billingData)
                }
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.$axios(config).then((response) => {
                    if (response.status == 201 || response.status == 200) {
                        this.$swal({
                            icon: 'success',
                            text: response.data.message
                        })
                        this.getCmsData();
                    }
                }).catch(error => {
                    console.log(error);
                    this.$swal.fire({
                        icon: "error",
                        text: "Something went wrong. Please try again later!"
                    });
                });



            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        },
    }
}