<template>
    <div class="page-wrapper">
      <div class="page-content">
        <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                User Wise Product Discount
              </li>
            </ol>
          </nav>
  
          <div class="ms-auto">
            <div class="btn-group">
              <button type="button" class="btn btn-primary" @click="$router.push('/add-product-discount')">
                <i class="bx bxs-plus-square"></i>Add New Discount
              </button>
            </div>
          </div>
        </div>
        <h6 class="mb-0 text-uppercase">User Wise Product Discount List</h6>
        <hr />
        <div class="card">
          <div class="card-body">
            <div class="text-end mb-3">
              <button type="button" class="btn btn-primary" @click="exportData">
                Export xlsx
              </button>
            </div>
            <div class="table-responsive">
              <table id="example" class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Product Name</th>
                    <th>User Name</th>
                    <th>Discount Type</th>
                    <th>Discount Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in dataList" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ data.productName }}</td>
                    <td>{{ data.name }}</td>
                    <td>
                        {{data.discountType}}
                    </td>
                    <td>{{ data.discountAmount }}</td>
                    <td>
                      <div class="d-flex order-actions">
                        <a href="javascript:void(0);" @click="$router.push('/update-product-discount/' + data.id)"
                          class="edit-button"><i class="bx bxs-edit text-white"></i></a>
                        <a href="javascript:void(0);" @click="deleteDiscount(data.id)" class="ms-2 delete-button"><i
                            class="bx bxs-trash text-white"></i></a>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>SL</th>
                    <th>Product Name</th>
                    <th>User Name</th>
                    <th>Discount Type</th>
                    <th>Discount Amount</th>
                    <th>Action</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script src='../js/user-wise-discount.js'></script>
  
  
  <style scoped>
    @import "../../../../public/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css";
  
    .edit-button {
      background-color: #17a00e;
    }
  
    .delete-button {
      background-color: #f41127;
    }
    .duplicate-button {
      background-color: #197cee;
    }
  </style>