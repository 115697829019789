<template>
  <header>
    <div class="topbar d-flex align-items-center">
      <nav class="navbar navbar-expand">
        <div class="mobile-toggle-menu"><i class="bx bx-menu"></i></div>
        <div class="search-bar flex-grow-1">
          <!-- <div class="position-relative search-bar-box">
            <input
              type="text"
              class="form-control search-control"
              placeholder="Type to search..."
            />
            <span
              class="position-absolute top-50 search-show translate-middle-y"
              ><i class="bx bx-search"></i
            ></span>
            <span
              class="position-absolute top-50 search-close translate-middle-y"
              ><i class="bx bx-x"></i
            ></span>
          </div> -->
        </div>
        <!-- <div class="top-menu ms-auto">
          <ul class="navbar-nav align-items-center">
            <li class="nav-item mobile-search-icon">
              <a class="nav-link" href="#"><i class="bx bx-search"></i></a>
            </li>
            <li class="nav-item dropdown dropdown-large">
              <a
                class="
                  nav-link
                  dropdown-toggle dropdown-toggle-nocaret
                  position-relative
                "
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="alert-count">1</span>
                <i class="bx bx-bell"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <a href="javascript:;">
                  <div class="msg-header">
                    <p class="msg-header-title">Notifications</p>
                    <p class="msg-header-clear ms-auto">Marks all as read</p>
                  </div>
                </a>
                <div class="header-notifications-list">
                  <a class="dropdown-item" href="javascript:;">
                    <div class="d-flex align-items-center">
                      <div class="notify bg-light-primary text-primary">
                        <i class="bx bx-group"></i>
                      </div>
                      <div class="flex-grow-1">
                        <h6 class="msg-name">
                          New Customers<span class="msg-time float-end"
                            >14 sec ago</span
                          >
                        </h6>
                        <p class="msg-info">5 new user registered</p>
                      </div>
                    </div>
                  </a>
                </div>
                <a href="#">
                  <div class="text-center msg-footer">
                    View All Notifications
                  </div>
                </a>
              </div>
            </li>
            <li class="nav-item dropdown dropdown-large">
              <a
                class="
                  nav-link
                  dropdown-toggle dropdown-toggle-nocaret
                  position-relative
                "
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="alert-count">1</span>
                <i class="bx bx-comment"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <a href="#">
                  <div class="msg-header">
                    <p class="msg-header-title">Messages</p>
                    <p class="msg-header-clear ms-auto">Marks all as read</p>
                  </div>
                </a>
                <div class="header-message-list">
                  <a class="dropdown-item" href="#">
                    <div class="d-flex align-items-center">
                      <div class="user-online">
                        <img
                          src="../../../../public/core/assets/images/avatars/avatar-24.png"
                          class="msg-avatar"
                          alt="user avatar"
                        />
                      </div>
                      <div class="flex-grow-1">
                        <h6 class="msg-name">
                          Daisy Anderson
                          <span class="msg-time float-end">5 sec ago</span>
                        </h6>
                        <p class="msg-info">The standard chunk of lorem</p>
                      </div>
                    </div>
                  </a>
                </div>
                <a href="#">
                  <div class="text-center msg-footer">View All Messages</div>
                </a>
              </div>
            </li>
          </ul>
        </div> -->
        <div class="user-box dropdown ms-auto">
          <a
            class="
              d-flex
              align-items-center
              nav-link
              dropdown-toggle dropdown-toggle-nocaret
            "
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="https://png.pngtree.com/element_our/png_detail/20181206/users-vector-icon-png_260862.jpg"
              class="user-img"
              alt="user avatar"
            />
            <div class="user-info ps-3">
              <p class="user-name mb-0">{{ name }}</p>
              <p class="designattion mb-0">{{ email }}</p>
            </div>
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li>
              <router-link to="/profile" class="dropdown-item"
                ><i class="bx bx-user"></i><span>Profile</span></router-link
              >
            </li>
            <li>
              <router-link to="/" class="dropdown-item"
                ><i class="bx bx-home-circle"></i
                ><span>Dashboard</span></router-link
              >
            </li>
            <li>
              <a @click="logout()" class="dropdown-item" href="javascript:void(0)"
                ><i class="bx bx-log-out-circle"></i><span>Logout</span></a
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>
<script src="../js/header.js"></script>
