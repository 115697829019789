import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            locationHomeData: {
                title: "",
                description: ""
            },
            setURL: this.$serverURL + this.$api.cms.LocationHomeCms
        }
    },
    async mounted() {
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.locationHomeData = this.pageCMS;
        }
        else {
            this.locationHomeData = {};
        }
    },
    methods: {
        async updateCms() {
            try {
                let data={
                    title: this.locationHomeData.title,
                    description: this.locationHomeData.description,
                };
                let config = {
                    method: 'POST',
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.createUpdateCMS(config);
                await this.getCmsData(this.setURL);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}