import { createRouter, createWebHistory } from "vue-router";
import guard from "../guard";
import adminGuard from "../admin-guard";
import Login from "../components/auth/template/Login";
import Dashboard from "../components/dashboard/template/Dashboard";
import Error404 from "../components/global/error_404/template/Error404";
import Unauthorized from "../components/global/unauthorized/template/Unauthorized";
import Customer from "../components/user/template/Customer";
import Admin from "../components/user/template/Admin";
import SeoEngineer from "../components/user/template/SeoEngineer";
import ProductReview from "../components/Product/ProductReview/template/ProductReview";
import Product from "../components/Product/ProductList/template/Product";
import ProductOnSale from "../components/Product/ProductOnSale/template/ProductOnSale";
import ProductReorder from "../components/Product/ProductReorder/template/ProductReorder";
import AddProduct from "../components/Product/AddProduct/template/AddProduct";
import UpdateProduct from "../components/Product/UpdateProduct/template/UpdateProduct";
import Brand from "../components/Product/Brand/template/Brand";
import ImportBrand from "../components/Product/Brand/template/ImportBrand";
import Size from "../components/Product/Size/template/Size";
import Color from "../components/Product/Color/template/Color";
import Discount from "../components/Product/Discount/template/Discount";
import ParentCategory from "../components/Product/ProductCategory/template/ParentCategory";
import SubCategory from "../components/Product/ProductCategory/template/SubCategory";
import Contact from "../components/Contact/template/Contact";
import ContactMessageList from "../components/Contact/template/ContactMessageList";
import AboutServices from "../components/AboutUs/AboutServices/template/AboutServices";
import AboutContent from "../components/AboutUs/AboutContent/template/AboutContent";
import ProductDetailsCMS from "../components/CMS/Product/ProductDetailsCMS/template/ProductDetailsCMS";
import ProductCMS from "../components/CMS/Product/ProductCMS/template/ProductCMS";
// import Slider from "../components/Slider/template/Slider";
import ReviewSlider from "../components/ReviewSlider/template/ReviewSlider";
import ContactPageCMS from "../components/CMS/Contact/template/ContactPageCMS";
import BlogCMS from "../components/CMS/BlogPageCMS/template/BlogCMS";
import AdPopModalCMS from "../components/CMS/PopUpModalCMS/AdPopUpModal/template/AdPopModalCMS";
import NewsletterCMS from "../components/CMS/PopUpModalCMS//NewsletterModalCMS/template/NewsletterCMS";
import SocialLinks from "../components/CMS/Common/SocialLinks/template/SocialLinks";
import HomePageCMS from "../components/CMS/Common/HomePageCMS/template/HomePageCMS";
import AboutPageCMS from "../components/CMS/AboutUs/AboutPageCMS/template/AboutPageCMS";
import TrackOrderCMS from "../components/CMS/TrackOrderCMS/template/TrackOrderCMS";
import HelpPageCms from "../components/CMS/HelpCMS/template/HelpPageCms";
import HelpPageContent from "../components/HelpUs/template/HelpPageContent";
import HeaderCms from "../components/CMS/HeaderFooterCMS/template/HeaderCms";
import FooterCms from "../components/CMS/HeaderFooterCMS/template/FooterCms";
import FranchiseCMS from "../components/CMS/Franchise/template/FranchiseCMS";
import QuotationCMS from "../components/CMS/Quotation/template/Quotation";
import SaleCMS from "../components/CMS/Sale/template/SaleCMS";
import BlogCategory from "../components/Blog/BlogCategory/template/BlogCategory";
import BlogComment from "../components/Blog/BlogComment/template/BlogComment";
import BlogTags from "../components/Blog/BlogTags/template/BlogTags";
import BlogPost from "../components/Blog/BlogPost/template/BlogPost"
import AddBlogPost from "../components/Blog/AddBlogPost/template/AddBlogPost"
import UpdateBlogPost from "../components/Blog/UpdateBlogPost/template/UpdateBlogPost";
import Profile from "../components/profile/template/Profile";
import OrderList from "../components/Order/OrderList/template/OrderList";
import CreateOrder from "../components/Order/CreateOrder/template/CreateOrder";
import TeamMember from "../components/TeamMember/template/TeamMember";
import OrderDetails from "../components/Order/OrderDetails/template/OrderDetails";
import OrderSuccess from "../components/Order/OrderSuccess/template/OrderSuccess";
import Coupon from "../components/Coupon/template/Coupon";
import PaymentMethod from "../components/PaymentMethod/template/PaymentMethod";
import PopUpSettings from "../components/PopUpSettings/template/PopUpSettings";
import FeaturedCategories from "../components/FeaturedCategories/template/FeaturedCategories";
import Subscriber from "../components/Subscriber/template/Subscriber";
import Franchise from "../components/Franchise/template/Franchise";
import Quotation from "../components/Quotation/template/Quotation";
import PrivacyPolicy from "../components/policies/privacy_policy/template/PrivacyPolicy";
import CancellationPolicy from "../components/policies/cancellation_policy/template/CancellationPolicy";
import ReturnPolicy from "../components/policies/return_policy/template/ReturnPolicy";
import TermsAndCondition from "../components/policies/terms_and_condition/template/TermsAndCondition";
import ProductBulkUpload from "../components/Product/ProductBulkUpload/template/ProductBulkUpload";
import ProductStockIn from "../components/Product/ProductStockIn/template/ProductStockIn";
import BulkPriceUpdate from "../components/Product/BulkPriceUpdate/template/BulkPriceUpdate";
import StockList from "../components/Product/StockList/template/StockList";
import StockDetail from "../components/Product/StockDetail/template/StockDetail";
import LocationList from "../components/location/template/LocationList";
import AddLocation from "../components/location/template/AddLocation";
import EditLocation from "../components/location/template/EditLocation";
import EnquiryList from "../components/location/template/EnquiryList";
import InstagramFeed from "../components/InstagramFeed/template/InstagramFeed";
import LocationCms from "../components/CMS/LocationCMS/template/LocationCms";
import Ups from "../components/ShippingSettings/Ups/template/Ups";
import Fedex from "../components/ShippingSettings/Fedex/template/Fedex";
import LtlFreightSetting from "../components/ShippingSettings/LtlFreightSetting/template/LtlFreightSetting";
import PaymentSetting from "../components/PaymentSettings/template/PaymentSetting";
import PaymentList from "../components/PaymentList/template/PaymentList";
import ChildCategory from "../components/Product/ProductCategory/template/ChildCategory";
import SalesReport from "../components/SalesReport/template/SalesReport";
import ProfitReport from "../components/ProfitReport/template/ProfitReport";
import GenCustomFile from "../components/policies/customFileGenerate/template/CustomFileGenerate";
import UserProductDiscount from "../components/UserWiseProductDiscount/template/UserProductDiscount";
import AddProductDiscount from "../components/UserWiseProductDiscount/template/AddProductDiscount";
import UpdateProductDiscount from "../components/UserWiseProductDiscount/template/UpdateProductDiscount";
import RefundReport from "../components/RefundReport/template/RefundReport";
import SalesTax from "../components/SalesTax/template/SalesTax";
import CareerCMS from "../components/CMS/Career/template/CareerCMS";
import Career from "../components/Career/template/Career";
import ShippingMethods from "../components/shipping-methods/template/ShippingMethods";
import CareerComponentCMS from "../components/ComponentUi/Career/template/CareerComponentCMS";
import BlogComponentCMS from "../components/ComponentUi/Blog/template/BlogComponentCMS";
import HelpComponentCMS from "../components/ComponentUi/Help/template/HelpComponentCMS";
import PolicyComponentCMS from "../components/ComponentUi/Policy/template/PolicyComponentCMS";
import ContactComponentCMS from "../components/ComponentUi/Contact/template/ContactComponentCMS";
import AboutComponentCMS from "../components/ComponentUi/About/template/AboutComponentCMS";
import HomeComponentCMS from "../components/ComponentUi/Home/template/HomeComponentCMS";
import FranchiseComponentCMS from "../components/ComponentUi/Franchise/template/FranchiseComponentCMS";
import QuotationComponentCMS from "../components/ComponentUi/Quotation/template/QuotationComponentCMS";
import LocationComponentCMS from "../components/ComponentUi/Location/template/LocationComponentCMS";
import ProductComponentCMS from "../components/ComponentUi/Product/template/ProductComponentCMS";
import ProductDetailsComponentCMS from "../components/ComponentUi/ProductsDetails/template/ProductDetailsComponentCMS";
import LocationDetailsComponentCMS from "../components/ComponentUi/Location/template/LocationDetailsComponentCMS";
import Tag from "../components/Product/Tag/template/Tag";
import ShippingCMS from "../components/CMS/ShippingCMS/template/ShippingCMS";
import PageTitleCMS from "../components/CMS/HeaderFooterCMS/template/PageTitleCMS";
import UnapprovedTaxExempt from "../components/tax-exempt/template/UnapprovedTaxExempt";
import ApprovedTaxExempt from "../components/tax-exempt/template/ApprovedTaxExempt";
import RejectedTaxExempt from "../components/tax-exempt/template/RejectedTaxExempt";
import ProductMeta from "../components/CMS/HeaderFooterCMS/template/ProductMeta";
import CategoryMeta from "../components/CMS/HeaderFooterCMS/template/CategoryMeta";
import SubcategoryMeta from "../components/CMS/HeaderFooterCMS/template/SubcategoryMeta";
import ChildCategoryMeta from "../components/CMS/HeaderFooterCMS/template/ChildCategoryMeta";
import BrandMeta from "../components/CMS/HeaderFooterCMS/template/BrandMeta";
import Approved from "../components/ReturnProducts/template/Approved";
import Rejected from "../components/ReturnProducts/template/Rejected";
import Unapproved from "../components/ReturnProducts/template/Unapproved";
import SalesTaxReport from "../components/SalesTaxReport/template/SalesTaxReport";
import SearchRecords from "../components/SearchRecords/template/SearchRecords";
import blogMeta from "../components/CMS/BlogPageCMS/template/blogMeta.vue";
import CartCMS from "../components/CMS/cart/template/CartCMS";
// import homeIntro from "../components/CMS/homeIntro/templete/homeIntro.vue";
import PopularSearch from "../components/PopularSearch/template/PopularSearch.vue";
import WhyChooseUs from "../components/WhyChooseUs/template/WhyChooseUs.vue";
import TopSellingProducts from "../components/TopSellingProducts/template/TopSellingProducts.vue"
import HomeBrand from "../components/HomeBrand/template/HomeBrand.vue"
import startYourAccount from "../components/CMS/startYourAccount/templete/startYourAccount.vue";
import FooterBranding from "../components/CMS/FooterBranding/template/FooterBranding.vue";
import CategorySeoContent from "../components/category-seo-content/template/CategorySeoContent";
import SubcategorySeoContent from "../components/subcategory-seo-content/template/SubcategorySeoContent";
import ChildCategorySeoContent from "../components/child-category-seo-content/template/ChildCategorySeoContent";
import BrandSeoContent from "../components/brand-seo-content/template/BrandSeoContent";
import SiteMapCMS from "../components/CMS/SiteMap/template/SiteMapCMS";
import LocationHomeCMS from "../components/CMS/LocationHomeCMS/template/LocationHomeCMS";
// import LocationHome from "../components/CMS/LocationHome/template/LocationHome";
import ShoppingJourney from "../components/CMS/shopping-journey/template/ShoppingJourney";
import ServicesSection from "../components/CMS/services-section/template/ServicesSection";
import HeroSection from "../components/CMS/hero-section/template/HeroSection";
import UserLog from "../components/user-log/template/UserLog";
import OrderDumpLog from "../components/OrderDumpLog/template/OrderDumpLog";

//Elementor
import CreateCustomerAccountEmailElementor from "../components/CMS/EmailTemplate/template/elementor/CustomerSignup/CustomerAccountEmailTemplateElementor.vue";
import CreateAdminAccountEmailElementor from "../components/CMS/EmailTemplate/template/elementor/AdminSignup/AdminAccountEmailTemplateElementor.vue";
import ProcessingOrderEmailTemplate from "../components/CMS/EmailTemplate/template/elementor/ProcessingOrder/ProcessingOrderEmailTemplate.vue";
import ShippedOrderEmailTemplate from "../components/CMS/EmailTemplate/template/elementor/ShippedOrder/ShippedOrderEmailTemplate.vue";
import DeliveredOrderEmailTemplate from "../components/CMS/EmailTemplate/template/elementor/DeliveredOrder/DeliveredOrderEmailTemplate.vue";
import PlaceOrderEmailTemplate from "../components/CMS/EmailTemplate/template/elementor/PlaceOrder/PlaceOrderEmailTemplate.vue";
import ForgotPasswordEmailTemplate from "../components/CMS/EmailTemplate/template/elementor/ForgotPassword/ForgotPasswordEmailTemplate.vue";
import FedxCutoffTime from "../components/CMS/FedxCutoffTime/template/FedxCutoffTime.vue";
import EasyPost from "../components/ShippingSettings/easyPost/template/EasyPost.vue";
const routes = [
    /* Auth Routes */
    { path: "/admin-login", name: "Login", component: Login },
    { path: "/", redirect: { name: 'Home' } },
    { path: "/home", name: "Home", component: Dashboard, beforeEnter: guard },
    { path: "/profile", name: "Profile", component: Profile, beforeEnter: guard },
    { path: "/admins", name: "Admin", component: Admin, beforeEnter: adminGuard },
    { path: "/seo-engineer", name: "SeoEngineer", component: SeoEngineer, beforeEnter: adminGuard },
    { path: "/customers", name: "Customer", component: Customer, beforeEnter: adminGuard },
    { path: "/user-log", name: "UserLog", component: UserLog, beforeEnter: adminGuard },
    { path: "/order-dump-log", name: "OrderDumpLog", component: OrderDumpLog, beforeEnter: adminGuard },

    //Product
    { path: "/product-list", name: "Product", component: Product, beforeEnter: guard },
    { path: "/product-on-sale", name: "ProductOnSale", component: ProductOnSale, beforeEnter: guard },
    { path: "/product-reorder", name: "ProductReorder", component: ProductReorder, beforeEnter: guard },
    { path: "/product-review", name: "ProductReview", component: ProductReview, beforeEnter: guard },
    { path: "/product-brand", name: "Brand", component: Brand, beforeEnter: guard },
    { path: "/brand-import", name: "ImportBrand", component: ImportBrand, beforeEnter: guard },
    { path: "/add-product", name: "AddProduct", component: AddProduct, beforeEnter: guard },
    { path: "/update-product/:id", name: "UpdateProduct", component: UpdateProduct, beforeEnter: guard },
    { path: "/product-size", name: "Size", component: Size, beforeEnter: guard },
    { path: "/product-color", name: "Color", component: Color, beforeEnter: guard },
    { path: "/product-discount", name: "Discount", component: Discount, beforeEnter: guard },
    { path: "/parent-category", name: "ParentCategory", component: ParentCategory, beforeEnter: guard },
    { path: "/category-seo-content", name: "CategorySeoContent", component: CategorySeoContent, beforeEnter: guard },
    { path: "/subcategory-seo-content", name: "SubcategorySeoContent", component: SubcategorySeoContent, beforeEnter: guard },
    { path: "/child-category-seo-content", name: "ChildCategorySeoContent", component: ChildCategorySeoContent, beforeEnter: guard },
    { path: "/brand-seo-content", name: "BrandSeoContent", component: BrandSeoContent, beforeEnter: guard },
    { path: "/sub-category", name: "SubCategory", component: SubCategory, beforeEnter: guard },
    { path: "/child-category", name: "ChildCategory", component: ChildCategory, beforeEnter: guard },
    { path: "/product-bulk-upload", name: "ProductBulkUpload", component: ProductBulkUpload, beforeEnter: guard },
    { path: "/stock-in-product", name: "ProductStockIn", component: ProductStockIn, beforeEnter: guard },
    { path: "/stock-list", name: "StockList", component: StockList, beforeEnter: guard },
    { path: "/stock-detail/:id", name: "StockDetail", component: StockDetail, beforeEnter: guard },
    { path: "/bulk-price-update", name: "BulkPriceUpdate", component: BulkPriceUpdate, beforeEnter: guard },
    { path: "/tag", name: "Tag", component: Tag, beforeEnter: guard },

    //Contact
    { path: "/contact", name: "Contact", component: Contact, beforeEnter: guard },
    { path: "/contact-message", name: "ContactMessageList", component: ContactMessageList, beforeEnter: guard },
    // { path: "/slider", name: "Slider", component: Slider, beforeEnter: guard },
    { path: "/review-slider", name: "ReviewSlider", component: ReviewSlider, beforeEnter: guard },

    //Intagram feed
    { path: "/brands", name: "InstagramFeed", component: InstagramFeed, beforeEnter: guard },

    //Blog
    { path: "/blog-category", name: "BlogCategory", component: BlogCategory, beforeEnter: guard },
    { path: "/blog-tags", name: "BlogTags", component: BlogTags, beforeEnter: guard },
    { path: "/blog-post", name: "BlogPost", component: BlogPost, beforeEnter: guard },
    { path: "/add-blog-post", name: "AddBlogPost", component: AddBlogPost, beforeEnter: guard },
    { path: "/update-blog-post/:id", name: "UpdateBlogPost", component: UpdateBlogPost, beforeEnter: guard },
    { path: "/blog-comments", name: "BlogComment", component: BlogComment, beforeEnter: guard },
    { path: "/blog-cms", name: "BlogCMS", component: BlogCMS, beforeEnter: guard },
    { path: "/blog-meta", name: "blogMeta", component: blogMeta, beforeEnter: guard },

    //About Us
    { path: "/about-services", name: "AboutServices", component: AboutServices, beforeEnter: guard },
    { path: "/about-content", name: "AboutContent", component: AboutContent, beforeEnter: guard },

    //Help content
    { path: "/help-content", name: "HelpPageContent", component: HelpPageContent, beforeEnter: guard },

    //Team Member
    { path: "/team-member", name: "TeamMember", component: TeamMember, beforeEnter: guard },

    //Coupon
    { path: "/coupon", name: "Coupon", component: Coupon, beforeEnter: adminGuard },
    { path: "/payment-method", name: "PaymentMethod", component: PaymentMethod, beforeEnter: adminGuard },
    { path: "/pop-up-settings", name: "PopUpSettings", component: PopUpSettings, beforeEnter: guard },
    { path: "/featured-categories", name: "FeaturedCategories", component: FeaturedCategories, beforeEnter: guard },

    //CMS
    { path: "/sale-cms", name: "SaleCMS", component: SaleCMS, beforeEnter: guard },
    { path: "/header-cms", name: "HeaderCMS", component: HeaderCms, beforeEnter: guard },
    { path: "/footer-cms", name: "FooterCMS", component: FooterCms, beforeEnter: guard },
    { path: "/product-cms", name: "ProductCMS", component: ProductCMS, beforeEnter: guard },
    { path: "/product-details-cms", name: "ProductDetailsCMS", component: ProductDetailsCMS, beforeEnter: guard },
    { path: "/home-page-cms", name: "HomePageCMS", component: HomePageCMS, beforeEnter: guard },
    { path: "/social-links", name: "SocialLinks", component: SocialLinks, beforeEnter: guard },
    { path: "/contact-cms", name: "ContactPageCMS", component: ContactPageCMS, beforeEnter: guard },
    { path: "/about-page-cms", name: "AboutPageCMS", component: AboutPageCMS, beforeEnter: guard },
    { path: "/ad-pop-up-cms", name: "AdPopModalCMS", component: AdPopModalCMS, beforeEnter: guard },
    { path: "/newsletter-cms", name: "NewsletterCMS", component: NewsletterCMS, beforeEnter: guard },
    { path: "/track-order-cms", name: "TrackOrderCMS", component: TrackOrderCMS, beforeEnter: guard },
    { path: "/help-page-cms", name: "HelpPageCms", component: HelpPageCms, beforeEnter: guard },
    { path: "/subscriber", name: "Subscriber", component: Subscriber, beforeEnter: guard },
    { path: "/franchise", name: "Franchise", component: Franchise, beforeEnter: guard },
    { path: "/franchise-cms", name: "FranchiseCMS", component: FranchiseCMS, beforeEnter: guard },
    { path: "/quotation", name: "Quotation", component: Quotation, beforeEnter: guard },
    { path: "/quotation-cms", name: "QuotationCMS", component: QuotationCMS, beforeEnter: guard },
    { path: "/career-cms", name: "CareerCMS", component: CareerCMS, beforeEnter: guard },
    { path: "/location-cms", name: "LocationnCMS", component: LocationCms, beforeEnter: guard },
    { path: "/shipping-cms", name: "ShippingCMS", component: ShippingCMS, beforeEnter: guard },
    { path: "/page-title-cms", name: "PageTitleCMS", component: PageTitleCMS, beforeEnter: guard },
    { path: "/product-meta", name: "ProductMeta", component: ProductMeta, beforeEnter: guard },
    { path: "/category-meta", name: "CategoryMeta", component: CategoryMeta, beforeEnter: guard },
    { path: "/subcategory-meta", name: "SubcategoryMeta", component: SubcategoryMeta, beforeEnter: guard },
    { path: "/child-category-meta", name: "ChildCategoryMeta", component: ChildCategoryMeta, beforeEnter: guard },
    { path: "/brand-meta", name: "BrandMeta", component: BrandMeta, beforeEnter: guard },
    { path: "/cart-cms", name: "CartCMS", component: CartCMS, beforeEnter: guard },
    // { path: "/homeIntro", name: "homeIntro", component: homeIntro, beforeEnter: guard },
    { path: "/popular-search", name: "PopularSearch", component: PopularSearch, beforeEnter: guard },
    { path: "/why-choose-us", name: "WhyChooseUs", component: WhyChooseUs, beforeEnter: guard },
    { path: "/home-section-two", name: "TopSellingProducts", component: TopSellingProducts, beforeEnter: guard },
    { path: "/home-brand", name: "HomeBrand", component: HomeBrand, beforeEnter: guard },
    { path: "/start-your-account", name: "startYourAccount", component: startYourAccount, beforeEnter: guard },
    { path: "/footer-branding", name: "FooterBranding", component: FooterBranding, beforeEnter: guard },
    { path: "/shipping-cutoff-time", name: "FedxCutoffTime", component: FedxCutoffTime, beforeEnter: guard },
    { path: "/sitemap", name: "SiteMapCMS", component: SiteMapCMS, beforeEnter: guard },
    { path: "/location-home-cms", name: "LocationHomeCMS", component: LocationHomeCMS, beforeEnter: guard },
    // { path: "/location-home", name: "LocationHome", component: LocationHome, beforeEnter: guard },
    { path: "/shopping-journey", name: "ShoppingJourney", component: ShoppingJourney, beforeEnter: guard },
    { path: "/services-section", name: "ServicesSection", component: ServicesSection, beforeEnter: guard },
    { path: "/hero-section", name: "HeroSection", component: HeroSection, beforeEnter: guard },

    // Elementor
    { path: "/create-customer-signup-email-template-elementor", name: "CreateCustomerAccountEmailElementor", component: CreateCustomerAccountEmailElementor, beforeEnter: guard },
    { path: "/create-admin-signup-email-template-elementor", name: "CreateAdminAccountEmailElementor", component: CreateAdminAccountEmailElementor, beforeEnter: guard },
    { path: "/processing-order-email-template", name: "ProcessingOrderEmailTemplate", component: ProcessingOrderEmailTemplate, beforeEnter: guard },
    { path: "/shipped-order-email-template", name: "ShippedOrderEmailTemplate", component: ShippedOrderEmailTemplate, beforeEnter: guard },
    { path: "/delivered-order-email-template", name: "DeliveredOrderEmailTemplate", component: DeliveredOrderEmailTemplate, beforeEnter: guard },
    { path: "/place-order-email-template", name: "PlaceOrderEmailTemplate", component: PlaceOrderEmailTemplate, beforeEnter: guard },
    { path: "/forgot-password-email-template", name: "ForgotPasswordEmailTemplate", component: ForgotPasswordEmailTemplate, beforeEnter: guard },

    //Order
    { path: "/orders", name: "OrderList", component: OrderList, beforeEnter: adminGuard },
    { path: "/create-order", name: "CreateOrder", component: CreateOrder, beforeEnter: adminGuard },
    { path: "/order-details/:id", name: "OrderDetails", component: OrderDetails, beforeEnter: guard },
    { path: "/success/:id", name: "OrderSuccess", component: OrderSuccess, beforeEnter: guard },

    //policies
    { path: "/add-custom-script", name: "add-custom-script", component: GenCustomFile, beforeEnter: adminGuard },
    { path: "/privacy-policy", name: "Privacy Policy", component: PrivacyPolicy, beforeEnter: guard },
    { path: "/return-policy", name: "Return Policy", component: ReturnPolicy, beforeEnter: guard },
    { path: "/cancellation-policy", name: "Cancellation Policy", component: CancellationPolicy, beforeEnter: guard },
    { path: "/terms&condition", name: "Terms and Condition", component: TermsAndCondition, beforeEnter: guard },

    /* Error and Unauthorized routes */
    { path: "/404-not-found", name: "404 Not Found", component: Error404, beforeEnter: guard },
    { path: "/unauthorized", name: "Unauthorized", component: Unauthorized, beforeEnter: guard },
    { path: '/:pathMatch(.*)*', component: Error404, beforeEnter: guard },

    // Our Location
    { path: "/location-list", name: "LocationList", component: LocationList, beforeEnter: guard },
    { path: "/add-new-location", name: "AddLocation", component: AddLocation, beforeEnter: guard },
    { path: "/edit-location", name: "EditLocation", component: EditLocation, beforeEnter: guard },
    { path: "/enquiry-list", name: "EnquiryList", component: EnquiryList, beforeEnter: guard },

    //shipping settins
    { path: "/ups-settings", name: 'Ups', component: Ups, beforeEnter: guard },
    { path: "/easypost-settings", name: 'EasyPost', component: EasyPost, beforeEnter: guard },
    { path: "/fedex-settings", name: 'Fedex', component: Fedex, beforeEnter: guard },
    { path: "/ltl-freight-setting", name: 'LtlFreightSetting', component: LtlFreightSetting, beforeEnter: guard },
    { path: "/shipping-methods", name: 'ShippingMethods', component: ShippingMethods, beforeEnter: guard },

    //career
    { path: "/career", name: 'Career', component: Career, beforeEnter: guard },

    //payment setting
    { path: "/payment-settings", name: 'PaymentSetting', component: PaymentSetting, beforeEnter: adminGuard },
    { path: "/payment-list", name: 'PaymentList', component: PaymentList, beforeEnter: adminGuard },

    //reports
    { path: "/sales-report", name: 'SalesReport', component: SalesReport, beforeEnter: adminGuard },
    { path: "/profit-report", name: 'ProfitReport', component: ProfitReport, beforeEnter: adminGuard },
    { path: "/refund-report", name: 'RefundReport', component: RefundReport, beforeEnter: adminGuard },
    { path: "/sales-tax-report", name: 'SalesTaxReport', component: SalesTaxReport, beforeEnter: adminGuard },
    { path: "/search-records", name: 'SearchRecords', component: SearchRecords, beforeEnter: adminGuard },

    //sales tax
    { path: "/sales-tax", name: 'SalesTax', component: SalesTax, beforeEnter: adminGuard },
    { path: "/unapproved-tax-exempt", name: 'UnapprovedTaxExempt', component: UnapprovedTaxExempt, beforeEnter: guard },
    { path: "/approved-tax-exempt", name: 'ApprovedTaxExempt', component: ApprovedTaxExempt, beforeEnter: guard },
    { path: "/rejected-tax-exempt", name: 'RejectedTaxExempt', component: RejectedTaxExempt, beforeEnter: guard },


    { path: "/user-wise-product-discount", name: "UserProductDiscount", component: UserProductDiscount, beforeEnter: guard },
    { path: "/add-product-discount", name: "AddProductDiscount", component: AddProductDiscount, beforeEnter: guard },
    { path: "/update-product-discount/:id", name: "UpdateProductDiscount", component: UpdateProductDiscount, beforeEnter: guard },


    //Component CMS
    { path: "/career-component-cms", name: "CareerComponentCMS", component: CareerComponentCMS, beforeEnter: guard },
    { path: "/blog-component-cms", name: "BlogComponentCMS", component: BlogComponentCMS, beforeEnter: guard },
    { path: "/help-component-cms", name: "HelpComponentCMS", component: HelpComponentCMS, beforeEnter: guard },
    { path: "/policy-component-cms", name: "PolicyComponentCMS", component: PolicyComponentCMS, beforeEnter: guard },
    { path: "/contact-component-cms", name: "ContactComponentCMS", component: ContactComponentCMS, beforeEnter: guard },
    { path: "/about-component-cms", name: "AboutComponentCMS", component: AboutComponentCMS, beforeEnter: guard },
    { path: "/home-component-cms", name: "HomeComponentCMS", component: HomeComponentCMS, beforeEnter: guard },
    { path: "/franchise-component-cms", name: "FranchiseComponentCMS", component: FranchiseComponentCMS, beforeEnter: guard },
    { path: "/quotation-component-cms", name: "QuotationComponentCMS", component: QuotationComponentCMS, beforeEnter: guard },
    { path: "/location-component-cms", name: "LocationComponentCMS", component: LocationComponentCMS, beforeEnter: guard },
    { path: "/location-details-component-cms", name: "LocationDetailsComponentCMS ", component: LocationDetailsComponentCMS, beforeEnter: guard },
    { path: "/product-component-cms", name: "ProductComponentCMS", component: ProductComponentCMS, beforeEnter: guard },
    { path: "/product-details-component-cms", name: "ProductDetailsComponentCMS", component: ProductDetailsComponentCMS, beforeEnter: guard },

    //Return product
    { path: "/return-product-approved", name: "Approved", component: Approved, beforeEnter: guard },
    { path: "/return-product-unapproved", name: "Unapproved", component: Unapproved, beforeEnter: guard },
    { path: "/return-product-rejected", name: "Rejected", component: Rejected, beforeEnter: guard },


];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
