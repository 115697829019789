import { authHeader } from "../../../../auth";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  data() {
    return {
      editor: ClassicEditor,
      topLogo: "",
      previewTopLogo: null,
      topLogoLink: "",
      contactIconImage: "",
      previewContactIconImage: null,
      contactText: "",
      contactNumber: "",
      socialConnectTitle: "",
      instagramLink: "",
      facebookLink: "",
      twitterLink: "",
      youTubeLink: "",
      facebookStatus: false,
      instagramStatus: false,
      twitterStatus: false,
      youtubeStatus: false,
      paymentTitle: "",
      paymentImage: "",
      previewPaymentImage: null,
      bottomLogo: "",
      previewBottomLogo: null,
      bottomLogoLink: "",
      backgroundColor: "",
      boxFontColor: "",
      footerBrandingCMS: {},
      setURL: this.$serverURL + this.$api.cms.footerBrandingCMS,
      setImageURL: this.$imageURL + 'FooterBranding/'
    };
  },
  async mounted() {
    await this.getFooterBrandingCmsData(this.setURL);
    this.cmsValueAssign();
  },
  methods: {
    getFooterBrandingCmsData: async function (url) {
      const config = {
        method: "GET",
        url: url,
        headers: {
          Authorization: authHeader(),
        },
      };

      await this.$axios(config)
        .then(async (response) => {
          if (response.status == 200) {
            this.footerBrandingCMS = await response.data.data[0];
          } else {
            this.$swal.fire({
              icon: "error",
              text: "Failed! please try again.",
            });
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            text: "Failed! please try again." + error,
          });
        });
    },

    updateFooterBrandingCms: async function () {
      try {
        if (!this.topLogo) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Top Logo",
          });
        } else if (!this.topLogoLink) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Top Logo Link",
          });
        } else if (!this.contactIconImage) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Contact Icon Image",
          });
        } else if (!this.contactText) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Contact",
          });
        } else if (!this.contactNumber) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Contact Number",
          });
        } else if (!this.socialConnectTitle) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Social Connect Title",
          });
        } else if (!this.instagramLink) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Instagram Link",
          });
        } else if (!this.facebookLink) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Facebook Link",
          });
        } else if (!this.twitterLink) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Twitter Link",
          });
        } else if (!this.youTubeLink) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter YouTube Link",
          });
        } else if (!this.paymentTitle) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Payment Title",
          });
        } else if (!this.paymentImage) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Payment Image",
          });
        } else if (!this.bottomLogo) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Bottom Logo",
          });
        } else if (!this.bottomLogoLink) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Bottom Logo Link",
          });
        } else if (!this.backgroundColor) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Background Color",
          });
        } else if (!this.boxFontColor) {
          this.$swal.fire({
            icon: "error",
            text: "Please enter Box Font Color",
          });
        } else {
          let formData = new FormData();
          formData.append("topLogo", this.topLogo);
          formData.append("topLogoLink", this.topLogoLink);
          formData.append("contactIconImage", this.contactIconImage);
          formData.append("contactText", this.contactText);
          formData.append("contactNumber", this.contactNumber);
          formData.append("socialConnectTitle", this.socialConnectTitle);
          formData.append("instagramLink", this.instagramLink);
          formData.append("facebookLink", this.facebookLink);
          formData.append("twitterLink", this.twitterLink);
          formData.append("youTubeLink", this.youTubeLink);
          formData.append("facebookStatus", this.facebookStatus ? 1 : 0);
          formData.append("instagramStatus", this.instagramStatus ? 1 : 0);
          formData.append("twitterStatus", this.twitterStatus ? 1 : 0);
          formData.append("youtubeStatus", this.youtubeStatus ? 1 : 0);
          formData.append("paymentTitle", this.paymentTitle);
          formData.append("paymentImage", this.paymentImage);
          formData.append("bottomLogo", this.bottomLogo);
          formData.append("bottomLogoLink", this.bottomLogoLink);
          formData.append("backgroundColor", this.backgroundColor);
          formData.append("boxFontColor", this.boxFontColor);

          let config = {
            method: "POST",
            url: this.setURL,
            data: formData,
            headers: {
              Authorization: authHeader(),
            },
          };

          await this.createUpdateCMS(config);

          await this.getFooterBrandingCmsData(this.setURL);
          this.cmsValueAssign();
          this.$refs.topLogo.value = "";
          this.$refs.contactIconImage.value = "";
          this.$refs.paymentImage.value = "";
          this.$refs.bottomLogo.value = "";
        }
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          text: "Something went wrong. Please try again! " + error,
        });
      }
    },

    cmsValueAssign: function () {
      if (this.footerBrandingCMS) {
        this.topLogo = this.footerBrandingCMS.topLogo;
        this.previewTopLogo = this.footerBrandingCMS.topLogo;
        this.topLogoLink = this.footerBrandingCMS.topLogoLink;
        this.contactIconImage = this.footerBrandingCMS.contactIconImage;
        this.previewContactIconImage = this.footerBrandingCMS.contactIconImage;
        this.contactText = this.footerBrandingCMS.contactText;
        this.contactNumber = this.footerBrandingCMS.contactNumber;
        this.socialConnectTitle = this.footerBrandingCMS.socialConnectTitle;
        this.instagramLink = this.footerBrandingCMS.instagramLink;
        this.facebookLink = this.footerBrandingCMS.facebookLink;
        this.twitterLink = this.footerBrandingCMS.twitterLink;
        this.youTubeLink = this.footerBrandingCMS.youTubeLink;
        this.facebookStatus = this.footerBrandingCMS.facebookStatus > 0;
        this.instagramStatus = this.footerBrandingCMS.instagramStatus > 0;
        this.twitterStatus = this.footerBrandingCMS.twitterStatus > 0;
        this.youtubeStatus = this.footerBrandingCMS.youtubeStatus > 0;
        this.paymentTitle = this.footerBrandingCMS.paymentTitle;
        this.paymentImage = this.footerBrandingCMS.paymentImage;
        this.previewPaymentImage = this.footerBrandingCMS.paymentImage;
        this.bottomLogo = this.footerBrandingCMS.bottomLogo;
        this.previewBottomLogo = this.footerBrandingCMS.bottomLogo;
        this.bottomLogoLink = this.footerBrandingCMS.bottomLogoLink;
        this.backgroundColor = this.footerBrandingCMS.backgroundColor;
        this.boxFontColor = this.footerBrandingCMS.boxFontColor;
      }
    },

    uploadTopLogo(event) {
      this.topLogo = event.target.files[0];
      let input = this.$refs.topLogo;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewTopLogo = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },

    uploadContactIconImage(event) {
      this.contactIconImage = event.target.files[0];
      let input = this.$refs.contactIconImage;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewContactIconImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },

    uploadPaymentImage(event) {
      this.paymentImage = event.target.files[0];
      let input = this.$refs.paymentImage;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewPaymentImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },

    uploadBottomLogo(event) {
      this.bottomLogo = event.target.files[0];
      let input = this.$refs.bottomLogo;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewBottomLogo = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
  },
};
