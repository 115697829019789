<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Update Product Discount
                        </li>
                    </ol>
                </nav>

                <div class="ms-auto"></div>
            </div>
            <h6 class="mb-0 text-uppercase">Update Product Discount</h6>
            <hr />
            <div class="card">
                <div class="card-body p-4">
                    <div class="row">
                        <div class="col-lg-12 mb-3">
                            <div class="border border-3 p-4 rounded">
                                <div class="row g-3">
                                    <div class="invoice-body">
                                        <div class="row gutters">
                                            <div class="col-6 col-md-3">
                                                <div class="form-group m-3">
                                                    <label class="form-label">Product</label>
                                                    <input type="text" v-model="singleDiscount.productName" class="form-control"
                                                         disabled/>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-3">
                                                <div class="form-group m-3">
                                                    <label class="form-label">User</label>
                                                    <input type="text" v-model="singleDiscount.name" class="form-control"
                                                        placeholder="Enter Price" disabled/>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-3">
                                                <div class="form-group m-3">
                                                    <label class="form-label">Discount Type:</label>
                                                    <select class="form-select mb-1" v-model="singleDiscount.discountType">
                                                        <option value="Amount">Amount</option>
                                                        <option value="Percentage">Percentage</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-3">
                                                <div class="form-group m-3">
                                                    <label class="form-label">Discount Amount/Percents:</label>
                                                    <div class="input-group">
                                                        <span v-if="discountType === 'Amount'" class="input-group-text"
                                                            id="basic-addon1">$</span>
                                                        <span v-if="discountType === 'Percentage'"
                                                            class="input-group-text" id="basic-addon1">%</span>
                                                        <input type="text" class="form-control" min="0"
                                                            v-model="singleDiscount.discountAmount" aria-label="Username"
                                                            aria-describedby="basic-addon1" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="form-group mt-3">
                        <button type="button" class="btn btn-primary" @click="updateDiscount">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/update-product-discount.js"></script>

<style scoped>
    @import "../css/add-product.css";
</style>