import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            setURL: '',
            pageCMSData: []
        }
    },
    async mounted() {
        this.setURL = this.$serverURL + this.$api.cms.helpPageCMS;
        await this.getCmsData(this.setURL);
        if (this.pageCMS) {
            this.pageCMSData = this.pageCMS;
        }
        else {
            this.pageCMSData = [];
        }
    },
    methods: {
        async updateCms() {
            try {
                if (!this.pageCMSData.title || !this.pageCMSData.subTitle || !this.pageCMSData.supportSectionTitle || !this.pageCMSData.supportSectionSubTitle) {
                   
                    this.$swal.fire({
                        icon: "error",
                        text: "Please enter title, subtitle, search text, support section title, support section subtitle"
                    });
                }
                else {

                    let data={
                        title: this.pageCMSData.title,
                        subTitle: this.pageCMSData.subTitle,
                        searchText: this.pageCMSData?.searchText ? this.pageCMSData?.searchText : "",
                        supportSectionTitle: this.pageCMSData.supportSectionTitle,
                        supportSectionSubTitle: this.pageCMSData.supportSectionSubTitle,
    
                    };
                    let config = {
                        method: 'POST',
                        url: this.$serverURL + this.$api.cms.helpPageCMS,
                        data:data,
                        headers: {
                            "Authorization": authHeader(),
                        }
                    };
                    await this.createUpdateCMS(config);
                    await this.getCmsData(this.setURL);
                }
                
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    }
}