import { authHeader } from "../../../../auth";
export default {
    name: "Brand",
    data() {
        return {
            singleTag: {},
            tagName: "",
            setURL: this.$serverURL + this.$api.product.productTag
        }
    },
    async created() {
        document.title = "HGS - Tag";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        addTag: async function () {

            if (!this.tagName) {

                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Brand Name!"
                });
            }
            else {
                let data = {
                    name: this.tagName
                };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.postDataToBackend(config);
                await this.getDataList(this.setURL);
                this.tagName = '';

            }
        },
        editTag: async function (data) {
            this.singleTag = data;
        },
        updateTag: async function () {
            if (!this.singleTag.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Brand Name!"
                });
            }
            else {
                let data = {
                    name: this.singleTag.name
                };
                let config = {
                    method: "PATCH",
                    url: this.setURL + '/' + this.singleTag.id,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.updateDataToBackend(config);
                this.tagName = '';
                await this.getDataList(this.setURL);
            }
        },
        deleteTag: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setURL + '/' + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);
                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! Error!" + error
                        });
                    }
                }
            })

        }
    }
}