<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">Header CMS</div>
        <div class="ps-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/dashboard"
                  ><i class="bx bx-home-alt"></i
                ></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Header CMS
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <form @submit.prevent="updateCms">
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Header CMS</h5>
            <hr />
            <div class="form-body mt-4">
              <div class="row">
                <div class="col-lg-12">
                  <div class="border border-3 p-4 rounded mb-4">
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label"
                        >Company Logo: <strong>[Preferred Image Size: 150X30, Max Upload Limit: 1MB]</strong></label
                      >
                      <input
                        class="form-control mb-2"
                        type="file"
                        name="featured_image"
                        id="featuredIcon"
                        ref="companyLogo"
                        aria-describedby="imageHelp"
                        accept="image/*"
                        @change="uploadLogo($event)"
                      />
                      <img
                        v-if="previewLogo"
                        :src="previewLogo"
                        height="90"
                        width="150"
                      />
                      <img
                        v-else
                        :src="setImageURL + logo"
                        height="90"
                        width="150"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Title One</label>
                      <input
                        type="text"
                        v-model="titleOne"
                        class="form-control"
                        placeholder="Title One"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Title One Link</label>
                      <input
                        type="text"
                        v-model="titleOneLink"
                        class="form-control"
                        placeholder="Title One Link"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Title Two</label>
                      <input
                        type="text"
                        v-model="titleTwo"
                        class="form-control"
                        placeholder="Title Two"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Title Two Link</label>
                      <input
                        type="text"
                        v-model="titleTwoLink"
                        class="form-control"
                        placeholder="Title Two Link"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Store</label>
                      <input
                        type="text"
                        v-model="store"
                        class="form-control"
                        placeholder="Store"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Store Title</label>
                      <input
                        type="text"
                        v-model="storeTitle"
                        class="form-control"
                        placeholder="Store Title"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Store Link</label>
                      <input
                        type="text"
                        v-model="storeLink"
                        class="form-control"
                        placeholder="Store Link"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Help Line Text</label>
                      <input
                        type="text"
                        v-model="helpLineText"
                        class="form-control"
                        id="inputPageHeaderTitle"
                        placeholder="Help Line Text"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductDescription" class="form-label">Help Line Number</label>
                      <input
                        type="tel"
                        class="form-control"
                        id="inputTitle"
                        v-model="helpLineNumber"
                        placeholder="Help Line Number"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Contact Email</label>
                      <input
                        type="text"
                        v-model="contactEmail"
                        class="form-control"
                        placeholder="Contact Email"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Contact Email Title</label>
                      <input
                        type="text"
                        v-model="contactEmailTitle"
                        class="form-control"
                        placeholder="Contact Email Title"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Search Text</label>
                      <input
                        type="text"
                        v-model="searchPlaceholder"
                        class="form-control"
                        id="inputPageHeaderTitle"
                        placeholder="Search Text"
                      />
                    </div>
                    <div class="mb-3">
                      <label class="form-label">Button Text</label>
                      <input
                        type="text"
                        v-model="buttonText"
                        class="form-control"
                        id="inputPageHeaderTitle"
                        placeholder="Button Text"
                      />
                      </div>
                    <div class="mb-3">
                      <label class="form-label">Button Link</label>
                      <input
                        type="text"
                        v-model="buttonLink"
                        class="form-control"
                        id="inputPageHeaderTitle"
                        placeholder="Button Link"
                      />
                    </div>
                    <div class="d-grid">
                      <button
                        type="submit"
                        class="btn btn-primary"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src="../js/header-cms.js"></script>