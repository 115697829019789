<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-9">
          <div class="card overflow-hidden radius-10">
            <div class="profile-cover bg-dark position-relative mb-4">
              <div
                class="
                  user-profile-avatar
                  shadow
                  position-absolute
                  top-50
                  start-0
                  translate-middle-x
                "
              >
                <img
                  src="https://png.pngtree.com/element_our/png_detail/20181206/users-vector-icon-png_260862.jpg"
                  alt="..."
                />
              </div>
            </div>
            <div class="card-body">
              <div
                class="
                  mt-5
                  d-flex
                  flex-column flex-sm-row
                  align-items-start
                  justify-content-between
                  gap-3 gap-sm-0
                "
              >
                <div class="">
                  <h3 class="mb-2">
                    {{ userDetails.firstName + " " + userDetails.lastName }}
                  </h3>
                  <p class="mb-1">
                    <strong>Email:</strong>
                    {{ email }}
                  </p>
                </div>
                <div class="">
                  <a
                    href="javascript:;"
                    class="btn btn_primary d-block mb-2"
                    @click="isEdit = !isEdit"
                  >
                    <i class="bx bx-edit"></i>
                    Edit Profile</a
                  >
                  <a
                    href="javascript:;"
                    class="btn btn_primary"
                    data-bs-toggle="modal"
                    data-bs-target="#change_password"
                  >
                    <i class="bx bxs-key"></i>
                    Change Password</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="isEdit" class="card radius-10">
            <div class="card-body">
              <form>
                <h5 class="mb-3">Edit Profile</h5>
                <!-- <div class="mb-4 d-flex flex-column gap-3 align-items-center justify-content-center">
              <div class="user-change-photo shadow">
                <img src="https://png.pngtree.com/element_our/png_detail/20181206/users-vector-icon-png_260862.jpg" alt="...">
              </div>
              <label for="profile_image" type="button" class="btn btn_outline_primary btn-sm radius-30 px-4">
                <i class='bx bxs-image'></i>
                Change Photo</label>
              <input type="file" class="d-none" id="profile_image" accept=".jpg, .png, .svg">
            </div> -->
                <h5 class="mb-0 mt-4">User Information</h5>
                <hr />
                <div class="row g-3">
                  <div class="col-12 col-sm-6">
                    <label class="form-label">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userDetails.firstName"
                      placeholder="N/A"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userDetails.lastName"
                      placeholder="N/A"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      v-model="userDetails.email"
                      disabled
                      placeholder="N/A"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Mobile Number</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userDetails.mobile"
                      placeholder="N/A"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Gender</label>
                    <select
                      v-model="userDetails.gender"
                      class="form-select mb-3"
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Date of Birth</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="userDetails.dateOfBirth"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Full Address</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userDetails.fullAddress"
                      placeholder="N/A"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">City</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userDetails.city"
                      placeholder="N/A"
                    />
                  </div>

                  <div class="col-12 col-sm-6">
                    <label class="form-label">Postal Code</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="userDetails.postalCode"
                      placeholder="N/A"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">Country</label>
                    <select
                      v-model="country"
                      class="form-select mb-3"
                    >
                      <option style="display: none" selected value="">
                        {{
                          userDetails.country
                            ? userDetails.country
                            : "Select Country."
                        }}
                      </option>
                      <option
                        v-for="(data, index) in countryList"
                        :key="index"
                        :value="data"
                        placeholder="N/A"
                      >
                        {{ data }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="text-start mt-3">
                  <button
                    @click="save"
                    type="button"
                    class="btn btn_primary px-4"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChangePassword />
  </div>
</template>

<script src="../js/profile.js">
</script>

<style scoped>
@import "../css/profile.css";
</style>