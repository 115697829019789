import { authHeader } from "../../../../auth";
export default {
    data() {
        return {
            
            genScriptContent:"",
        }

    },
    async created() {
        document.title = "HGS - Privacy Policy.";
        //await this.getCmsData(this.setURL);
        await this.getGenScriptCms();
    },
    methods: {
        async getGenScriptCms() {
            try {
               

            let url = this.$serverURL + this.$api.policies.genScriptget;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                   console.log(response.data);
                   this.genScriptContent = response.data;
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });

            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        },
        async updateCms() {
            try {
                let data = {
                    scriptContent: this.genScriptContent,
                };
                let config = {
                    method: 'POST',
                    url: this.$serverURL + this.$api.policies.genScriptget,
                    data: data,
                    headers: {
                        "Authorization": authHeader(),
                    }
                };
                await this.createUpdateCMS(config);
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again! " + error
                });
            }
        }
    },
}