<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add New Product Discount For User
            </li>
          </ol>
        </nav>

        <div class="ms-auto"></div>
      </div>
      <h6 class="mb-0 text-uppercase">Product wise user discount</h6>
      <hr />
      <div class="card">
        <div class="card-body p-4" style="min-height: 500px;">
          <div class="row">
            <div class="col-lg-12 mb-3">
                  <table class="table custom-table m-0">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th style="min-width: 150px" scope="col">
                          Product
                        </th>
                        <th style="min-width: 150px" scope="col">
                          Price
                        </th>
                        <th style="min-width: 120px" scope="col">
                          User
                        </th>
                        <th style="min-width: 120px" scope="col">
                          Discount Type
                        </th>
                        <th style="min-width: 120px" scope="col">
                          Discount Amount
                        </th>
                        <th style="min-width: 120px" scope="col">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in addedDiscount" :key="index">
                        <td scope="row">{{ index + 1 }}</td>
                        <td scope="row">
                          <Multiselect v-model="productValue[index]" @select="
                                () => {
                                  productData(
                                    productValue[index],
                                    index
                                  );
                                }
                              " :options="productList" :searchable="true" />
                        </td>
                        <td>{{selectedProduct[index] ? selectedProduct[index].price : "N/A"}}</td>
                        <td>
                          <Multiselect v-model="userValue[index]" @select="
                                () => {
                                  userData(
                                    userValue[index],
                                    index
                                  );
                                }
                              " :options="userList" :searchable="true" />
                        </td>
                        <td>
                          <select required class="form-select" v-model="discountType[index]"
                            @change="selectType(discountType[index], index)">
                            <option disabled selected value="">
                              Select Type
                            </option>
                            <option v-for="(data, index) in types" :key="index" :value="data">
                              {{ data}}
                            </option>
                          </select>
                        </td>
                        <td scope="row">
                          <input type="text" name="" id="" class="form-control" v-model="discountAmount[index]"
                            @change="selectAmount(discountAmount[index], index)" placeholder="Enter Amount">
                        </td>
                        <td>
                          <button :hidden="addedDiscount.length < 2" class="btn btn-danger"
                            @click="removeItem(index)"><i class="bx bx-x text-white"></i></button>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="6">&nbsp;</td>
                        <td style="float: right">
                          <button class="btn btn-sm btn-success" @click="pushData()">
                            Add More
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
            </div>
          </div>

          <div class="form-group mt-3">
            <button type="button" class="btn btn-primary" @click="addDiscount">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="../js/add-product-discount.js"></script>

<style scoped>
  @import "../css/add-product.css";
</style>